import { MessageService } from "primeng/api";
import { EventService } from "./../../../services/event.service";
import { Component, NgModule, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { EventPageInfo } from "src/app/models/event-page-info";
import { MessageServiceService } from "src/app/message.service";
import { EventInfo } from "src/app/models/event-info/event-info";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.css"],
})


export class EventComponent implements OnInit {
  slug: string;
  isLoggedIn: boolean = false;
  isEventApplied: boolean = false;
  eventPageInfo: EventPageInfo = {
    pageHeroImage: "Events-SS.png",
    title: "Matchmaking Events",
    description:
      "We regularly organise matchmaking events in the UK. Follow us on social media to stay up to date.",
    event: new EventInfo(),
  };
  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private Jarvis: AuthService,
    private messageService: MessageServiceService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.slug = params["slug"];
      this.eventService.getEvent(this.slug).subscribe(
        (data) => this.handleEventData(data),
        (error) => this.handleError(error)
      );
      
    });
  }
  handleEventApllied(data) {
    this.isEventApplied = data.data.eventApplied ? true : false;
  
  }

  ngOnInit(): void {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    const authToken = localStorage.getItem('AuthToken');
    this.isLoggedIn = !!authToken; // Convert to boolean
  }
  
  
  handleEventData(data) {
    this.eventPageInfo.event = data;
    this.Jarvis.eventApllied(this.eventPageInfo.event.id).subscribe(
      (data) => this.handleEventApllied(data),
      (error) => this.handleError(error)
    );
  }
  handleError(error) {
    // const errorMessage = error.error.message
    //   ? error.error.message
    //   : error.error.reason
    //   ? error.error.reason
    //   : error.reason;
    // this.messageService.addMessages("error", "Error", errorMessage);
  }
}
