import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";
import { SearchInfo } from "src/app/models/search-info";
import { searchResultPageInfo } from "src/app/models/searchResult-page-info";
import { UserInfo } from "src/app/models/user-info";

@Component({
  selector: "app-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.css"],
})
export class SearchResultComponent implements OnInit {
  isLogin: boolean = true;
  inputInfo: InputInfo[] = [];
  searchedInfo: any;
  searchInfo: SearchInfo = new SearchInfo();
  changeFlag = false;
  counterInputs = 0;
  userInfo: UserInfo = new UserInfo();

  searchResultInfo: searchResultPageInfo = {
    pageHeroImage: "./assets/images/books-hero-image.jpg",
    title: "Search Result",
    searchResultCardData: [
      {
        name: "Saif butt",
        img: "./assets/images/Intersection-3.jpg",
        status: "Offline",
        rating: 3,
        gender: "Men",
        age: 21,
        height: "147 cms",
        address: "UK",
        profession: "Programmer",
      },
      {
        name: "Mina",
        img: "./assets/images/satria-hutama-AFH8fH60T_M-unsplash.jpg",
        status: "Online",
        rating: 4,
        age: 23,
        height: "147 cms",
        gender: "Women",
        address: "Pakistan",
        profession: "Designer",
      },
      {
        name: "Fizan Khalid",
        img: "./assets/images/nith-in-w1N1WmL.png",
        status: "Offline",
        rating: 5,
        age: 19,
        gender: "Men",
        height: "147 cms",
        address: "Pakistan",
        profession: "Manager",
      },
      {
        name: "Reena",
        img: "./assets/images/Intersection-3.jpg",
        status: "Offline",
        rating: 3,
        gender: "Women",
        age: 18,
        height: "147 cms",
        address: "UK",
        profession: "Programmer",
      },
      {
        name: "Mina",
        img: "./assets/images/satria-hutama-AFH8fH60T_M-unsplash.jpg",
        status: "Online",
        rating: 4,
        age: 19,
        height: "147 cms",
        gender: "Women",
        address: "Pakistan",
        profession: "Designer",
      },
      {
        name: "Fizan Khalid",
        img: "./assets/images/nith-in-w1N1WmL.png",
        status: "Offline",
        rating: 5,
        age: 20,
        gender: "Men",
        height: "147 cms",
        address: "Pakistan",
        profession: "Manager",
      },
    ],
  };
  lokfr = "Men";
  lookinFor = [
    {
      name: "Men",
    },
    {
      name: "Women",
    },
  ];
  country = [
    {
      name: "UK",
    },
    {
      name: "Pakistan",
    },
  ];
  age = [
    {
      name: "18",
    },
    {
      name: "19",
    },
    {
      name: "20",
    },
    {
      name: "21",
    },
  ];
  constructor(
    private messageService: MessageServiceService,
    private activatedRouter: ActivatedRoute,
    private Jarwis: AuthService
  ) {
    this.activatedRouter.queryParams.subscribe((params) => {
      this.searchedInfo = params;
      this.searchData();
    });
  }

  ngOnInit(): void {
    this.userInfo.image = "assets/images/Umar.jpg";
    this.userInfo.name = "Muhammad Umar";

    this.generateFormData();
  }
  searchData() {
    // this.Jarwis.searchData(this.searchedInfo).subscribe(
    //   (data) => this.handleSearchData(data),
    //   (error) => this.handleError(error)
    // );
    let data = "success";
    this.handleSearchData(data);
  }
  handleSearchData(data) {
    this.searchResultInfo.searchResultCardData =
      this.searchResultInfo.searchResultCardData.filter((item) => {
        return (
          item.gender == this.lokfr &&
          item.address == this.searchedInfo.country &&
          Number(item.age) >= Number(this.searchedInfo.ageFrom) &&
          Number(item.age) <= Number(this.searchedInfo.ageTo)
        );
      });
  }
  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error && error.error.reason
        ? error.error.reason
        : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }
  generateFormData() {
    this.inputInfo = [];
    this.createInput(
      "Looking For",
      "lookingFor",
      "text",
      "Looking For",
      "myMatches",
      this.searchedInfo["lookingFor"]
        ? { name: this.searchedInfo["lookingFor"] }
        : "",
      this.lookinFor,
      [
        {
          type: Validators.required,
          msg: "You must select Type",
        },
      ]
    );
    this.createInput(
      "Country",
      "country",
      "text",
      "Country",
      "myMatches",
      this.searchedInfo["country"]
        ? { name: this.searchedInfo["country"] }
        : "",

      this.country,
      [
        {
          type: Validators.required,
          msg: "You must select Country",
        },
      ]
    );
    this.createInput(
      "Age From",
      "ageFrom",
      "text",
      "Age From",
      "myMatches",
      this.searchedInfo["ageFrom"]
        ? { name: this.searchedInfo["ageFrom"] }
        : "",

      this.age,
      [
        {
          type: Validators.required,
          msg: "You must select Age",
        },
      ]
    );
    this.createInput(
      "Age To",
      "ageTo",
      "text",
      "Age To",
      "myMatches",
      this.searchedInfo["ageTo"] ? { name: this.searchedInfo["ageTo"] } : "",

      this.age,
      [
        {
          type: Validators.required,
          msg: "You must select Age",
        },
      ]
    );
  }
  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    data,
    validatorsInfo
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.data = data;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }
  updateData(value, modelName) {
    this.searchInfo[modelName] = value["name"];
  }
  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }
  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }
    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }
    this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
    this.searchData();
  }
}
