import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";

@Component({
  selector: "app-delete-account",
  templateUrl: "./delete-account.component.html",
  styleUrls: ["./delete-account.component.css"],
})
export class DeleteAccountComponent implements OnInit {
  deleteInputInfo: any = [];
  changeFlag = false;
  counterInputs = 0;
  deleteOptions = [
    {
      key: "I have found my spouse using ShiaSpouse website.",
      value: "20",
      id: 1,
    },
    {
      key: "I have found my spouse using other channels / websites.",
      value: "21",
      id: 2,
    },
    { key: "I do not like the website.", value: "22", id: 3 },
    { key: "I couldn't find a suitable match.", value: "23", id: 4 },
  ];

  inputInfo: InputInfo[] = [];

  constructor(
    private messageService: MessageServiceService,
    private confirmationService: ConfirmationService,
    private Jarvis: AuthService
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  generateForm() {
    this.inputInfo = [];
    this.createInput(
      ``,
      `delete`,
      `radioButton`,
      ``,
      `radioButton`,
      "",
      this.deleteOptions,
      "",
      "",
      [
        {
          type: Validators.required,
          msg: `You must select one option`,
        },
      ]
    );
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    option,
    data,
    val,
    validatorsInfo
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.option = option;
    inputObj.data = data;
    inputObj.value = value;
    inputObj.val = val;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  updateData(event, modelName) {
    if (event) {
      this.deleteInputInfo[modelName] = event.value;
    } else {
      const foundElement = this.inputInfo.find(
        (el) => el.modelName === "delete"
      );

      if (foundElement) {
        foundElement.errorFlag = true;
      }

      this.messageService.addMessages(
        `error`,
        `Error`,
        "You must select one option "
      );
    }
  }

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }

  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }

    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }

    let formData = new FormData();
    formData.append("state_id", "delete");
    formData.append("reason_id", this.deleteInputInfo.delete);
    formData.append("other_message", "");

    this.confirmationService.confirm({
      message: "Are you sure you want to delete account?",
      accept: () => {
        this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
        this.Jarvis.disableAccount(formData).subscribe(
          (data) => this.handleDeleteAccount(data),
          (error) => this.handleError(error)
        );
      },
      reject: () => {
        this.generateForm();
      },
    });
  }

  handleDeleteAccount(data) {
    this.messageService.addMessages(
      `success`,
      `Success`,
      `Profile Deleted Successfully`
    );
  }

  handleError(error) {}
}
