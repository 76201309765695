import { ActivatedRoute } from "@angular/router";
import { EventService } from "./../../../services/event.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { MessageServiceService } from "src/app/message.service";
import { EventCard } from "src/app/models/events-info.ts/event-card";
import { EventsPageInfo } from "src/app/models/events-page-info";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.css"],
})
export class EventsComponent implements OnInit {
  tabParam: string;
  selectedTab: string;
  activeTab;
  allEvents: EventCard[] = [];
  eventsPageInfo: EventsPageInfo = {
    pageHeroImage: "brandon-morgan-k3QFdmUpDBo-unsplash.jpg",
    title: "Our Upcoming Events",
    description:
      "We regularly organise matchmaking events in the UK. Follow us on social media to stay up to date.",
    eventTabs: [
      // { label: "New Events" },
      { label: "Upcoming Events" },
      { label: "Past Events" },
    ],
    events: [],
  };

  constructor(
    private eventService: EventService,
    private messageService: MessageServiceService,
    private route: ActivatedRoute
  ) {
    this.eventService.getAllEvents().subscribe(
      (data) => this.handleEventsData(data),
      (error) => this.handleError(error)
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.tabParam = params["tab"];
      // if (this.tabParam == "new") {
      //   this.tabSelected("New Events");
      //   this.activeTab = 0;
      // } 
      if (this.tabParam == "upcoming") {
        this.tabSelected("Upcoming Events");
        this.activeTab = 0;
      } else if (this.tabParam == "past") {
        this.tabSelected("Past Events");
        this.activeTab = 1;
      } else {
        this.tabSelected("Upcoming Events");
        this.activeTab = 0;
      }
    });
  }

  tabSelected(event: string) {
    this.getEvents(event);
    this.selectedTab = event;
  }
  getEvents(eventType) {
    let currentDate = new Date();
    this.eventsPageInfo.events = [];
    for (let event of this.allEvents) {

      
      let eventDate = new Date(event.date);
      // if (eventType === "New Events" && eventDate >= currentDate) {
      //   this.eventsPageInfo.events.push(event);
      // } 
       if (eventType === "Upcoming Events" && eventDate > currentDate) {
        this.eventsPageInfo.events.push(event);
      } else if (eventType === "Past Events" && eventDate < currentDate) {
        this.eventsPageInfo.events.push(event);
      }
    }
  }
  handleEventsData(data) {
    console.log("events data ",data)
    this.allEvents = data;
    this.getEvents(this.selectedTab);
  }
  handleError(error) {
    // const errorMessage = error.error.message
    //   ? error.error.message
    //   : error.error.reason
    //   ? error.error.reason
    //   : error.reason;
    // this.messageService.addMessages("error", "Error", errorMessage);
  }
}
