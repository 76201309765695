<div class="settings">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/jonathan-borba-i2gom1zDv5Y-unsplash.jpg"
      alt="hero image"
    />
  </div>

  <div class="settings-main container">
    <div class="row justify-content-between">
      <div class="deactivate-container col-md-5">
        <ng-container *ngIf="userState == 1">
          <app-deactivate-account
            (submited)="accountDeactivated()"
          ></app-deactivate-account>
        </ng-container>
        <ng-container *ngIf="isLoading">
          <div class="setting-loader">
            <p-progressSpinner></p-progressSpinner>
          </div>
        </ng-container>
        <ng-container *ngIf="userState == 5 && !isLoading">
          <div class="account-deactivated">
            <h2>Your account is deactivated.</h2>
            <p>
              Profile is not visible to other users,To activate your account
              please click
            </p>
            <app-button
              [label]="'Active Account'"
              [padding]="'5px 0'"
              [color]="'#fff'"
              [type]="'login'"
              (button)="reactiveAccount()"
              [bgColor]="'var(--color-blue)'"
              [borderRadius]="'35px !important'"
              [fontSize]="'clamp(16px, 4vw, 22px)'"
              [maxWidth]="'400px'"
              [padding]="'1rem 2rem'"
            ></app-button>
          </div>
        </ng-container>
      </div>

      <div class="delete-container col-md-5">
        <app-delete-account></app-delete-account>
      </div>
    </div>
  </div>
</div>
