export class BasicAndLifeStyleInfo {
  id: string;
  age: string;
  dateOfBirth: string;
  maritalStatus: string;
  height: string;
  skinTone: string;
  prayStatus: string;
  childrenStatus: string;
  noOfChildren?: string;
  bodyWeight: string;
  smoke: string;
  contactNo: string;
  bodyType: string;
  workingStatus: string;
  ithnaAshri: string;
  language: string;
}
