<div class="eventRegistration">
  <div class="hero">
    <img class="hero-image" src="./assets/images/events-ss.png" alt="hero image" />
  </div>
  <div class="eventRegistration-main">
    <div class="eventRegistration-heading">
      <h3>Sign Up for the Event</h3>
    </div>
    <ng-container *ngIf="!isLoading && isUserUk && !isEventApplied && isEligible">
      <div class="eventRegistration-main-inner container" *ngIf="this.inputInfo.length > 0">
        <div class="eventRegistration-input row mb-4">
          <div [ngClass]="
              box.modelName === 'income'
                ? 'col-sm-7 col-md-3 mt-4'
                : box.modelName === 'currency'
                ? 'col-sm-5 col-md-3 mt-4'
                : box.modelName === 'aboutMe'
                ? 'col-sm-12 mt-4'
                : 'col-md-6 mt-4'
            " *ngFor="let box of this.inputInfo">
            <app-text-box [inputInfo]="box" [changeFlag]="this.changeFlag"
              *ngIf="box.type == 'profileCreate' || box.type == 'disabled'"
              (updateData)="updateData($event, box.modelName)" [customValidator]="box.customValidator"
              (preSubmit)="preSubmit()" (submit)="onSubmit()" [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
              [borderRadius]="'35px'" [width]="'100%'" [height]="'65px'" [padding]="'0 2rem'"></app-text-box>
            <app-drop-down *ngIf="box.type == 'profileCreateDropdown'" [inputInfo]="box" [width]="'100%'"
              (updateData)="updateData($event, box.modelName, true)" [customValidator]="box.customValidator"
              [changeFlag]="changeFlag" [data]="box.option" [scrollHeight]="
                '200px'
              " (preSubmit)="preSubmit()" (submit)="onSubmit()">
            </app-drop-down>
          </div>
        </div>

        <div class="eventRegistration-members-input" *ngIf="numberOfExtraMembers > 0">
          <div class="members-input-heading">
            <h3>Add members</h3>
            <div>
              <app-button [label]="''" [color]="'#fff'" [type]="'roundedBtnWithIcon'" [bgColor]="
                  addMemberFlag == false
                    ? 'var(--color-tabMenu-bg)'
                    : 'var(--color-pink-bg)'
                " [borderRadius]="'35px !important'" [fontSize]="'clamp(12px, 4vw, 16px)'"
                [padding]="'clamp(.2rem, 3vw, .5rem) !important'"
                [icon]="addMemberFlag == false ? 'pi pi-plus' : 'pi pi-minus'" (button)="addMembers()">
              </app-button>
            </div>
            <div class="members-input-para">
              <p>
                Each user can bring up to
                <span> {{ numberOfExtraMembers }} </span> guests to the event
                for an additional fee of
                <span> {{ feeExtraMember }}£ </span> per guest.
              </p>
            </div>
          </div>
          <ng-container *ngIf="this.membersArray.length > 0 && addMemberFlag">
            <div class="members-input row mb-4 mt-4">
              <div [ngClass]="'dropdownAndBtn mb-4'" *ngFor="let box of this.membersArray; let i = index">
                <app-text-box [inputInfo]="box.name" [changeFlag]="this.changeFlag"
                  *ngIf="box.name.type == 'profileCreate'" (updateData)="
                    updateData($event, box.name.modelName, false, i)
                  " (preSubmit)="preSubmit()" (submit)="onSubmit()" [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                  [borderRadius]="'35px'" [width]="'100%'" [height]="'65px'" [padding]="'0 2rem'"></app-text-box>
                <app-drop-down *ngIf="box.relation.type == 'profileCreateDropdown'" [inputInfo]="box.relation"
                  [width]="'100%'" (updateData)="
                    updateData($event, box.relation.modelName, true, i)
                  " [changeFlag]="changeFlag" [data]="box.relation.option" [scrollHeight]="
                    box.data?.length >= 1 && box.data?.length <= 3
                      ? box.data?.length * 50 + 'px'
                      : '200px'
                  " (preSubmit)="preSubmit()" (submit)="onSubmit()">
                </app-drop-down>
                <app-button [label]="''" *ngIf="box.relation.modelName == 'Relation'" [color]="'#fff'"
                  [type]="'roundedBtnWithIcon'" [bgColor]="
                    membersArray.length == i + 1
                      ? 'var(--color-tabMenu-bg)'
                      : 'var(--color-pink-bg)'
                  " [borderRadius]="'35px !important'" [fontSize]="'clamp(16px, 4vw, 22px)'"
                  [padding]="'clamp(1.8rem, 3vw, 1.8rem)'" [icon]="
                    membersArray.length == i + 1
                      ? 'pi pi-plus-circle'
                      : 'pi pi-minus-circle'
                  " (button)="
                    membersButtonClicked(
                      i,
                      membersArray.length == i + 1 ? 'add' : 'remove'
                    )
                  ">
                </app-button>
              </div>
            </div>
          </ng-container>
        </div>

        <app-button [label]="'Submit'" [padding]="'5px 0'" [color]="'#fff'" [type]="'login'" (button)="preSubmit()"
          [bgColor]="'var(--color-pink-bg)'" [borderRadius]="'35px !important'" [fontSize]="'clamp(16px, 4vw, 22px)'"
          [maxWidth]="'400px'" [padding]="'1rem 2rem'">
        </app-button>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <div class="d-flex w-100 justify-content-center py-4">
        <p-progressSpinner aria-label="Loading"></p-progressSpinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && isEligible && !isUserUk">
      <div class="isUserUk eventRegistration-main-inner">
        <p>
          The event is exclusively accessible to residents of the United
          Kingdom.
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && isUserUk && isEventApplied">
      <div class="isUserUk eventRegistration-main-inner">
        <div class="event-card">
          <app-registration-card [registrationCardData]="eventInfo"></app-registration-card>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && !isEligible">
      <div class="isUserUk eventRegistration-main-inner">
        <p>
          We appreciate your interest, but unfortunately, you do not meet the
          eligibility criteria for this event.
        </p>
      </div>
    </ng-container>
  </div>
</div>