import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { ProfileService } from "src/app/services/profile.service";

@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.css"],
})
export class OtpComponent implements OnInit {
  inputInfo: InputInfo[] = [];
  optInfo: any = {};
  counterInputs = 0;
  changeFlag = false;
  isLoadingFlag = true;
  constructor(
    private messageService: MessageService,
    private Jarvis: AuthService,
    private router: Router,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.sendEmailOtp();
  }
  sendEmailOtp() {
    this.isLoadingFlag = true;
    this.addMessages("info", "Info", "Please Wait OTP is being sent ");
    this.Jarvis.sendEmailToken().subscribe(
      (data) => this.handleEmailOtp(data),
      (error) => this.handleError(error)
    );
  }
  handleEmailOtp(data) {
    this.messageService.clear();
    this.addMessages("success", "Success", data.data.message);
    this.generateFormData();
  }
  generateFormData() {
    this.inputInfo = [];
    this.createInput(
      "",
      "otp",
      "text",
      "profileCreate",
      "Enter Verification Code",
      [
        {
          type: Validators.required,
          msg: "You must enter OTP",
        },
      ]
    );
    this.isLoadingFlag = false;
  }

  createInput(label, modelName, inputType, type, placeHolder, validatorsInfo) {
    let inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.type = type;
    inputObj.placeHolder = placeHolder;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }

  updateData(value, modelName) {
    this.optInfo[modelName] = value;
  }

  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }
    let errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag || !this.optInfo.otp) {
      return;
    }

    this.messageService.clear();

    this.addMessages("info", "Info", "Please Wait...");

    let formData = new FormData();
    formData.append("code", this.optInfo.otp);
    this.Jarvis.verifyEmail(formData).subscribe(
      
      (data) => this.handleVerifyOtp(data),
      (error) => this.handleError(error)
    );
  }

  resendOTP() {
    this.sendEmailOtp();
  }

  Logout() {
    localStorage.removeItem("AuthToken");
    this.router.navigate(['/login']);  // Use parentheses to correctly call the navigate function
  }
  

  handleVerifyOtp(data) {
    console.log('otp data ',data)
    if (data.status == 200) {
      this.messageService.clear();
      this.inputInfo = [];
      this.generateFormData();
      this.addMessages(`success`, `Success`, data.data.message);
      this.router.navigate(["/user"]);
    }
  }
  handleResendOtpCode(data) {
    if (data.status == 1) {
      this.inputInfo = [];
      this.generateFormData();
      this.addMessages(`success`, `Success`, "OTP Resent");
    }
  }

  handleError(error) {
    this.messageService.clear();
    this.addMessages(
      `error`,
      `Error`,
      error.error.errors
        ? error.error.errors.message
        : error.error
        ? error.error.message
        : error.message
    );
    this.inputInfo = [];
    this.generateFormData();
  }

  addMessages(severity, summary, detail) {
    this.messageService.add({
      severity,
      summary,
      detail,
      sticky: true,
    });
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }

  ngOnDestroy(): void {
    // Trigger the function in UserNavigationComponent
    this.profileService.triggerOtpComponentDestroyed();
  }
}
