import { Component, OnInit } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { NgxIntlTelInputComponent } from "ngx-intl-tel-input";
import { Observable } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { CustomValidatorsInfo } from "src/app/component/componentModel/customValidator-info";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";
import { EditProfileInfo } from "src/app/models/editProfile-info";
import { ProfilePageInfo } from "src/app/models/profile-info";
import { ProfileService } from "src/app/services/profile.service";
import { UserProfileConverterService } from "src/app/services/user-profile-converter.service";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.css"],
})
export class EditProfileComponent implements OnInit {
  editProfileInputInfo: any = [];
  editProfileInputCounter: number;
  changeFlag = false;
  counterInputs = 0;
  handleuser = '';

  inputInfo: InputInfo[] = [];
  editProfilePageInfo: ProfilePageInfo = new ProfilePageInfo();
  editProfileInfo = new EditProfileInfo();
  editProfileData: EditProfileInfo = new EditProfileInfo();

  infoObj = {
    label: "",
    inputInfo: [],
  };

  profileForOptions = [];
  areYouShiaOptions = [];
  syedOptions = [];
  educationOptions = [];
  professionOptions = [];
  nationalityOption = [];
  hijabOptions = [];

  booleanConfirmation = [
    { name: `Yes`, value: `1` },
    { name: `No`, value: `0` },
  ];
  genderOption = [];
  maritalOption = [];
  residencyOption = [];
  workingStatus = [];

  currency = [];

  ethnicityOptions = [];

  build = [
    { value: "1", name: "Slim" },
    { value: "2", name: "Athletic" },
    { value: "3", name: "Average" },
    { value: "4", name: "Heavy" },
  ];
  complexion = [
    { value: "1", name: "Fair" },
    { value: "2", name: "Medium" },
    { value: "3", name: "Brown" },
    { value: "4", name: "White" },
    { value: "5", name: "Black" },
    { value: "6", name: "Other" },
  ];
  smoke = [
    { value: "1", name: "Socially" },
    { value: "2", name: "Occasionally" },
    { value: "3", name: "Regularly" },
    { value: "4", name: "Do Not Smoke" },
  ];
  pray_regularly = [
    { value: "1", name: "5 times a day" },
    { value: "2", name: "Occasionally" },
    { value: "3", name: "Never" },
    { value: "4", name: "Do not state" },
  ];

  disability = [
    { key: "No", value: 0 },
    { key: "Yes", value: 1 },
  ];

  languageOptions = [];

  maxDate: Date;

  constructor(
    private messageService: MessageServiceService,
    private Jarvis: AuthService,
    private router: Router,
    private userProfileConverter: UserProfileConverterService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.getOptions();
  }

  getOptions() {
    this.profileService.getAllOptions().subscribe(
      (data) => this.handleOptions(data),
      (error) => this.handleError(error)
    );
  }
  handleOptions(data) {
    this.profileForOptions = [];
    this.areYouShiaOptions = [];
    this.syedOptions = [];
    this.genderOption = [];
    this.maritalOption = [];
    this.residencyOption = [];
    this.workingStatus = [];
    this.currency = [];
    this.ethnicityOptions = [];
    this.educationOptions = [];
    this.professionOptions = [];
    this.nationalityOption = [];
    this.hijabOptions = [];
    this.languageOptions = [];


    this.maxDate = this.calculateMaxDate(16);

    for (let [key, value] of Object.entries(data.data.ProfileOptions)) {
      this.profileForOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.IsShiaOptions)) {
      this.areYouShiaOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.Syed)) {
      this.syedOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.GenderOptions)) {
      this.genderOption.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.MaritalOptions)) {
      this.maritalOption.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.ResidencyOptions)) {
      this.residencyOption.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.WorkingStatusOptions)) {
      this.workingStatus.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.CurrencyList)) {
      this.currency.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.EthnicityOptions)) {
      this.ethnicityOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.HijabOptions)) {
      this.hijabOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.EducationOptions)) {
      this.educationOptions.push({
        value: key,
        name: value.toString(),
      });
    }
    for (let [key, value] of Object.entries(data.data.ProfessionOptions)) {
      this.professionOptions.push({
        value: key,
        name: value.toString(),
      });
    }
    for (let [key, value] of Object.entries(data.data.CountryOptions)) {
      this.nationalityOption.push({
        value: key,
        name: value.toString(),
      });
    }
    for (let [key, value] of Object.entries(data.data.LanguageOptions)) {
      this.languageOptions.push({
        value: key,
        name: value.toString(),
      });
    }
    this.getOwnProfileData();
  }

  getOwnProfileData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    this.initializingData(data);
  }


  initializingData(data) {
    console.log("data", data.data.user?.id);
    this.handleuser = data?.data?.user?.id;
    const userData = data.data.user;
    const profileData = data.data.profile;

    this.editProfilePageInfo = {
      profileHeroImage:
        "./assets/images/jonathan-borba-i2gom1zDv5Y-unsplash.jpg",
      profileImage: userData.profile_file,
      name: userData.full_name,
      profession: "",
      address:
        userData.address != "" ? userData.address : "Address Not Defined",
      city: userData?.city ? userData?.city : "",
      country: userData?.country ? userData?.country : "",
      status:
        userData.email_verified == 1 || userData.is_phone_verify == 1
          ? "Verified"
          : "unverified",
      phone: userData.contact_no,
      description: userData.about_me ? userData.about_me : "",
      facebook_link: "",
      instagram_link: "",
      nickname: userData?.nickname
    };



    this.editProfileData.profile_for = this.profileForOptions.find(
      (el) => el.value == userData.profile_for
    );
    this.editProfileData.first_name = profileData?.first_name;
    this.editProfileData.visible = JSON.parse(profileData?.visible ?? "{}");
    this.editProfileInfo.visible = JSON.parse(profileData?.visible ?? "{}");
    this.editProfileData.middle_name = profileData?.middle_name;
    this.editProfileData.last_name = profileData?.last_name;
    this.editProfileData.nickname = profileData?.nickname;
    this.editProfileData.date_of_birth = userData.date_of_birth;
    this.editProfileData.contact_no = userData.dial_code + userData.contact_no;
    this.editProfileData.gender = this.genderOption.find(
      (el) => el.value == userData.gender
    );
    this.editProfileData.hijab = this.hijabOptions.find(
      (el) => el.value == profileData?.is_wear_hijab
    );
    this.editProfileData.relationship_status = this.maritalOption.find(
      (el) => el.value == profileData?.relationship_status
    );
    this.editProfileData.is_shia = this.areYouShiaOptions.find(
      (el) => el.value == profileData?.is_shia
    );
    this.editProfileData.is_syed = this.syedOptions.find(
      (el) => el.value == profileData?.is_syed
    );

    this.editProfileData.children = profileData?.children
      ? profileData?.children
      : 0;

    if (userData.language) {
      const userDataLanguages = userData.language.split(",");
      this.editProfileData.language = this.languageOptions.filter((option) =>
        userDataLanguages.includes(option.value)
      );
    }

    this.editProfileData.education = this.educationOptions.find(
      (el) => el.value == profileData?.education
    );
    this.editProfileData.profession = this.professionOptions.find(
      (el) => el.value == profileData?.profession
    );
    this.editProfileData.working_status = this.workingStatus.find(
      (el) => el.value == profileData?.working_status
    );
    this.editProfileData.income = profileData?.income;
    this.editProfileData.currency = this.currency.find(
      (el) => el.value == profileData?.currency
    );
    this.editProfileData.facebook = profileData?.fb_link;
    this.editProfileData.instagram = profileData?.insta_link;
    this.editProfileData.city = userData.city;
    this.editProfileData.state = userData.state;
    this.editProfileData.country = userData.country;
    this.editProfileData.nationality = this.nationalityOption.find(
      (el) => el.value == profileData?.nationality
    );
    this.editProfileData.residency = this.residencyOption.find(
      (el) => el.value == profileData?.residency
    );
    this.editProfileData.ethnicity = this.ethnicityOptions.find(
      (el) => el.value == userData.ethnicity
    );
    this.editProfileData.height = profileData?.height;
    this.editProfileData.weight = profileData?.weight;
    this.editProfileData.body_type = this.build.find(
      (el) => el.value == profileData?.body_type
    );
    this.editProfileData.skin_tone = this.complexion.find(
      (el) => el.value == profileData?.skin_tone
    );
    this.editProfileData.smoke = this.smoke.find(
      (el) => el.value == profileData?.smoke
    );
    this.editProfileData.pray_regularly = this.pray_regularly.find(
      (el) => el.value == profileData?.pray_regularly
    );

    this.editProfileData.disability = profileData?.disability == 1 ? 1 : 0;

    if (
      this.editProfileData.disability &&
      this.editProfileData.disability == 1
    ) {
      this.editProfileInfo["disability"] = 1;
    } else {
      this.editProfileInfo["disability"] = 0;
    }

    this.editProfileData.disabilityDetails = profileData?.disability_other_text;
    this.editProfileData.aboutMe = userData.about_me;

    this.generateFormData(data);

  }



  generateFormData(data) {
    this.inputInfo = [];
    this.editProfileInputInfo = [];
    this.editProfileInputCounter = 0;
    this.createInput(
      ``,
      `profile_for`,
      `text`,
      `Profile is for`,
      `profileCreateDropdown`,
      this.editProfileData.profile_for ? this.editProfileData.profile_for : ``,
      this.profileForOptions,
      [
        {
          type: Validators.required,
          msg: `You must enter for whom this profile is for`,
        },
      ]
    );

    this.createInput(
      ``,
      `first_name`,
      `text`,
      `First Name`,
      `profileCreate`,
      this.editProfileData.first_name ? this.editProfileData.first_name : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your first name`,
        },
      ]
    );

    this.createInput(
      ``,
      `middle_name`,
      `text`,
      `Middle Name`,
      `profileCreate`,
      this.editProfileData.middle_name ? this.editProfileData.middle_name : ``,
      [],
      [
        // {
        //   type: Validators.required,
        //   msg: `You must enter your middle name`,
        // },
      ]
    );

    this.createInput(
      ``,
      `last_name`,
      `text`,
      `Last Name`,
      `profileCreate`,
      this.editProfileData.last_name ? this.editProfileData.last_name : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your last name`,
        },
      ]
    );

    this.createInput(
      ``,
      `nickname`,
      `text`,
      `Profile Name others registered users will see`,
      `profileCreate`,
      this.editProfileData.nickname ? this.editProfileData.nickname : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your profile name`,
        },
      ]
    );
    this.createInput(
      ``,
      `date_of_birth`,
      `calender`,
      `Date of Birth`,
      `calender`,
      this.editProfileData.date_of_birth
        ? this.editProfileData.date_of_birth
        : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your date of birth`,
        },
      ], null, null, null, true, this.editProfileData.visible.date_of_birth ?? null
    );

    this.createInput(
      ``,
      `contact_no`,
      `phone`,
      ``,
      `profileCreate`,
      this.editProfileData.contact_no ? this.editProfileData.contact_no : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your phone number`,
        }
      ]
    );

    this.createInput(
      ``,
      `gender`,
      `text`,
      `Gender`,
      `profileCreateDropdown`,
      this.editProfileData.gender ? this.editProfileData.gender : ``,
      this.genderOption,
      [
        {
          type: Validators.required,
          msg: `You must enter your gender`,
        },
      ],
      false
    );

    if (data.data.user.gender == 2) {
      this.createInput(
        ``,
        `hijab`,
        `text`,
        `Do you regularly wear Hijab (cover head)?`,
        `profileCreateDropdown`,
        this.editProfileData.hijab ? this.editProfileData.hijab : ``,
        this.hijabOptions,
        [
          // {
          //   type: Validators.required,
          //   msg: `You must enter your gender`,
          // },
        ]
      );
    }

    this.createInput(
      ``,
      `relationship_status`,
      `text`,
      `Marital Status`,
      `profileCreateDropdown`,
      this.editProfileData.relationship_status
        ? this.editProfileData.relationship_status
        : ``,
      this.maritalOption,
      [
        {
          type: Validators.required,
          msg: `You must enter your marital status`,
        },
      ]
    );

    if (this.editProfileData.relationship_status?.value != 0) {
      this.createInput(
        ``,
        `children`,
        `number`,
        `How many children you have?`,
        `profileCreate`,
        this.editProfileData.children ? this.editProfileData.children : ``,
        "",
        [
          // {
          //   type: this.numberLessThan,
          //   msg: `You must enter valid number of children`,
          // },
        ]
      );
    }

    this.createInput(
      ``,
      `is_shia`,
      `text`,
      `Are you Shia Ithna Ashari (believe in 12 Imams)`,
      `profileCreateDropdown`,
      this.editProfileData.is_shia ? this.editProfileData.is_shia : ``,
      this.booleanConfirmation,
      [
        {
          type: Validators.required,
          msg: `You must enter your believe`,
        },
      ]
    );
    this.createInput(
      ``,
      `is_syed`,
      `text`,
      `Syed?`,
      `profileCreateDropdown`,
      this.editProfileData.is_syed ? this.editProfileData.is_syed : ``,
      this.syedOptions,
      [
        {
          type: Validators.required,
          msg: `You must enter your cast`,
        },
      ], null, null, null, true, this.editProfileData.visible.is_syed ?? null
    );



    this.createInput(
      "",
      "language",
      "multi",
      "Language",
      "profileCreateMulti",
      this.editProfileData.language ? this.editProfileData.language : "",
      this.languageOptions,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ],
      "",
      "",
      this.languageOptions
    );

    this.createInput(
      ``,
      `education`,
      `text`,
      `Education`,
      `profileCreateDropdown`,
      this.editProfileData.education ? this.editProfileData.education : ``,
      this.educationOptions,
      [
        {
          type: Validators.required,
          msg: `You must enter your education`,
        },
      ], null, null, null, true, this.editProfileData.visible.education ?? null
    );
    this.createInput(
      ``,
      `profession`,
      `text`,
      `Profession`,
      `profileCreateDropdown`,
      this.editProfileData.profession ? this.editProfileData.profession : ``,
      this.professionOptions,
      [
        {
          type: Validators.required,
          msg: `You must enter your profession`,
        },
      ], null, null, null, true, this.editProfileData.visible.profession ?? null
    );

    this.createInput(
      ``,
      `working_status`,
      `text`,
      `Working Status`,
      `profileCreateDropdown`,
      this.editProfileData.working_status
        ? this.editProfileData.working_status
        : ``,
      this.workingStatus,
      [], null, null, null, true, this.editProfileData.visible.working_status ?? null
    );

    this.createInput(
      ``,
      `income`,
      `number`,
      `Income`,
      `profileCreate`,
      this.editProfileData.income ? this.editProfileData.income : ``,
      ``,
      [], null, null, null, true, this.editProfileData.visible.income ?? null
    );

    this.createInput(
      ``,
      `currency`,
      `text`,
      `Currency`,
      `profileCreateDropdown`,
      this.editProfileData.currency ? this.editProfileData.currency : ``,
      this.currency,
      [], null, null, null, true, this.editProfileData.visible.currency ?? null
    );
    // social
    // this.createInput(
    //   ``,
    //   `facebook`,
    //   `text`,
    //   `Facebook Profile`,
    //   `profileCreate`,
    //   this.editProfileData.facebook ? this.editProfileData.facebook : ``,
    //   [],
    //   [
    //     {
    //       type: Validators.pattern(
    //         "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    //       ),
    //       msg: "Invalid url",
    //       name: "pattern",
    //     },
    //   ]
    // );
    // this.createInput(
    //   ``,
    //   `instagram`,
    //   `text`,
    //   `Instagram Profile`,
    //   `profileCreate`,
    //   this.editProfileData.instagram ? this.editProfileData.instagram : ``,
    //   [],
    //   [
    //     {
    //       type: Validators.pattern(
    //         "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    //       ),
    //       msg: "Invalid url",
    //       name: "pattern",
    //     },
    //   ]
    // );
    // Location

    this.createInput(
      ``,
      `city`,
      `text`,
      `City`,
      `profileCreate`,
      this.editProfileData.city ? this.editProfileData.city : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your city`,
        },
      ]
    );
    this.createInput(
      ``,
      `state`,
      `text`,
      `State/County/Province`,
      `profileCreate`,
      this.editProfileData.state ? this.editProfileData.state : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your State/County/Province`,
        },
      ]
    );
    this.createInput(
      ``,
      `country`,
      `text`,
      `Country`,
      `profileCreate`,
      this.editProfileData.country ? this.editProfileData.country : ``,
      ``,
      [
        {
          type: Validators.required,
          msg: `You must enter your country`,
        },
      ]
    );
    this.createInput(
      ``,
      `nationality`,
      `text`,
      `Nationality`,
      `profileCreateDropdown`,
      this.editProfileData.nationality ? this.editProfileData.nationality : ``,
      this.nationalityOption,
      [
        {
          type: Validators.required,
          msg: `You must enter your nationality`,
        },
      ]
    );
    this.createInput(
      ``,
      `residency`,
      `text`,
      `Residency Status`,
      `profileCreateDropdown`,
      this.editProfileData.residency ? this.editProfileData.residency : ``,
      this.residencyOption,
      [
        {
          type: Validators.required,
          msg: `You must enter your residency status`,
        },
      ]
    );
    this.createInput(
      ``,
      `ethnicity`,
      `text`,
      `Ethnicity/Culture`,
      `profileCreateDropdown`,
      this.editProfileData.ethnicity ? this.editProfileData.ethnicity : ``,
      this.ethnicityOptions,
      []
    );

    // Other Details
    this.createInput(
      ``,
      `height`,
      `number`,
      `Height (cm)`,
      `profileCreate`,
      this.editProfileData.height ? this.editProfileData.height : ``,
      [{ type: 'min', value: 3, message: 'Height must be at least 3 feet.' }, { type: 'max', value: 12, message: 'Height must be at most 12 feet.' }],
      [], null, null, null, true, this.editProfileData.visible.height ?? null
    );

    // Method to convert height from centimeters to feet

    this.createInput(
      ``,
      `weight`,
      `number`,
      `Weight(kgs)`,
      `profileCreate`,
      this.editProfileData.weight ? this.editProfileData.weight : ``,
      [{ type: 'min', value: 20, message: 'Weight must be at least 20 kg.' }, { type: 'max', value: 150, message: 'Weight must be at most 150 kg.' }],
      [], null, null, null, true, this.editProfileData.visible.weight ?? null
    );
    this.createInput(
      ``,
      `body_type`,
      `text`,
      `Build`,
      `profileCreateDropdown`,
      this.editProfileData.body_type ? this.editProfileData.body_type : ``,
      this.build,
      [], null, null, null, true, this.editProfileData.visible.body_type ?? null
    );
    this.createInput(
      ``,
      `skin_tone`,
      `text`,
      `Complexion`,
      `profileCreateDropdown`,
      this.editProfileData.skin_tone ? this.editProfileData.skin_tone : ``,
      this.complexion,
      [], null, null, null, true, this.editProfileData.visible.skin_tone ?? null
    );

    this.createInput(
      ``,
      `smoke`,
      `text`,
      `Smoke`,
      `profileCreateDropdown`,
      this.editProfileData.smoke ? this.editProfileData.smoke : ``,
      this.smoke,
      [], null, null, null, true, this.editProfileData.visible.smoke ?? null
    );
    this.createInput(
      ``,
      `pray_regularly`,
      `text`,
      `Do you pray regularly?`,
      `profileCreateDropdown`,
      this.editProfileData.pray_regularly
        ? this.editProfileData.pray_regularly
        : ``,
      this.pray_regularly,
      [], null, null, null, true, this.editProfileData.visible.pray_regularly ?? null
    );

    // About Myself
    this.createInput(
      `Disablity`,
      `disability`,
      ``,
      `Disability`,
      `radio`,
      this.editProfileData.disability,
      this.disability,
      [], null, null, null, true, this.editProfileData.visible.disability ?? null
    );

    this.createInput(
      ``,
      `disabilityDetails`,
      `textArea`,
      `Please state disability details`,
      `text`,
      this.editProfileData.disabilityDetails
        ? this.editProfileData.disabilityDetails
        : ``,
      [],
      [
        // {
        //   type: Validators.required,
        //   msg: `You must enter disability Details`,
        // },
      ],
      "",
      this.editProfileData.disabilityDetails
        ? this.editProfileData.disabilityDetails
        : ""
    );

    this.createInput(
      ``,
      `aboutMe`,
      `textArea`,
      `About Me`,
      `textArea`,
      this.editProfileData.aboutMe ? this.editProfileData.aboutMe : ``,
      [],
      [],
      "",
      this.editProfileData.aboutMe ? this.editProfileData.aboutMe : ""
    );
  }

  numberLessThan(field1: AbstractControl): Validators {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const f1 = field1.value;
      if (parseFloat(f1) < 0) {
        f1.setValue(0);
      }
      return null;
    };
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    option,
    validatorsInfo,
    disabled?,
    messageBody?,
    data?,
    hiddenCheckBox?,
    hideValue?
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.option = option;
    inputObj.validatorsInfo = validatorsInfo;
    inputObj.disabled = disabled;
    inputObj.messageBody = messageBody;
    inputObj.data = option;
    inputObj.hiddenCheckBox = hiddenCheckBox;
    inputObj.hideValue = hideValue;
    this.inputInfo.push(inputObj);

    if (this.editProfileInputCounter == 0) {
      this.infoObj.label = "Personal Information";
      this.infoObj.inputInfo.push(inputObj);
    }
    if (this.editProfileInputCounter == 1) {
      this.infoObj.label = "Education & Career";
      this.infoObj.inputInfo.push(inputObj);
    }
    if (this.editProfileInputCounter == 2) {
      this.infoObj.label = "Location";
      this.infoObj.inputInfo.push(inputObj);
    }
    if (this.editProfileInputCounter == 3) {
      this.infoObj.label = "Physical Details";
      this.infoObj.inputInfo.push(inputObj);
    }
    if (this.editProfileInputCounter == 4) {
      this.infoObj.label = "Other Details";
      this.infoObj.inputInfo.push(inputObj);
    }

    if (modelName == 'hijab') {
      var obj = this.editProfileInputInfo.find(a => a.label == 'Personal Information');
      if (obj) {
        const index = obj.inputInfo.findIndex(item => item.modelName === 'gender');
        if (index !== -1) {
          // Insert the new item after the found index
          if (!obj.inputInfo.some(a => a.modelName === 'hijab'))
            obj.inputInfo.splice(index + 1, 0, inputObj);
        }

      }
    }

    if (modelName == 'children') {
      var obj = this.editProfileInputInfo.find(a => a.label == 'Personal Information');
      if (obj) {
        const index = obj.inputInfo.findIndex(item => item.modelName === 'relationship_status');
        if (index !== -1) {
          // Insert the new item after the found index
          if (!obj.inputInfo.some(a => a.modelName === 'children'))
            obj.inputInfo.splice(index + 1, 0, inputObj);
        }
      }


    }

    if (modelName == "language") {
      this.editProfileInputCounter++;
      this.editProfileInputInfo.push(this.infoObj);
      this.infoObj = {
        label: "",
        inputInfo: [],
      };
    } else if (modelName == "currency") {
      this.editProfileInputCounter++;
      this.editProfileInputInfo.push(this.infoObj);
      this.infoObj = {
        label: "",
        inputInfo: [],
      };
    } else if (modelName == "instagram") {
      this.editProfileInputCounter++;
      this.editProfileInputInfo.push(this.infoObj);
      this.infoObj = {
        label: "",
        inputInfo: [],
      };
    } else if (modelName == "ethnicity") {
      this.editProfileInputCounter++;
      this.editProfileInputInfo.push(this.infoObj);
      this.infoObj = {
        label: "",
        inputInfo: [],
      };
    } else if (modelName == "aboutMe") {
      this.editProfileInputCounter++;
      this.editProfileInputInfo.push(this.infoObj);
      this.infoObj = {
        label: "",
        inputInfo: [],
      };
    }
  }

  // userValueToString(label: string, value: string): Observable<string> {
  //   return this.userProfileConverter.convertToText(label, value);
  // }
  hideData(value, modelName) {
    if (!this.editProfileInfo['visible']) {
      this.editProfileInfo['visible'] = {} as any;
    }
    this.editProfileInfo['visible'][modelName] = value;
  }
  updateData(value, modelName, dropDown) {
    this.editProfileInfo[modelName] =
      dropDown && dropDown == true
        ? value?.value
        : modelName == "disability"
          ? value.value
          : value;

    if (modelName == "contact_no" && value != null && value != "") {
      try {
        this.editProfileInfo[modelName] = value.e164Number.replace(value.dialCode, '').replace(/[^0-9]/g, '');
        this.editProfileInfo['dial_code'] = value.dialCode;
      } catch (error) {
        this.editProfileInfo[modelName] = value;
        this.editProfileInfo['dial_code'] = '';
      }

    }

    if (modelName == "disabilityDetails" || modelName == "disability") {
      let disability_details_input = this.inputInfo.find(
        (input) => input.modelName == "disabilityDetails"
      );
      if (modelName == "disabilityDetails" && value == "") {
        let obj = new CustomValidatorsInfo();
        obj.msg = "You must enter disability Details";
        obj.activeName = "empty";
        disability_details_input.customValidator = obj;
      } else if (disability_details_input.customValidator) {
        disability_details_input.customValidator.msg = "";
        disability_details_input.customValidator.activeName = "";
      }
      if (modelName == "disability" && value == 0) {
        if (disability_details_input.customValidator) {
          disability_details_input.customValidator.msg = "";
          disability_details_input.customValidator.activeName = "";
          disability_details_input.errorFlag = false;
        }
      }
    }

    if (modelName == "gender" && value != null && value != "") {
      var obj = this.editProfileInputInfo.find(a => a.label == 'Personal Information');
      if (this.editProfileInfo[modelName] == "2") {
        if (!obj.inputInfo.some(a => a.modelName == 'hijab')) {
          this.createInput(
            ``,
            `hijab`,
            `text`,
            `Do you regularly wear Hijab (cover head)?`,
            `profileCreateDropdown`,
            this.editProfileData.hijab ? this.editProfileData.hijab : ``,
            this.hijabOptions,
            [
              // {
              //   type: Validators.required,
              //   msg: `You must enter your gender`,
              // },
            ]
          );
        }
      } else {

        obj.inputInfo = obj.inputInfo.filter(
          a => a.modelName != 'hijab'
        );
        this.inputInfo = this.inputInfo.filter(
          a => a.modelName != 'hijab'
        );


      }

    }


    if (modelName == "relationship_status" && value != null && value != "") {
      let model_value = this.inputInfo.find(
        (input) => input.modelName == modelName);
      var obj = this.editProfileInputInfo.find(a => a.label == 'Personal Information');
      if (this.editProfileInfo[modelName] == "0") {
        this.editProfileData.children = 0;

      }
      if (this.editProfileInfo[modelName] != "0") {
        if (!obj.inputInfo.some(a => a.modelName == 'children')) {
          this.createInput(
            ``,
            `children`,
            `number`,
            `How many children you have?`,
            `profileCreate`,
            this.editProfileData.children ? this.editProfileData.children : ``,
            "",
            [
              // {
              //   type: this.numberLessThan,
              //   msg: `You must enter valid number of children`,
              // },
            ]
          );
        }

      } else {
        obj.inputInfo = obj.inputInfo.filter(a => a.modelName != 'children');
        this.inputInfo = this.inputInfo.filter(
          a => a.modelName != 'children'
        );
      }

    }


  }

  calculateMaxDate(minimumAge: number): Date {
    const today = new Date();
    return new Date(
      today.getFullYear() - minimumAge,
      today.getMonth(),
      today.getDate()
    );
  }

  preSubmit() {
    this.counterInputs = 0;

    this.counterInputs++;
    this.changeFlag = !this.changeFlag;
  }

  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error && error.error.reason
          ? error.error.reason
          : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }

  formatDate(dateString: any): any {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }



  handleAddress($event: Address) {
    let cityControl = this.inputInfo.find(a => a.modelName == 'city');
    let countryControl = this.inputInfo.find(a => a.modelName == 'country');
    let countryObj = $event.address_components.find(a => a.types.includes('country'));
    let stateControl = this.inputInfo.find(a => a.modelName == 'state');
    cityControl?.control.setValue($event.name);
    countryControl?.control.setValue(countryObj.long_name);
    let stateObj = $event.address_components.find(a => a.types.includes('administrative_area_level_1'));
    // if (countryObj && (countryObj.short_name == 'England' || countryObj.short_name == 'ENGLAND' || countryObj.long_name == 'United Kingdom')) {
    //   stateObj = $event.address_components.find(a => a.types.includes('administrative_area_level_1') || a.types.includes('administrative_area_level_2'));
    // }
    stateControl?.control.setValue(stateObj.long_name);
  }

  onSubmit() {

    this.counterInputs++;

    if (
      this.counterInputs <
      this.inputInfo.length + this.editProfileInfo["disability"]
    ) {
      return;
    }
    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }
    let languageValues =
      this.editProfileInfo.language.length > 0
        ? this.editProfileInfo.language.map((language) => language.value)
        : null;

    this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
    let formatDate = this.formatDate(this.editProfileInfo.date_of_birth);
    let submittedData = {
      User: {
        profile_for: this.editProfileInfo.profile_for,
        date_of_birth: formatDate,
        gender: this.editProfileInfo.gender,
        city: this.editProfileInfo.city,
        state: this.editProfileInfo.state,
        country: this.editProfileInfo.country,
        contact_no: this.editProfileInfo.contact_no,
        dial_code: this.editProfileInfo.dial_code,
        full_name: `${this.editProfileInfo.first_name.trim()} ${this.editProfileInfo.last_name.trim()}`,
        about_me: this.editProfileInfo.aboutMe,
        language: languageValues,
        ethnicity: this.editProfileInfo.ethnicity,
      },
      UserProfile: {
        first_name: this.editProfileInfo.first_name,
        middle_name: this.editProfileInfo.middle_name,
        last_name: this.editProfileInfo.last_name,
        nickname: this.editProfileInfo.nickname,
        education: this.editProfileInfo.education,
        profession: this.editProfileInfo.profession,
        income: this.editProfileInfo.income,
        currency: this.editProfileInfo.currency,
        nationality: this.editProfileInfo.nationality,
        gender: this.editProfileInfo.gender,
        is_shia: this.editProfileInfo.is_shia,
        is_syed: this.editProfileInfo.is_syed,
        residency: this.editProfileInfo.residency,
        relationship_status: this.editProfileInfo.relationship_status,
        height: this.editProfileInfo.height,
        weight: this.editProfileInfo.weight,
        skin_tone: this.editProfileInfo.skin_tone,
        body_type: this.editProfileInfo.body_type,
        working_status: this.editProfileInfo.working_status,
        smoke: this.editProfileInfo.smoke,
        disability: this.editProfileInfo.disability,
        disability_other_text: this.editProfileInfo.disabilityDetails,
        fb_link: this.editProfileInfo.facebook,
        insta_link: this.editProfileInfo.instagram,
        pray_regularly: this.editProfileInfo.pray_regularly,
        is_wear_hijab: this.editProfileInfo.hijab,
        children: this.editProfileInfo.children ?? 0,
        visible: this.editProfileInfo.visible,
      },
    };

    this.Jarvis.editProfile(submittedData).subscribe(
      (data) => this.handleEditData(data),
      (error) => this.handleError(error)
    );
  }

  handleEditData(data) {
    if (data) {
      this.profileService.triggerProfileImageChange();
    }
    this.messageService.addMessages(
      `success`,
      `Success`,
      `Profile Updated Successfully`
    );
    this.router.navigateByUrl(`/user/${this.handleuser}`);
    this.getOptions();
  }
}
