import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { SubscriptionCard } from "src/app/models/subscription-info/subscription-card";
import { SubscriptionInfo } from "src/app/models/subscription-page-info";
import { MessageServiceService } from "src/app/message.service";
import { AuthService } from "src/app/auth/auth.service";
import { Validators } from "@angular/forms";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.css"],
})
export class SubscriptionComponent implements OnInit {
  inputInfo: InputInfo[] = [];
  isLoggedIn = false;
  submitFlag = false;
  counterInputs = 0;
  changeFlag = false;
  subsData: SubscriptionCard;
  subscriptionpageinfo: SubscriptionInfo = {
    title: "Choose your plan",
    subsCardData: [],
  };
  subscribedFlag: boolean;
  subscribedIData = {};
  subscriptionPlansCopy;
  isBrowser: Boolean = false;
  showPromoCode: boolean = false;
  promoCode: string | null = null;
  subscriptionPrice: string | null = null;
  planId: string | null = null;
  countryLocation: string | null = null;

  @ViewChild('codeInput') codeInput!: ElementRef;
  constructor(
    private messageService: MessageServiceService,
    private Jarvis: AuthService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // setPromoCode(promoCode: string) {
  //   if(!this.subscriptionPrice){
  //     alert('Please select plan first');
  //   }
    
  //   else if(this.subscriptionPrice && promoCode.length){
  //     this.promoCode = promoCode
  //     let apiObject = {
  //       promo_code:promoCode, 
  //       amount:this.subscriptionPrice
  //     }

  //     this.messageService.addMessages("warn", "Warning", "Please Wait...");
  //     this.Jarvis.applyPromoCode(apiObject).subscribe(
  //       (data:any) => {
  //         if(data.status == "NOK") {
  //           this.messageService.addMessages("error", "Error", "Invalid Promo Code");
  //         }
  //         else{
  //           this.messageService.addMessages("success", "Success", "Promo Code Applied Successfully");
  //           this.subscriptionPrice = data.mainDiscntdAmonut;
  //           let subspripeApi = {
  //             id: this.planId,
  //             subsPrice:data.mainDiscntdAmonut
  //           } 
  //           this.promoApplied(data.mainDiscntdAmonut)
            

  //           this.showPromoCode = !this.showPromoCode;
  //         }
  //       },
  //       (error) => this.handleError(error)
  //     );

  //     promoApplied(discountValue:any){
  //       console.log('discountValue ',discountValue)
  //     }

  //     console.log('subscription price ',this.subscriptionPrice)
  //     console.log('promo code ',promoCode)
  //     console.log('plan id ',this.planId)

      
  //     this.codeInput.nativeElement.value = '';
  //     this.subscriptionPrice = '';
  //     this.planId =''
  //   }else{
  //     alert('Please Add the promo code');

  //   }

  // }
  setPromoCode(promoCode: string) {
    if (!this.subscriptionPrice) {
      alert('Please select plan first');
    } else if (this.subscriptionPrice && promoCode.length) {
      this.promoCode = promoCode;
      const apiObject = {
        promo_code: promoCode,
        amount: this.subscriptionPrice
      };

      this.messageService.addMessages( "warn", "Warning", "Please Wait..." );

      this.Jarvis.applyPromoCode(apiObject).subscribe(
        (data: any) => {
          if (data.status === "NOK") {
            this.messageService.addMessages( "error", "Error", "Invalid Promo Code" );
          } else {
            this.handlePromoSuccess(data);
          }
        },
        (error) => this.handleError(error)
      );
    } else {
      alert('Please Add the promo code');
    }
  }

  handlePromoSuccess(data: any) {
    this.messageService.addMessages( "success", "Success", "Promo Code Applied Successfully" );
    this.subscriptionPrice = data.mainDiscntdAmonut;

    // const subspripeApi = {
    //   id: this.planId,
    //   subsPrice: data.mainDiscntdAmonut
    // };

    this.promoApplied(data.mainDiscntdAmonut);
    this.showPromoCode = !this.showPromoCode;

    this.resetFields();
  }

  promoApplied(discountValue: any) {
    let promokeys = {
      subsPrice: discountValue,
      id: this.planId,
    }
    // this.subscriptionPrice = discountValue;
    this.radioParent(promokeys)

    // console.log('Discount value:', discountValue);
    // console.log('Subscription price:', this.subscriptionPrice);
    // console.log('Promo code:', this.promoCode);
    // console.log('Plan ID:', this.planId);
  }

  handleError(error: any) {
    console.error('Error applying promo code:', error);
    this.messageService.addMessages( "error", "Error",  "Failed to apply promo code" );
  }

  resetFields() {
    if (this.codeInput) {
      this.codeInput.nativeElement.value = '';
    }
    this.subscriptionPrice = '';
    this.planId = '';
    this.promoCode = '';
  }

  togglePromoForm() {
    this.showPromoCode = !this.showPromoCode;
  }


  ngOnInit(): void {
    this.Jarvis.subscriptionPlans().subscribe(
      (data) => this.handleSubscriptionPlans(data),
      (error) => this.handleError(error)
    );

    // get location details 
    this.Jarvis.getLocationDetails().subscribe(
     (data)=>{
        this.countryLocation = data?.country
     } 
    )
  }

  handleSubscriptionPlans(data) {
    this.inputInfo = [];
    this.subscriptionPlansCopy = data;
    for (let info of data) {
      this.subscriptionpageinfo.subsCardData.push({
        subsTitle:
          info.title == "Premium Subscription" ? "Premium Plan" : "Basic Plan",
        subsPara:
          info.title == "Premium Subscription"
            ? "Go premium with Shia Spouse"
            : "Best to start with Shia Spouse",
        subsPrice: "0",
        subsradio: new InputInfo(),
        subsCardPara: [
          { papraOne: "View chat message sent by other members" },
          { papraOne: `Connection limit - ${info.connection} per month` },
          {
            papraOne: `${info.messages_per_month} messages/month for each profile`,
          },
          { papraOne: "Auto-renewable - off" },
        ],
      });
      const membershipsArray = [];

      for (const membership of info.memberships) {
        membershipsArray.push({
          key: `£${membership.amount} for ${membership.duration} month/s`,
          value: membership.amount.toString(),
          id: membership.id,
        });
      }

      this.createInput(
        ``,
        info.title == `Premium Subscription` ? `PremiumPlan` : `BasicPlan`,
        `radioButton`,
        ``,
        `radioButton`,
        "",
        membershipsArray,
        "",
        "",
        [
          // {
          //   type: Validators.required,
          //   msg: `You must select one option`,
          // },
        ]
      );
    }
    this.Jarvis.mySubscriptionPlan().subscribe(
      (data) => this.handleMySubscriptionPlan(data),
      (error) => this.handleError(error)
    );
  }

  
  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    option,
    data,
    val,
    validatorsInfo
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.option = option;
    inputObj.data = data;
    inputObj.value = value;
    inputObj.val = val;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  

  handleMySubscriptionPlan(data) {
    this.subscribedFlag = false;

    if (data.membership_id) {
      this.subscribedFlag = true;
      let subscribedPlan = this.subscriptionPlansCopy.find((el) =>
        el.memberships.some((el) => el.id == data.membership_id)
      );
      this.subscribedIData = {
        title: subscribedPlan.title,
        description: `You are subscribed to ${subscribedPlan.title} of  ${
          subscribedPlan.memberships.find((el) => el.id == data.membership_id)
            .duration
        }/ Month(s) from date ${data.start_date} to ${data.end_date}`,
      };
    } else {
    }
  }

  updateData(event: any){
    
    this.subscriptionPrice = event.subsPrice
    this.planId = event.id;
  }

  radioParent(event: any) {

    this.subscriptionPrice = event.subsPrice;
    if (event.id) {
      let apiObject = {
        radio_3: event.subsPrice,
        plan: event.id,
      };
      
      this.messageService.addMessages("warn", "Warning", "Please Wait...");
      this.Jarvis.subscription(apiObject).subscribe(
        (data) => this.handleRadioData(data),
        (error) => this.handleError(error)
      );
    } else {
      this.messageService.addMessages(
        "info",
        "Info",
        "Please select an option"
      );
    }
  }
  handleRadioData(data) {
    this.messageService.addMessages("success", "Success", "Make payment to complete subcription");
    if (this.isBrowser) {
      window.open(data, "_self");
    }
  }
  // handleError(error) {
  //   const msg = error.error ? error.error : error.message;
  //   this.messageService.addMessages("error", "Error", msg);
  // }
}
