import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { MessageServiceService } from "src/app/message.service";
import { NotificationPageInfo } from "src/app/models/notification-page-info";

@Component({
  selector: "app-notification-card",
  templateUrl: "./notification-card.component.html",
  styleUrls: ["./notification-card.component.css"],
})
export class NotificationCardComponent implements OnInit {
  @Input() cardData: NotificationPageInfo;

  @Output() updateData = new EventEmitter<string>();

  constructor(private messageService: MessageServiceService,
    private Jarvis: AuthService,) { }

  ngOnInit(): void { }


  onButtonClick(buttonName, id?) {

    if (buttonName === "accept-request") {
      const data = {
        id: id,
        state: 1,
      };
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);

      this.Jarvis.acceptOrReject(data).subscribe(
        (data) => this.handleAcceptOrReject(data, "accept"),
        (error) => this.handleError(error)
      );
    }

    if (buttonName === "reject-request") {
      const data = {
        id: id,
        state: 2,
      };
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
      this.Jarvis.acceptOrReject(data).subscribe(
        (data) => this.handleAcceptOrReject(data, "reject"),
        (error) => this.handleError(error)
      );
    }
  }

  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error.errors.error
          ? error.error.errors.error
          : error.error.errors && error.error.errors.message
            ? error.error.errors.message
            : error.error && error.error.reason
              ? error.error.reason
              : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }

  handleAcceptOrReject(data, from) {
    if (from == "accept") {
      if (data.data.msg == "request successfully.") {
        this.messageService.addMessages(
          `success`,
          `Success`,
          "Request has been accepted"
        );
      }
    }
    if (from == "reject") {
      if (data.data.msg == "request successfully.") {
        this.messageService.addMessages(
          `success`,
          `Success`,
          "Request has been rejected"
        );
      }
    }
    this.updateData.emit('update');
  }
}
