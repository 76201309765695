import {
  GoogleLoginProvider,
  SocialAuthService,
} from "@abacritt/angularx-social-login";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as e from "express";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { CustomValidatorsInfo } from "src/app/component/componentModel/customValidator-info";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { LoginInfo } from "src/app/models/login-info";
import { ProfileCreateInfo } from "src/app/models/profileCreate-info";
import { SubscriptionInfo } from "src/app/models/subscription-page-info";
import { ProfileService } from "src/app/services/profile.service";
import { environment } from "src/environments/environment";
@Component({
  selector: `app-profile-create`,
  templateUrl: `./profile-create.component.html`,
  styleUrls: [`./profile-create.component.css`],
})
export class ProfileCreateComponent implements OnInit, OnDestroy {
  @ViewChild("signup") signupRef: ElementRef;
  inputInfo: InputInfo[] = [];
  activeIndex: number = 0;


  firstInputInfo: InputInfo[] = [];
  secondInputInfo: InputInfo[] = [];
  thirdInputInfo: InputInfo[] = [];
  relationshipStatusFlag = false;

  token: string | undefined;
  captchaErrorFlag: boolean;
  changeFlag = false;
  firstFlag = false;
  secondFlag = false;
  isLoadingFlag: boolean = true;
  removeCustomValidatorFlag: boolean;
  counterInputs = 0;
  isLoggedIn = false;
  data = [];
  public profileCreateInfo = new ProfileCreateInfo();
  backgroundImage = [];
  profileForOptions = [];
  areYouShiaOptions = [];
  syedOptions = [];
  educationOptions = [];
  professionOptions = [];
  genderOption = [];
  maritalOption = [];
  nationalityOption = [];
  residencyOption = [];

  get FirstFormInValid(): boolean {
    return this.firstInputInfo.some(a => a.errorFlag == true);
  }
  get SecondFormInValid(): boolean {
    return this.secondInputInfo.some(a => a.errorFlag == true);
  }
  get ThirdFormInValid(): boolean {
    return this.thirdInputInfo.some(a => a.errorFlag == true);
  }

  booleanConfirmation = [
    { name: `Yes`, value: `1` },
    { name: `No`, value: `0` },
  ];

  maxDate: Date;

  // social variables
  private userFirstName: string;
  private userLastName: string;
  private userEmail: string;
  private socialFlag: boolean = false;
  authSubscription!: Subscription;
  captchaImageUrl = '';
  constructor(
    private messageService: MessageService,
    private profileService: ProfileService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,

    private socialAuthService: SocialAuthService
  ) {
    // this.captchaImageUrl;
    this.googleAuthState();
    this.getOptions();
    this.getCaptcha();
  }
  checkFirstForm() {


    this.firstFlag = !this.firstFlag;
    setTimeout(() => {
      if (this.firstInputInfo.some(a => a.errorFlag == true)) {
        return;
      } else {
        this.activeIndex = 1;
      }
    }, 300);
    return;

  }
  checkSecondForm() {

    this.secondFlag = !this.secondFlag;
    setTimeout(() => {
      if (this.secondInputInfo.some(a => a.errorFlag == true)) {
        return;
      } else {
        this.activeIndex = 2;
      }
    }, 300);
    return;

  }
  googleAuthState() {
    this.authSubscription = this.socialAuthService.authState.subscribe(
      (user) => {
        if (user) {
          this.socialFlag = true;
          this.userFirstName = user.firstName;
          this.userLastName = user.lastName;
          this.userEmail = user.email;
        }
      }
    );
  }
  captchaKey: string = '';
  getCaptcha() {
    this.profileService.getCaptcha().subscribe(
      (data) => {
        this.captchaImageUrl = data.data.image;
        this.captchaKey = data.data.image_k;
      },
      (error) => this.handleError(error)
    );
  }
  getOptions() {
    this.profileService.getAllOptions().subscribe(
      (data) => this.handleOptions(data),
      (error) => this.handleError(error)
    );
  }
  handleOptions(data) {
    this.profileForOptions = [];
    this.areYouShiaOptions = [];
    this.syedOptions = [];
    this.genderOption = [];
    this.maritalOption = [];
    this.residencyOption = [];

    this.educationOptions = [];
    this.professionOptions = [];
    this.nationalityOption = [];

    for (let [key, value] of Object.entries(data.data.ProfileOptions)) {
      this.profileForOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.IsShiaOptions)) {
      this.areYouShiaOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.Syed)) {
      this.syedOptions.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.GenderOptions)) {
      this.genderOption.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.MaritalOptions)) {
      this.maritalOption.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.ResidencyOptions)) {
      this.residencyOption.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.EducationOptions).sort((a, b) => {
      return a[1].toString().localeCompare(b[1].toString());
    })) {
      this.educationOptions.push({
        value: key,
        name: value.toString(),
      });
    }
    for (let [key, value] of Object.entries(data.data.ProfessionOptions).sort((a, b) => {
      return a[1].toString().localeCompare(b[1].toString());
    })) {
      this.professionOptions.push({
        value: key,
        name: value.toString(),
      });
    }
    for (let [key, value] of Object.entries(data.data.CountryOptions)) {
      this.nationalityOption.push({
        value: key,
        name: value.toString(),
      });
    }
    this.generateFormData();
  }

  updateData(value, modelName, dropDown?) {
    this.profileCreateInfo[modelName] =
      dropDown && dropDown == true ? value?.value : value;

    if (modelName == "contact_no" && value != null && value != "") {
      try {
        this.profileCreateInfo[modelName] = value.number.replace(value.dialCode, '').replace(/[^0-9]/g, '');
        this.profileCreateInfo['dial_code'] = value.dialCode;
      } catch (error) {
        this.profileCreateInfo[modelName] = value;
        this.profileCreateInfo['dial_code'] = '';
      }

    }

    if (modelName == "confirm_password" && value != null && value != "") {
      let confirm_pass_input = this.inputInfo.find(
        (input) => input.modelName == modelName
      );

      if (
        this.profileCreateInfo["password"] !=
        this.profileCreateInfo["confirm_password"]
      ) {
        let obj = new CustomValidatorsInfo();
        obj.msg = "Password does not match";
        obj.activeName = "notSame";
        confirm_pass_input.customValidator = obj;
      } else if (confirm_pass_input.customValidator) {
        confirm_pass_input.customValidator.msg = "";
        confirm_pass_input.customValidator.activeName = "";
      }
    }

    if (modelName == "is_shia" && value != null && value != "") {
      let is_shia_input = this.inputInfo.find(
        (input) => input.modelName == modelName
      );
      if (this.profileCreateInfo["is_shia"] != "1") {
        let obj = new CustomValidatorsInfo();
        obj.msg = "You have to be Shia Ithna Ashari";
        obj.activeName = "notShia";
        is_shia_input.customValidator = obj;
        // this.profileCreateInfo[modelName] = '1';
      } else if (is_shia_input.customValidator) {
        is_shia_input.customValidator.msg = "";
        is_shia_input.customValidator.activeName = "";
      }
    }

    if (modelName == "relationship_status" && value != null && value != "") {
      let model_value = this.inputInfo.find(
        (input) => input.modelName == modelName);
      if (this.profileCreateInfo[modelName] != "0") {

        this.createInput(
          `Do you have childrens?`,
          `have_children`,
          `radio`,
          ``,
          `radio`,

          [{ key: "No", value: 0 },
          { key: "Yes", value: 1 }],
          null,
          [], null, null, 3
        );

      } else {

        this.thirdInputInfo = this.thirdInputInfo.filter(a => a.modelName != 'have_children');
        this.inputInfo = this.inputInfo.filter(
          a => a.modelName != 'have_children'
        );
      }

    }

    if (modelName == "profile_for" && value != null && value != "") {

      let model_value = this.inputInfo.find(
        (input) => input.modelName == modelName);
      if (this.profileCreateInfo[modelName] != 1) {

        this.createInput(
          `Permission has been obtained to share personal details`,
          `is_permissions_obtained`,
          `radio`,
          ``,
          `radio`,
          [{ key: "No", value: 0 },
          { key: "Yes", value: 1 }],
          null,
          [], null, null, 1
        );
      } else {
        this.firstInputInfo = this.firstInputInfo.filter(a => a.modelName != 'is_permissions_obtained');
        this.inputInfo = this.inputInfo.filter(
          a => a.modelName != 'is_permissions_obtained'
        );
      }
    }
    if (modelName == "have_children" && value != null && value != "") {

      let model_value = this.inputInfo.find(
        (input) => input.modelName == modelName);
      if (this.profileCreateInfo[modelName].value == 1) {

        this.createInput(
          `No. of children`,
          `noOfchildrens`,
          `number`,
          `No. of children`,
          `profileCreate`,
          [],
          null,
          [
            {
              type: Validators.min(0),
              msg: `You must enter a value greater than or equal to 0`,
            },
          ], null, null, 3
        );
      } else {
        this.thirdInputInfo = this.thirdInputInfo.filter(a => a.modelName != 'noOfchildrens');
        this.inputInfo = this.inputInfo.filter(
          a => a.modelName != 'noOfchildrens'
        );
      }
    }
  }
  preSubmit() {
    this.counterInputs = 0;
    this.firstFlag = !this.firstFlag;
    this.secondFlag = !this.secondFlag;
    this.changeFlag = !this.changeFlag;
  }
  submit3() {
    // this.changeFlag = !this.changeFlag;
  }
  preSubmit2() {
    this.changeFlag = !this.changeFlag;
    setTimeout(() => {
      if (this.inputInfo.some(a => a.errorFlag == true)) {
        return;
      } else {
        this.onSubmit();
      }
    }, 300);
    return;
  }
  onSubmit() {
    // this.counterInputs++;
    // if (this.counterInputs < this.inputInfo.length) {
    //   return;
    // }

    if (this.profileCreateInfo?.is_shia !== '1') {
      return; // Prevent form submission
    }

    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      if (this.signupRef && this.signupRef.nativeElement) {
        this.scrollTo(errorFlag.modelName);
      }
      return;
    }
    this.captchaErrorFlag = !this.profileCreateInfo.captcha ? false : true;
    if (!this.captchaErrorFlag) {
      return;
    }

    this.messageService.clear();
    this.addMessagesWithoutClear(`info`, `Creating Account`, `Please Wait..`);
    const dateOfBirth = this.profileCreateInfo.date_of_birth;
    const year = dateOfBirth.getFullYear();
    const month = (dateOfBirth.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month and pad with zero if needed
    const day = dateOfBirth.getDate().toString().padStart(2, '0'); // Pad with zero if needed



    let submittedData = {
      User: {
        profile_for: this.profileCreateInfo.profile_for,
        is_permissions_obtained: 1,
        date_of_birth: `${year}-${month}-${day}`,
        email: this.profileCreateInfo.email,
        password: this.profileCreateInfo.password,
        confirm_password: this.profileCreateInfo.confirm_password,
        gender: this.profileCreateInfo.gender,
        city: this.profileCreateInfo.city,
        state: this.profileCreateInfo.state,
        country: this.profileCreateInfo.country,
        contact_no: this.profileCreateInfo.contact_no,
        dial_code: this.profileCreateInfo.dial_code,
        securityCode: this.profileCreateInfo.securityCode,
        securityKey: this.captchaKey,
        //full name required by backend
        full_name:
          this.profileCreateInfo.first_name.trim() +
          this.profileCreateInfo.last_name.trim(),
        //to be changed
        role_id: 2,
      },
      UserProfile: {
        first_name: this.profileCreateInfo.first_name.trim(),
        middle_name: this.profileCreateInfo.middle_name.trim(),
        last_name: this.profileCreateInfo.last_name.trim(),
        nickname: this.profileCreateInfo.nickname.trim(),
        relationship_status: this.profileCreateInfo.relationship_status,
        is_shia: this.profileCreateInfo.is_shia,
        is_syed: this.profileCreateInfo.is_syed,
        education: this.profileCreateInfo.education,
        profession: this.profileCreateInfo.profession,
        nationality: this.profileCreateInfo.nationality,
        residency: this.profileCreateInfo.residency,
        // securityCode: "124evp",
        // full_name: "Subyyal",
        // role_id: 2,
      },
      PlanInvoice: {
        membership_id: 10,
      },
      website: {
        "g-recaptcha-response": 1,
        ajax: "signUp-form",
      },
    };

    this.profileService.createProfile(submittedData).subscribe(
      (data) => this.handleData(data, `success`),
      (error) => this.handleError(error)
    );
  }

  handleData(data, type) {
    this.messageService.clear();
    this.addMessages(`success`, `Success`, data.message);
    this.inputInfo = [];
    this.generateFormData();
    this.getOptions();
    if (data.data.type == 'otp') {
      this.tokenStorage.saveToken(data.data.authorization_code);
      this.isLoggedIn = true;
      this.router.navigate(["/user/otp"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  handleError(error) {
    let err = error.error.errors;
    let inputModelNames = [];
    inputModelNames = this.inputInfo.map((input) => input.modelName);
    if (err && Object.keys(err).length > 0) {
      for (let input of this.inputInfo) {
        input.customValidator = new CustomValidatorsInfo();
      }
      for (const key in err) {
        let errorMatched = inputModelNames.find((el) => `user-${el}`.toLowerCase() == key);
        if (!errorMatched) {
          errorMatched = inputModelNames.find((el) => `userprofile-${el}`.toLowerCase() == key);
        }

        let obj = new CustomValidatorsInfo();
        obj.msg = err[key][0];
        obj.activeName = errorMatched;
        this.inputInfo.find(
          (input) => input.modelName == errorMatched
        ).customValidator = obj;
        let ind = this.inputInfo.find(
          (input) => input.modelName == errorMatched
        ).tabNumber - 1;
        if (ind > 0) {
          this.activeIndex = ind;
        }

        this.scrollTo(errorMatched);
      }
    } else {
      for (let input of this.inputInfo) {
        input.customValidator = new CustomValidatorsInfo();
      }
    }
  }

  scrollTo(modelName) {
    this.signupRef.nativeElement.querySelector(`#${modelName}`).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  generateFormData() {
    this.inputInfo = [];
    this.firstInputInfo = [];
    this.secondInputInfo = [];
    this.relationshipStatusFlag = false;

    this.createInput(
      ``,
      `profile_for`,
      `text`,
      `Profile is for *`,
      `profileCreateDropdown`,
      this.profileForOptions,
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter for whom this profile is for`,
        },
      ],
      null,
      1
    );

    this.createInput(
      ``,
      `first_name`,
      `text`,
      `First Name *`,
      `profileCreate`,
      [],
      this.socialFlag == true ? this.userFirstName : "",
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your first name`,
        },
      ],
      this.socialFlag == true ? true : false,
      1
    );

    this.createInput(
      ``,
      `middle_name`,
      `text`,
      `Middle Name`,
      `profileCreate`,
      [],
      ``,
      false,
      [], null, 1
    );

    this.createInput(
      ``,
      `last_name`,
      `text`,
      `Last Name *`,
      `profileCreate`,
      [],
      this.socialFlag == true ? this.userLastName : "",
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your last name`,
        },
      ],
      this.socialFlag == true ? true : false, 1
    );

    this.createInput(
      ``,
      `nickname`,
      `text`,
      `Profile Name *`,
      `profileCreate`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your profile name`,
        },
      ], null, 1
    );

    this.createInput(
      ``,
      `date_of_birth`,
      `calender`,
      `Date of Birth *`,
      `calender`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your date of birth`,
        },
      ], null, 1
    );

    this.createInput(
      ``,
      `email`,
      `email`,
      `Email Address *`,
      `profileCreate`,
      [],
      this.socialFlag == true ? this.userEmail : "",
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your Email Address`,
        },
        {
          type: Validators.email,
          msg: `You must enter valid Email Address`,
        },
      ],
      this.socialFlag == true ? true : false, 3
    );

    this.createInput(
      ``,
      `password`,
      `password`,
      `Password *`,
      `profileCreate`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your Password`,
        },
      ], null, 3
    );

    this.createInput(
      ``,
      `confirm_password`,
      `password`,
      `Confirm Password *`,
      `profileCreate`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your Password`,
        },
      ], null, 3
    );

    this.createInput(
      ``,
      `gender`,
      `text`,
      `Gender *`,
      `profileCreateDropdown`,
      this.genderOption,
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your gender`,
        },
      ], null, 1
    );

    this.createInput(
      ``,
      `relationship_status`,
      `text`,
      `Marital Status *`,
      `profileCreateDropdown`,
      this.maritalOption,
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your marital status`,
        },
      ], null, 3
    );

    this.createInput(
      ``,
      `is_shia`,
      `text`,
      `Are you Shia Ithna Ashari (believe in 12 Imams) *`,
      `profileCreateDropdown`,
      this.booleanConfirmation,
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your believe`,
        },
      ], null, 2
    );

    this.createInput(
      ``,
      `is_syed`,
      `text`,
      `Syed? *`,
      `profileCreateDropdown`,
      this.syedOptions,
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your cast`,
        },
      ], null, 2
    );

    this.createInput(
      ``,
      `education`,
      `text`,
      `Education *`,
      `profileCreateDropdown`,
      this.educationOptions,
      ``,
      true,
      [
        {
          type: Validators.required,
          msg: `You must enter your education`,
        },
      ], null, 2
    );

    this.createInput(
      ``,
      `profession`,
      `text`,
      `Profession *`,
      `profileCreateDropdown`,
      this.professionOptions,
      ``,
      true,
      [
        {
          type: Validators.required,
          msg: `You must enter your profession`,
        },
      ], null, 2
    );

    this.createInput(
      ``,
      `city`,
      `text`,
      `City *`,
      `profileCreate`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your city`,
        },
      ], null, 2
    );

    this.createInput(
      ``,
      `state`,
      `text`,
      `State/County/Province *`,
      `profileCreate`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your State/County/Province`,
        },
      ], null, 2
    );

    this.createInput(
      ``,
      `country`,
      `text`,
      `Country *`,
      `profileCreate`,
      this.nationalityOption,
      ``,
      true,
      [
        {
          type: Validators.required,
          msg: `You must enter your country`,
        },
      ], null, 2
    );

    this.createInput(
      ``,
      `nationality`,
      `text`,
      `Nationality *`,
      `profileCreateDropdown`,
      this.nationalityOption,
      ``,
      true,
      [
        {
          type: Validators.required,
          msg: `You must enter your nationality`,
        },
      ], null, 3
    );

    this.createInput(
      ``,
      `residency`,
      `text`,
      `Residency Status *`,
      `profileCreateDropdown`,
      this.residencyOption,
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your residency status`,
        },
      ], null, 3
    );

    this.createInput(
      ``,
      `contact_no`,
      `phone`,
      ``,
      `profileCreate`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter your phone number`,
        },
      ], null, 3
    );

    this.createInput(
      ``,
      `securityCode`,
      `text`,
      `Security Code`,
      `captcha`,
      [],
      ``,
      false,
      [
        {
          type: Validators.required,
          msg: `You must enter the security code`,
        },
      ], null, 0
    );

    this.createInput(
      ``,
      `agreePolicy`,
      ``,
      `By submitting you agree to our Privacy Policy and T&C *`,
      `checkBox`,
      [],
      [`true`],
      false,
      [
        {
          type: Validators.required,
          msg: `You must accept the privacy policy`,
        },
      ], null, 0
    );
    this.createInput(
      ``,
      `afirmInformation`,
      ``,
      `“Surely Allah does not guide him aright who is a liar, ungrateful.” (Quran 39:3) I affirm that the information provided is true to the best of my knowledge and belief. *`,
      `checkBox`,
      [],
      [`true`],
      false,
      [
        {
          type: Validators.required,
          msg: `You must accept the information`,
        }
      ], null, 0
    );
    // add kalma as checkbox 
    this.createInput(
      ``,
      `kalmaInfo`,
      ``,
      `La ilaha illallaho Mohammad-ur Rasool Allah Ali-un Wali Ullah Wasi-o Rasool Allah wa Khalifato hu bila fasl.
 Translation:I bear witness that there is no god but Allah,Muhammad is the messenger of Allah,Ali is the Wali of Allah.`,
      `checkBox`,
      [],
      [`true`],
      false,
      [
        {
          type: Validators.required,
          msg: `You must accept the information`,
        }
      ], null, 0
    );
    this.isLoadingFlag = false;
  }
  handleAddress($event: Address) {
    let cityControl = this.inputInfo.find(a => a.modelName == 'city');
    let countryControl = this.inputInfo.find(a => a.modelName == 'country');
    let countryObj = $event.address_components.find(a => a.types.includes('country'));
    let stateControl = this.inputInfo.find(a => a.modelName == 'state');
    cityControl?.control.setValue($event.name);
    countryControl?.control.setValue(countryObj.long_name);
    let stateObj = $event.address_components.find(a => a.types.includes('administrative_area_level_1'));
    // if (countryObj && (countryObj.short_name == 'England' || countryObj.short_name == 'ENGLAND' || countryObj.long_name == 'United Kingdom')) {
    //   stateObj = $event.address_components.find(a => a.types.includes('administrative_area_level_1') || a.types.includes('administrative_area_level_2'));
    // }
    stateControl?.control.setValue(stateObj.long_name);
  }
  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    option,
    value,
    search,
    validatorsInfo,
    disabled?,
    tabNumber?
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.option = option;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.search = search;
    inputObj.validatorsInfo = validatorsInfo;
    inputObj.tabNumber = tabNumber;
    inputObj.disabled = disabled;
    this.inputInfo.push(inputObj);

    if (!this.relationshipStatusFlag) {
      if (inputObj.modelName != "is_shia") {
        // this.firstInputInfo.push(inputObj);
      } else {
        this.relationshipStatusFlag = true;
        // this.secondInputInfo.push(inputObj);
      }
    } else {
      // this.secondInputInfo.push(inputObj);
    }
    switch (tabNumber) {
      case 0:
        break;
      case 2:
        if (!this.secondInputInfo.some(a => a.modelName == modelName))
          this.secondInputInfo.push(inputObj);
        break;
      case 3:
        if (inputObj.modelName == 'have_children') {
          const index = this.thirdInputInfo.findIndex(item => item.modelName === 'relationship_status');
          if (index !== -1) {
            // Insert the new item after the found index
            if (!this.thirdInputInfo.some(a => a.modelName === 'have_children'))
              this.thirdInputInfo.splice(index + 1, 0, inputObj);
          }

        } else if (inputObj.modelName == 'noOfchildrens') {
          const index = this.thirdInputInfo.findIndex(item => item.modelName === 'have_children');
          if (index !== -1) {
            // Insert the new item after the found index
            if (!this.thirdInputInfo.some(a => a.modelName === 'noOfchildrens'))
              this.thirdInputInfo.splice(index + 1, 0, inputObj);
          }

        } else if (!this.thirdInputInfo.some(a => a.modelName == modelName)) {
          this.thirdInputInfo.push(inputObj);
        }
        break;
      default:
        if (inputObj.modelName == 'is_permissions_obtained') {
          const index = this.firstInputInfo.findIndex(item => item.modelName === 'profile_for');
          if (index !== -1) {
            // Insert the new item after the found index
            if (!this.firstInputInfo.some(a => a.modelName === 'is_permissions_obtained'))
              this.firstInputInfo.splice(index + 1, 0, inputObj);
          }

        } else if (!this.firstInputInfo.some(a => a.modelName == modelName)) {
          this.firstInputInfo.push(inputObj);
        }

        break;
    }
  }

  addMessages(severity, summary, detail) {
    this.messageService.add({
      severity,
      summary,
      detail,
      sticky: true,
    });
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }

  addMessagesWithoutClear(severity, summary, detail) {
    this.messageService.add({
      severity,
      summary,
      detail,
      sticky: true,
    });
  }

  onDataReady(event: string) {
    this.backgroundImage.push(event[`preview`]);
    this.profileCreateInfo.images = event["image"];
  }

  // getting subscription plan 


  ngOnInit(): void {
    this.maxDate = this.calculateMaxDate(18);

  }



  calculateMaxDate(minimumAge: number): Date {
    const today = new Date();
    return new Date(
      today.getFullYear() - minimumAge,
      today.getMonth(),
      today.getDate()
    );
  }



  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.socialAuthService) {
      if (this.socialAuthService.authState) {
        this.socialAuthService
          .signOut()
          .then(() => { })
          .catch(() => { });
      } else {
      }
    }
  }
}
