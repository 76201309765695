import { Component, HostListener, OnInit } from "@angular/core";
import { AboutUsCardInfo } from "src/app/models/about-us-card";
import { AboutUsCard } from "src/app/models/about-us-info/about-us-card";
import { AboutUsInfo } from "src/app/models/about-us-page-info";

@Component({
  selector: "app-aboutus",
  templateUrl: "./aboutus.component.html",
  styleUrls: ["./aboutus.component.css"],
})
export class AboutusComponent implements OnInit {
  aboutUsPageInfo: AboutUsInfo = {
    pageHeroImage: "aboutus-ss.png",
    title: "Welcome to ShiaSpouse",
    description:
      "A trustworthy, reliable and confidential online matchmaking service for Shia Ithna Ashari across the world.",
    pagecenterimage: "pexels-yan-krukov-8911780.jpg",
    carouselItems: [
      {
        title: "Muhammad Umar",
        description:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
      {
        title: "Muhammad Umar ",
        description:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
      {
        title: "Muhammad Umar",
        description:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
    ],
  };
  aboutData: AboutUsCard;
  aboutUsCardInfo: AboutUsCardInfo = {
    aboutCardData: [
      {
        aboutTitle: "Muhammad Umar",
        aboutPara:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
      {
        aboutTitle: "Muhammad Umar",
        aboutPara:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
      {
        aboutTitle: "Muhammad Umar",
        aboutPara:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
    ],
  };
  isMobileView: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileView = width < 950;
  }
  constructor() {}

  ngOnInit(): void {
    const width = window.innerWidth;
    this.isMobileView = width < 950;
  }
}
