import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-navigations",
  templateUrl: "./navigations.component.html",
  styleUrls: ["./navigations.component.css"],
})
export class NavigationsComponent implements OnInit {
  item: any[];
  displayLogin: boolean = false;
  displayRegister: boolean = false;
  footerFlag: boolean = true;
  currentUrl: string;
  constructor(private router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentUrl = this.router.url;

        if (this.currentUrl == "/login" || this.currentUrl == "/sign-up") {
          this.footerFlag = false;
        } else {
          this.footerFlag = true;
        }
      });
  }
  isMobileView: boolean;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileView = width <= 960;
    if (width < 960) {
      this.mobileItems();
    } else if (width > 960) {
      this.defaultItems();
    }
  }
  defaultItems() {
    this.item = [
      {
        label: "Home",
        // icon: 'pi pi-plus',
        routerLink: ["./"],
      },
      {
        label: "About Us",
        // icon: 'pi pi-plus',
        routerLink: ["./site/about"],
      },
      {
        label: "Events",
        // icon: 'pi pi-plus',
        routerLink: ["./events"],
        styleClass: "events",

        items: [
          {
            label: "New Events",
            routerLink: ["./events"],
            styleClass: "new-event",
          },
          {
            label: "UpComing Events",
            routerLink: ["./events"],
            styleClass: "upcoming-event",
          },
          {
            label: "Past Events",
            routerLink: ["./events"],
            styleClass: "past-event",
          },
        ],
      },
      {
        label: "Books",
        // icon: 'pi pi-plus',
        routerLink: ["./site/books"],
      },
      {
        label: "Videos",
        // icon: 'pi pi-plus',
        routerLink: ["./site/video"],
        styleClass: "videos-btn",
      },
      {
        label: "Login",
        // icon: 'pi pi-plus',
        routerLink: ["/login"],
        // queryParams: { recent: "true" },
        styleClass: "login-btn",
        // command: () => {
        //   this.login();
        // },
      },
      {
        label: "Sign Up",
        // icon: 'pi pi-plus',
        routerLink: ["/user/signup"],
        // queryParams: { recent: "true" },
        styleClass: "signup-btn",
        // command: () => {
        //   this.signup();
        // },
      },
      {
        label: "Login your account",
        // icon: 'pi pi-plus',
        routerLink: ["/login"],
        queryParams: { recent: "true" },
        styleClass: "login-account-btn",
        // command: () => {
        //   this.login();
        // },
      },
      {
        label: '<img src="../assets/images/facebook-app-symbol.png" alt=""/>',
        escape: false,
        styleClass: "fb",
        url: "https://www.facebook.com/shiaspouse.org",
      },
      {
        label: '<img src="../assets/images/instagram-2.png" alt=""/>',
        escape: false,
        styleClass: "inst",
        url: "https://www.instagram.com/shiaspouse/",
      },
      {
        label: '<img src="../assets/images/tiktok.png" alt="tiktok"/>',
        escape: false,
        styleClass: "tiktok",
        url: "https://www.tiktok.com/@shiaspouse",
      },
      {
        label: '<img src="../assets/images/youtube-logo-hd-8.png" alt=""/>',
        escape: false,
        styleClass: "yout",
        url: "https://www.youtube.com/@shiaspouse5713",
      },
    ];
  }
  mobileItems() {
    this.item = [
      {
        label: "Home",
        // icon: 'pi pi-plus',
        routerLink: ["./"],
      },
      {
        label: "About Us",
        // icon: 'pi pi-plus',
        routerLink: ["./site/about"],
      },
      {
        label: "Events",
        // icon: 'pi pi-plus',
        // routerLink: ["./events"],
        styleClass: "events",

        items: [
          {
            label: "New Events",
            routerLink: ["./events"],
            queryParams: { tab: "new" },
            styleClass: "new-event",
          },
          {
            label: "Upcoming Events",
            routerLink: ["./events"],
            queryParams: { tab: "upcoming" },
            styleClass: "upcoming-event",
          },
          {
            label: "Past Events",
            routerLink: ["./events"],
            queryParams: { tab: "past" },
            styleClass: "past-event",
          },
        ],
      },
      {
        label: "Books",
        // icon: 'pi pi-plus',
        routerLink: ["./site/books"],
      },
      {
        label: "Videos",
        // icon: 'pi pi-plus',
        routerLink: ["./site/video"],
        styleClass: "videos-btn",
      },
      {
        label: "Login",
        // icon: 'pi pi-plus',
        routerLink: ["/login"],
        // queryParams: { recent: "true" },
        styleClass: "login-btn",
        // command: () => {
        //   this.login();
        // },
      },
      {
        label: "Sign Up",
        // icon: 'pi pi-plus',
        routerLink: ["/user/signup"],
        // queryParams: { recent: "true" },
        styleClass: "signup-btn",
        // command: () => {
        //   this.signup();
        // },
      },
      {
        label: "Login your account",
        // icon: 'pi pi-plus',
        routerLink: ["/login"],
        // queryParams: { recent: "true" },
        styleClass: "login-account-btn",
        // command: () => {
        //   this.login();
        // },
      },
      {
        label: '<img src="../assets/images/facebook-app-symbol.png" alt=""/>',
        escape: false,
        styleClass: "fb",
        url: "https://www.facebook.com/shiaspouse.org",
      },
      {
        label: '<img src="../assets/images/instagram-2.png" alt=""/>',
        escape: false,
        styleClass: "inst",
        url: "https://www.instagram.com/shiaspouse/",
      },
      {
        label: '<img src="../assets/images/tiktok.png" alt="tiktok"/>',
        escape: false,
        styleClass: "tiktok",
        url: "https://www.tiktok.com/@shiaspouse",
      },
      {
        label: '<img src="../assets/images/youtube-logo-hd-8.png" alt=""/>',
        escape: false,
        styleClass: "yout",
        url: "https://www.youtube.com/@shiaspouse5713",
      },
    ];
  }
  ngOnInit(): void {
    const width = window.innerWidth;
    this.isMobileView = width < 960;
    if (width < 960) {
      this.mobileItems();
    } else {
      this.defaultItems();
    }
  }
  login() {
    this.displayLogin = true;
  }
  signup() {
    this.displayRegister = true;
  }

  loginDialogClosed() {
    this.displayLogin = false;
  }
  registerDialogClosed() {
    this.displayRegister = false;
  }

  registerDialogShow() {
    this.displayLogin = false;
    this.displayRegister = true;
  }
}
