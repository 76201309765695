import { Injectable } from "@angular/core";
import { BehaviorSubject, interval } from "rxjs";
import { distinctUntilChanged, startWith, timeInterval } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { TokenStorageService } from "../auth/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private notificationCountSubject = new BehaviorSubject<number>(0);
  public notificationsCount$ = this.notificationCountSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private messageCountSubject = new BehaviorSubject<number>(0);
  public messagesCount$ = this.messageCountSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  // notificationCount: number = 0;
  // messageCount: number = 0;
  constructor(
    private Jarvis: AuthService,
    private tokenStorage: TokenStorageService
  ) {
    const seconds = interval(5000);

    seconds.pipe(startWith(0), timeInterval()).subscribe(
      () => {
        if (this.tokenStorage.getToken()) {
          this.Jarvis.notificationsCount().subscribe(
            (data) => this.handleNotificationsCount(data),
            (error) => this.handleError(error)
          );
          this.Jarvis.messagesCount().subscribe(
            (data) => this.handleMessagesCount(data),
            (error) => this.handleError(error)
          );
        }
      },
      (err) => {}
    );
  }

  handleNotificationsCount(data) {
    this.notificationCountSubject.next(data.count);
  }

  handleMessagesCount(data) {
    this.messageCountSubject.next(data.count);
  }

  handleError(error) {}
}
