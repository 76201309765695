<div class="container-fluid sign-up-page">
  <div class="hero">
    <img class="hero-image" src="./assets/images/signup-login-ss.png" alt="" />
  </div>
  <div class="row justify-content-center sign-up-inner">
    <div class="text-center m-auto">
      <div class="sign-up-heading">
        <div class="sign-up-heading-title">
          <h3>Create your profile on Shia Spouse</h3>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isLoadingFlag">
      <div class="isLoadingDiv">
        <p-progressSpinner [style]="{ width: '80px', height: '80px' }" styleClass="custom-spinner" strokeWidth="5"
          animationDuration=".7s"></p-progressSpinner>
      </div>
    </ng-container>
    <p-tabView [(activeIndex)]="activeIndex">
      <p-tabPanel header="Step I" [disabled]="FirstFormInValid">
        <ng-container *ngIf="!isLoadingFlag">
          <div class="sign-up-page sign-up-inputs" #signup>
            <div class="row justify-content-between">
              <div class="col-md-5" *ngFor="let box of firstInputInfo">
                <div class="mt-4" [id]="box.modelName">
                  <app-radio-button *ngIf="box.type == 'radio'" [inputInfo]="box" [label]="box.placeHolder"
                    (updateData)="updateData($event, box.modelName)" [changeFlag]="firstFlag" [data]="box.option"
                    (preSubmit)="preSubmit()" (submit)="submit3()">
                  </app-radio-button>
                  <app-text-box [inputInfo]="box" [changeFlag]="this.firstFlag" *ngIf="box.type == 'profileCreate'"
                    (updateData)="updateData($event, box.modelName)" [customValidator]="box.customValidator"
                    (preSubmit)="preSubmit()" (submit)="submit3()" [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                    [borderRadius]="'35px'" [width]="'100%'" [height]="'65px'" [padding]="'0 2rem'"
                    [fontSize]="'clamp(14px, 3vw, 18px)'" [color]="'rgba(0, 0, 0, 0.87)'"
                    [fontFamily]="'Montserrat'"></app-text-box>
                  <app-drop-down *ngIf="box.type == 'profileCreateDropdown'" [inputInfo]="box" [width]="'100%'"
                    (updateData)="updateData($event, box.modelName, true)" [customValidator]="box.customValidator"
                    [changeFlag]="firstFlag" [data]="box.option" [scrollHeight]="
                      box.option?.length >= 1 && box.option?.length <= 3
                        ? box.option?.length * 50 + 'px'
                        : '200px'
                    " (preSubmit)="preSubmit()" (submit)="submit3()">
                  </app-drop-down>

                  <app-calender [accessFrom]="'createProfile'" [inputInfo]="box" *ngIf="box.type == 'calender'"
                    [changeFlag]="this.firstFlag" [selectionMode]="'basic'" [inLine]="false" [maxDate]="maxDate"
                    (updateData)="updateData($event, box.modelName)" (preSubmit)="preSubmit()"
                    (submit)="submit3()"></app-calender>
                </div>
              </div>
            </div>

            <div class="my-2">
              <app-button [disabled]="FirstFormInValid" [label]="'Next'" [padding]="'5px 0'" [color]="'#fff'"
                [type]="'login'" (button)="checkFirstForm()" [bgColor]="'var(--color-pink-bg)'"
                [borderRadius]="'35px !important'" [fontSize]="'clamp(16px, 4vw, 22px)'" [maxWidth]="'400px'"
                [padding]="'1rem 2rem'">
              </app-button>
            </div>
          </div>
        </ng-container>
      </p-tabPanel>
      <p-tabPanel header="Step II" [disabled]="FirstFormInValid || SecondFormInValid">
        <ng-container *ngIf="!isLoadingFlag">
          <div class="sign-up-page sign-up-inputs signup-seccond-step" #signup>
            <div class="row justify-content-between">
              <div class="col-md-5" *ngFor="let box of secondInputInfo">
                <div class="mt-4" [id]="box.modelName">
                  <app-radio-button *ngIf="box.type == 'radio'" [inputInfo]="box"
                    (updateData)="updateData($event, box.modelName)" [changeFlag]="secondFlag" [data]="box.option"
                    (preSubmit)="preSubmit()" (submit)="submit3()"></app-radio-button>
                  <app-text-box [inputInfo]="box" [changeFlag]="this.secondFlag" *ngIf="box.type == 'profileCreate'"
                    (updateData)="updateData($event, box.modelName)" (handleAddress)="handleAddress($event)"
                    [customValidator]="box.customValidator" (preSubmit)="preSubmit()" (submit)="submit3()"
                    [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'" [borderRadius]="'35px'" [width]="'100%'"
                    [height]="'65px'" [padding]="'0 2rem'" [fontSize]="'clamp(14px, 3vw, 18px)'"
                    [color]="'rgba(0, 0, 0, 0.87)'" [fontFamily]="'Montserrat'"></app-text-box>
                  <app-drop-down *ngIf="box.type == 'profileCreateDropdown'" [inputInfo]="box" [width]="'100%'"
                    (updateData)="updateData($event, box.modelName, true)" [customValidator]="box.customValidator"
                    [changeFlag]="secondFlag" [data]="box.option" [scrollHeight]="
                      box.option?.length >= 1 && box.option?.length <= 3
                        ? box.option?.length * 50 + 'px'
                        : '200px'
                    " (preSubmit)="preSubmit()" (submit)="submit3()">
                  </app-drop-down>

                  <app-calender [accessFrom]="'createProfile'" [inputInfo]="box" *ngIf="box.type == 'calender'"
                    [changeFlag]="this.secondFlag" [selectionMode]="'basic'" [inLine]="false" [maxDate]="maxDate"
                    (updateData)="updateData($event, box.modelName)" (preSubmit)="preSubmit()"
                    (submit)="submit3()"></app-calender>
                </div>
              </div>
            </div>
            <div class="my-2">
              <app-button [disabled]="SecondFormInValid" [label]="'Next'" [padding]="'5px 0'" [color]="'#fff'"
                [type]="'login'" (button)="checkSecondForm()" [bgColor]="'var(--color-pink-bg)'"
                [borderRadius]="'35px !important'" [fontSize]="'clamp(16px, 4vw, 22px)'" [maxWidth]="'400px'"
                [padding]="'1rem 2rem'">
              </app-button>
            </div>
          </div>
        </ng-container>
      </p-tabPanel>
      <p-tabPanel header="Step III" [disabled]="FirstFormInValid || SecondFormInValid || ThirdFormInValid">
        <!-- [disabled]="ThirdFormInValid" -->
        <ng-container *ngIf="!isLoadingFlag">
          <div class="sign-up-page sign-up-inputs" #signup>
            <div class="row justify-content-between">
              <div class="col-md-5" *ngFor="let box of thirdInputInfo">
                <div class="mt-4" [id]="box.modelName">
                  <app-radio-button *ngIf="box.type == 'radio'" [inputInfo]="box" [label]="box.placeHolder"
                    (updateData)="updateData($event, box.modelName)" [changeFlag]="changeFlag" [data]="box.option"
                    (action)="updateData(0, box.modelName)" (preSubmit)="preSubmit()" (submit)="submit3()">
                  </app-radio-button>
                  <app-text-box [inputInfo]="box" [changeFlag]="this.changeFlag" *ngIf="box.type == 'profileCreate'"
                    (updateData)="updateData($event, box.modelName)" [customValidator]="box.customValidator"
                    (preSubmit)="preSubmit()" (submit)="submit3()" [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                    [borderRadius]="'35px'" [width]="'100%'" [height]="'65px'" [padding]="'0 2rem'"
                    [fontSize]="'clamp(14px, 3vw, 18px)'" [color]="'rgba(0, 0, 0, 0.87)'"
                    [fontFamily]="'Montserrat'"></app-text-box>
                  <app-drop-down *ngIf="box.type == 'profileCreateDropdown'" [inputInfo]="box" [width]="'100%'"
                    (updateData)="updateData($event, box.modelName, true)" [customValidator]="box.customValidator"
                    [changeFlag]="changeFlag" [data]="box.option" [scrollHeight]="
                      box.option?.length >= 1 && box.option?.length <= 3
                        ? box.option?.length * 50 + 'px'
                        : '200px'
                    " (preSubmit)="preSubmit()" (submit)="submit3()">
                  </app-drop-down>

                  <app-calender [accessFrom]="'createProfile'" [inputInfo]="box" *ngIf="box.type == 'calender'"
                    [changeFlag]="this.changeFlag" [selectionMode]="'basic'" [inLine]="false" [maxDate]="maxDate"
                    (updateData)="updateData($event, box.modelName)" (preSubmit)="preSubmit()"
                    (submit)="submit3()"></app-calender>
                </div>
              </div>
            </div>
            <div class="row mt-5" *ngIf="this.inputInfo.length > 0">
              <div class="col-sm-12 mb-2">

                <div class="d-flex align-items-center gap-2">
                  <img [src]="captchaImageUrl" id='captcha' />
                  <ng-container *ngFor="let box of this.inputInfo">
                    <div class="" *ngIf="box.type == 'captcha'">
                      <!-- <label class="label ml-2 pt-1" *ngIf="box.type == 'checkBox'">{{ box.placeHolder }}</label> -->
                      <app-text-box [inputInfo]="box" [changeFlag]="this.changeFlag" *ngIf="box.type == 'captcha'"
                        (updateData)="updateData($event, box.modelName)" [customValidator]="box.customValidator"
                        (preSubmit)="preSubmit()" (submit)="submit3()" [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                        [borderRadius]="'35px'" [width]="'100%'" [height]="'65px'" [padding]="'0 2rem'"
                        [fontSize]="'clamp(14px, 3vw, 18px)'" [color]="'rgba(0, 0, 0, 0.87)'"
                        [fontFamily]="'Montserrat'"></app-text-box>

                    </div>
                  </ng-container>
                </div>





              </div>
              <div class="col-sm-12 mb-2">
                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="profileCreateInfo.captcha"
                  required [class.is-invalid]="this.captchaErrorFlag"></re-captcha>
                <div class="captchaError" *ngIf="this.captchaErrorFlag == false">
                  This field is required.
                </div>
              </div>
            </div>
            <div class="row">
              <ng-container *ngFor="let box of this.inputInfo">
                <div class="col-md-12 my-3 checkBoxes" *ngIf="box.type == 'checkBox'">
                  <app-check-box class="d-inline-flex align-items-center" [inputInfo]="box" [changeFlag]="changeFlag"
                    (updateData)="updateData($event, box.modelName)" *ngIf="box.type == 'checkBox'"
                    (preSubmit)="preSubmit()" (submit)="submit3()"
                    [boxShadow]="'rgb(0 0 0 / 13%) 0px 0px 12px;'"></app-check-box>
                  <label class="label ml-2 pt-1" *ngIf="box.type == 'checkBox'">{{ box.placeHolder }}</label>
                </div>
              </ng-container>
            </div>
            <div class="my-2">
              <app-button [label]="'Submit'" [padding]="'5px 0'" [color]="'#fff'" [type]="'login'"
                (button)="preSubmit2()" [bgColor]="'var(--color-pink-bg)'" [borderRadius]="'35px !important'"
                [fontSize]="'clamp(16px, 4vw, 22px)'" [maxWidth]="'400px'" [padding]="'1rem 2rem'">
              </app-button>
            </div>
          </div>
        </ng-container>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>