import {
  Component,
  HostBinding,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { Observable, forkJoin } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { FileUploadComponent } from "src/app/component/htmlComponents/file-upload/file-upload.component";
import { MessageServiceService } from "src/app/message.service";
import { AboutUserInfo } from "src/app/models/aboutUser-info";
import { AlimREferenceInfo } from "src/app/models/alimReference-info";
import { ProfileAlimInfo } from "src/app/models/profile-alim-info";
import { ProfileDocumentsInfo } from "src/app/models/profile-documents-info";
import { ProfilePageInfo } from "src/app/models/profile-info";
import { SubscriptionInfo } from "src/app/models/subscription-page-info";
import { UserImagesInfo } from "src/app/models/userImages-info";
import { VerificationInfo } from "src/app/models/verificationUser-info";
import { EventNotificationService } from "src/app/services/event-notification.service";
import { ProfileService } from "src/app/services/profile.service";
import { UserProfileConverterService } from "src/app/services/user-profile-converter.service";
import { FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {


  searchForm: FormGroup;
  ageRange: number[] = [];
  filteredAgeTo: number[] = [];
  // getting id from router 
  userId: string;
  public accordionObj: any = [];
  inputInfo: InputInfo[] = [];
  profileInputInfo: InputInfo[] = [];
  documentTypeInfo: InputInfo[] = [];
  changeFlag = false;
  changeFlag2 = false;

  deleteImageIdFlag: number = null;
  counterInputs = 0;
  isMobileView: boolean;
  loggedInUserId;
  loggedInUserEmail;
  profileUserId;
  loggedInUserStateId;
  isProfileProtected: boolean;
  isPicPrivate: boolean;
  displayImageUploadDialog: boolean;
  displayPhoneVerifyDialog: boolean;
  profileRequestSendStatus: string;
  requestSentStatus: boolean = false;
  requestReceiveStatus: boolean = false;
  // profileRequestSend;
  profileRequestRecieved = null;
  profileInfoLoadedFlag: boolean = false;
   // event notification 
  //  notificationTexts: any[] = [];
  //  currentNotificationIndex: number = 0;
  //  currentNotificationText: string = this.notificationTexts[this.currentNotificationIndex];
  //  animate: boolean = false;
  notificationTexts: any[] = [];
  currentNotificationIndex: number = 0;
  currentNotificationText: string = '';
  notificationSlug: string = '';
  animate: boolean = false;
  countryOptions:any;
  subscriptionPlansCopy;
  subscribedFlag: boolean;
  subscribedIData = {};
  subscriptionpageinfo: SubscriptionInfo = {
    title: "Choose your plan",
    subsCardData: [],
  };

  // multple options
  build = [
    { value: "1", name: "Slim" },
    { value: "2", name: "Athletic" },
    { value: "3", name: "Average" },
    { value: "4", name: "Heavy" },
  ];
  complexion = [
    { value: "1", name: "Fair" },
    { value: "2", name: "Medium" },
    { value: "3", name: "Brown" },
    { value: "4", name: "White" },
    { value: "5", name: "Black" },
    { value: "6", name: "Other" },
  ];
  smoke = [
    { value: "1", name: "Socially" },
    { value: "2", name: "Occasionally" },
    { value: "3", name: "Regularly" },
    { value: "4", name: "Do Not Smoke" },
  ];
  pray_regularly = [
    { value: "1", name: "5 times a day" },
    { value: "2", name: "Occasionally" },
    { value: "3", name: "Never" },
    { value: "4", name: "Do not state" },
  ];

  disability = [
    { key: "No", value: 0 },
    { key: "Yes", value: 1 },
  ];

  photoVerifiedFlag: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileView = width < 960;
  }
  dialCodes = [
    { name: "+0" },
    { name: "+1" },
    { name: "+2" },
    { name: "+7" },
    { name: "+92" },
    { name: "+82" },
    { name: "+27" },
    { name: "+227" },
    { name: "+35" },
    { name: "+87" },
    { name: "+12" },
    { name: "+65" },
    { name: "+666" },
    { name: "+999" },
    { name: "+123" },
    { name: "+21" },
    { name: "+97" },
    { name: "+966" },
    { name: "+42" },
    { name: "+15" },
    { name: "+52" },
    { name: "+37" },
    { name: "+93" },
    { name: "+472" },
    { name: "+534" },
    { name: "+20" },
    { name: "+01" },
    { name: "+100" },
    { name: "+200" },
    { name: "+300" },
    { name: "+301" },
    { name: "+201" },
  ];
  public saveType: string;
  documentType: any[] = [
    { name: "Driving License", value: 0 },
    { name: "Passport", value: 1 },
    { name: "National ID card", value: 2 },
    { name: "Birth Certificate", value: 3 },
    { name: "Voter ID", value: 4 },
    { name: "Other", value: 5 },
  ];

  idDocuments: ProfileDocumentsInfo[] = [];
  alimData: ProfileAlimInfo[] = [];
  profilePageInfo: ProfilePageInfo = {
    profileHeroImage: "./assets/images/profile-hero.jpg",
    profileImage: "",
    name: "",
    profession: "",
    address: "",
    status: "",
    phone: "",
    description: "",
    facebook_link:"",
    instagram_link:"",
    nickname: "",
    city: "",
    country: "",
  };

  aboutUserInfo: AboutUserInfo = new AboutUserInfo();
  userImageInfo: UserImagesInfo[] = [];
  verificationInfo: VerificationInfo = new VerificationInfo();
  aboutUsDataSide: string = "basicLifeStyle";
  verificationDataSide: string = "verificationStatus";
  noteFlag = false;
  myProfileFlag: boolean;
  protectedFlag: boolean;
  profileId: number;
  note: string =
    "Boost Your Profile or Unlock More Matches: Complete it now for a higher star rating!";
  aboutUsMenuItems: MenuItem[] = [
    {
      label: "Basic & Lifestyle",
      command: () => {
        this.selectedMenu("aboutUs", "basicLifeStyle");
      },
    },
    {
      label: "Personal Details",
      command: () => {
        this.selectedMenu("aboutUs", "personalDetails");
      },
    },
    {
      label: "Rating & Verification",
      command: () => {
        this.selectedMenu("aboutUs", "ratingVerification");
      },
    },
    {
      label: "Education & Career",
      command: () => {
        this.selectedMenu("aboutUs", "educationCarrer");
      },
    },
  ];
  verificationsMenuItems: MenuItem[] = [
    {
      label: "Verification Status",
      command: () => {
        this.selectedMenu("verificationStatus", "verificationStatus");
      },
    },
    {
      label: "Alim(Religious Scholar) Reference",
      command: () => {
        this.selectedMenu("verificationStatus", "alimReference");
      },
    },
    {
      label: "Email Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "emailVerification");
      },
    },
    {
      label: "Location Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "locationVerification");
      },
    },
    {
      label: "Phone Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "phoneVerification");
      },
    },
    {
      label: "Photo Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "photoVerification");
      },
    },
  ];

  whoViewedMe = [];
  recentlyViewed = [];
  documentsArray: any[] = [];
  alimArray: [] = [];
  alimTabs: MenuItem[] = [{ label: "Main " }, { label: "History" }];
  showAppliedAlimTab: boolean = false;
  alimActiveTab: MenuItem = this.alimTabs[0];

  @ViewChild(FileUploadComponent) fileUploader: FileUploadComponent;

  constructor(
    private messageService: MessageServiceService,
    private Jarvis: AuthService,
    private userProfileConverter: UserProfileConverterService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private NotificationEvents:EventNotificationService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.searchForm = this.fb.group({
      country: ['', Validators.required],
      age_from: ['', Validators.required],
      age_to: ['', Validators.required]
    })
  }

  
  
  onAgeFromChange(event: any): void {
    const selectedAgeFrom = event.target.value;
    this.filteredAgeTo = this.ageRange.filter(age => age >= selectedAgeFrom);
    if (this.searchForm.controls['age_to'].value < selectedAgeFrom) {
      this.searchForm.controls['age_to'].setValue('');
    }
  }
  onSearch(): void {
    // if (this.searchForm.valid) {
      const formValues = this.searchForm.value;
      this.router.navigate(['/user/site/search'], { queryParams: formValues, });
      // console.log('search Form Submitted:', formValues);
    // }
  }
  ngOnInit(): void {

    this.ageRange = Array.from({ length: 63 }, (_, i) => i + 18);
    this.profileService.getAllOptions().subscribe(
      (data) => {
        const countryOptionsObject = data?.data?.CountryOptions;
    
        // Convert the object to an array of country names
        this.countryOptions = Object.values(countryOptionsObject);
      },
    )

    this.initializeComponent();
    this.Jarvis.subscriptionPlans().subscribe(
      (data) => this.handleSubscriptionPlans(data),
      (error) => this.handleError(error)
    );

    // extracting id from url 
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('name');
    });

    // event animation and data fetching 
    this.fetchEventNotifications();

      
    // Event notification change with animation
 setInterval(() => {
      this.animate = true;
      setTimeout(() => {
        this.currentNotificationIndex = (this.currentNotificationIndex + 1) % this.notificationTexts?.length;
        this.currentNotificationText = this.notificationTexts[this.currentNotificationIndex]?.text??"";
        this.notificationSlug = this.notificationTexts[this.currentNotificationIndex]?.slug??"";
        this.animate = false;
      }, 500); // Duration of animation
    }, 5000);
  }

  
  // event notification section logic 

  // handling event notification data 
  fetchEventNotifications() {
    this.NotificationEvents.getProfileEvents().subscribe(
      (data) => this.handleEventNotification(data),
      (error) => this.handleError(error)
    );
  }



  stripHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return (div.textContent || div.innerText || '').replace(/\n|\r/g, ' ').trim();
  }
   formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  }
  
  
  
  isWithinUK(latitude: number, longitude: number): boolean {
    // Define the bounding box for the UK
    const UK_BOUNDING_BOX = {
      north: 60.84,  // Northernmost point (around Shetland)
      south: 49.84,  // Southernmost point (around Channel Islands)
      west: -8.649,  // Westernmost point (around Dingle, Ireland, considering some Northern Ireland locations)
      east: 1.768   // Easternmost point (around Lowestoft, England)
    };
    // Check if the coordinates are within the bounding box
    return latitude >= UK_BOUNDING_BOX.south && latitude <= UK_BOUNDING_BOX.north &&
           longitude >= UK_BOUNDING_BOX.west && longitude <= UK_BOUNDING_BOX.east;
  }
  
  

  handleEventNotification(notifications: any) {
    const formattedNotifications = notifications.map(notification => {
      const plainTitle = this.stripHtml(notification.title);
      const plainLocation = notification.location;
      const formattedDate = this.formatDate(notification.date);
      const slug = notification.slug;
      return {
        text: `${formattedDate} | <strong>${plainTitle}</strong> - ${plainLocation}`,
        slug: slug
      };
    });
    // console.log('current notifications', formattedNotifications);
    this.notificationTexts = formattedNotifications;
    if (this.notificationTexts.length > 0) {
      this.currentNotificationText = this.notificationTexts[this.currentNotificationIndex].text??"";
      this.notificationSlug = this.notificationTexts[this.currentNotificationIndex].slug??"";
    }
  }

  // event notification code end 

  initializeComponent() {
    this.activatedRouter.url.subscribe((paramMap) => {
      if (paramMap.length > 0) {
        const lastSegment = paramMap[paramMap.length - 1];
        const userId = lastSegment.path;
        this.profileId = +userId;
        this.getOwnProfileData();
        this.profileInfoLoadedFlag = false;

        // Set initial value of isMobileView based on window width
        const width = window.innerWidth;
        this.isMobileView = width < 960;
        this.generateFormData();
        this.noteFlag = true;
      }
    });
  }
  selectedMenu(menuType, menuItem) {
    this.showAppliedAlimTab = false;
    this.alimActiveTab = this.alimTabs[0];

    if (menuType == "aboutUs") {
      this.aboutUsDataSide = menuItem;
    }
    if (menuType == "verificationStatus") {
      this.verificationDataSide = menuItem;
      if (this.verificationDataSide == "alimReference") {
        this.saveType = "alimReference";
        this.generateFormData(this.saveType);
      }
      if (this.verificationDataSide == "emailVerification") {
        this.saveType = "emailVerification";
        this.generateFormData(this.saveType);
      }
      if (this.verificationDataSide == "phoneVerification") {
        this.saveType = "phoneVerification";
        this.generateFormData(this.saveType);
      }
    }
  }

  generateFormData(type?) {
    this.inputInfo = [];
    this.documentTypeInfo = [];

    if (type == "alimReference") {
      this.createInput(
        "Name",
        "name",
        "text",
        "Enter name of Alim",
        "text2",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Name cannot be blank",
          },
        ]
      );
      this.createInput(
        "Dial Code",
        "dialCode",
        "text",
        "Dial Code",
        "profileCreateDropdown",
        "",
        this.dialCodes,
        [
          {
            type: Validators.required,
            msg: "Dial code cannot be blank.",
          },
        ]
      );
      this.createInput(
        "Contact No",
        "contactNo",
        "text",
        "Enter contact no.",
        "text2",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Contact No cannot be blank.",
          },
        ]
      );
      this.createInput(
        "Email Address",
        "email",
        "email",
        "Enter valid email address ",
        "text2",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Email cannot be blank.",
          },
          {
            type: Validators.email,
            msg: "Enter valid email address",
          },
        ]
      );
    }
    if (type == "phoneVerification") {
      this.createInput("", "phoneNo", "text", "", "profileCreate", "", "", [
        {
          type: Validators.required,
          msg: "Please enter code.",
        },
      ]);
    }
    if (type == "emailVerification") {
      this.createInput(
        "",
        "emailVerification",
        "text",
        "Please enter code",
        "profileCreate",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Please enter code",
          },
        ]
      );
    }
    // if (type == "otherVerification") {
    this.createInput(
      "Document Type",
      "documentType",
      "text2",
      "Select document Type",
      "profileCreateDropdown",
      "",
      this.documentType,
      [
        {
          type: Validators.required,
          msg: "Document Type cannot be blank.",
        },
      ],
      true
    );
    // }
  }
  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    data,
    validatorsInfo,
    permanent?
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.data = data;
    inputObj.validatorsInfo = validatorsInfo;
    if (permanent == true) {
      this.documentTypeInfo.push(inputObj);
    } else {
      this.inputInfo.push(inputObj);
    }
  }

  getOwnProfileData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    this.loggedInUserStateId = data.data.user.state_id;
    this.loggedInUserId = data.data.user.id;
    this.loggedInUserEmail = data.data.user.email;


    this.isProfileProtected =
      data.data.user.is_profile_protected == 1 ? true : false;

    if (this.profileId == data.data.user.id) {
      this.myProfileFlag = true;
      this.initializingData(data);
    } else {
      this.getOtherProfileData(this.profileId);
    }
  }

  getOtherProfileData(id) {
    this.Jarvis.getOtherProfileData(id).subscribe(
      (data) => this.HanldeGetOtherProfileData(data),
      (error) => this.handleError(error)
    );
  }

  HanldeGetOtherProfileData(data) {
    this.profileUserId = data.data.user.id;
    this.protectedFlag =
      data.data.user.is_profile_protected == 1 ? true : false;
    this.isPicPrivate = data.data.profile.is_pic_private == 1 ? true : false;
    this.profileRequestSendStatus =
      data.data.sentProfileRequest == null
        ? "rejected"
        : data.data.sentProfileRequest.state_id == 0
        ? "pending"
        : data.data.sentProfileRequest.state_id == 1
        ? "accepted"
        : "rejected";

    this.profileRequestRecieved = data.data.receiveProfileRequest
      ? data.data.receiveProfileRequest
      : null;
    this.requestReceiveStatus = false;
    if (data.data.receiveProfileRequest) {
      if (data.data.receiveProfileRequest.state_id == 0) {
        this.requestReceiveStatus = true;
      } else {
        this.requestReceiveStatus = false;
      }
    } else {
      this.requestReceiveStatus = false;
    }

    if (data.data.sentProfileRequest) {
      if (data.data.sentProfileRequest.state_id == 0) {
        this.requestSentStatus = true;
      } else {
        this.requestSentStatus = false;
      }
    } else {
      this.requestSentStatus = true;
    }

    this.myProfileFlag = false;
    this.initializingData(data);
  }

  initializingData(data) {
    this.verificationInfo = new VerificationInfo();
    const userData = data.data.user;
    const profileData = data.data.profile;
    this.documentsArray = data.data.ids;
    this.alimArray = data.data.maulanaReference;
    let protectedProfileStatus;
    if (data.data.sentProfileRequest) {
      if (data.data.sentProfileRequest.state_id == 1) {
        protectedProfileStatus = false;
      } else {
        protectedProfileStatus = true;
      }
    } else {
      protectedProfileStatus = true;
    }
   
    this.userImageInfo = [];

    this.profilePageInfo = {
      profileHeroImage:
        "./assets/images/profile-hero.jpg",
      profileImage: userData.profile_file,
      name: userData.full_name,

      profession: "",
      address:
        userData.address != "" ? userData.address : "Address Not Defined",
      city:userData?.city ? userData?.city : "",
      country:userData?.country ? userData?.country : "",
      status:
        userData.email_verified == 1 && userData.phone_verified == 1
          ? "Verified"
          : "unverified",
      phone: userData.contact_no,
      description: userData.about_me ? userData.about_me : "",
      facebook_link:profileData?.fb_link,
      instagram_link:profileData?.insta_link,
      nickname:profileData?.nickname
    };

    // calculate age
    let age = null;
    if (data.data.user.date_of_birth) {
      const dob = new Date(data.data.user.date_of_birth);
      const currentDate = new Date();

      const years = currentDate.getFullYear() - dob.getFullYear();

      if (
        currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() &&
          currentDate.getDate() < dob.getDate())
      ) {
        age = years - 1;
      } else {
        age = years;
      }
    }
    // let timeDiff = Math.abs(
    //   Date.now() - new Date(userData.date_of_birth).getTime()
    // );

    // const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    
    this.aboutUserInfo.basicAndLifestyle = {
      id: userData.user_id,
      age: age || age == 0 ? `${age} years` : "Not Defined",
      dateOfBirth:
        userData.date_of_birth != null ? userData.date_of_birth : "N/A",
      maritalStatus: "",
      height:
        profileData?.height != null
          ? `${profileData?.height} cm`
          : "Not Defined",
      skinTone:
        profileData?.skin_tone !== ""
          ? this.complexion.find((el) => el.value == profileData?.skin_tone)
              ?.name
          : "Not Defined",

      prayStatus:
        profileData?.pray_regularly != null
          ? this.pray_regularly.find(
              (el) => el.value == profileData?.pray_regularly
            )?.name
          : "Not Defined",
      childrenStatus:
      profileData?.children != null 
      ? (profileData.children > 0 ? "Yes" : "No") 
      : "Not Defined",

          

      bodyWeight:
        profileData?.weight != null
          ? `${profileData?.weight} kgs`
          : "Not Defined",
      smoke:
        profileData?.smoke != null
          ? this.smoke.find((el) => el.value == profileData?.smoke)?.name
          : "Not Defined",
      contactNo: userData.contact_no,
      bodyType:
        profileData?.body_type != null && profileData?.body_type != ""
          ? this.build.find((el) => el.value == profileData?.body_type)?.name
          : "Not Defined",
      workingStatus: "",
      ithnaAshri: "",
      language: "",
    };

    if (profileData?.children != null || profileData?.children == "") {
      this.aboutUserInfo.basicAndLifestyle.noOfChildren = profileData?.children;
    }

    // languages
    let languages: string[] = [];
    if (userData.language) {
      const languageValues = userData.language.split(",");
      const observables = [];
      for (let lang of languageValues) {
        observables.push(this.userValueToString("language", lang));
      }
      forkJoin(observables).subscribe((results) => {
        for (let language of results) {
          languages.push(language as string);
        }
        const joinedLanguages = languages.join(", ");
        this.aboutUserInfo.basicAndLifestyle.language = joinedLanguages;
      });
    } else {
      this.aboutUserInfo.basicAndLifestyle.language = "Not Defined";
    }

    this.userValueToString("shia", profileData?.is_shia).subscribe((isShia) => {
      this.aboutUserInfo.basicAndLifestyle.ithnaAshri = isShia;
    });

    this.userValueToString(
      "workingStatus",
      profileData?.working_status
    ).subscribe((workingStatus) => {
      this.aboutUserInfo.basicAndLifestyle.workingStatus = workingStatus;
    });

    this.aboutUserInfo.personalDetails = {
      
      id: userData.user_id,
      gender: profileData?.gender == 1 ? "Male" : "Female",
      country: userData.country == "" ? "Not Defined" : userData.country,
      residency: "",
      syed: profileData?.is_syed == 0 ? "Yes" : "No",
      city: userData.city == "" ? "Not Defined" : userData.city,
      ethnicity: "",
    };

    this.userValueToString("residency", profileData?.residency).subscribe(
      (residency) => {
        this.aboutUserInfo.personalDetails.residency = residency;
      }
    );

    this.userValueToString("ethnicity", userData.ethnicity).subscribe(
      (ethnicity) => {
        this.aboutUserInfo.personalDetails.ethnicity = ethnicity;
      }
    );

    this.aboutUserInfo.ratingAndVerification = {
      ratings: profileData?.star_rating,
      email_verified: userData.email_verified == 0 ? "unVerified" : "Verified",
      maulana:
        userData.maulana_refrence == 0 || userData.maulana_refrence == null
          ? "unVerified"
          : "Verified",
      phone: userData.phone_verified == 0 ? "unVerified" : "Verified",
    };

    // update educationAndCareer later
    this.aboutUserInfo.educationAndCareer = {
      education: "",
      profession: "",
      income: profileData?.income
        ? `${profileData?.income} ${
            profileData?.currency ? profileData?.currency : ""
          } `
        : "Not Defined",
    };

    this.verificationInfo.verificationStatus = {
      alimReference:
        userData.maulana_refrence == 0 || userData.maulana_refrence == null
          ? "unVerified"
          : "Verified",
      email: userData.email_verified == 0 ? "unVerified" : "Verified",
      location:
        userData.is_location_verified == "" ||
        userData.is_location_verified == null
          ? "unVerified"
          : "Verified",
      phone: userData.phone_verified == 0 ? "unVerified" : "Verified",
      photo: userData.photo_upload == 0 ? "unVerified" : "Verified",
    };
    let starCount: number = 0;
    if (userData.maulana_refrence == 1) {
      starCount++;
    }
    if (userData.email_verified == 1) {
      starCount++;
    }
    if (userData.latitude != "") {
      starCount++;
    }
    if (userData.phone_verified == 1) {
      starCount++;
    }
    if (userData.photo_upload == 1) {
      starCount++;
    }

    this.verificationInfo.rating = starCount;
    this.verificationInfo.noOfStars =
      this.verificationInfo.rating == 1
        ? "one"
        : this.verificationInfo.rating == 2
        ? "two"
        : this.verificationInfo.rating == 3
        ? "three"
        : this.verificationInfo.rating == 4
        ? "four"
        : this.verificationInfo.rating == 5
        ? "five"
        : "zero";

    this.noteFlag =
      userData.email_verified == 1 && userData.phone_verified == 1
        ? false
        : true;
    const userValueToStringObservables = [
      this.userValueToString(
        "relationship_status",
        profileData?.relationship_status
      ),
      this.userValueToString("country", userData.country),
      this.userValueToString("education", profileData?.education),
      this.userValueToString("profession", profileData?.profession),
    ];
    
    forkJoin(userValueToStringObservables).subscribe(
      ([maritalStatusValue, country, education, profession]) => {
        this.aboutUserInfo.basicAndLifestyle.maritalStatus = maritalStatusValue;
        this.aboutUserInfo.personalDetails.country = country;
        this.aboutUserInfo.educationAndCareer.education = education;
        this.aboutUserInfo.educationAndCareer.profession = profession;
        this.profilePageInfo.profession = profession;
        
        // Set the flag after all assignments are complete
        this.profileInfoLoadedFlag = true;
      }
    );

    this.renderProfilePictures(data.data.photos);

    this.accordionObj.push(this.aboutUserInfo);
    this.accordionObj.push(this.verificationInfo);

    if (this.myProfileFlag) {
      this.getViewedData(data);
      this.initializeDocuments();
      this.initializeAlimData();
    }
    // this.profileInfoLoadedFlag = true;
  }
  

  renderProfilePictures(images) {
    if (images.length > 0) {
      this.userImageInfo = [];

      images.forEach((element) => {
        this.userImageInfo.push({
          imgPath: `${element.absoluteUrl}&thumb=0`,
          thumbnailImageSrc: `${element.absoluteUrl}`,
          alt: element.title,
          title: element.title,
          id: element.id,
          isApproved: element.is_approved,
          isPrimary: element.is_primary,
        });
      });

      for (let image of images) {
        if (image.is_approved == 1) {
          this.photoVerifiedFlag = true;
          break;
        }
      }
    }
  }

  onConfirmation(event) {
    if (event.modelName == "profile-picture") {
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
      this.Jarvis.setProfileImage(event.imageId).subscribe(
        (data) => this.handleSetProfileImage(data),
        (error) => this.handleError(error)
      );
    }
    if (event.modelName == "delete") {
      if (event.decision == true) {
        this.Jarvis.deleteImage(event.imageId).subscribe(
          (data) => this.handleDeleteImage(data, event.imageId),
          (error) => this.handleError(error)
        );
      }
    }
  }

  handleDeleteImage(data, id) {
    if (data.data.success) {
      this.deleteImageIdFlag = id;
      this.messageService.addMessages(
        `success`,
        `Success`,
        "Image has been deleted."
      );
    }
  }

  handleSetProfileImage(data) {
    if (data) {
      this.profileService.triggerProfileImageChange();
    }
    this.messageService.addMessages(
      `success`,
      `Success`,
      "Your profile picture has been updated."
    );
    this.getOwnProfileData();
  }

  getViewedData(data) {
    this.whoViewedMe = [];
    this.recentlyViewed = [];
    if (data.data.searchs.length > 0) {
      for (let val of data.data.searchs) {
        this.Jarvis.getOtherProfileData(val.user_id).subscribe(
          (data) => this.handleGetViewedData(data, "searches"),
          (error) => this.handleError(error)
        );
      }
    }
    if (data.data.views.length > 0) {
      for (let val of data.data.views) {
        this.Jarvis.getOtherProfileData(val.created_by_id).subscribe(
          (data) => this.handleGetViewedData(data, "views"),
          (error) => this.handleError(error)
        );
      }
    }
  }

  handleGetViewedData(data, name) {
    let age = null;
    if (data.data.user.date_of_birth) {
      const dob = new Date(data.data.user.date_of_birth);
      const currentDate = new Date();

      const years = currentDate.getFullYear() - dob.getFullYear();

      if (
        currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() &&
          currentDate.getDate() < dob.getDate())
      ) {
        age = years - 1;
      } else {
        age = years;
      }
    }

    if (name === "searches") {
      let protectedProfileStatus;
      if (data.data.sentProfileRequest) {
        if (data.data.sentProfileRequest.state_id == 1) {
          protectedProfileStatus = false;
        } else {
          protectedProfileStatus = true;
        }
      } else {
        protectedProfileStatus = true;
      }
      this.recentlyViewed.push({
        id: data.data.user.id,
        image: data.data.user.profile_file,

        fullName: data.data.profile.nickname,
        age: age,
      });
    }
    if (name === "views") {
      let protectedProfileStatus;
      if (data.data.sentProfileRequest) {
        if (data.data.sentProfileRequest.state_id == 1) {
          protectedProfileStatus = false;
        } else {
          protectedProfileStatus = true;
        }
      } else {
        protectedProfileStatus = true;
      }
      this.whoViewedMe.push({
        id: data.data.user.id,
        image: data.data.user.profile_file,
        fullName: data.data.profile.nickname,
        age: age,
      });
    }
  }

  updateData(value, modelName, sectionName?) {
    if (sectionName == "alimReference") {
      this.verificationInfo.alimReferenceInfo[modelName] =
        value && value.name ? value.name : value;
    }
    if (sectionName == "otherVerification") {
      this.verificationInfo.otherVerification[modelName] =
        value && value.value != undefined
          ? value.value
          : value && value.image
          ? value.image
          : value;
    }

    if (value && modelName == "phoneNo") {
      this.profileInputInfo[modelName] = value;
    }
    if (value && modelName == "emailVerification") {
      this.profileInputInfo[modelName] = value;
    }
  }
  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }

  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }

    const errorFlag =
      this.saveType == "alimReference" ||
      this.saveType == "photoVerification" ||
      this.saveType == "phoneVerification" ||
      this.saveType == "emailVerification" ||
      this.saveType == "photoVerification"
        ? this.inputInfo.find((item) => item.errorFlag == true)
        : false;
    if (errorFlag) {
      return;
    }

    if (this.saveType == "alimReference") {
      const formData = new FormData();
      formData.append(
        "Reference[name]",
        this.verificationInfo.alimReferenceInfo["name"]
      );
      formData.append(
        "Reference[dial_code]",
        this.verificationInfo.alimReferenceInfo["dialCode"]
      );
      formData.append(
        "Reference[contact_no]",
        this.verificationInfo.alimReferenceInfo["contactNo"]
      );
      formData.append(
        "Reference[address]",
        this.verificationInfo.alimReferenceInfo["email"]
      );

      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
      this.Jarvis.addAlimReference(formData).subscribe(
        (data) => this.handleData(data),
        (error) => this.handleError(error)
      );
    }

    if (this.saveType == "phoneVerification") {
      this.displayPhoneVerifyDialog = false;
      const formData = new FormData();
      formData.append("code", this.profileInputInfo["phoneNo"]);
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
      this.Jarvis.verifyPhoneNo(formData).subscribe(
        (data) => this.handleData(data),
        (error) => this.handleError(error)
      );
    }
    if (this.saveType == "emailVerification") {
      this.displayPhoneVerifyDialog = false;

      const formData = new FormData();
      formData.append("code", this.profileInputInfo["emailVerification"]);
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
      this.Jarvis.verifyEmail(formData).subscribe(
        (data) => this.handleData(data),
        (error) => this.handleError(error)
      );
    }
  }

  preSubmit2() {
    this.counterInputs = 0;
    this.changeFlag2 = !this.changeFlag2;
  }

  onSubmit2() {
    this.counterInputs++;
    if (this.counterInputs < this.documentTypeInfo.length) {
      return;
    }

    let errorFlag = this.documentTypeInfo.find(
      (item) => item.errorFlag == true
    );
    if (errorFlag) {
      return;
    }

    const otherData = new FormData();
    this.verificationInfo.otherVerification.image;
    if (this.verificationInfo.otherVerification.image == undefined) {
      this.messageService.addMessages(
        "error",
        "Image Missing",
        "Please upload into image type"
      );
      return;
    }

    const objectURL =
      this.verificationInfo.otherVerification.image.currentFiles[0].objectURL
        .changingThisBreaksApplicationSecurity;
    const imgName =
      this.verificationInfo.otherVerification.image.currentFiles[0].name;
    // Fetch the image data as a Blob
    fetch(objectURL)
      .then((response) => response.blob())
      .then((blob) => {
        // Append the Blob to the FormData
        otherData.append("File[file][]", blob, imgName);
        otherData.append(
          "File[document_type]",
          this.verificationInfo.otherVerification.documentType
        );

        otherData.append("File[model_type_id]", "1");
        otherData.append("File[type_id]", "3");

        // Send the FormData to the backend
        this.messageService.addMessagesTimeLess(
          `info`,
          `Info`,
          `Please Wait...`
        );

        this.Jarvis.verifyDocuments(otherData).subscribe(
          (data) => this.handleVerifyDocuments(data),
          (error) => this.handleError(error)
        );
      })
      .catch((error) => {
        // Handle errors here
      });
  }

  handleVerifyDocuments(data) {
    this.messageService.addMessages(`success`, `Success`, data.message);
    this.clearFileUploader();
    this.getOwnProfileData();
    this.generateFormData(this.saveType);
  }

  handleData(data) {
    const msg = data.msg
      ? data.msg
      : data.message
      ? data.message
      : data.data.msg
      ? data.data.msg
      : data.data.message;
    this.messageService.addMessages(`success`, `Success`, msg);
    this.getOwnProfileData();
    this.generateFormData(this.saveType);
  }

  // generate activation key in database
  recoverWithoutEmail() {}

  verifyEmail() {
    this.messageService.addMessagesTimeLess(`info`, `Info`, `Please Wait...`);
    this.Jarvis.sendEmailToken().subscribe(
      (data) => this.handleSendEmailToken(data),
      (error) => this.handleError(error)
    );
  }

  handleSendEmailToken(data) {
    this.messageService;
    this.messageService.addMessages(`success`, `Success`, data.data.message);
    this.displayPhoneVerifyDialog = true;
    // this.Jarvis.verifyEmail(data.data.activation_key).subscribe(
    //   (data) => this.handleVerifyEmailData(data),
    //   (error) => this.handleError(error)
    // );
  }

  handleVerifyEmailData(data) {
    const msg = data?.data?.msg ? data?.data?.msg : data?.data?.message;
    this.messageService.addMessages(`success`, `Success`, msg);
    this.generateFormData("emailVerification");
  }

  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error.errors.error
        ? error.error.errors.error
        : error.error.errors && error.error.errors.message
        ? error.error.errors.message
        : error.error && error.error.reason
        ? error.error.reason
        : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }
  verifyLocation() {
    this.Jarvis.verifyLocation().subscribe(
      (data) => this.handleVerifyEmailData(data),
      (error) => this.handleError(error)
    );
  }
  handleVerifyLocationData(data) {}

  verifyPhoneNo() {
    this.displayPhoneVerifyDialog = true;
    // this.counterInputs++;
    // if (this.counterInputs < this.inputInfo.length) {
    //   return;
    // }
    this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
    this.Jarvis.getPhonetoken().subscribe(
      (data) => this.handleVerifyPhoneNoData(data),
      (error) => this.handleError(error)
    );
  }
  handleVerifyPhoneNoData(data) {
    this.messageService.addMessages(`success`, `Success`, data.data.message);
  }

  onAboutTabOpen(e) {
    // let index = e.index;
  }
  onVerificationsTabOpen(e) {
    let index = e.index;

    if (index == 0) {
      this.selectedMenu("verificationStatus", "verificationStatus");
    }
    if (index == 1) {
      this.selectedMenu("verificationStatus", "alimReference");
    }
    if (index == 2) {
      this.selectedMenu("verificationStatus", "emailVerification");
    }
    if (index == 3) {
      this.selectedMenu("verificationStatus", "locationVerification");
    }
    if (index == 4) {
      this.selectedMenu("verificationStatus", "phoneVerification");
    }
    if (index == 5) {
      this.selectedMenu("verificationStatus", "phoneVerification");
    }
  }

  userValueToString(label: string, value: string): Observable<string> {
    return this.userProfileConverter.convertToText(label, value);
  }

  handleRequestData(data) {
    this.messageService.addMessages(`success`, `Success`, data.data.msg);
    this.getOwnProfileData();
  }

  handleBlockData(data) {
    this.messageService.addMessages(`success`, `Success`, data.data.msg);
  }

  handleprotectProfileData(data) {
    if (data.data.status == 200) {
      this.Jarvis.getProfileData().subscribe(
        (data) => this.handleGetOwnIsProfileProtectedData(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleGetOwnIsProfileProtectedData(data) {
    if (data.data.user.is_profile_protected == 1) {
      this.isProfileProtected = true;
      this.messageService.addMessages(
        `success`,
        `Success`,
        "Your profile is protected"
      );
    } else if (data.data.user.is_profile_protected == 0) {
      this.isProfileProtected = false;
      this.messageService.addMessages(
        `success`,
        `Success`,
        "Your profile is not protected"
      );
    }
  }

  onButtonClick(buttonName, id?) {
    if (buttonName === "edit-profile") {
      this.router.navigateByUrl("user/edit-profile");
    }

    if (buttonName === "invite-to-connect") {
      let requestProfileData = {
        id: this.profileUserId,
      };
      this.Jarvis.requestProfile(requestProfileData).subscribe(
        (data) => this.handleRequestData(data),
        (error) => this.handleError(error)
      );
    }

    if (buttonName === "invite-to-connect-pending") {
      this.messageService.addMessages(
        `info`,
        `Info`,
        "Request has already been sent"
      );
    }

    if (buttonName === "protected-profile") {
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);

      this.Jarvis.protectProfile({}).subscribe(
        (data) => this.handleprotectProfileData(data),
        (error) => this.handleError(error)
      );
    }

    if (buttonName === "accept-request") {
      const data = {
        id: id,
        state: 1,
      };
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);

      this.Jarvis.acceptOrReject(data).subscribe(
        (data) => this.handleAcceptOrReject(data, "accept"),
        (error) => this.handleError(error)
      );
    }

    if (buttonName === "reject-request") {
      const data = {
        id: id,
        state: 2,
      };
      this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
      this.Jarvis.acceptOrReject(data).subscribe(
        (data) => this.handleAcceptOrReject(data, "reject"),
        (error) => this.handleError(error)
      );
    }
  }

  handleAcceptOrReject(data, from) {
    if (from == "accept") {
      if (data.data.msg == "request successfully.") {
        this.messageService.addMessages(
          `success`,
          `Success`,
          "Request has been accepted"
        );
      }
    }
    if (from == "reject") {
      if (data.data.msg == "request successfully.") {
        this.messageService.addMessages(
          `success`,
          `Success`,
          "Request has been rejected"
        );
      }
    }
    this.getOwnProfileData();
  }

  ImageUploadDialogClosed() {
    this.displayImageUploadDialog = false;
  }
  phoneVerifyDialogClosed() {
    this.displayPhoneVerifyDialog = false;
  }

  showImageUplaoderDialog() {
    this.displayImageUploadDialog = true;
  }

  dialogUpdateData(data) {
    if (data.cardButtonName == "profileImageUploadButton") {
      fetch(data.cropedImage)
        .then((response) => response.blob())
        .then((blob) => {
          let imageData = {
            id: this.loggedInUserId,
            formData: new FormData(),
          };
          // Append the Blob to the FormData
          imageData.formData.append("qqfile", blob, data.cropedImageName);

          this.messageService.addMessagesTimeLess(
            `info`,
            `Info`,
            `Please Wait...`
          );

          // Send the FormData to the backend
          this.Jarvis.uploadImage(imageData).subscribe(
            (data) => this.handleUploadImage(data),
            (error) => this.handleUploadImageError(error)
          );
        })
        .catch((error) => {
          // Handle errors here
        });
    }
  }

  handleUploadImage(data) {
    if (data.success == true) {
      this.messageService.addMessages(
        `success`,
        ``,
        "Image Uploaded Successfully. Waiting for approval"
      );
      this.getImagesData();
    }
    if (data.success == false) {
      this.messageService.addMessages(`error`, ``, data.error);
    }
  }

  handleUploadImageError(err) {
    this.messageService.addMessages(`error`, ``, err.error);
  }

  getImagesData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetImagesData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetImagesData(data) {
    if (data) {
      this.renderProfilePictures(data.data.photos);
    }
  }

  profileClicked(data) {
  
    if (data && data.cardName == "viewed-btn") {
      this.router.navigateByUrl(`user/${data.id}`);
    }
  }

  subscribeNow() {
    this.router.navigate(["user/plans"]);
  }

  // getting subscription plan if have any 
  handleSubscriptionPlans(data) {
    this.inputInfo = [];
    this.subscriptionPlansCopy = data;
    for (let info of data) {
      this.subscriptionpageinfo.subsCardData.push({
        subsTitle:
          info.title == "Premium Subscription" ? "Premium Plan" : "Basic Plan",
        subsPara:
          info.title == "Premium Subscription"
            ? "Go premium with Shia Spouse"
            : "Best to start with Shia Spouse",
        subsPrice: "0",
        subsradio: new InputInfo(),
        subsCardPara: [
          { papraOne: "View chat message sent by other members" },
          { papraOne: `Connection limit - ${info.connection} per month` },
          {
            papraOne: `${info.messages_per_month} messages/month for each profile`,
          },
          { papraOne: "Auto-renewable - off" },
        ],
      });
      const membershipsArray = [];

      for (const membership of info.memberships) {
        membershipsArray.push({
          key: `£${membership.amount} for ${membership.duration} month/s`,
          value: membership.amount.toString(),
          id: membership.id,
        });
      }

      this.createInput(
        ``,
        info.title == `Premium Subscription` ? `PremiumPlan` : `BasicPlan`,
        `radioButton`,
        ``,
        `radioButton`,
        "",
        membershipsArray,
        "",
        "",
      
      );
    }
    this.Jarvis.mySubscriptionPlan().subscribe(
      (data) => this.handleMySubscriptionPlan(data),
      (error) => this.handleError(error)
    );
  }

  handleMySubscriptionPlan(data) {
    this.subscribedFlag = false;

    if (data.membership_id) {
      this.subscribedFlag = true;
      let subscribedPlan = this.subscriptionPlansCopy.find((el) =>
        el.memberships.some((el) => el.id == data.membership_id)
      );
      this.subscribedIData = {
        title: subscribedPlan.title,
        description: `You are subscribed to ${subscribedPlan.title} of  ${
          subscribedPlan.memberships.find((el) => el.id == data.membership_id)
            .duration
        }/ Month(s) from date ${data.start_date} to ${data.end_date}`,
      };
    } else {
    }
  }


  initializeDocuments() {
    this.idDocuments = [];
    if (this.documentsArray.length > 0) {
      this.documentsArray.forEach((el) => {
        let tempData = new ProfileDocumentsInfo();
        tempData.id = el["id"];
        tempData.title = el["title"];
        tempData.image = el["absoluteUrl"];
        tempData.documentType = this.documentType.find((data) => {
          return data.value == el["document_type"];
        })?.name;
        tempData.status =
          el["state_id"] == 0
            ? "Not Verified"
            : el["state_id"] == 1
            ? "Verified"
            : "Rejected";

        this.idDocuments.push(tempData);
      });
    }
  }

  initializeAlimData() {
    this.alimData = [];
    this.alimArray.forEach((el) => {
      let tempData = new ProfileAlimInfo();
      tempData.id = el["id"];
      tempData.name = el["name"];
      tempData.address = el["address"];
      tempData.dialCode = el["dial_code"];
      tempData.contactNo = el["contact_no"];

      tempData.status =
        el["state_id"] == 0
          ? "Not Verified"
          : el["state_id"] == 1
          ? "Verified"
          : "Rejected";
      this.alimData.push(tempData);
    });
  }

  deleteDocument(id) {
    this.Jarvis.documentDelete(id).subscribe(
      (data) => this.handleDeleteDocument(data),
      (error) => this.handleError(error)
    );
  }

  handleDeleteDocument(data) {
    this.messageService.addMessages(
      `success`,
      ``,
      "Document Deleted Successfully!"
    );

    this.getOwnProfileData();
  }

  alimTabClicked(event) {
    this.showAppliedAlimTab = event.label == "History" ? true : false;
  }

  sendMessage(id) {
    this.router.navigate(["user/chat/panel"], {
      queryParams: { id },
    });
  }

  userProfileView(){
    this.router.navigate([`user/profile-view/${this.userId}`]);
  }

  clearFileUploader() {
    if (this.fileUploader) {
      this.fileUploader.clearUploader();
    }
  }

  onUploadError(error) {
    this.messageService.addMessages(`error`, ``, error);
  }
}




