<div class="isUserUk eventRegistration-main-inner">
  <!-- <div class="eventRegister-container">
      <h3>Registered</h3>
      <p>Thank you for registering to the event.t</p>
    </div> -->
  <div class="eventRegister-container-card">
    <div class="event-box">
      <div class="head">
        <h3 style="margin-top: 5px">Registered</h3>
        <h4>Thank you for registering to the event.</h4>
      </div>
      <!-- <h1 style="text-align: center">
        <div class="checkmark-circle">
          <div class="background"></div>
          <div class="checkmark draw"></div>
        </div>
      </h1>
      <h4 class="text-center">Event Card</h4> -->
      <div class="details_h">
        <div class="business2">
          <div class="front">
            <div class="red">
              <div class="head">
                <img
                  src="https://shiaspouse.org/themes/base/matrimonial/images/shia_spouse.png"
                  alt="logo"
                />
                <div>
                  <h2>ShiaSpouse Member Card</h2>
                  <p>{{ registrationCardData.event.title }}</p>
                  <p>
                    {{ registrationCardData.event.date | date : "dd MMM yyyy" }}
                  </p>
                  <div class="star_card">
                    <ng-container
                      *ngFor="
                        let point of [registrationCardData.eventApplied.points]
                      "
                    >
                      <ng-container
                        *ngFor="let i of [0, 1, 2, 3, 4]; let index = index"
                      >
                        <i class="fas fa-star" *ngIf="index < point"></i>
                        <i class="far fa-star" *ngIf="index >= point"></i>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="avatar">
                <div class="img">
                  <img
                    class="user-img-header"
                    [src]="registrationCardData.userData.user.profile_file"
                    width="50"
                    height="50"
                    alt="ShiaSpouse"
                    style="
                      object-fit: cover;
                      object-position: top center;
                      vertical-align: middle;
                    "
                  />
                </div>
              </div>
            </div>
            <div class="infos">
              <div class="top_bold">
                <div class="col25">
                  <p>
                    Name: {{ registrationCardData.userData.user.full_name }}
                  </p>
                </div>
                <div class="col25">
                  <p>ID: {{ registrationCardData.userData.user.id }}</p>
                </div>
                <div class="col25">
                  <p>
                    Age Group: {{ registrationCardData.eventApplied.age_group }}
                  </p>
                </div>
              </div>
              <div>
                <div class="col33">
                  <img src="https://img.icons8.com/ios/50/ffffff/family.png" />
                  <div>
                    <p>{{ this.personalInfo.relationship_status }}</p>
                  </div>
                </div>
                <div class="col33">
                  <img
                    src="https://img.icons8.com/ios/50/ffffff/education.png"
                  />
                  <div>
                    <p>{{ this.personalInfo.education }}</p>
                  </div>
                </div>
                <div class="col33">
                  <img
                    src="https://img.icons8.com/ios/50/ffffff/portfolio.png"
                  />
                  <div>
                    <p>{{ this.personalInfo.profession }}</p>
                  </div>
                </div>
              </div>
              <div>
                <div class="col33">
                  <img
                    src="https://img.icons8.com/ios/50/ffffff/contract-job.png"
                  />
                  <div>
                    <p>
                      {{
                        registrationCardData.userData.profile.is_syed == 0
                          ? "Syed"
                          : "Non-Syed"
                      }}
                    </p>
                  </div>
                </div>
                <div class="col33">
                  <img src="https://img.icons8.com/ios/50/ffffff/address.png" />
                  <div>
                    <p>
                      {{ registrationCardData.eventApplied.eventApplied.city }},
                      {{ registrationCardData.eventApplied.eventApplied.state }}
                    </p>
                  </div>
                </div>
                <div class="col33">
                  <img
                    src="https://img.icons8.com/ios/50/ffffff/flag-of-europe.png"
                  />
                  <div>
                    <p>United Kingdom</p>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div>
                  <p class="disclaimer">
                    Note: This card belongs to the name holder and should only
                    be used during ShiaSpouse match making event. Please return
                    to the reception if it does not belong to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
