import { AuthGuard } from "./auth.guard";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { TokenStorageService } from "../auth/token-storage.service";
import { isPlatformBrowser } from "@angular/common";
/**
 * It is a decorator that marks a class as available to be provided and injected as a dependency.
 */
@Injectable({
  providedIn: "root",
})
export class LoggedInGuard implements CanActivate {
  /**
   * This is our contructor
   * @param router  It is a parameter of a service that provides navigation among views and URL manipulation capabilities.
   * @param tokenStorage It is a parameter of tokenstorage services
   * @param authGuardit Is a parameter of tokenstorage services
   */
  isBrowser: Boolean = false;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    private tokenStorage: TokenStorageService,
    private authGuard: AuthGuard
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

  }
  /**
   * This functions checks if the token is expired or not adn then proceeds accordingly
   * @returns
   */
  canActivate() {
    if (this.isBrowser) {
      if (!this.tokenStorage.getToken()) {
        this.router.navigate(["login"]);
        return false;
      }
      return true;
    }
    return null;



  }
}
