import { Component, OnInit } from '@angular/core';
import { DummyCookieConsentService } from '../dummy-cookie-consent.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent implements OnInit {
  isVisible: boolean = false;

  constructor(private cookieConsentService: DummyCookieConsentService) {}

  ngOnInit(): void {
    // Check if there is any value for 'cookie' in localStorage
    const cookieValue = localStorage.getItem('cookies');
  
    // Hide the banner if 'cookie' has any value, otherwise show it
    this.isVisible = !cookieValue;
  }

  acceptCookies(): void {
    this.cookieConsentService.setConsent(true);
    this.isVisible = false;
  }

  rejectCookies(): void {
    this.cookieConsentService.setConsent(false);
    this.isVisible = false;
  }
}
