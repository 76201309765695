import { Injectable } from "@angular/core";
import { ProfileService } from "./profile.service";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class UserProfileConverterService {
  private baseUrl = environment.url;
  constructor(private profileService: ProfileService) {}

  convertToText(label: string, value: string): Observable<string> {
    if (isNaN(Number(value))) {
      return of(value);
    }
    if (label == "country") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const countryOptions = data.data.CountryOptions;
            for (const [key, val] of Object.entries(countryOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }
            observer.next("N/A");
            observer.complete();
          },
          (error) => {
            observer.next("N/A");
            observer.complete();
          }
        );
      });
    }
    if (label == "relationship_status") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const maritalOptions = data.data.MaritalOptions;
            const index = parseInt(value, 10); // Convert value to a number
            if (!isNaN(index) && index >= 0 && index < maritalOptions.length) {
              observer.next(maritalOptions[index]);
            } else {
              observer.next("N/A");
            }
            observer.complete();
          },
          (error) => {
            observer.next("N/A");
            observer.complete();
          }
        );
      });
    }
    if (label == "education") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const educationOptions = data.data.EducationOptions;
            for (const [key, val] of Object.entries(educationOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }

            observer.next("N/A");
            observer.complete();
          },
          (error) => {
            observer.next("N/A");
            observer.complete();
          }
        );
      });
    }
    if (label == "profession") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const professionOptions = data.data.ProfessionOptions;
            for (const [key, val] of Object.entries(professionOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }

            observer.next("N/A");
            observer.complete();
          },
          (error) => {
            observer.next("N/A");
            observer.complete();
          }
        );
      });
    }

    if (label == "workingStatus") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const workingStatusOptions = data.data.WorkingStatusOptions;
            for (const [key, val] of Object.entries(workingStatusOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }

            observer.next("Not Defined");
            observer.complete();
          },
          (error) => {
            observer.next("Not Defined");
            observer.complete();
          }
        );
      });
    }

    if (label == "syed") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const syedOptions = data.data.Syed;
            for (const [key, val] of Object.entries(syedOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }

            observer.next("Not Defined");
            observer.complete();
          },
          (error) => {
            observer.next("Not Defined");
            observer.complete();
          }
        );
      });
    }

    if (label == "shia") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const shiaOptions = data.data.IsShiaOptions;
            for (const [key, val] of Object.entries(shiaOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }

            observer.next("Not Defined");
            observer.complete();
          },
          (error) => {
            observer.next("Not Defined");
            observer.complete();
          }
        );
      });
    }

    if (label == "residency") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const residencyOptions = data.data.ResidencyOptions;
            for (const [key, val] of Object.entries(residencyOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }

            observer.next("Not Defined");
            observer.complete();
          },
          (error) => {
            observer.next("Not Defined");
            observer.complete();
          }
        );
      });
    }

    if (label == "ethnicity") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const ethnicityOptions = data.data.EthnicityOptions;
            for (const [key, val] of Object.entries(ethnicityOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }

            observer.next("Not Defined");
            observer.complete();
          },
          (error) => {
            observer.next("Not Defined");
            observer.complete();
          }
        );
      });
    }

    if (label == "language") {
      return new Observable((observer) => {
        this.profileService.getAllOptions().subscribe(
          (data) => {
            const languageOptions = data.data.LanguageOptions;
            for (const [key, val] of Object.entries(languageOptions)) {
              if (value == key) {
                observer.next(val.toString());
                observer.complete();
                return;
              }
            }
            observer.next("Not Defined");
            observer.complete();
            return;
          },
          (error) => {
            observer.next("Not Defined");
            observer.complete();
          }
        );
      });
    } else {
      return new Observable((observer) => {
        observer.next("Not Defined");
        observer.complete();
      });
    }
  }

  getProfileImage(
    myProfileFlag: boolean,
    userData: any,
    protectedProfileStatus: boolean
  ): string {
    switch (true) {
      case userData.profile_file &&
        myProfileFlag == true &&
        (userData.is_profile_protected == 0 ||
          userData.is_profile_protected == 1):
        return `${userData.profile_file}`;
      case !userData.profile_file &&
        myProfileFlag == true &&
        (userData.is_profile_protected == 0 ||
          userData.is_profile_protected == 1) &&
        userData.gender == 1:
        return `${this.baseUrl}/themes/base/img/default.png`;
      case !userData.profile_file &&
        myProfileFlag == true &&
        (userData.is_profile_protected == 0 ||
          userData.is_profile_protected == 1) &&
        userData.gender == 2:
        return `${this.baseUrl}/themes/base/img/lady_default.png`;
      case userData.profile_file &&
        myProfileFlag == false &&
        userData.is_profile_protected == 0:
        return `${userData.profile_file}`;
      case (!userData.profile_file || userData.profile_file) &&
        myProfileFlag == false &&
        userData.gender == 1 &&
        userData.is_profile_protected == 1 &&
        protectedProfileStatus == true:
        return `${this.baseUrl}/themes/base/img/man_lock.png`;
      case (!userData.profile_file || userData.profile_file) &&
        myProfileFlag == false &&
        userData.gender == 1 &&
        userData.is_profile_protected == 1 &&
        protectedProfileStatus == false:
        return `${userData.profile_file}`;
      case (!userData.profile_file || userData.profile_file) &&
        myProfileFlag == false &&
        userData.gender == 2 &&
        userData.is_profile_protected == 1 &&
        protectedProfileStatus == true:
        return `${this.baseUrl}/themes/base/img/girl_lock.png`;
      case (!userData.profile_file || userData.profile_file) &&
        myProfileFlag == false &&
        userData.gender == 2 &&
        userData.is_profile_protected == 1 &&
        protectedProfileStatus == false:
        return `${userData.profile_file}`;
      case !userData.profile_file &&
        myProfileFlag == false &&
        userData.gender == 1 &&
        userData.is_profile_protected == 0:
        return `${this.baseUrl}/themes/base/img/default.png`;
      case !userData.profile_file &&
        myProfileFlag == false &&
        userData.gender == 2 &&
        userData.is_profile_protected == 0:
        return `${this.baseUrl}/themes/base/img/default_lady.png`;
      default:
        return `${this.baseUrl}/themes/base/img/default.png`;
    }
  }
}
