import { Component, OnInit } from "@angular/core";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { LoginInfo } from "src/app/models/login-info";
import { MessageService } from "primeng/api";
import { CustomValidatorsInfo } from "src/app/component/componentModel/customValidator-info";
/**
 * This is the component class of our component
 */
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  changeFlag = false;
  resetPasswordFlag: boolean;
  recoverPasswordFlag: boolean;
  counterInputs = 0;
  private activation_key: string;
  inputInfo: InputInfo[] = [];
  public resetPassInfo = new LoginInfo();

  constructor(
    private messageService: MessageService,
    private Jarwis: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && Object.keys(params).length > 0) {
        if (params.token) {
          this.activation_key = params.token;
          this.recoverPasswordFlag = true;
          this.resetPasswordFlag = false;
        }
      } else {
        this.resetPasswordFlag = true;
        this.recoverPasswordFlag = false;
      }
    });
  }

  ngOnInit(): void {
    this.generateFormData();
  }

  generateFormData() {
    this.inputInfo = [];
    if (this.resetPasswordFlag) {
      this.createInput(
        ``,
        `oldpassword`,
        `password`,
        `Old Password`,
        `profileCreate`,
        [],
        ``,
        [
          {
            type: Validators.required,
            msg: `You must enter your old Password`,
          },
        ]
      );
    }
    this.createInput(
      ``,
      `newpassword`,
      `password`,
      `New Password`,
      `profileCreate`,
      [],
      ``,
      [
        {
          type: Validators.required,
          msg: `You must enter your new Password`,
        },
      ]
    );
    this.createInput(
      ``,
      `confirm_password`,
      `password`,
      `Confirm Password`,
      `profileCreate`,
      [],
      ``,
      [
        {
          type: Validators.required,
          msg: `You must enter your confirm Password`,
        },
      ]
    );
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    option,
    value,
    validatorsInfo
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.option = option;
    inputObj.value = value;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  updateData(value, modelName) {
    this.resetPassInfo[modelName] = value;
    if (modelName == "confirm_password" && value != null && value != "") {
      let confirm_pass_input = this.inputInfo.find(
        (input) => input.modelName == modelName
      );

      if (
        this.resetPassInfo["newpassword"] !=
        this.resetPassInfo["confirm_password"]
      ) {
        let obj = new CustomValidatorsInfo();
        obj.msg = "Password does not match";
        obj.activeName = "notSame";
        confirm_pass_input.customValidator = obj;
      } else if (confirm_pass_input.customValidator) {
        confirm_pass_input.customValidator.msg = "";
        confirm_pass_input.customValidator.activeName = "";
      }
    }
    if (modelName == "newpassword" && value.length == 0) {
      let new_pass_input = this.inputInfo.find(
        (input) => input.modelName == modelName
      );
      if (new_pass_input.customValidator) {
        new_pass_input.customValidator.msg = "";
        new_pass_input.customValidator.activeName = "";
      }
    }
  }

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }
  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }

    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }

    if (errorFlag) {
      return;
    }

    this.messageService.clear();
    this.addMessages("info", "Info", "Please Wait...");

    if (this.recoverPasswordFlag == true) {
      this.recoverWithEmail();
    }

    if (this.resetPasswordFlag == true) {
      this.Jarwis.getOwnProfileData().subscribe(
        (data) => this.handleGetOwnProfileData(data),
        (error) => this.handleError(error)
      );
    }
  }

  recoverWithEmail() {
    const formData = new FormData();
    formData.append("User[password]", this.resetPassInfo.newpassword);
    formData.append(
      "User[confirm_password]",
      this.resetPassInfo.confirm_password
    );

    let resetData = {
      formData,
      activation_key: this.activation_key,
    };

    this.Jarwis.recoverPassword(resetData).subscribe(
      (data) => this.handleRecoverPassword(data),
      (error) => this.handleError(error, "resetPassword")
    );
  }

  handleRecoverPassword(data) {
    this.messageService.clear();
    this.addMessages("success", "Password Reset successfully", data.message);
    this.generateFormData();

    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
    this.router.navigate(["/login"]);
  }

  handleGetOwnProfileData(data) {
    const emailFormData = new FormData();
    emailFormData.append("User[email]", data.data.email);

    this.Jarwis.recoverWithoutEmail(emailFormData).subscribe(
      (data) => this.handleRecoverWithoutEmail(data),
      (error) => this.handleError(error)
    );
  }

  handleRecoverWithoutEmail(data) {
    const formData = new FormData();
    formData.append("User[oldPassword]", this.resetPassInfo.oldpassword);
    formData.append("User[newPassword]", this.resetPassInfo.newpassword);
    formData.append(
      "User[confirm_password]",
      this.resetPassInfo.confirm_password
    );

    let resetData = {
      formData,
      activation_key: data.data.activation_key,
    };

    this.Jarwis.resetPassword(resetData).subscribe(
      (data) => this.handleResetPassword(data),
      (error) => this.handleError(error, "resetPassword")
    );
  }

  handleResetPassword(data) {
    this.messageService.clear();
    this.addMessages("success", "Password Reset successfully", data.message);
    this.generateFormData();
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }

  handleError(error, from?) {
    if (from == "resetPassword") {
      this.messageService.clear();
      let err = error.error.errors;
      let inputModelNames = [];
      inputModelNames = this.inputInfo.map((input) => input.modelName);
      if (err && Object.keys(err).length > 0) {
        // for (let input of this.inputInfo) {
        //   input.customValidator = new CustomValidatorsInfo();
        // }
        for (const key in err) {
          let errorMatched = inputModelNames.find((el) => `user-${el}` == key);

          let obj = new CustomValidatorsInfo();
          obj.msg = err[key][0];
          obj.activeName = errorMatched;
          this.inputInfo.find(
            (input) => input.modelName == errorMatched
          ).customValidator = obj;
        }
      } else {
        for (let input of this.inputInfo) {
          input.customValidator = new CustomValidatorsInfo();
        }
      }
    }
  }

  addMessages(severity, summary, detail) {
    this.messageService.add({
      severity,
      summary,
      detail,
      sticky: true,
    });
    // setTimeout(() => {
    //   this.messageService.clear();
    // }, 3000);
  }
}
