import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { ChipsInfo } from "../../componentModel/chips-info";

@Component({
  selector: "app-chips",
  templateUrl: "./chips.component.html",
  styleUrls: ["./chips.component.css"],
})
export class ChipsComponent implements OnInit {
  // chips data in array of objects
  @Input() chipsData: ChipsInfo;

  @Output() chipClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClick(event, modelName, id) {
    this.chipClicked.emit({ event, modelName, id });
  }

  onRemoveClicked(event) {}
}
