<div class="videos">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/{{ videosPageInfo.pageHeroImage }}"
      alt=""
    />
    <div class="hero-content">
      <div class="hero-pageTitle">
        <h2>{{ videosPageInfo.title }}</h2>
      </div>
      <div class="hero-pageDescription">
        <p>{{ videosPageInfo.description }}</p>
      </div>
    </div>
  </div>
  <section class="videos-section container-fluid">
    <div class="videos-section-title">
      <h3>{{ videosPageInfo.videos.title }}</h3>
    </div>
    <div class="row">
      <ng-container
        *ngIf="
          videosPageInfo &&
          videosPageInfo.videos &&
          videosPageInfo.videos.videoItem
        "
      >
        <div
          class="col-md-6 col-lg-4 mb-4"
          *ngFor="let data of videosPageInfo.videos.videoItem; let i = index"
        >
          <div class="videos-section-video">
            <div [innerHTML]="data.url"></div>
            <!-- <iframe
              width="388"
              height="221"
              [src]="data.url | safe : 'resourceUrl'"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> -->
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</div>
