import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DummyCookieConsentService {
  isConsentGiven(): boolean {
    return false; // Default value
  }

  setConsent(consent: boolean): void {
    localStorage.setItem('cookies', JSON.stringify(consent));
    
  }
}
