import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class VerifiedGuard implements CanActivate {
  isBrowser: Boolean = false;

  constructor(
    private Jarvis: AuthService,
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // return this.Jarvis.getProfileData().pipe(
    //   map((data) => {
    //     this.handleVerifyOtp(data, state);
    //     return true; // Return true to allow navigation
    //   }),
    //   catchError((error) => {
    //     this.handleError(error);
    //     return of(false); // Return false to deny navigation wrapped in 'of' function
    //   })
    // );
    return new Observable<boolean>((observer) => {
      if (this.isBrowser) {
        this.Jarvis.getProfileData().subscribe(
          (data) => {
            if (data["status"] === 200) {
              if (state.url == "/user/otp") {
                this.router.navigate(["/user/"]);
              }
              observer.next(true);
            } else {
              observer.next(false);
            }
            observer.complete();
          },
          (error) => {
            let err = error?.error.errors??error;
            if (err["status"] && err["status"] == 301) {
              if (state.url == "/user/otp") {
                observer.next(true);
              } else {
                observer.next(false);
                this.router.navigate(["/user/otp"]);
              }
            } else {
              observer.next(false);
            }
            observer.complete();
          }
        );
      } else {
        return null;
      }
    });
  }
}
