<div class="container-fluid otp-page">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/signup-login-ss.png"
      alt=""
    />
  </div>
  <div class="row justify-content-center otp-inner">
    <div class="text-center m-auto">
      <div class="otp-heading">
        <div>
          <h3 class="otp-heading-title">Signed Up Successfully</h3>
          <p class="otp-heading-para">
            Thank you for signing up. In order to activate your account, please
            enter the OTP sent to the Email you have provided.
          </p>
          <h3 class="otp-heading-title">OTP Code</h3>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <ng-container *ngIf="isLoadingFlag">
        <div class="text-center my-4">
          <p-progressSpinner
            [style]="{ width: '65px', height: '65px' }"
          ></p-progressSpinner>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoadingFlag">
        <div class="col-md-5 my-4" *ngFor="let box of this.inputInfo">
          <app-text-box
            [inputInfo]="box"
            [changeFlag]="this.changeFlag"
            (updateData)="updateData($event, box.modelName)"
            (preSubmit)="preSubmit()"
            (submit)="onSubmit()"
            [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
            [borderRadius]="'35px'"
            [width]="'100%'"
            [height]="'65px'"
            [padding]="'0 2rem'"
          ></app-text-box>
        </div>
      </ng-container>
    </div>

    <div class="otp-submit-btn">
      <app-button
        [label]="'Submit'"
        [padding]="'5px 0'"
        [color]="'#fff'"
        [type]="'login'"
        (button)="preSubmit()"
        [bgColor]="'var(--color-pink-bg)'"
        [borderRadius]="'35px !important'"
        [fontSize]="'clamp(16px, 4vw, 22px)'"
        [maxWidth]="'400px'"
        [padding]="'1rem 2rem'"
      >
      </app-button>
      <div class="row justify-content-center">
        <div class="resend-code my-3">
          <p (click)="resendOTP()">Resend OTP Code</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="resend-code my-3 underline">
          <p (click)="Logout()">Logout</p>
        </div>
      </div>
    </div>
  </div>
</div>
