import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { FileUpload } from "primeng/fileupload";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.css"],
})
export class FileUploadComponent implements OnInit {
  uploadedFiles = [];
  url: SafeUrl;

  // inputs
  @Input() mode: string;
  @Input() accept: string ;
  @Input() label: any;
  @Input() accessFrom: string;
  @Input() auto: boolean = false;
  @Input() multiple: boolean = false;
  @Input() customUpload: boolean = false;
  @Input() acceptedTypes: string[] = [];
  @Input() maxFileSize: number = 5000000;
  @Output() dataReady = new EventEmitter<any>();
  @Output() error = new EventEmitter<string>();

  // Arrays for accepted file types
  acceptedDocuments: string[] = [
    "application/pdf",
    "application/msword",
    "text/plain",
  ];
  acceptedImages: string[] = ["image/jpeg", "image/png", "image/gif"];
  acceptedVideos: string[] = [
    "video/mp4",
    "video/quicktime",
    "video/x-msvideo",
  ];

  @ViewChild("fileUpload") fileUpload: FileUpload;

  constructor(protected sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  handleOnSelect(event) {
    this.uploadedFiles = [];
    // this.uploadedFiles.push(event);

    const files = event.currentFiles;

    // Check if uploaded file types are accepted
    for (let file of files) {
      if (!this.isFileAccepted(file)) {
        this.error.emit(`File type is not accepted.`);
        this.fileUpload.clear();
        return;
      }
      if (file.size > this.maxFileSize) {
        let sizeMB = this.maxFileSize / (1024 * 1024); // Convert bytes to megabytes
        this.error.emit(`File size can't be greater than ${sizeMB}MB.`);
        return;
      }
    }

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      event.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity
    );
    let data = {
      preview: this.url,
      image: event,
    };
    this.dataReady.emit(data);
  }

  handleOnClear(event: any) {}

  clearUploadedFile() {
    this.uploadedFiles = [];
  }
 

  clearUploader() {
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
  }

  // Check if file type is accepted
  isFileAccepted(file: File): boolean {
    if (this.acceptedTypes.includes("documents")) {
      if (this.acceptedDocuments.includes(file.type)) {
        return true;
      }
    }

    if (this.acceptedTypes.includes("images")) {
      if (this.acceptedImages.includes(file.type)) {
        return true;
      }
    }

    if (this.acceptedTypes.includes("videos")) {
      if (this.acceptedVideos.includes(file.type)) {
        return true;
      }
    }

    return false;
  }
}
