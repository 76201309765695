<div class="mt-2" *ngIf="!type">
  <p-card
    header="Simple Card"
    [style]="{ width: '25rem', 'margin-bottom': '2em' }"
    (click)="onClick()"
  >
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed
      consequuntur error repudiandae numquam deserunt quisquam repellat libero
      asperiores earum nam nobis, culpa ratione quam perferendis esse,
      cupiditate neque quas!
    </p>
  </p-card>
</div>

<div
  class="mt-2"
  *ngIf="type && type == 'storyCard'"
  class="storyCard"
  [ngClass]="{ 'card-hovered': isHovered }"
  (mouseover)="isHovered = true"
  (mouseout)="isHovered = false"
>
  <p-card
    [class.clickable]="true"
    [data]="cardData"
    (click)="onClick($event, 'storyCard', cardData.id)"
    header="{{
      cardData.title.length > 20
        ? (cardData.title | slice : 0 : 17) + '...'
        : cardData.title
    }}"
  >
    <ng-container *ngIf="cardData.image">
      <img alt="Card" [src]="cardData.image" />
    </ng-container>
    <p
      [innerHTML]="
        cardData.description.length > 270
          ? (cardData.description | slice : 0 : 267) + '...'
          : cardData.description
      "
    ></p>
  </p-card>
</div>

<div
  class="mt-2"
  *ngIf="type && type == 'eventCard'"
  class="eventCard"
  [ngClass]="{ 'card-hovered': isHovered }"
  (mouseover)="isHovered = true"
  (mouseout)="isHovered = false"
>
  <p-card
    [class.clickable]="true"
    [data]="cardData"
    (click)="onEventCardClick(cardData.slug, 'eventCard')"
    header="{{ cardData.title }}"
  >
    <ng-template pTemplate="header">
      <img alt="Event Card" [src]="'/assets/images/events.png'" />
    </ng-template>
  </p-card>
</div>

<!-- For Subscription Page Card -->
<div
  class="mt-2"
  *ngIf="type && type == 'subscriptionCard'"
  class="subscripcard"
>
  <p-card
    [data]="subsData"
    header="{{ subsData.subsTitle }}"
    subheader="{{ subsData.subsPrice | currency : 'GBP' : true : '1.0-0' }}"
    styleClass="shadow-2"
  >
    <p class="descrip">{{ subsData.subsPara }}</p>
    <div class="radio-but">
      <ng-container *ngIf="this.inputInfoData">
        <app-radio-button
          [inputInfo]="this.inputInfoData"
          (updateData)="updateData($event)"
        ></app-radio-button>
      </ng-container>
    </div>
    <div class="center-line"></div>
    <div class="card-data-para" *ngFor="let data of subsData.subsCardPara">
      <i class="pi pi-check" style="font-size: 1rem; font-weight: 700"></i>
      <p>
        {{ data.papraOne }}
      </p>
    </div>
    <ng-template pTemplate="footer">
      <div class="subs-but">
        <app-button
          [label]="'Subscribe'"
          [type]="'login'"
          [bgColor]="'#1CA9F4 0% 0% no-repeat padding-box'"
          [color]="'#fff'"
          [fontSize]="'clamp(25px, 4vw, 29px)'"
          [fontWeight]="'600'"
          [padding]="'19.5px'"
          [boxShadow]="'0px 3px 6px #00000029'"
          [borderRadius]="'0px 0px 29px 29px !important'"
          (button)="onSubmit()"
        >
        </app-button>
      </div>
    </ng-template>
  </p-card>
</div>

<div class="mt-2" *ngIf="type && type == 'imageCardWithButton'">
  <p-card
    header="{{ header }}"
    subheader="{{ subHeader }}"
    styleClass="shadow-2"
    (click)="onClick()"
  >
    <ng-template pTemplate="header">
      <img alt="Card" src="https://picsum.photos/200/200" />
    </ng-template>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed
      consequuntur error repudiandae numquam deserunt quisquam repellat libero
      asperiores earum nam nobis, culpa ratione quam perferendis esse,
      cupiditate neque quas!
    </p>
    <ng-template pTemplate="footer">
      <p-button label="Save" icon="pi pi-check"></p-button>
      <p-button
        label="Cancel"
        icon="pi pi-times"
        styleClass="p-button-secondary"
        [style]="{ 'margin-left': '.5em' }"
      ></p-button>
    </ng-template>
  </p-card>
</div>

<div
  class="mb-3 matchCard"
  *ngIf="type == 'matchCard' || type == 'shortlistCard'"
>
  <p-card
    header="{{ header }}"
    subheader="{{ subHeader }}"
    (click)="onClick(cardData.name, 'match&shortListCard', cardData.id)"
  >
    <ng-template pTemplate="header">
      <ng-container *ngIf="cardData.image">
        <img
          class="MCImage"
          alt="{{ cardData.name }} Image"
          [src]="cardData.image"
        />
      </ng-container>
    </ng-template>
    <div class="MCCardBody">
      <div class="MCName">
        {{ cardData.name }}
      </div>
      <p class="MCDescription">
        {{ cardData.description }}
      </p>
      <div class="MCInfoDiv">
        <p class="MCInfo">Age:{{ cardData.age?cardData.age+" Years":"Hidden" }}</p>
        <p class="MCInfo">Height: {{ cardData.height }} cms</p>
        <p class="MCInfo">Profession: {{ cardData.profession }}</p>
        <p class="MCInfo">
          {{ cardData.address }}
        </p>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="row justify-content-between align-items-center MCCardBody">
        <div class="col-8">
          <p class="MCStatus">
            <span class="Ss">Status:</span
            ><span class="Ds">{{ cardData.status }}</span>
          </p>
        </div>
        <div
          class="col-4 text-center d-flex justify-content-center align-items-center"
        >
          <i
            class="cmntIcon far fa-comment-dots"
            (click)="onCardButtonClick($event, 'messageBtn', cardData.id)"
          ></i>
          <!-- <i
            class="heartIcon fas fa-heart"
            (click)="onCardButtonClick($event, 'favouriteBtn', cardData.id)"
          ></i> -->
          <p-toggleButton

            [(ngModel)]="cardData.shortlist"
            onIcon="pi pi-heart-fill"
            offIcon="pi pi-heart"
            class="heartIcon"
            (click)="onCardButtonClick($event, 'favouriteBtn', cardData.id)"
          ></p-toggleButton>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>

<div class="mt-2 matchCardCarousel" *ngIf="type == 'matchCardCarousel'">
  <p-card
    header="{{ header }}"
    subheader="{{ subHeader }}"
    (click)="onClick(cardData.name, 'match&shortListCard', cardData.id)"
  >
    <ng-template pTemplate="header"> </ng-template>
    <div class="MCCardBody">
      <div class="MCName">{{ cardData.name }}</div>
      <div class="MCInfoDiv">
        <p class="MCInfo">Age:{{ cardData.age?cardData.age+" Years":"Hidden" }}</p>
        <p class="MCInfo">Height: {{ cardData.height }} cms</p>
        <p class="MCInfo">Profession: {{ cardData.profession }}</p>
        <p class="MCInfo">
          {{ cardData.address }}
        </p>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="row justify-content-between align-items-center MCCardBody">
        <div class="col-8">
          <p class="MCStatus">
            <span class="Ss">Status:</span
            ><span class="Ds">{{ cardData.status }}</span>
          </p>
        </div>
        <div class="col-4 d-flex justify-content-end align-items-center">
          <i
            class="cmntIcon far fa-comment-dots"
            (click)="onCardButtonClick($event, 'messageBtn', cardData.id)"
          ></i>
          <p-toggleButton
            [(ngModel)]="cardData.shortlist"
            onIcon="pi pi-heart-fill"
            offIcon="pi pi-heart"
            class="heartIcon"
            (click)="onCardButtonClick($event, 'favouriteBtn', cardData.id)"
          ></p-toggleButton>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>

<div class="search-card mt-4" *ngIf="type == 'searchResultCard'">
  <p-card header="{{ cardData.name }}">
    <ng-template pTemplate="header">
      <a href="" target="_blank" *ngIf="isLoggedIn == true">
        <img alt="Card" [src]="cardData.img" />
      </a>
    </ng-template>
    <div class="card-rating">
      <app-rating
        [type]="'readOnlySearchCard'"
        [score]="cardData.rating"
        [stars]="5"
      ></app-rating>
    </div>
    <div class="container-fluid card-body">
      <div
        class="card-status"
        [ngClass]="
          cardData.status == 'Offline' ? 'status-offline' : 'status-online'
        "
      >
        <span>{{ cardData.status }}</span>
      </div>
      <div class="row">
        <div class="col-12 card-address">
          <i class="pi pi-map-marker"> </i>
          <span> {{ cardData.address }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 card-age">
          <i class="pi pi-calendar"> </i>
          <span>{{ cardData.age }} years</span>
        </div>
        <div class="col-sm-4 card-height" *ngIf="isLoggedIn">
          <i class="pi pi-user"> </i>
          <span>{{ cardData.height }}</span>
        </div>
        <div class="col-sm-4 card-profession">
          <i class="pi pi-briefcase"> </i>
          <span>{{ cardData.profession }}</span>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <app-button
        label="View Profile"
        [type]="'login'"
        [bgColor]="'var(--color-blue)'"
        [borderRadius]="'35px !important'"
        [color]="'#fff'"
        [fontWeight]="'600'"
        [bgColor]="'var(--color-blue)'"
        (button)="preSubmit()"
        [fontSize]="'clamp(10px, 4vw, 15px)'"
        [padding]="'.5rem 1rem'"
      ></app-button>
    </ng-template>
  </p-card>
</div>

<div class="mt-2" *ngIf="type && type == 'about-us-card'" class="aboutUsCard">
  <p-card [data]="aboutData" header="{{ aboutData.aboutTitle }}">
    <p class="desc">{{ aboutData.aboutPara }}</p>
  </p-card>
</div>
<div class="mt-2" *ngIf="type && type == 'home-page-card'" class="homePageCard">
  <p-card [data]="homeData" header="{{ homeData.homeTitle }}">
    <p class="desc">{{ homeData.homePara }}</p>
  </p-card>
</div>

<div class="left-message-card" *ngIf="type && type == 'message-friends'">
  <p-card class="left-card">
    <p-header>
      <div class="left-card-heading">
        <h5>Messaging</h5>
        <div class="left-card-heading-buttons">
          <!-- <div class="left-card-heading-buttons-first">
            <app-button
              [type]="'messages'"
              [width]="'1.75rem'"
              [height]="'1.75rem'"
              [padding]="'0.643rem'"
              [borderRadius]="'50%'"
              [icon]="'pi pi-ellipsis-h'"
              [ButtonModelData]="optionsMenu"
            >
            </app-button>
          </div>
          <div class="left-card-heading-buttons-second">
            <app-button
              [type]="'messages'"
              [width]="'1.75rem'"
              [height]="'1.75rem'"
              [padding]="'0.643rem'"
              [borderRadius]="'50%'"
              [icon]="'far fa-edit'"
              [ButtonModelData]="editMenu"
            >
            </app-button>
          </div> -->
        </div>
      </div>
      <div class="left-card-search">
        <app-text-box
          [inputInfo]="this.inputInfoData[0]"
          [changeFlag]="changeFlag"
          (updateData)="
            onCardUpdateData($event, this.inputInfoData[0].modelName)
          "
          (preSubmit)="onCardPreSubmit()"
          (submit)="onCardSubmit()"
          [borderRadius]="'28px'"
          [boxShadow]="'0px 3px 6px #00000029'"
          [height]="'33px'"
          [fontSize]="'clamp(6px, 3vw, 12px)'"
        >
        </app-text-box>
      </div>
    </p-header>

    <p-scrollPanel
      #chatContainer
      [style]="{ 'max-width': '100%', height: '477px' }"
    >
      <ul class="dm-msg-list">
        <li
          *ngFor="let data of cardData; let i = index"
          class="dm-msg-item"
          [ngClass]="{
            liSelected: selectedConvoId === data.conversationId,
            unreadMessage: data.unread
          }"
          (click)="
            changeCardWindow(data.conversationId);
            conversationSelected(data.conversationId)
          "
        >
          <div class="dm-msg-item-img">
            <div class=" dm-msg-image">

              <img [src]="data.avatarUrl" alt="" />
              <div
                class="right-card-header-status-icon dm-msg-item-icon"
                [ngClass]="
                  data.isOnline ? 'status-online-color' : 'status-offline-color'
                "
              ></div>
            </div>
          </div>
          <div class="dm-msg-item-inner">
            <div class="dm-msg-item-nameAndTime">
              <div class="dm-msg-item-name">
                <h5>{{ data.friendName }}</h5>
              </div>
              <div class="dm-msg-item-time">
                <p>{{ data.lastMessageTime | date : "MMMM d" }}</p>
              </div>
            </div>
            <div class="dm-msg-item-msg">
              <p>
                {{ data.lastMessage | slice : 0 : 30
                }}{{ data.lastMessage.length > 30 ? "..." : "" }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </p-scrollPanel>
  </p-card>
</div>

<div class="right-message-card" *ngIf="type && type == 'message-conversation'">
  <p-card class="right-card" *ngIf="cardData">
    <p-header class="right-card-header">
      <app-button
        [label]="''"
        [icon]="'fas fa-arrow-left'"
        [bgColor]="''"
        *ngIf="mobileMsgSize"
        [type]="'btnWithIcon'"
        [fontSize]="'clamp(12px, 3vw, 16px)'"
        [fontWeight]="'500'"
        [boxShadow]="'none !important'"
        [padding]="'10px 10px !important'"
        [borderRadius]="'50px !important'"
        [color]="'#000'"
        [width]="'100% !important'"
        (button)="onCardUpdateData(this.cardData.friendId, 'back')"
      ></app-button>
      <!-- <div class="right-card-header-backIcon">
        <i class="pi pi-arrow-left" style="font-size: 2rem"></i>
      </div> -->
      <a
        class="messageImageAndName"
        (click)="
          onClick(
            cardData.friendName,
            'messageProfile',
            cardData.conversationId
          )
        "
      >
        <div class="right-card-header-img ">
          <img src="{{ cardData.avatarUrl }}" alt="" />
          <div
            class="right-card-header-status-icon"
            [ngClass]="
              cardData.isOnline ? 'status-online-color' : 'status-offline-color'
            "
          ></div>
        </div>
      </a>
      <div class="right-card-header-nameAndStatus">
        <a
          class="messageImageAndName"
          (click)="
            onClick(
              cardData.friendName,
              'messageProfile',
              cardData.conversationId
            )
          "
        >
          <div class="right-card-header-nameAndStatus-name">
            <h5>{{ cardData.friendName }}</h5>
          </div>
        </a>
        <div class="right-card-header-nameAndStatus-status">
          <p *ngIf="cardData.isOnline">Active Now</p>
          <p *ngIf="!cardData.isOnline">Offline {{ cardData.lastActivity }}</p>
        </div>
      </div>
      <div class="right-card-header-nameAndStatus-status blockAndUnblockButton">
        <app-button
          [label]="this.cardData.blocked ? 'unBlock' : 'Block'"
          [bgColor]="this.cardData.blocked ? '#00e00e' : '#ff3e3e'"
          [type]="'login'"
          [fontSize]="'clamp(12px, 3vw, 16px)'"
          [fontWeight]="'500'"
          [padding]="'10px 20px !important'"
          [borderRadius]="'50px !important'"
          [color]="'#fff'"
          [width]="'100% !important'"
          (button)="
            onCardUpdateData(
              this.cardData.friendId,
              this.cardData.blocked ? 'unBlock' : 'block'
            )
          "
        ></app-button>
        <!-- <app-button
          [label]="'Unblock'"
          [bgColor]="'#00e00e'"
          *ngIf="this.cardData.blocked"
          [type]="'login'"
          [fontSize]="'clamp(12px, 3vw, 16px)'"
          [fontWeight]="'500'"
          [padding]="'10px 20px !important'"
          [borderRadius]="'50px !important'"
          [color]="'#fff'"
          [width]="'100% !important'"
          (button)="onCardUpdateData(this.cardData.friendId, 'unBlock')"
        ></app-button> -->
      </div>
    </p-header>
    <p-scrollPanel
      [style]="{
        'max-width': '100%',
        height: mobileMsgSize ? '100%' : '450px'
      }"
    >
      <div class="DM-main">
        <ng-container *ngIf="!isMobileView">
          <div class="DM-friend-info">
            <a
              class="messageImageAndName"
              (click)="
                onClick(
                  cardData.friendName,
                  'messageProfile',
                  cardData.conversationId
                )
              "
            >
              <div class="DM-friend-info-image">
                <img src="{{ cardData.avatarUrl }}" alt="" />
              </div>
            </a>
            <a
              class="messageImageAndName"
              (click)="
                onClick(
                  cardData.friendName,
                  'messageProfile',
                  cardData.conversationId
                )
              "
            >
              <div class="DM-friend-info-name">
                <h5>{{ cardData.friendName }}</h5>
              </div>
            </a>
            <div class="DM-friend-info-city">
              <p>{{ cardData.residency }}</p>
            </div>
          </div>
        </ng-container>
        <div class="DM-friend-conversation-started-date">
          <!-- <p>31 Aug 2022, 03:39</p> -->
        </div>
        <div class="DM-conversation">
          <ng-container *ngFor="let msg of cardData.messages">
            <div class="DM-recieved" *ngIf="cardData.friendId == msg.senderId">
              <div class="DM-recieved-image">
                <img src="{{ cardData.avatarUrl }}" alt="" />
              </div>
              <div class="DM-recieved-msg">
                <p>{{ msg.messageText }}</p>
              </div>
              <div class="DM-recieved-time">
                <p>{{ msg.timestamp }}</p>
              </div>
            </div>
            <div class="DM-sent" *ngIf="cardData.friendId !== msg.senderId">
              <div class="DM-sent-msg">
                <p>{{ msg.messageText }}</p>
              </div>
              <div class="DM-sent-time">
                <p>{{ msg.timestamp }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </p-scrollPanel>
    <ng-template pTemplate="footer">
      <div class="DM-footer">
        <!-- <div class="DM-footer-buttons">
          <div class="DM-footer-buttons-first">
            <app-button
              [type]="'messages'"
              [width]="'1.75rem'"
              [height]="'1.75rem'"
              [color]="'white'"
              [bgColor]="'#1CA9F4'"
              [padding]="'0.643rem'"
              [borderRadius]="'50%'"
              [icon]="'pi pi-paperclip'"
              [ButtonModelData]="linkMenu"
            >
            </app-button>
          </div>
          <div class="DM-footer-buttons-second">
            <app-button
              [type]="'messages'"
              [width]="'1.75rem'"
              [height]="'1.75rem'"
              [color]="'white'"
              [bgColor]="'#1CA9F4'"
              [padding]="'0.643rem'"
              [borderRadius]="'50%'"
              [icon]="'pi pi-images'"
              [ButtonModelData]="picsMenu"
            >
            </app-button>
          </div>
        </div> -->
        <div class="DM-footer-message-input">
          <app-text-box
            [inputInfo]="this.inputInfoData[1]"
            [changeFlag]="changeFlag"
            [resetValueFlag]="resetValueFlag"
            (updateData)="
              onCardUpdateData($event, this.inputInfoData[1].modelName)
            "
            (preSubmit)="onCardPreSubmit()"
            (submit)="onCardSubmit()"
            [disabled]="this.cardData.blocked"
            [bgColor]="'#E4E6EB'"
            [border]="'1px solid #C4C4C4'"
            [padding]="'0.5rem 2.5rem .5rem 1rem'"
            [borderRadius]="'25px'"
            [fontSize]="'clamp(6px, 3vw, 12px)'"
          ></app-text-box>
        </div>
        <div class="DM-footer-emoji-btn">
          <app-button
            [type]="'sendMessages'"
            [width]="'1.75rem'"
            [height]="'1.75rem'"
            [color]="'white'"
            [bgColor]="'#1CA9F4'"
            [padding]="'0.643rem 0.843rem 0.643rem 0.643rem'"
            [borderRadius]="'50%'"
            [position]="'relative'"
            [top]="'-14px'"
            [right]="'42px'"
            [icon]="'fas fa-paper-plane'"
            [iconPos]="'center'"
            (button)="onCardPreSubmit()"
          >
            <!-- [icon]="'fas fa-laugh'"
            [ButtonModelData]="emojiMenu"
             -->
          </app-button>
        </div>
      </div>
    </ng-template>
  </p-card>
  <div class="no-messages-selected" *ngIf="!cardData">
    <span>No Messages Selected</span>
  </div>
</div>
<div class="profileImageUpload" *ngIf="type == 'profileImageUpload'">
  <div
    class="profileImageUpload-uploadImage"
    *ngIf="!this.imageUploadedFlagLocal"
  >
    <p-card header="" (click)="onClick()">
      <div>
        <input
          type="file"
          (change)="onCardButtonClick($event, 'uploadImageForCrop')"
          style="display: none"
          id="fileInput"
        />
        <app-button
          [label]="'Upload Image'"
          [borderRadius]="'35px'"
          [color]="'#ffffff'"
          [type]="'homeSearch'"
          [bgColor]="'#DD1485'"
          [fontWeight]="'600'"
          [height]="'100%'"
          [width]="'100% !important'"
          [fontSize]="'clamp(12px, 4vw, 16px)'"
          [padding]="'1rem !important'"
          (button)="loadImage()"
        >
        </app-button>
      </div>
    </p-card>
  </div>
  <div
    class="profileImageUpload-uploadCroppedImage"
    *ngIf="this.imageUploadedFlagLocal"
  >
    <p-card
      header="{{ header }}"
      subheader="{{ subHeader }}"
      (click)="onClick()"
    >
      <ng-template pTemplate="header"> </ng-template>
      <div class="piu-main">
        <div class="piu-main-image">
          <img #croppingImage id="image" [src]="imageURL" />
        </div>
        <div class="cropper-img-preview">
          <img [src]="imageDestination" class="img-preview" />
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="piu-btn">
          <app-button
            [label]="'Upload Croped Image'"
            [borderRadius]="'35px'"
            [color]="'#ffffff'"
            [type]="'homeSearch'"
            [bgColor]="'#DD1485'"
            [fontWeight]="'600'"
            [height]="'100%'"
            [width]="'100% !important'"
            [fontSize]="'clamp(12px, 4vw, 16px)'"
            [padding]="'1rem !important'"
            iconPos="right"
            (button)="onCardButtonClick($event, 'profileImageUploadButton')"
          >
          </app-button>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>

<div class="viewed-card" *ngIf="type == 'viewed-card'">
  <p-card header="" (click)="onClick()">
    <ng-template pTemplate="header">
      <img alt="{{ cardData.fullName }}" src="{{ cardData.image }}" />
    </ng-template>
    <div class="viewed-card-body">
      <div class="viewed-card-main">
        <div class="viewed-card-name">
          <h2>
            {{ cardData.fullName | slice : 0 : 10
            }}{{ cardData?.fullName.length > 8 ? ".." : "" }}
          </h2>
        </div>
        <div class="viewed-card-age">
          <i class="fad fa-calendar-alt"></i>
          <h4>{{ cardData.age?cardData.age+" Years":"Hidden" }}</h4>
        </div>
      </div>
      <div class="viewed-card-btn">
        <app-button
          [label]="'View'"
          [borderRadius]="'35px'"
          [color]="'#ffffff'"
          [type]="'homeSearch'"
          [bgColor]="'var(--color-blue)'"
          [fontWeight]="'600'"
          [fontSize]="'clamp(12px, 4vw, 16px)'"
          [padding]="'.5rem 1rem !important'"
          iconPos="right"
          (button)="onClick(cardData.fullName, 'viewed-btn', cardData.id)"
        >
        </app-button>
      </div>
    </div>
  </p-card>
</div>
