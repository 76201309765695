import { OptionInfo } from "src/app/component/componentModel/option-info";
import { Latest } from "./latest-items";
import { InputInfo } from "src/app/component/componentModel/input-info";

export class SubscriptionCard {
  id?: number;
  subsTitle: string;
  subsPara: string;
  subsPrice: string;
  duration?: number;
  subsradio: InputInfo;
  subsCardPara: Latest[];
}
