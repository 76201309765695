<div class="books">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/{{ booksPageInfo.pageHeroImage }}"
      alt=""
    />
    <div class="hero-content">
      <div class="hero-pageTitle">
        <h2>{{ booksPageInfo.title }}</h2>
      </div>
      <div class="hero-pageDescription">
        <p>{{ booksPageInfo.description }}</p>
      </div>
    </div>
  </div>

  <section class="books-section container-fluid">
    <div class="books-section-title">
      <h3>{{ booksPageInfo.books.title }}</h3>
    </div>
    <div class="row">
      <ng-container
        *ngIf="
          booksPageInfo && booksPageInfo.books && booksPageInfo.books.books
        "
      >
        <div
          class="col-sm-6 col-md-3 mb-4"
          *ngFor="let data of booksPageInfo.books.books"
        >
          <div class="books-card">
            <a href="{{ data.url }}" target="_blank">
              <img [src]="data?.file" [alt]="data.title"
            /></a>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
  <section class="stories-section container-fluid">
    <div class="stories-section-title">
      <h3>{{ booksPageInfo.articles.title }}</h3>
    </div>
    <div class="stories-section-main">
      <div class="row">
        <ng-container *ngIf="booksPageInfo && booksPageInfo.articles">
          <div
            class="col-sm-6 col-md-4 mb-4"
            *ngFor="let data of booksPageInfo.articles.articles"
          >
            <app-card
              [type]="'storyCard'"
              [cardData]="data"
              (cardClicked)="onCardClick($event)"
            ></app-card>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</div>
