import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MenuItem } from "primeng/api";
import { EventTabs } from "src/app/models/events-info.ts/event-tabs";

@Component({
  selector: "app-tab-menu",
  templateUrl: "./tab-menu.component.html",
  styleUrls: ["./tab-menu.component.css"],
})
export class TabMenuComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() tabItems: MenuItem[];
  @Input() activeTab: number;

  @Output() changedTab: EventEmitter<string> = new EventEmitter<string>();

  items: MenuItem[];
  activeItem: MenuItem;
  constructor() {}

  ngOnInit(): void {
    this.items = this.tabItems;
    this.activeItem = this.items[this.activeTab];
  }

  tabClicked(event: any) {
    this.activeItem = this.tabItems.find((element) => element.label == event);
    this.changedTab.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.activeTab && !changes.activeTab.firstChange) {
      this.activeItem = this.items[changes.activeTab.currentValue];
    }
  }
}
