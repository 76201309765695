import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { JwtResponse } from "../auth/jwt-response";
import { TokenStorageService } from "../auth/token-storage.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BookService {
  private baseUrl = environment.url;
  httpOptionsSaved = {
    headers: new HttpHeaders({
      "Content-Type": "*/*",
      Accept: "*/*",
      // "Access-Control-Allow-Origin": `${this.baseUrl}`,
    }),
  };

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) {}

  // getAllBooks(): Observable<any>  {
  //   return this.http.get<any>(
  //     `${this.baseUrl}/site/book`,
  //     this.httpOptionsSaved
  //   );
  // }

  getAllBooks(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/book`,
      httpOptionsSaved
    );
  }

  getAllArticles(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/article`,
      httpOptionsSaved
    );
  }
  getArticle(id): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/article-view/${id}`,
      httpOptionsSaved
    );
  }
}
