<div class="container-fluid reset-password-page">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/signup-login-ss.png"
      alt=""
    />
  </div>
  <div class="row justify-content-center reset-password-inner">
    <div class="reset-password-heading text-center m-auto">
      <div class="reset-password-heading-title">
        <h3>Reset password</h3>
      </div>
      <div class="reset-password-heading-para">
        <ng-container *ngIf="recoverPasswordFlag">
          <p>Please enter your New password.</p>
        </ng-container>
        <ng-container *ngIf="resetPasswordFlag">
          <p>Please enter your old Password and New password.</p>
        </ng-container>
      </div>
    </div>
    <div class="reset-password-main">
      <div class="col-md-12 mt-4" *ngFor="let box of this.inputInfo">
        <app-text-box
          [inputInfo]="box"
          [changeFlag]="this.changeFlag"
          *ngIf="box.type == 'profileCreate'"
          (updateData)="updateData($event, box.modelName)"
          (preSubmit)="preSubmit()"
          (submit)="onSubmit()"
          [customValidator]="box.customValidator"
          [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
          [borderRadius]="'35px'"
          [width]="'100%'"
          [height]="'65px'"
          [padding]="'0 2rem'"
        ></app-text-box>
      </div>
      <div class="col-md-12 mt-5">
        <app-button
          [label]="'Send'"
          [padding]="'5px 0'"
          [color]="'#fff'"
          [type]="'login'"
          (button)="preSubmit()"
          [bgColor]="'var(--color-blue)'"
          [borderRadius]="'35px !important'"
          [fontSize]="'clamp(16px, 4vw, 22px)'"
          [padding]="'1rem 3rem'"
          [width]="'100%'"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
