import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { TokenStorageService } from "../auth/token-storage.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard implements CanActivate {
  isBrowser: Boolean = false;
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    private tokenStorage: TokenStorageService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.isBrowser) {
      if (this.tokenStorage.getToken()) {
        // User is logged in, redirect to UserNavigationComponent
        this.router.navigate(["user"]);
        return false; // Prevent the current route from being activated
      }
      return true; // Allow the current route to be activated
    }
    return null;

  }
}
