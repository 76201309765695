import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { UserProfileConverterService } from "src/app/services/user-profile-converter.service";

@Component({
  selector: "app-registration-card",
  templateUrl: "./registration-card.component.html",
  styleUrls: ["./registration-card.component.css"],
})
export class RegistrationCardComponent implements OnInit {
  @Input() registrationCardData: any;
  personalInfo: any = {
    relationship_status: "",
    education: "",
    profession: "",
  };

  constructor(private userProfileConverter: UserProfileConverterService) {}

  ngOnInit(): void {
    this.userValueToString(
      "relationship_status",
      this.registrationCardData.userData.profile.relationship_status
    ).subscribe((convertedValue: string) => {
      this.personalInfo.relationship_status = convertedValue;
    });
    this.userValueToString(
      "education",
      this.registrationCardData.userData.profile.education
    ).subscribe((convertedValue: string) => {
      this.personalInfo.education = convertedValue;
    });
    this.userValueToString(
      "profession",
      this.registrationCardData.userData.profile.profession
    ).subscribe((convertedValue: string) => {
      this.personalInfo.profession = convertedValue;
    });
  }

  userValueToString(label: string, value: string): Observable<string> {
    return this.userProfileConverter.convertToText(label, value);
  }
}
