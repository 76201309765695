<div class="events-tabMenu" *ngIf="type == 'eventMenuTab'">
  <p-tabMenu
    [model]="items"
    [activeItem]="activeItem"
    (click)="tabClicked($event.target.innerText)"
  >
    <ng-template pTemplate="item" let-item let-i="index">
      {{ item.label }}
    </ng-template>
  </p-tabMenu>
</div>
