<div class="subscriptions">
  <!-- show user jazzcash payment info  -->
  <div class="p-grid justify-content-center align-items-center pricing-container" *ngIf="this.countryLocation=='PK'">
    <div class="p-col-12 p-md-8 text-center">
      <h2><b>Are you in Pakistan and don’t have a PayPal Account?</b></h2>
      <div>
        <p>Please use JAZZ Cash transfer facility as detailed below:</p>
        <p><b>Details of our account:</b></p>
        <p>Name : Ali Abbas Shah</p>
        <p>Contact Number : 03002614455</p>
        <br />
        <p>You can pick one of the following subscription plans:</p>
        <div class="flex align-items-center gap-4  justify-content-center">
          <div class="p-col-5">
            <b>Premium subscription:</b><br />
            PKR 3000 for 1 month<br />
            PKR 6000 for 3 months<br />
            PKR 9000 for 6 months<br />
          </div>
          <div class="p-col-5 d-none">
            <b>Basic Subscription:</b><br />
            PKR 2000 for 1 month<br />
            PKR 4000 for 3 months<br />
            PKR 6000 for 6 months<br />
          </div>
        </div>
        <br />
        <p><b>Important:</b> When you have made a Jazz cash deposit, please email the following details to <a href="mailto:admin@shiaspouse.org?subject=ShiaSpouse%20JazzCash%20Payment%20Reference&amp;body=Sender%20mobile%20number:%20%0DAmount%20transferred:%20%0DSubscription%20Plan:%20%0DRegistered%20Email%20Address:%20%0D">admin&#64;shiaspouse.org</a>:</p>
        <ul style="list-style-type: none;">
          <li>(a) the sender mobile number</li>
          <li>(b) the amount transferred</li>
          <li>(c) the subscription plan (select from above)</li>
          <li>(d) the email address registered on our website</li>
        </ul>
        <br /><br />
        <h4><b>If you have a PayPal Account, you can subscribe on the website. See plan details below:</b></h4>
      </div>
    </div>
  </div>
  
  <!-- show user subscription plan info  -->
  <ng-container *ngIf="subscribedFlag == true">
    <div class="not-subscribed">
      <h4>Your current plan</h4>
      <div class="not-subscribed-main">
        <h2>{{ subscribedIData.title }}</h2>
        <p>{{ subscribedIData.description }}</p>
      </div>
    </div>
  </ng-container>
  <ng-container >
    <div class="plans">
      <div class="plans-heading">
        <h2 *ngIf="subscribedFlag == true">Upgarade your subscription plan</h2>
        <h2 *ngIf="subscribedFlag == false">{{ subscriptionpageinfo.title }}</h2>
      </div>
    </div>
    <section>
      <div class="container-fluid">
        <div class="row subscriptions-card">
          <div
            class="col-lg-5 col-md-8 col-sm-11 subscription-card-display"
            *ngFor="
              let data of subscriptionpageinfo.subsCardData;
              let i = index
            "
          >
            <div class="cards">
              <app-card
                [type]="'subscriptionCard'"
                [inputInfoData]="this.inputInfo[i]"
                [subsData]="data"
                (updateDataparent)="radioParent($event)"
                (updateRadioData)="updateData($event)"
                
              >
              </app-card>
            </div>
          </div>
          <div class="promo-code">
            <div *ngIf="showPromoCode" class="promo-form">
              <div class="promo-input-container">
                <input pInputText type="text" #codeInput placeholder="Enter promo code" />
                <button pButton type="button" label="Apply Promo" (click)="setPromoCode(codeInput.value)"></button>
              </div>
            </div>
            
            <h3 (click)="togglePromoForm()" style="cursor: pointer;">
              {{ showPromoCode ? "Don't have a promo code" : "Have a promo code" }}
            </h3>
          </div>
          
          
        </div>
      </div>
    </section>
  </ng-container>
</div>
