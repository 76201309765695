import {
  Component,
  HostBinding,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { Observable, forkJoin } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { FileUploadComponent } from "src/app/component/htmlComponents/file-upload/file-upload.component";
import { MessageServiceService } from "src/app/message.service";
import { AboutUserInfo } from "src/app/models/aboutUser-info";
import { AlimREferenceInfo } from "src/app/models/alimReference-info";
import { ProfileAlimInfo } from "src/app/models/profile-alim-info";
import { ProfileDocumentsInfo } from "src/app/models/profile-documents-info";
import { ProfilePageInfo } from "src/app/models/profile-info";
import { SubscriptionInfo } from "src/app/models/subscription-page-info";
import { UserImagesInfo } from "src/app/models/userImages-info";
import { VerificationInfo } from "src/app/models/verificationUser-info";
import { EventNotificationService } from "src/app/services/event-notification.service";
import { ProfileService } from "src/app/services/profile.service";
import { UserProfileConverterService } from "src/app/services/user-profile-converter.service";

@Component({
  selector: 'app-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.css']
})
export class UserProfileViewComponent implements OnInit {

  // getting id from router 
  userId: string;
  public accordionObj: any = [];
  inputInfo: InputInfo[] = [];
  profileInputInfo: InputInfo[] = [];
  documentTypeInfo: InputInfo[] = [];
  changeFlag = false;
  changeFlag2 = false;

  deleteImageIdFlag: number = null;
  counterInputs = 0;
  isMobileView: boolean;
  loggedInUserId;
  loggedInUserEmail;
  profileUserId;
  loggedInUserStateId;
  isProfileProtected: boolean;
  isPicPrivate: boolean;
  displayImageUploadDialog: boolean;
  displayPhoneVerifyDialog: boolean;
  profileRequestSendStatus: string;
  requestSentStatus: boolean = false;
  requestReceiveStatus: boolean = false;
  // profileRequestSend;
  profileRequestRecieved = null;
  profileInfoLoadedFlag: boolean = false;
  // event notification 
  //  notificationTexts: any[] = [];
  //  currentNotificationIndex: number = 0;
  //  currentNotificationText: string = this.notificationTexts[this.currentNotificationIndex];
  //  animate: boolean = false;
  notificationTexts: any[] = [];
  currentNotificationIndex: number = 0;
  currentNotificationText: string = '';
  notificationSlug: string = '';
  animate: boolean = false;

  subscriptionPlansCopy;
  subscribedFlag: boolean;
  subscribedIData = {};
  subscriptionpageinfo: SubscriptionInfo = {
    title: "Choose your plan",
    subsCardData: [],
  };

  // multple options
  build = [
    { value: "1", name: "Slim" },
    { value: "2", name: "Athletic" },
    { value: "3", name: "Average" },
    { value: "4", name: "Heavy" },
  ];
  complexion = [
    { value: "1", name: "Fair" },
    { value: "2", name: "Medium" },
    { value: "3", name: "Brown" },
    { value: "4", name: "White" },
    { value: "5", name: "Black" },
    { value: "6", name: "Other" },
  ];
  smoke = [
    { value: "1", name: "Socially" },
    { value: "2", name: "Occasionally" },
    { value: "3", name: "Regularly" },
    { value: "4", name: "Do Not Smoke" },
  ];
  pray_regularly = [
    { value: "1", name: "5 times a day" },
    { value: "2", name: "Occasionally" },
    { value: "3", name: "Never" },
    { value: "4", name: "Do not state" },
  ];

  disability = [
    { key: "No", value: 0 },
    { key: "Yes", value: 1 },
  ];

  photoVerifiedFlag: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileView = width < 960;
  }
  dialCodes = [
    { name: "+0" },
    { name: "+1" },
    { name: "+2" },
    { name: "+7" },
    { name: "+92" },
    { name: "+82" },
    { name: "+27" },
    { name: "+227" },
    { name: "+35" },
    { name: "+87" },
    { name: "+12" },
    { name: "+65" },
    { name: "+666" },
    { name: "+999" },
    { name: "+123" },
    { name: "+21" },
    { name: "+97" },
    { name: "+966" },
    { name: "+42" },
    { name: "+15" },
    { name: "+52" },
    { name: "+37" },
    { name: "+93" },
    { name: "+472" },
    { name: "+534" },
    { name: "+20" },
    { name: "+01" },
    { name: "+100" },
    { name: "+200" },
    { name: "+300" },
    { name: "+301" },
    { name: "+201" },
  ];
  public saveType: string;
  documentType: any[] = [
    { name: "Driving License", value: 0 },
    { name: "Passport", value: 1 },
    { name: "National ID card", value: 2 },
    { name: "Birth Certificate", value: 3 },
    { name: "Voter ID", value: 4 },
    { name: "Other", value: 5 },
  ];

  idDocuments: ProfileDocumentsInfo[] = [];
  alimData: ProfileAlimInfo[] = [];
  profilePageInfo: ProfilePageInfo = {
    profileHeroImage: "./assets/images/profile-hero.jpg",
    profileImage: "",
    name: "",
    profession: "",
    address: "",
    status: "",
    phone: "",
    description: "",
    facebook_link: "",
    instagram_link: "",
    nickname: "",
    city: "",
    country: "",
  };

  aboutUserInfo: AboutUserInfo = new AboutUserInfo();
  userImageInfo: UserImagesInfo[] = [];
  verificationInfo: VerificationInfo = new VerificationInfo();
  aboutUsDataSide: string = "basicLifeStyle";
  verificationDataSide: string = "verificationStatus";
  noteFlag = false;
  myProfileFlag: boolean;
  protectedFlag: boolean;
  profileId: number;
  note: string =
    "Boost Your Profile or Unlock More Matches: Complete it now for a higher star rating!";
  aboutUsMenuItems: MenuItem[] = [
    {
      label: "Basic & Lifestyle",
      command: () => {
        this.selectedMenu("aboutUs", "basicLifeStyle");
      },
    },
    {
      label: "Personal Details",
      command: () => {
        this.selectedMenu("aboutUs", "personalDetails");
      },
    },
    {
      label: "Rating & Verification",
      command: () => {
        this.selectedMenu("aboutUs", "ratingVerification");
      },
    },
    {
      label: "Education & Career",
      command: () => {
        this.selectedMenu("aboutUs", "educationCarrer");
      },
    },
  ];
  verificationsMenuItems: MenuItem[] = [
    {
      label: "Verification Status",
      command: () => {
        this.selectedMenu("verificationStatus", "verificationStatus");
      },
    },
    {
      label: "Alim(Religious Scholar) Reference",
      command: () => {
        this.selectedMenu("verificationStatus", "alimReference");
      },
    },
    {
      label: "Email Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "emailVerification");
      },
    },
    {
      label: "Location Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "locationVerification");
      },
    },
    {
      label: "Phone Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "phoneVerification");
      },
    },
    {
      label: "Photo Verification",
      command: () => {
        this.selectedMenu("verificationStatus", "photoVerification");
      },
    },
  ];

  whoViewedMe = [];
  recentlyViewed = [];
  documentsArray: any[] = [];
  alimArray: [] = [];
  alimTabs: MenuItem[] = [{ label: "Main " }, { label: "History" }];
  showAppliedAlimTab: boolean = false;
  alimActiveTab: MenuItem = this.alimTabs[0];

  @ViewChild(FileUploadComponent) fileUploader: FileUploadComponent;

  constructor(
    private messageService: MessageServiceService,
    private Jarvis: AuthService,
    private userProfileConverter: UserProfileConverterService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private NotificationEvents: EventNotificationService,
    private route: ActivatedRoute
  ) { }



  ngOnInit(): void {
    this.initializeComponent();


    // extracting id from url 
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('name');
    });

    // event animation and data fetching 
    this.fetchEventNotifications();


    // Event notification change with animation
    setInterval(() => {
      this.animate = true;
      setTimeout(() => {
        this.currentNotificationIndex = (this.currentNotificationIndex + 1) % this.notificationTexts?.length;
        this.currentNotificationText = this.notificationTexts[this.currentNotificationIndex]?.text ?? "";
        this.notificationSlug = this.notificationTexts[this.currentNotificationIndex]?.slug ?? "";
        this.animate = false;
      }, 500); // Duration of animation
    }, 5000);
  }


  // event notification section logic 

  // handling event notification data 
  fetchEventNotifications() {
    this.NotificationEvents.getProfileEvents().subscribe(
      (data) => this.handleEventNotification(data),
      (error) => this.handleError(error)
    );
  }



  stripHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return (div.textContent || div.innerText || '').replace(/\n|\r/g, ' ').trim();
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  }



  isWithinUK(latitude: number, longitude: number): boolean {
    // Define the bounding box for the UK
    const UK_BOUNDING_BOX = {
      north: 60.84,  // Northernmost point (around Shetland)
      south: 49.84,  // Southernmost point (around Channel Islands)
      west: -8.649,  // Westernmost point (around Dingle, Ireland, considering some Northern Ireland locations)
      east: 1.768   // Easternmost point (around Lowestoft, England)
    };
    // Check if the coordinates are within the bounding box
    return latitude >= UK_BOUNDING_BOX.south && latitude <= UK_BOUNDING_BOX.north &&
      longitude >= UK_BOUNDING_BOX.west && longitude <= UK_BOUNDING_BOX.east;
  }



  handleEventNotification(notifications: any) {
    const formattedNotifications = notifications.map(notification => {
      const plainTitle = this.stripHtml(notification.title);
      const plainLocation = notification.location;
      const formattedDate = this.formatDate(notification.date);
      const slug = notification.slug;
      return {
        text: `${formattedDate} | <strong>${plainTitle}</strong> - ${plainLocation}`,
        slug: slug
      };
    });
    // console.log('current notifications', formattedNotifications);
    this.notificationTexts = formattedNotifications;
    if (this.notificationTexts.length > 0) {
      this.currentNotificationText = this.notificationTexts[this.currentNotificationIndex].text ?? "";
      this.notificationSlug = this.notificationTexts[this.currentNotificationIndex].slug ?? "";
    }
  }

  // event notification code end 

  initializeComponent() {
    this.activatedRouter.url.subscribe((paramMap) => {
      if (paramMap.length > 0) {
        const lastSegment = paramMap[paramMap.length - 1];
        const userId = lastSegment.path;
        this.profileId = +userId;
        this.getOwnProfileData();
        this.profileInfoLoadedFlag = false;

        // Set initial value of isMobileView based on window width
        const width = window.innerWidth;
        this.isMobileView = width < 960;
        this.generateFormData();
        this.noteFlag = true;
      }
    });
  }
  selectedMenu(menuType, menuItem) {
    this.showAppliedAlimTab = false;
    this.alimActiveTab = this.alimTabs[0];

    if (menuType == "aboutUs") {
      this.aboutUsDataSide = menuItem;
    }
    if (menuType == "verificationStatus") {
      this.verificationDataSide = menuItem;
      if (this.verificationDataSide == "alimReference") {
        this.saveType = "alimReference";
        this.generateFormData(this.saveType);
      }
      if (this.verificationDataSide == "emailVerification") {
        this.saveType = "emailVerification";
        this.generateFormData(this.saveType);
      }
      if (this.verificationDataSide == "phoneVerification") {
        this.saveType = "phoneVerification";
        this.generateFormData(this.saveType);
      }
    }
  }

  generateFormData(type?) {
    this.inputInfo = [];
    this.documentTypeInfo = [];

    if (type == "alimReference") {
      this.createInput(
        "Name",
        "name",
        "text",
        "Enter name of Alim",
        "text2",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Name cannot be blank",
          },
        ]
      );
      this.createInput(
        "Dial Code",
        "dialCode",
        "text",
        "Dial Code",
        "profileCreateDropdown",
        "",
        this.dialCodes,
        [
          {
            type: Validators.required,
            msg: "Dial code cannot be blank.",
          },
        ]
      );
      this.createInput(
        "Contact No",
        "contactNo",
        "text",
        "Enter contact no.",
        "text2",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Contact No cannot be blank.",
          },
        ]
      );
      this.createInput(
        "Email Address",
        "email",
        "email",
        "Enter valid email address ",
        "text2",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Email cannot be blank.",
          },
          {
            type: Validators.email,
            msg: "Enter valid email address",
          },
        ]
      );
    }
    if (type == "phoneVerification") {
      this.createInput("", "phoneNo", "text", "", "profileCreate", "", "", [
        {
          type: Validators.required,
          msg: "Please enter code.",
        },
      ]);
    }
    if (type == "emailVerification") {
      this.createInput(
        "",
        "emailVerification",
        "text",
        "Please enter code",
        "profileCreate",
        "",
        "",
        [
          {
            type: Validators.required,
            msg: "Please enter code",
          },
        ]
      );
    }
    // if (type == "otherVerification") {
    this.createInput(
      "Document Type",
      "documentType",
      "text2",
      "Select document Type",
      "profileCreateDropdown",
      "",
      this.documentType,
      [
        {
          type: Validators.required,
          msg: "Document Type cannot be blank.",
        },
      ],
      true
    );
    // }
  }
  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    data,
    validatorsInfo,
    permanent?
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.data = data;
    inputObj.validatorsInfo = validatorsInfo;
    if (permanent == true) {
      this.documentTypeInfo.push(inputObj);
    } else {
      this.inputInfo.push(inputObj);
    }
  }

  getOwnProfileData() {
    this.Jarvis.getOtherProfileData(this.profileId).subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    this.loggedInUserStateId = data.data.user.state_id;
    this.loggedInUserId = data.data.user.id;
    this.loggedInUserEmail = data.data.user.email;


    this.isProfileProtected =
      data.data.user.is_profile_protected == 1 ? true : false;

    if (this.profileId == data.data.user.id) {
      this.myProfileFlag = true;
      this.initializingData(data);
    } else {
      // this.getOtherProfileData(this.profileId);
    }
  }

  // getOtherProfileData(id) {
  //   this.Jarvis.getOtherProfileData(id).subscribe(
  //     (data) => this.HanldeGetOtherProfileData(data),
  //     (error) => this.handleError(error)
  //   );
  // }

  // HanldeGetOtherProfileData(data) {
  //   this.profileUserId = data.data.user.id;
  //   this.protectedFlag =
  //     data.data.user.is_profile_protected == 1 ? true : false;
  //   this.isPicPrivate = data.data.profile.is_pic_private == 1 ? true : false;
  //   this.profileRequestSendStatus =
  //     data.data.sentProfileRequest == null
  //       ? "rejected"
  //       : data.data.sentProfileRequest.state_id == 0
  //       ? "pending"
  //       : data.data.sentProfileRequest.state_id == 1
  //       ? "accepted"
  //       : "rejected";

  //   this.profileRequestRecieved = data.data.receiveProfileRequest
  //     ? data.data.receiveProfileRequest
  //     : null;
  //   this.requestReceiveStatus = false;
  //   if (data.data.receiveProfileRequest) {
  //     if (data.data.receiveProfileRequest.state_id == 0) {
  //       this.requestReceiveStatus = true;
  //     } else {
  //       this.requestReceiveStatus = false;
  //     }
  //   } else {
  //     this.requestReceiveStatus = false;
  //   }

  //   if (data.data.sentProfileRequest) {
  //     if (data.data.sentProfileRequest.state_id == 0) {
  //       this.requestSentStatus = true;
  //     } else {
  //       this.requestSentStatus = false;
  //     }
  //   } else {
  //     this.requestSentStatus = true;
  //   }

  //   this.myProfileFlag = false;
  //   this.initializingData(data);
  // }

  initializingData(data) {
    this.verificationInfo = new VerificationInfo();
    const userData = data.data.user;
    const profileData = data.data.profile;
    this.documentsArray = data.data.ids;
    this.alimArray = data.data.maulanaReference;
    let protectedProfileStatus;
    if (data.data.sentProfileRequest) {
      if (data.data.sentProfileRequest.state_id == 1) {
        protectedProfileStatus = false;
      } else {
        protectedProfileStatus = true;
      }
    } else {
      protectedProfileStatus = true;
    }

    this.userImageInfo = [];

    this.profilePageInfo = {
      profileHeroImage:
        "./assets/images/profile-hero.jpg",
      profileImage: userData.profile_file,
      name: userData.full_name,

      profession: "",
      address:
        userData.address != "" ? userData.address : "Address Not Defined",
      city: userData?.city ? userData?.city : "",
      country: userData?.country ? userData?.country : "",
      status:
        userData.email_verified == 1 && userData.phone_verified == 1
          ? "Verified"
          : "unverified",
      phone: userData.contact_no,
      description: userData.about_me ? userData.about_me : "",
      facebook_link: profileData?.fb_link,
      instagram_link: profileData?.insta_link,
      nickname: profileData?.nickname
    };

    // calculate age
    let age = null;
    if (data.data.user.date_of_birth) {
      const dob = new Date(data.data.user.date_of_birth);
      const currentDate = new Date();

      const years = currentDate.getFullYear() - dob.getFullYear();

      if (
        currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() &&
          currentDate.getDate() < dob.getDate())
      ) {
        age = years - 1;
      } else {
        age = years;
      }
    }
    // let timeDiff = Math.abs(
    //   Date.now() - new Date(userData.date_of_birth).getTime()
    // );

    // const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    this.aboutUserInfo.basicAndLifestyle = {
      id: userData.user_id,
      age: age || age == 0 ? `${age} years` : "Not Defined",
      dateOfBirth:
        userData.date_of_birth != null ? userData.date_of_birth : "N/A",
      maritalStatus: "",
      height:
        profileData?.height != null
          ? profileData?.height == "Hidden" ? profileData?.height : `${profileData?.height} ft`
          : "Not Defined",
      skinTone:
        profileData?.skin_tone !== ""
          ? profileData?.skin_tone == "Hidden" ? profileData?.skin_tone : this.complexion.find((el) => el.value == profileData?.skin_tone)
            ?.name
          : "Not Defined",

      prayStatus:
        profileData?.pray_regularly != null
          ? profileData?.pray_regularly == "Hidden" ? profileData?.pray_regularly : this.pray_regularly.find(
            (el) => el.value == profileData?.pray_regularly
          )?.name
          : "Not Defined",
      childrenStatus:
        profileData?.children != null || profileData?.children == ""
          ? "Yes"
          : "Not Defined",

      bodyWeight:
        profileData?.weight != null
          ? profileData?.weight == "Hidden" ? profileData?.weight : `${profileData?.weight} kgs`
          : "Not Defined",
      smoke:
        profileData?.smoke != null
          ? profileData?.smoke == "Hidden" ? profileData?.smoke : this.smoke.find((el) => el.value == profileData?.smoke)?.name
          : "Not Defined",
      contactNo: userData.contact_no,
      bodyType:
        profileData?.body_type != null && profileData?.body_type != ""
          ? profileData?.body_type == "Hidden" ? profileData?.body_type : this.build.find((el) => el.value == profileData?.body_type)?.name
          : "Not Defined",
      workingStatus: "",
      ithnaAshri: "",
      language: "",
    };

    if (profileData?.children != null || profileData?.children == "") {
      this.aboutUserInfo.basicAndLifestyle.noOfChildren = profileData?.children;
    }

    // languages
    let languages: string[] = [];
    if (userData.language) {
      const languageValues = userData.language.split(",");
      const observables = [];
      for (let lang of languageValues) {
        observables.push(this.userValueToString("language", lang));
      }
      forkJoin(observables).subscribe((results) => {
        for (let language of results) {
          languages.push(language as string);
        }
        const joinedLanguages = languages.join(", ");
        this.aboutUserInfo.basicAndLifestyle.language = joinedLanguages;
      });
    } else {
      this.aboutUserInfo.basicAndLifestyle.language = "Not Defined";
    }

    this.userValueToString("shia", profileData?.is_shia).subscribe((isShia) => {
      this.aboutUserInfo.basicAndLifestyle.ithnaAshri = isShia;
    });

    if (profileData?.working_status != "hidden") {
      this.userValueToString(
        "workingStatus",
        profileData?.working_status
      ).subscribe((workingStatus) => {
        this.aboutUserInfo.basicAndLifestyle.workingStatus = workingStatus;
      });
    } else {
      this.aboutUserInfo.basicAndLifestyle.workingStatus = profileData?.working_status;
    }


    this.aboutUserInfo.personalDetails = {

      id: userData.user_id,
      gender: profileData?.gender == 1 ? "Male" : "Female",
      country: userData.country == "" ? "Not Defined" : userData.country,
      residency: "",
      syed: profileData?.is_syed == 0 ? "Yes" : "No",
      city: userData.city == "" ? "Not Defined" : userData.city,
      ethnicity: "",
    };

    this.userValueToString("residency", profileData?.residency).subscribe(
      (residency) => {
        this.aboutUserInfo.personalDetails.residency = residency;
      }
    );

    this.userValueToString("ethnicity", userData.ethnicity).subscribe(
      (ethnicity) => {
        this.aboutUserInfo.personalDetails.ethnicity = ethnicity;
      }
    );

    this.aboutUserInfo.ratingAndVerification = {
      ratings: profileData?.star_rating,
      email_verified: userData.email_verified == 0 ? "unVerified" : "Verified",
      maulana:
        userData.maulana_refrence == 0 || userData.maulana_refrence == null
          ? "unVerified"
          : "Verified",
      phone: userData.phone_verified == 0 ? "unVerified" : "Verified",
    };

    // update educationAndCareer later
    this.aboutUserInfo.educationAndCareer = {
      education: "",
      profession: "",
      income: profileData?.income
        ? profileData?.income == "Hidden" ? profileData?.income : `${profileData?.income} ${profileData?.currency ? profileData?.currency : ""
          } `
        : "Not Defined",
    };

    this.verificationInfo.verificationStatus = {
      alimReference:
        userData.maulana_refrence == 0 || userData.maulana_refrence == null
          ? "unVerified"
          : "Verified",
      email: userData.email_verified == 0 ? "unVerified" : "Verified",
      location:
        userData.is_location_verified == "" ||
          userData.is_location_verified == null
          ? "unVerified"
          : "Verified",
      phone: userData.phone_verified == 0 ? "unVerified" : "Verified",
      photo: userData.photo_upload == 0 ? "unVerified" : "Verified",
    };
    let starCount: number = 0;
    if (userData.maulana_refrence == 1) {
      starCount++;
    }
    if (userData.email_verified == 1) {
      starCount++;
    }
    if (userData.latitude != "") {
      starCount++;
    }
    if (userData.phone_verified == 1) {
      starCount++;
    }
    if (userData.photo_upload == 1) {
      starCount++;
    }

    this.verificationInfo.rating = starCount;
    this.verificationInfo.noOfStars =
      this.verificationInfo.rating == 1
        ? "one"
        : this.verificationInfo.rating == 2
          ? "two"
          : this.verificationInfo.rating == 3
            ? "three"
            : this.verificationInfo.rating == 4
              ? "four"
              : this.verificationInfo.rating == 5
                ? "five"
                : "zero";

    this.noteFlag =
      userData.email_verified == 1 && userData.phone_verified == 1
        ? false
        : true;
    const userValueToStringObservables = [
      this.userValueToString(
        "relationship_status",
        profileData?.relationship_status
      ),
      this.userValueToString("country", userData.country),
      this.userValueToString("education", profileData?.education),
      this.userValueToString("profession", profileData?.profession),
    ];

    forkJoin(userValueToStringObservables).subscribe(
      ([maritalStatusValue, country, education, profession]) => {
        this.aboutUserInfo.basicAndLifestyle.maritalStatus = maritalStatusValue;
        this.aboutUserInfo.personalDetails.country = country;
        if (profileData?.education != "hidden") {
          this.aboutUserInfo.educationAndCareer.education = education;
        } else {
          this.aboutUserInfo.educationAndCareer.education = profileData?.education;
        }
        if (profileData?.education != "hidden") {
          this.aboutUserInfo.educationAndCareer.profession = profession;
          this.profilePageInfo.profession = profession;
        } else {
          this.aboutUserInfo.educationAndCareer.profession = profileData?.profession;
          this.profilePageInfo.profession = profileData?.profession;
        }



        // Set the flag after all assignments are complete
        this.profileInfoLoadedFlag = true;
      }
    );

    this.renderProfilePictures(data.data.photos);

    this.accordionObj.push(this.aboutUserInfo);
    this.accordionObj.push(this.verificationInfo);


    // this.profileInfoLoadedFlag = true;
  }


  renderProfilePictures(images) {
    if (images.length > 0) {
      this.userImageInfo = [];

      images.forEach((element) => {
        this.userImageInfo.push({
          imgPath: `${element.absoluteUrl}&thumb=0`,
          thumbnailImageSrc: `${element.absoluteUrl}`,
          alt: element.title,
          title: element.title,
          id: element.id,
          isApproved: element.is_approved,
          isPrimary: element.is_primary,
        });
      });

      for (let image of images) {
        if (image.is_approved == 1) {
          this.photoVerifiedFlag = true;
          break;
        }
      }
    }
  }

  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error.errors.error
          ? error.error.errors.error
          : error.error.errors && error.error.errors.message
            ? error.error.errors.message
            : error.error && error.error.reason
              ? error.error.reason
              : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }
  userValueToString(label: string, value: string): Observable<string> {
    return this.userProfileConverter.convertToText(label, value);
  }

  handleRequestData(data) {
    this.messageService.addMessages(`success`, `Success`, data.data.msg);
    this.getOwnProfileData();
  }

  handleBlockData(data) {
    this.messageService.addMessages(`success`, `Success`, data.data.msg);
  }

  handleprotectProfileData(data) {
    if (data.data.status == 200) {
      this.Jarvis.getProfileData().subscribe(
        (data) => this.handleGetOwnIsProfileProtectedData(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleGetOwnIsProfileProtectedData(data) {
    if (data.data.user.is_profile_protected == 1) {
      this.isProfileProtected = true;
      this.messageService.addMessages(
        `success`,
        `Success`,
        "Your profile is protected"
      );
    } else if (data.data.user.is_profile_protected == 0) {
      this.isProfileProtected = false;
      this.messageService.addMessages(
        `success`,
        `Success`,
        "Your profile is not protected"
      );
    }
  }





  ImageUploadDialogClosed() {
    this.displayImageUploadDialog = false;
  }
  phoneVerifyDialogClosed() {
    this.displayPhoneVerifyDialog = false;
  }

  showImageUplaoderDialog() {
    this.displayImageUploadDialog = true;
  }



  handleUploadImageError(err) {
    this.messageService.addMessages(`error`, ``, err.error);
  }

  getImagesData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetImagesData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetImagesData(data) {
    if (data) {
      this.renderProfilePictures(data.data.photos);
    }
  }



  subscribeNow() {
    this.router.navigate(["user/plans"]);
  }
  goBack() {
    this.router.navigate([`user/${this.userId}`]);
  }



  sendMessage(id) {
    this.router.navigate(["user/chat/panel"], {
      queryParams: { id },
    });
  }

  clearFileUploader() {
    if (this.fileUploader) {
      this.fileUploader.clearUploader();
    }
  }

  onUploadError(error) {
    this.messageService.addMessages(`error`, ``, error);
  }
}



