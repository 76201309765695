export class EventInfo {
  title: string;
  id: string;
  slug: string;
  description: string;
  location: string;
  latitude: string;
  longitude: string;
  date: string;
  fee: string;
  extra_members: string;
  fee_extra_member: string;
  type_id:number;
}
