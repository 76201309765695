<div
  [ngClass]="
    accessFrom == 'singUp'
      ? 'mt-2 sign-up'
      : accessFrom == 'profile'
      ? 'profile'
      : accessFrom == 'profileVideoVerification'
      ? 'profileVideoVerification'
      : ''
  "
>
  <p-fileUpload
    #fileUpload
    [mode]="mode"
    [multiple]="'multiple'"
    [chooseLabel]="label"
    name="demo[]"
    [accept]="accept"
    [customUpload]="customUpload"
    [maxFileSize]="maxFileSize"
    (onSelect)="handleOnSelect($event)"
    (onClear)="handleOnClear($event)"
    (click)="fileUpload.clear()"
  >
    <ng-template pTemplate="content">
      <ul>
        <li>
          <i class="pi pi-times" (click)="clearUploadedFile()"></i>
        </li>
      </ul>
    </ng-template>
  </p-fileUpload>
</div>
<!-- <p-fileUpload
  #form
  name="myfile"
  customUpload="true"
  (uploadHandler)="handleOnSelect($event)"
  [auto]="true"
  [mode]="'basic'"
  [chooseLabel]="'Change'"
  [chooseIcon]="'pi pi-upload'"
>
</p-fileUpload> -->
