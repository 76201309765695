<div class="notification-card">
  <div class="card-container">
    <ng-container *ngIf="cardData">
      <p-card [style.height]="'300px'">
        <p-header> {{ cardData.heading }} </p-header>
        <p-scrollPanel [style]="{ width: '100%', height: '700px' }">
          <div class="p-card-subtitle">{{ cardData.firstSubHeading }}</div>
          <ul class="notification-card-items">
            <ng-container *ngIf="cardData.notificationData.unread.length > 0">
              <li class="notification-card-items-item" *ngFor="let data of cardData.notificationData.unread">
                <div class="NC-item-main">
                  <div class="NC-item-main-img">
                    <img src="{{ data.img }}" alt="" />
                  </div>
                  <div class="NC-item-main-notification">
                    <div [innerHtml]="data.msg"></div>
                    <div *ngIf="data.action == 'acceptOrReject'" class="acceptOrReject">
                      <app-button [label]="'Accept'" [bgColor]="'#1CA9F4'" [type]="'login'"
                        [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="
                                isMobileView ? '10px 10px !important' : '10px 20px !important'
                              " [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'"
                        (button)="
                                onButtonClick('accept-request', data.model_id)
                              "></app-button>
                      <app-button [label]="'Reject'" [bgColor]="'#ff2222'" [type]="'login'"
                        [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="
                                isMobileView ? '10px 10px !important' : '10px 20px !important'
                              " [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'"
                        (button)="
                                onButtonClick('reject-request', data.model_id)
                              "></app-button>
                    </div>
                  </div>
                  <div class="NC-item-main-time">
                    <span>{{ data.time }}</span>
                  </div>
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="cardData.notificationData.unread.length == 0">
              <li class="notification-card-items-item">
                <div class="NC-items-item-empty">
                  <span>No New Notifications</span>
                </div>
              </li>
            </ng-container>
          </ul>
          <div class="p-card-subtitle">{{ cardData.secondSubHeading }}</div>
          <ul class="notification-card-items">
            <ng-container *ngIf="cardData.notificationData.read.length > 0">
              <li class="notification-card-items-item" *ngFor="let data of cardData.notificationData.read">
                <div class="NC-item-main">
                  <div class="NC-item-main-img">
                    <img src="{{ data.img }}" alt="" />
                  </div>
                  <div class="NC-item-main-notification">
                    <div [innerHtml]="data.msg"></div>
                    <div *ngIf="data.action == 'acceptOrReject'" class="acceptOrReject">
                      <app-button [label]="'Accept'" [bgColor]="'#1CA9F4'" [type]="'login'"
                        [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="
                                                  isMobileView ? '10px 10px !important' : '10px 20px !important'
                                                " [borderRadius]="'50px !important'" [color]="'#fff'"
                        [width]="'100% !important'" (button)="
                                                  onButtonClick('accept-request', data.model_id)
                                                "></app-button>
                      <app-button [label]="'Reject'" [bgColor]="'#ff2222'" [type]="'login'"
                        [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="
                                                  isMobileView ? '10px 10px !important' : '10px 20px !important'
                                                " [borderRadius]="'50px !important'" [color]="'#fff'"
                        [width]="'100% !important'" (button)="
                                                  onButtonClick('reject-request', data.model_id)
                                                "></app-button>
                    </div>
                  </div>
                  <div class="NC-item-main-time">
                    <span>{{ data.time }}</span>
                  </div>
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="cardData.notificationData.read.length == 0">
              <li class="notification-card-items-item">
                <div class="NC-items-item-empty">
                  <span>No Notifications</span>
                </div>
              </li>
            </ng-container>
          </ul>
        </p-scrollPanel>
      </p-card>
    </ng-container>
  </div>
</div>