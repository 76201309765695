<ng-container *ngIf="!isMobileView">
  <div
    class="notifications container-fluid container-fluid-overflow messageBody"
  >
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="main-card">
          <app-card
            [type]="'message-friends'"
            [inputInfoData]="this.inputInfo"
            [cardData]="this.messages.conversations"
            (selectedConversationId)="selectedConvo($event, messageFromParam)"
            [conversationSelectedFlag]="this.alreadySelectedFlagAndId"
            [changeFlag]="changeFlag"
            (cardUpdate)="updateData($event.value, $event.modelName)"
            (cardPreSubmit)="preSubmit()"
            (cardSubmit)="onSubmit()"
            [style]="{ 'flex-basis': '30%', 'max-width': '30%' }"
          ></app-card>
          <app-card
            [type]="'message-conversation'"
            [inputInfoData]="this.inputInfo"
            [messageSelectedFlag]="messageSelectedFlag"
            [cardData]="this.selectedConversation"
            (cardClicked)="onCardClicked($event)"
            [changeFlag]="changeFlag"
            (cardUpdate)="updateData($event.value, $event.modelName)"
            (cardPreSubmit)="preSubmit()"
            (cardSubmit)="checkBlockStatus(); onSubmit()"
            [style]="{ 'flex-basis': '70%', 'max-width': '70%' }"
          ></app-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isMobileView">
  <div
    [ngClass]="showMessage && isMobileView ? 'fullScreen-msg' : ''"
    class="container-fluid container-fluid-overflow-mobile mobile-messageBody"
  >
    <app-card
      *ngIf="!showMessage"
      [type]="'message-friends'"
      [inputInfoData]="this.inputInfo"
      [cardData]="this.messages.conversations"
      style="flex-basis: 100%"
      [conversationSelectedFlag]="this.alreadySelectedFlagAndId"
      [changeFlag]="changeFlag"
      (cardUpdate)="updateData($event.value, $event.modelName)"
      (cardPreSubmit)="preSubmit()"
      (cardSubmit)="onSubmit()"
      (selectedConversationId)="selectedConvo($event, messageFromParam)"
      (changeWindow)="this.showMessage = true"
    ></app-card>
    <app-card
      *ngIf="showMessage"
      [mobileMsgSize]="isMobileView"
      [type]="'message-conversation'"
      [inputInfoData]="this.inputInfo"
      [messageSelectedFlag]="messageSelectedFlag"
      [cardData]="this.selectedConversation"
      (cardClicked)="onCardClicked($event)"
      [changeFlag]="changeFlag"
      (cardUpdate)="updateData($event.value, $event.modelName)"
      (cardPreSubmit)="preSubmit()"
      (cardSubmit)="checkBlockStatus(); onSubmit()"
      style="flex-basis: 100%"
    ></app-card>
  </div>
</ng-container>
<app-dialog
  (close)="messageDialogClosed()"
  [openDialog]="messageDialog"
  [type]="'message'"
  [dialogData]="messageDialogData"
>
</app-dialog>
