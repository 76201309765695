import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { HomePageInfo } from "src/app/models/home-page-info";
import { SearchInfo } from "src/app/models/search-info";
import { MessageServiceService } from "src/app/message.service";
import { Router } from "@angular/router";
import { HomeCard } from "src/app/models/home-card-info/home-card";
import { ProfileService } from "src/app/services/profile.service";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { isPlatformBrowser } from "@angular/common";
import { EventNotificationService } from "src/app/services/event-notification.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  isLoggedIn = false;
  changeFlag = false;
  itemDataFlag = false;
  counterInputs = 0;
  optionsLoadedFlag: boolean = false;
  inputInfo: InputInfo[] = [];
  searchInfo: SearchInfo = new SearchInfo();
  // event notification 
  notificationTexts: string[] = [];
  currentNotificationIndex: number = 0;
  currentNotificationText: string = this.notificationTexts[this.currentNotificationIndex];
  animate: boolean = false;

  homeData: HomeCard;
  homePageInfo: HomePageInfo = {
    pageHeroImage: "home-hero-image.jpg",
    title: "Find your perfect Life Partner",
    logoPath: "shia-spouse-home-logo.png",
    whyChoseUs: {
      title: "Why Choose Us",
      para: "Shia Spouse is a project of Majlis-e-Ulama-e-Shia (Europe).",
      BrandPropositionDetail: [
        {
          title: "Affordable",
          para: "Free to register. Subscription is required to send messages.",
          logoPath: "affordable.png",
        },
        {
          title: "Transparent",
          para: "Your data is confidential and you are in control of what to share with others.",
          logoPath: "transparent.png",
        },
        {
          title: "Private, Secure & Anonymous",
          para: "Compliant with GDPR and Data Protection Regulations.",
          logoPath: "secure.png",
        },
      ],
    },
    successStory: {
      heroImage: "pexels-ikram-nasma-9280110.jpg",
      title: "Start Your Success Story On Shia Spouse",
      para: "The secret of a happy marriage is finding the right person. ShiaSpouse is a platform to help you in this journey.",
    },
    ourCustomers: {
      title: "What Our Members Say",
      CaroselItems: [
        {
          title: "Review 1",
          description: "ShiaSpouse has been a blessing for me and my family. The platform is so well-organized and easy to use. The team behind it genuinely cares about creating meaningful connections, and I felt supported throughout my journey. Alhamdulillah, I found my perfect match here. Highly recommend!",
        },
        {
          title: "Review 2",
          description:
            "After trying several matrimonial sites, I finally found ShiaSpouse. The focus on the Shia community made it easier to find someone who shares my values and beliefs. The profiles are detailed, and the verification process ensures that you’re connecting with serious individuals. I couldn’t be happier with the outcome!",
        },
        {
          title: "Review 3",
          description:
            "ShiaSpouse is a great platform with a lot of potential. The website is user-friendly, and I appreciate the emphasis on privacy and security. I did experience some delays in responses from matches, but overall, the service was excellent, and I found a compatible partner.",
        },
        {
          title: "Review 4",
          description:
            "The best thing about ShiaSpouse is how specific it is to the Shia community. I was able to find a partner who not only shares my faith but also understands and respects my cultural background. The process was smooth, and I’m grateful for this platform.",
        },
        {
          title: "Review 5",
          description:
            "ShiaSpouse offers a unique and much-needed service for the Shia community. The site is well-designed, and the support team is responsive. I found someone special here, but I think the site could benefit from more user engagement features like chat options or video calls.",
        },
        {
          title: "Review 6",
          description:
            "ShiaSpouse exceeded my expectations. The platform is specifically tailored to the Shia community, which made my search so much easier. The process of connecting with potential matches was straightforward, and I found the love of my life here. I would highly recommend it to anyone serious about finding a spouse",
        },
      ],
    },
    homeCardData: [
      {
        homeTitle: "Muhammad Umar",
        homePara:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
      {
        homeTitle: "Muhammad Umar",
        homePara:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
      {
        homeTitle: "Muhammad Umar",
        homePara:
          "Shiaspouse helped me finding right person. The platform is very easy to use with accurate database. Highly recommended & Halal way to find your spouse.",
      },
    ],
  };
  looking_for = [
    { name: `Male`, value: `1` },
    { name: `Female`, value: `2` },
  ];
  country = [];

  age_from = [
    // { name: 16, value: 16 },
    // { name: 17, value: 17 },
    { name: 18, value: 18 },
    { name: 19, value: 19 },
    { name: 20, value: 20 },
    { name: 21, value: 21 },
    { name: 22, value: 22 },
    { name: 23, value: 23 },
    { name: 24, value: 24 },
    { name: 25, value: 25 },
    { name: 26, value: 26 },
    { name: 27, value: 27 },
    { name: 28, value: 28 },
    { name: 29, value: 29 },
    { name: 30, value: 30 },
    { name: 31, value: 31 },
    { name: 32, value: 32 },
    { name: 33, value: 33 },
    { name: 34, value: 34 },
    { name: 35, value: 35 },
    { name: 36, value: 36 },
    { name: 37, value: 37 },
    { name: 38, value: 38 },
    { name: 39, value: 39 },
    { name: 40, value: 40 },
    { name: 41, value: 41 },
    { name: 42, value: 42 },
    { name: 43, value: 43 },
    { name: 44, value: 44 },
    { name: 45, value: 45 },
    { name: 46, value: 46 },
    { name: 47, value: 47 },
    { name: 48, value: 48 },
    { name: 49, value: 49 },
    { name: 50, value: 50 },
    { name: 51, value: 51 },
    { name: 52, value: 52 },
    { name: 53, value: 53 },
    { name: 54, value: 54 },
    { name: 55, value: 55 },
    { name: 56, value: 56 },
    { name: 57, value: 57 },
    { name: 58, value: 58 },
    { name: 59, value: 59 },
    { name: 60, value: 60 },
    { name: 61, value: 61 },
    { name: 62, value: 62 },
    { name: 63, value: 63 },
    { name: 64, value: 64 },
    { name: 65, value: 65 },
    { name: 66, value: 66 },
    { name: 67, value: 67 },
    { name: 68, value: 68 },
    { name: 69, value: 69 },
    { name: 70, value: 70 },
    { name: 71, value: 71 },
    { name: 72, value: 72 },
    { name: 73, value: 73 },
    { name: 74, value: 74 },
    { name: 75, value: 75 },
    { name: 76, value: 76 },
    { name: 77, value: 77 },
    { name: 78, value: 78 },
    { name: 79, value: 79 },
    { name: 80, value: 80 },
  ];
  age_to = [
    // { name: 16, value: 16 },
    // { name: 17, value: 17 },
    { name: 18, value: 18 },
    { name: 19, value: 19 },
    { name: 20, value: 20 },
    { name: 21, value: 21 },
    { name: 22, value: 22 },
    { name: 23, value: 23 },
    { name: 24, value: 24 },
    { name: 25, value: 25 },
    { name: 26, value: 26 },
    { name: 27, value: 27 },
    { name: 28, value: 28 },
    { name: 29, value: 29 },
    { name: 30, value: 30 },
    { name: 31, value: 31 },
    { name: 32, value: 32 },
    { name: 33, value: 33 },
    { name: 34, value: 34 },
    { name: 35, value: 35 },
    { name: 36, value: 36 },
    { name: 37, value: 37 },
    { name: 38, value: 38 },
    { name: 39, value: 39 },
    { name: 40, value: 40 },
    { name: 41, value: 41 },
    { name: 42, value: 42 },
    { name: 43, value: 43 },
    { name: 44, value: 44 },
    { name: 45, value: 45 },
    { name: 46, value: 46 },
    { name: 47, value: 47 },
    { name: 48, value: 48 },
    { name: 49, value: 49 },
    { name: 50, value: 50 },
    { name: 51, value: 51 },
    { name: 52, value: 52 },
    { name: 53, value: 53 },
    { name: 54, value: 54 },
    { name: 55, value: 55 },
    { name: 56, value: 56 },
    { name: 57, value: 57 },
    { name: 58, value: 58 },
    { name: 59, value: 59 },
    { name: 60, value: 60 },
    { name: 61, value: 61 },
    { name: 62, value: 62 },
    { name: 63, value: 63 },
    { name: 64, value: 64 },
    { name: 65, value: 65 },
    { name: 66, value: 66 },
    { name: 67, value: 67 },
    { name: 68, value: 68 },
    { name: 69, value: 69 },
    { name: 70, value: 70 },
    { name: 71, value: 71 },
    { name: 72, value: 72 },
    { name: 73, value: 73 },
    { name: 74, value: 74 },
    { name: 75, value: 75 },
    { name: 76, value: 76 },
    { name: 77, value: 77 },
    { name: 78, value: 78 },
    { name: 79, value: 79 },
    { name: 80, value: 80 },
  ];
  isBrowser: Boolean = false;
  constructor(
    private messageService: MessageServiceService,
    private router: Router,
    private profileService: ProfileService,
    private tokenStorage: TokenStorageService,
    private NotificationEvents: EventNotificationService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  isMobileView: boolean = false;
  toMobileView: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (this.isBrowser) {
      const width = event.target.innerWidth;
      this.isMobileView = width < 950;
      this.toMobileView = width < 576;
    }
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      const width = window.innerWidth;
      this.isMobileView = width < 950;
      this.toMobileView = width < 576;
    }
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }
    this.getOptions();

    this.fetchEventNotifications();

    // Event notification change with animation
    setInterval(() => {
      this.animate = true;
      setTimeout(() => {
        this.currentNotificationIndex = (this.currentNotificationIndex + 1) % this.notificationTexts.length;
        this.currentNotificationText = this.notificationTexts[this.currentNotificationIndex];
        this.animate = false;
      }, 500); // Duration of animation
    }, 5000);
  }

  // handling event notification data 
  fetchEventNotifications() {
    this.NotificationEvents.getAllEvents().subscribe(
      (data) => this.handleEventNotification(data),
      (error) => this.handleError(error)
    );
  }



  stripHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return (div.textContent || div.innerText || '').replace(/\n|\r/g, ' ').trim();
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  }





  handleEventNotification(notifications: any) {
    const formattedNotifications = notifications.map(notification => {
      let plainDescription = this.stripHtml(notification.description);
      plainDescription = plainDescription.slice(0, 100)
      const formattedDate = this.formatDate(notification.date);
      return `${formattedDate} | ${plainDescription}`;
    });



    this.notificationTexts = formattedNotifications;




    // this.currentNotificationText = this.notificationTexts[this.currentNotificationIndex];

  }

  generateFormData() {
    this.inputInfo = [];
    this.createInput(
      "Looking For",
      "looking_for",
      "text",
      "Looking For",
      "dropdown",
      "",
      this.looking_for,
      " ",
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Type",
        // },
      ]
    );
    this.createInput(
      "Country",
      "country",
      "text",
      "Country",
      "dropdown",
      "",
      this.country,
      " ",
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Country",
        // },
      ]
    );
    this.createInput(
      "Age From",
      "age_from",
      "text",
      "Age From",
      "dropdown",
      "",
      this.age_from,
      " ",
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Age To",
      "age_to",
      "text",
      "Age To",
      "dropdown",
      "",
      this.age_to,
      " ",
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    data,
    option,
    search,
    validatorsInfo,
    scrollHeight?
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.data = data;
    inputObj.option = option;
    inputObj.search = search;
    inputObj.scrollHeight = scrollHeight;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  getOptions() {
    this.profileService.getAllOptions().subscribe(
      (data) => this.handleOptions(data),
      (error) => this.handleError(error)
    );
  }

  handleOptions(data) {
    this.optionsLoadedFlag = true;
    this.country = [];

    for (let [key, value] of Object.entries(data.data.CountryOptions)) {
      this.country.push({
        value: key,
        name: value.toString(),
      });
    }

    this.generateFormData();
  }

  handleError(error) { }

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }
  updateData(value, modelName) {
    if (modelName == "age_from") {
      if (value != null) {
        let tempAgeToArr = JSON.parse(JSON.stringify(this.age_to));
        tempAgeToArr = this.age_to.filter((age) => age.value >= value.value);
        this.inputInfo.find((input) => input.modelName == "age_to").option =
          tempAgeToArr;
        this.inputInfo.find((input) => input.modelName == "age_to").data =
          tempAgeToArr;
      } else {
        let tempAgeToArr = JSON.parse(JSON.stringify(this.age_to));
        this.inputInfo.find((input) => input.modelName == "age_to").option =
          tempAgeToArr;
        this.inputInfo.find((input) => input.modelName == "age_to").data =
          tempAgeToArr;
      }
    }
    if (modelName == "country") {
      if (value != null) {
        this.searchInfo[modelName] = value.name ? value.name : "";
      }
    } else {
      if (value != null) {
        this.searchInfo[modelName] = value.value ? value.value : "";
      }
    }
  }

  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }
    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      this.messageService.addMessages(
        "info",
        "Info",
        "Please select all options"
      );
      return;
    }
    // If all filters empty it should go in params otherwise only that which is not empty
    if (this.searchInfo) {
      const allEmpty = Object.values(this.searchInfo).every(
        (value) => value === ""
      );
      if (!allEmpty) {
        const keysToRemove = [];
        for (const key in this.searchInfo) {
          if (
            this.searchInfo.hasOwnProperty(key) &&
            this.searchInfo[key] === ""
          ) {
            keysToRemove.push(key);
          }
        }
        for (const keyToRemove of keysToRemove) {
          delete this.searchInfo[keyToRemove];
        }
      }
    }
    const authToken = localStorage.getItem('AuthToken');

    if (authToken) {
      this.router.navigate(['/user/site/search'], { queryParams: this.searchInfo, });
    } else {
      this.router.navigate(['./site/search'], { queryParams: this.searchInfo, });
    }
  }
}
