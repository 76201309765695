<p-dialog
  [modal]="true"
  [(visible)]="openDialog"
  [breakpoints]="{ '960px': '50vw', '640px': '75vw' }"
  [style]="{ width: '35vw' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  *ngIf="this.type == 'sms'"
>
  <div class="modal-content modalpayment">
    <div class="modal-header">
      <h5 class="modal-title">Save as Template</h5>
      <button
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        <img src="assets/close.png" alt="" />
      </button>
    </div>
    <div class="modal-body">
      <span class="label">{{ this.inputInfo.label }}</span>
      <app-text-box
        [inputInfo]="this.inputInfo"
        [changeFlag]="this.changeFlag"
        *ngIf="this.inputInfo && this.inputInfo.type == 'text2'"
        (updateData)="saveData($event)"
      >
      </app-text-box>
      <div class="row justify-content-center">
        <div class="col-md-4 col-lg-4 col-xl-3">
          <app-button
            [label]="'Submit'"
            [bgColor]="'#55A8FD'"
            [color]="'#ffff'"
            (button)="onSubmit()"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<div class="loginDialog" *ngIf="this.type == 'login'">
  <p-dialog
    [modal]="true"
    [(visible)]="openDialog"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '60vw' }"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    *ngIf="this.type == 'login'"
    (onHide)="closeModal()"
  >
    <app-login (showRegisterDialog)="showRegisterModal()"></app-login>
  </p-dialog>
</div>

<div class="registerDialog" *ngIf="this.type == 'register'">
  <p-dialog
    [modal]="true"
    [(visible)]="openDialog"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '60vw' }"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    *ngIf="this.type == 'register'"
    (onHide)="closeModal()"
  >
    <app-profile-create></app-profile-create>
  </p-dialog>
</div>

<div class="mobileFilters" *ngIf="this.type == 'mobileFilters'">
  <p-dialog
    [modal]="true"
    [(visible)]="openDialog"
    [breakpoints]="{ '960px': '100vw' }"
    [style]="{ width: '60vw' }"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    position="top"
    *ngIf="this.type == 'mobileFilters'"
    (onHide)="closeModal()"
  >
    <app-filter
      [type]="'myMatchesFilters'"
      [inputInfo]="this.inputInfo"
      (submit)="onDailogFilterSubmit($event)"
      (updateData)="onUpdateData($event)"
    >
    </app-filter>
  </p-dialog>
</div>

<div class="profile-image-upload" *ngIf="this.type == 'profile-image-upload'">
  <p-dialog
    [modal]="true"
    [(visible)]="openDialog"
    [breakpoints]="{ '960px': '80vw' }"
    [style]="{ width: '90vw', height: '90vh' }"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    *ngIf="this.type == 'profile-image-upload'"
    (onHide)="closeModal()"
  >
    <app-card
      (cardButtonClick)="onCardButtonClicked($event)"
      [type]="'profileImageUpload'"
      [cardData]="inputInfo"
      [imageUploadedFlag]="imageUploadedFlag"
    ></app-card>
  </p-dialog>
</div>

<div class="verify" *ngIf="this.type == 'verify'">
  <p-dialog
    header="Enter Verification Code"
    [modal]="true"
    [(visible)]="openDialog"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    closable="true"
    *ngIf="this.type == 'verify'"
    (onHide)="closeModal()"
  >
    <div class="verify-container d-flex flex-column">
      <div *ngFor="let box of this.inputInfo">
        <app-text-box
          [inputInfo]="box"
          [changeFlag]="this.changeFlag"
          (updateData)="onUpdateData($event, box.modelName)"
          (preSubmit)="preSubmit()"
          (submit)="onSubmitVerification()"
          [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
          [borderRadius]="'35px'"
          [width]="'100%'"
          [height]="'40px'"
          [padding]="'0 2rem'"
        ></app-text-box>
      </div>
      <div>
        <app-button
          [label]="'Enter Code'"
          [borderRadius]="'35px'"
          [color]="'#ffffff'"
          [type]="'homeSearch'"
          [bgColor]="'#DD1485'"
          [fontWeight]="'600'"
          [height]="'100%'"
          [fontSize]="'clamp(12px, 4vw, 16px)'"
          [padding]="'.5rem 1rem !important'"
          iconPos="right"
          (button)="onDialogSubmit($event)"
        ></app-button>
      </div>
    </div>
  </p-dialog>
</div>
<div class="messageDialog" *ngIf="this.type == 'message'">
  <p-dialog
    [modal]="true"
    [(visible)]="openDialog"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '50vw' }"
    [closeOnEscape]="true"
    (onHide)="closeModal()"
    [header]="dialogData.header"
  >
    <!-- <h2>Subscription Required</h2> -->
    <div class="messageData" [innerHtml]="dialogData.message"></div>
  </p-dialog>
</div>
