/**
 * This is an object of the boolean type
 */
export const environment = {
  production: true,
  url: "https://staging.shiaspouse.org/api",
  captchaSiteKey: "6Ld2Ws8pAAAAAJknrTfLaCSeSiGx9EhYNXSm_Ujn",
  captchaSecretKey: "6Ld2Ws8pAAAAALjWtijmGYuOEYg2QEuOmIEz8x3o",
  // Social links
  googleClientId:
    "295457135099-vob856e6jtr6lkvj3ung38reejd7cebq.apps.googleusercontent.com",
  googleSecretKey: "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",
  facebookClientId: "1018359373282148",
  facebookSecretKey: "705e978bd117ca5c8b3307d51a4146b3",
};
