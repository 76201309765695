import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactUsInfo } from '../models/contact-us-page-info';
import { AuthService } from '../auth/auth.service';
import { MessageServiceService } from '../message.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  captchaErrorFlag: boolean;
  captcha: any;

  ContactUsPageInfo: ContactUsInfo = {
    pageHeroImage: "aboutus-ss.png",
    title: "Contact Us",
    description:
      "Contact us at info@shiaspouse.org for any feedback and suggestions.",


  };

  constructor(private fb: FormBuilder,
    private messageService: MessageServiceService,
    private Jarvis: AuthService,
  ) { }

  isMobileView: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileView = width < 950;
  }

   

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', [
        Validators.required,
        Validators.pattern('^[0-9]+$'),  // Ensures only integers
        Validators.min(1),               // Ensures positive numbers
        Validators.minLength(8)          // Ensures minimum length of 8 digits
      ]],
      body: ['', Validators.required],
    });

  

    const width = window.innerWidth;
    this.isMobileView = width < 950;
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }
  errored($event: any) {
    this.captcha = null;
    this.captchaErrorFlag = true;
  }
  onSubmit(): void {
    if (this.contactForm.valid) {
      console.log(this.contactForm.value);
      // Perform API integration here

      this.captchaErrorFlag = !this.captcha ? false : true;
      if (!this.captchaErrorFlag) {
        return;
      }

      this.Jarvis.contactForm({
        ContactForm: this.contactForm.value
      }).subscribe(
        (data) => this.handleEditData(data),
        (error) => this.handleError(error)
      );
    }





  }

  handleEditData(data) {
    this.messageService.addMessages(
      `success`,
      `Success`,
      data.data.msg
    );
    this.contactForm.reset();
  }
  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error && error.error.reason
          ? error.error.reason
          : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }
}

