<div *ngIf="this.accessFrom == 'campaignWhen'">
  <div class="p-fluid p-grid p-formgrid p-float-label">
    <p-calendar autocomplete="nope" [formControl]="this.formControl" placeholder=" {{ this.inputInfo.placeHolder }}"
      (keyup.enter)="onEnter()" dateFormat="dd/mm/yy" [minDate]="this.minDate"></p-calendar>
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
    <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
      <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
      <p>Hide</p>
    </div>
    <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
      getError()
      }}</small>
  </div>
</div>
<div *ngIf="this.accessFrom == 'smsPlusDashBoard'">
  <div class="p-fluid p-grid p-formgrid p-float-label">
    <p-calendar autocomplete="nope" [formControl]="this.formControl" placeholder=" {{ this.inputInfo.placeHolder }}"
      (keyup.enter)="onEnter()" dateFormat="yy-mm-dd" [minDate]="this.minDate"></p-calendar>
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
    <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
      <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
      <p>Hide</p>
    </div>
    <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
      getError()
      }}</small>
  </div>
</div>
<div *ngIf="this.accessFrom == 'createProfile'">
  <div class="p-fluid d-flex p-grid p-formgrid cProfile p-float-label flex-column">
    <!-- <label for="username">{{this.inputInfo.placeHolder}}</label> -->

    <div class=" d-flex align-items-center gap-2">
      <p-calendar autocomplete="nope" [formControl]="this.formControl" placeholder=" {{ this.inputInfo.placeHolder }}"
      (keyup.enter)="onEnter()" dateFormat="yy-mm-dd" [minDate]="this.minDate" [maxDate]="this.maxDate"
      [defaultDate]="this.defaultDate" class=" d-flex flex-grow-1 custom-calender"></p-calendar>
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
    <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
      <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
      <p>Hide</p>
    </div>
    </div>
    <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
      getError()
      }}</small>
  </div>
</div>
<div *ngIf="this.accessFrom == 'campaignReporting'">
  <div class="p-fluid p-grid p-formgrid p-float-label">
    <p-calendar autocomplete="nope" [formControl]="this.formControl" placeholder=" {{ this.inputInfo.placeHolder }}"
      (keyup.enter)="onEnter()" selectionMode="range" dateFormat="dd/mm/yy" [maxDate]="this.maxDate"
      [inline]="true"></p-calendar>
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
    <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
      <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
      <p>Hide</p>
    </div>
    <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
      getError()
      }}</small>
  </div>
</div>

<div class="profileCreateCalender calendar-label" *ngIf="this.accessFrom == 'profileCreateCalender'">
  <div class="p-fluid p-grid p-formgrid p-float-label">
    <p-calendar autocomplete="nope" [formControl]="this.formControl" placeholder=" {{ this.inputInfo.placeHolder }}"
      (keyup.enter)="onEnter()" dateFormat="dd/mm/yy" [minDate]="this.minDate" class=" px-2 custom-calender"></p-calendar>
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
    <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
      <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
      <p>Hide</p>
    </div>
    <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
      getError()
      }}</small>
  </div>
</div>