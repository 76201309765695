import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { ChipsInfo } from "../../componentModel/chips-info";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-galleria",
  templateUrl: "./galleria.component.html",
  styleUrls: ["./galleria.component.css"],
})
export class GalleriaComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() deleteImageIdFlag: number;
  @Input() imagesData: any;
  @Input() isMyProfile: any;

  @Output() confirmAction = new EventEmitter();

  images: any[] = [];
  chipsDataArray: ChipsInfo[] = [];

  responsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  responsiveOptions2: any[] = [
    {
      breakpoint: "1500px",
      numVisible: 5,
    },
    {
      breakpoint: "1024px",
      numVisible: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  displayCustom: boolean;

  activeIndex: number = 0;

  constructor(private confirmationService: ConfirmationService) {}

  ngOnInit() {
    this.initImages(this.imagesData);
  }

  initImages(images) {
    this.images = [];
    for (let image of images) {
      const statusLabel = image.isApproved == 1
        ? "Verified"
        : image.isApproved == 2
        ? "Rejected"
        : "Not Verified";
  
      const statusClassName = image.isApproved == 0
        ? "not-verified status-chip"
        : image.isApproved == 1
        ? "verified status-chip"
        : image.isApproved == 2
        ? "rejected status-chip"
        : "status-chip";
  
      const chipData = [
        {
          id: image.id,
          label: statusLabel,
          modelName: "status",
          className: statusClassName,
          image: "",
          icon: "",
          removable: false,
        },
        {
          id: image.id,
          label: "",
          modelName: "delete",
          className: "delete-chip",
          image: "",
          icon: "pi pi-trash",
          removable: false,
        },
      ];
  
      // Add the "profile-picture" chip only if the image is verified
      if (image.isApproved == 1) {
        chipData.push({
          id: image.id,
          label: "",
          modelName: "profile-picture",
          className: "set-as-profile-pic-chip",
          image: "",
          icon: image.isPrimary == 1 ? "pi pi-image" : "pi pi-upload",
          removable: false,
        });
      }
  
      this.images.push({
        id: image.id,
        previewImageSrc: image.imgPath,
        thumbnailImageSrc: image.thumbnailImageSrc,
        alt: image.alt,
        title: image.title,
        chipData: chipData,
      });
    }
  }
  

  removeImage(id) {
    const indexOfImage = this.images.findIndex((img) => {
      return img.id == id;
    });
    this.images.splice(indexOfImage, 1);
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  onChipClicked(data) {
    // event.stopPropagation();
    if (data.modelName == "profile-picture") {
      this.confirmAction.emit({
        modelName: data.modelName,
        imageId: data.id,
      });
    }

    if (data.modelName == "delete") {
      this.confirmationService.confirm({
        target: data.event.target,
        message: "Are you sure that you want to delete this Image?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.confirmAction.emit({
            modelName: data.modelName,
            imageId: data.id,
            decision: true,
          });
        },
        reject: () => {
          this.confirmAction.emit({
            modelName: data.modelName,
            imageId: data.id,
            decision: false,
          });
        },
      });
    }
  }

  filterChips(chipData: ChipsInfo[]): ChipsInfo[] {
    return chipData.filter((chip) => {
      if (chip.modelName === 'profile-picture') {
        const statusChip = chipData.find((c) => c.modelName === 'status');
        // Only show the "profile-picture" chip if the status is verified (1)
        return statusChip && statusChip.label === 'Verified';
      }
      return true; // Keep all other chips
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imagesData && !changes.imagesData.firstChange) {
      const previousImages = changes.imagesData.previousValue;
      const newImages = changes.imagesData.currentValue;

      // const uniqueImagesData = newImages.filter(
      //   (newImage) =>
      //     !previousImages.some(
      //       (prevImage) => prevImage.imgPath === newImage.imgPath
      //     )
      // );
      this.initImages(newImages);
    }

    if (changes.deleteImageIdFlag && !changes.deleteImageIdFlag.firstChange) {
      this.removeImage(changes.deleteImageIdFlag.currentValue);
    }
  }
}
