import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { NotificationPageInfo } from "src/app/models/notification-page-info";
import { Subscription, interval } from "rxjs";
import { NotificationsService } from "src/app/services/notifications.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit {
  notificationPageInfo: NotificationPageInfo | null = null;
  initialLoadFlag;

  private notificationSubscription: Subscription;
  constructor(
    private Jarvis: AuthService,
    private notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.notificationInit();
    this.initialLoadFlag = true;
  }

  notificationInit() {
    // subscribe for notification count
    this.notificationSubscription =
      this.notificationService.notificationsCount$.subscribe((count) => {
        this.init();
      });
  }

  init() {
    this.Jarvis.notificationsList().subscribe(
      (data) => this.handleNotificationsList(data),
      (error) => this.handleError(error)
    );
  }

  handleNotificationsList(data) {
    this.notificationPageInfo = {
      heading: "Notifications",
      firstSubHeading: "Unread",
      secondSubHeading: "Read",
      notificationData: {
        unread: [],
        read: [],
      },
    };
    for (let noti of data) {
      let action = '';
      let message = noti.title;
      if (noti.title.includes("see your profile image")) {
        action = 'acceptOrReject';
      }
      if (noti.title.includes("ShiaSpouse/profile")) {
        message = noti.title.replace("ShiaSpouse/profile", "user");
      }
      if (noti.title.includes("/profile")) {
        message = noti.title.replace("/profile", "user");
      }
      if (noti.title.includes("/api/user/other-profile")) {
        message = noti.title.replace("api/user/other-profile", "user");
      }

      if (noti.title.includes("Your Photo is successfully verified")) {
        message = noti.title.replace(/<a.*?<\/a>/g, "");
      }

      if (noti.title.includes("Your request has been rejected")) {
        // let rejectd = noti.title.match(/\/rejected\/(\d+)/);
        // message = noti.title.replace("profile picture", "<a href='/user/other-profile/25470'></a>").replace("user/other-profile", "user").replace("View Profile", "Profile").replace("class='btn btn-success'", "").replace("<br /><br />", "");

        // message = noti.title.replace("user/other-profile", "user");

      }
      if (noti.title.includes("Your request has been accepted")) {

        let match = noti.title.match(/\/profile\/(\d+)/);

        let userId = noti?.created_by_id;
        let index = noti.title.indexOf("profile picture");
        let modifiedString = noti.title.slice(
          0,
          index + "profile picture".length
        );
        // console.log(message)

        message = modifiedString.replace(
          "profile",
          `<a href='/user/${userId}'>Profile</a>  `
        );

      }

      // mutating the notification title url 
      if (message) {

        const regex = /<a\s+href='\/user\/other-profile\/(\d+)'/g;

        // Use replace method to replace the matched pattern
        const modifiedTitle = message.replace(regex, (match, userId) => {
          return `<a href='/user/${userId}'`; // Replace the href attribute with /user/{userId}
        });

        message = modifiedTitle
      }


      let tempObj = {
        img: "assets/images/user.png", // placeholder for now
        msg: message,
        time: this.formatTimeAgo(noti.created_on),
        url: "",
        model_id: noti.model_id,
        action: action
      };

      if (noti.is_read == 0) {
        this.notificationPageInfo.notificationData.unread.push(tempObj);
      } else if (noti.is_read == 1) {
        this.notificationPageInfo.notificationData.read.push(tempObj);
      }
    }
  }

  formatTimeAgo(timestamp: number): string {
    const now: number = Date.now();
    const createdDate: Date = new Date(timestamp);
    const timeDifference: number = now - createdDate.getTime();
    const seconds: number = Math.floor(timeDifference / 1000);
    const minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);
    const days: number = Math.floor(hours / 24);
    const months: number = Math.floor(days / 30);
    const years: number = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else {
      return "Just now";
    }
  }

  readAllNotifications() {
    this.Jarvis.readAllNotifications().subscribe(
      (data) => this.handleReadAllNotifications(data),
      (error) => this.handleError(error)
    );
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.readAllNotifications();

    this.notificationSubscription.unsubscribe();
  }

  handleReadAllNotifications(data) { }

  handleError(error) { }
}
