<div
  [ngClass]="
    accessFrom == 'navigations'
      ? 'row headers'
      : accessFrom == 'userNavigation'
      ? 'row headers userHeader'
      : ''
  "
>
  <div class="col-3 headers-logo">
    <a href="{{ logoSrc }}">
      <img [src]="logoPath" height="70" class="logo" alt="brand logo" />
    </a>
  </div>
  <div
    class="col-9 headers-items"
    [ngClass]="navbarItemsWhite == true ? 'navbarItemsWhite' : ''"
  >
    <p-menubar [model]="items" styleClass="my-menubar"></p-menubar>
  </div>
</div>
