<div class="container-fluid justify-content-between matchesBody">
  <ng-container *ngIf="!isMobileView">
    <div class="row cardSide" *ngIf="shortlistDisplay && shortlistDisplay.length > 0">
      <div class="col-sm-12 col-md-6 col-lg-3 card" *ngFor="let data of shortlistDisplay">
        <app-card (cardClicked)="onCardClicked($event)" (cardButtonClick)="onCardButtonClick($event)"
          [type]="'matchCard'" [cardData]="data"></app-card>
      </div>
    </div>
    <div class="shortlist-empty" *ngIf="shortlistDisplay && shortlistDisplay.length == 0">
      <h4 class="text-center my-4">Shortlist is empty</h4>
    </div>
    <app-pagination [rowsPerPage]="rowsCount" [totalRecord]="totalCount"
      (nextPage)="nextRecords($event)"></app-pagination>
  </ng-container>
</div>

<ng-container *ngIf="isMobileView">
  <div class="carousel-match">
    <div class="shortlist-empty" *ngIf="shortlistDisplay && shortlistDisplay.length == 0">
      <h4 class="text-center my-4">Shortlist is empty</h4>
    </div>
    <ng-container *ngIf="shortlistDisplay && shortlistDisplay.length > 0">
      <app-carousel [type]="'myMatches'" [items]="shortlistDisplay" (buttonClicked)="onCardButtonClick($event)">
      </app-carousel>
    </ng-container>
  </div>
</ng-container>