import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/auth/auth.service";
import { CustomValidatorsInfo } from "src/app/component/componentModel/customValidator-info";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { LoginInfo } from "src/app/models/login-info";

@Component({
  selector: "app-recover",
  templateUrl: "./recover.component.html",
  styleUrls: ["./recover.component.css"],
})
export class RecoverComponent implements OnInit {
  changeFlag = false;
  counterInputs = 0;
  inputInfo: InputInfo[] = [];
  public loginInfo = new LoginInfo();

  constructor(
    private messageService: MessageService,
    private Jarwis: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.generateFormData();
  }

  generateFormData() {
    this.inputInfo = [];
    this.createInput("emailAddress", "email", "email", "Email", "text2", "", [
      {
        type: Validators.email,
        msg: "You must enter valid Email Address",
      },
    ]);
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    validatorsInfo
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  updateData(value, label) {
    this.loginInfo[label] = value;

    if (value.length == 0) {
      this.inputInfo[0].customValidator.msg = "";
      this.inputInfo[0].customValidator.activeName = "";
    }
  }

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }
  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }

    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }
    this.messageService.clear();
    this.addMessages("info", "Info", "Please Wait...");
    if (errorFlag) {
      return;
    }

    const formData = new FormData();
    formData.append("User[email]", this.loginInfo.email);

    this.Jarwis.recover(formData).subscribe(
      (data) => this.handleData(data),
      (error) => this.handleError(error)
    );
  }

  handleData(data) {
    this.messageService.clear();
    this.addMessages("success", "Success", data.data.msg);
    this.generateFormData();
  }

  handleError(error) {
    this.messageService.clear();
    let errorMessage = error.error.errors.error;
    let emailInput = this.inputInfo[0];
    if (errorMessage) {
      emailInput.customValidator = new CustomValidatorsInfo();
      let obj = new CustomValidatorsInfo();
      obj.msg = errorMessage;
      obj.activeName = emailInput.modelName;
      emailInput.customValidator = obj;
    } else {
      emailInput.customValidator = new CustomValidatorsInfo();
    }
  }

  addMessages(severity, summary, detail) {
    this.messageService.add({
      severity,
      summary,
      detail,
      sticky: true,
    });
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }
}
