<app-button
  [label]="'Sign in with google'"
  [color]="'#000000'"
  [type]="'btnWithCustomIcon'"
  [bgColor]="'#ffffff'"
  [borderRadius]="'35px !important'"
  [border]="'1px solid #707070'"
  [fontSize]="'clamp(12px, 4vw, 16px)'"
  [padding]="'1rem 4rem'"
  [width]="'100%'"
  [imgSrc]="'./assets/images/google-icon.png'"
  (click)="handleGoogleLogin()"
>
</app-button>
