// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/**
 * This is an object of the boolean type
 */
export const environment = {
  production: false,
  url: "https://staging.shiaspouse.org/api",
  captchaTestSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  captchaSiteKey: "6Ld2Ws8pAAAAAJknrTfLaCSeSiGx9EhYNXSm_Ujn",
  captchaSecretKey: "6Ld2Ws8pAAAAALjWtijmGYuOEYg2QEuOmIEz8x3o",

  // Social links
  googleClientId:
    "295457135099-vob856e6jtr6lkvj3ung38reejd7cebq.apps.googleusercontent.com",
  googleSecretKey: "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",
  facebookClientId: "1018359373282148",
  facebookSecretKey: "705e978bd117ca5c8b3307d51a4146b3",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
