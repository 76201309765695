<div class="home">
  <div class="home-hero">
    <video [muted]="'muted'" loop autoplay [src]="'assets/videos/shiaspouse-header-video.mp4'"></video>

    <div class="home-hero-content">
      <div class="home-hero-logo">
        <img src="./assets/images/{{ homePageInfo.logoPath }}" alt="" />
      </div>
      <div class="home-hero-pageTitle">
        <h2>{{ homePageInfo.title }}</h2>
      </div>
      <ng-container *ngIf="optionsLoadedFlag">
        <div class="home-search-box">
          <div class="search-box-items" *ngFor="let box of this.inputInfo; let i = index">
            <app-drop-down [inputInfo]="box" [changeFlag]="changeFlag" [data]="box.data" *ngIf="box.type == 'dropdown'"
              [scrollHeight]="
                box.data?.length >= 1 && box.data?.length <= 3
                  ? box.data?.length * 50 + 'px'
                  : (box.scrollHeight?? '200px')
              " [type]="'profileCreate'" (updateData)="updateData($event, box.modelName)" (preSubmit)="preSubmit()"
              (submit)="onSubmit()">
            </app-drop-down>
          </div>
          <div class="search-box-btn">
            <app-button [label]="'Search'" [borderRadius]="'0px 35px 35px 0px'" [color]="'#ffffff'"
              [type]="'profileCreate'" [bgColor]="'var(--color-blue)'" [width]="'100%'" [height]="'100%'"
              [type]="'homeSearch'" [padding]="'0 2rem'" (button)="preSubmit()" [fontSize]="'clamp(16px, 4vw, 18px)'"
              [icon]="'pi pi-search'" [iconPos]="'right'">
            </app-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- add event notification here  -->
  <section class="event-notification">
    <div class="notification-btn">
      <i class="pi pi-bell" style="font-size: 1.4rem"></i>
      <h2>notification</h2>
    </div>
    <div class="notification-text" [ngClass]="{ 'fade': animate }">
      <ng-container *ngIf="currentNotificationText && currentNotificationText.length; else loading">
        {{ currentNotificationText }}
      </ng-container>
      <ng-template #loading>
        Please wait while we load your notification...
      </ng-template>
    </div>
  </section>


  <section class="why-choose-us">
    <div class="container-fluid">
      <div class="row mt-5 mb-3">
        <div class="why-choose-us-heading">
          <h4>{{ homePageInfo.whyChoseUs.title }}</h4>
        </div>
        <div class="why-choose-us-para my-3">
          <p>{{ homePageInfo.whyChoseUs.para }}</p>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="homePageInfo.whyChoseUs">
          <div class="BrandPropositionDetail-card col-md-4"
            *ngFor="let data of homePageInfo.whyChoseUs.BrandPropositionDetail">
            <div class="BrandPropositionDetail-card-icon">
              <img src="./assets/images/{{ data.logoPath }}" alt="" />
            </div>
            <div class="BrandPropositionDetail-card-heading">
              <h5>{{ data.title }}</h5>
            </div>
            <div class="BrandPropositionDetail-card-para">
              <p>{{ data.para }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <section class="success-story">
    <img *ngIf="!toMobileView" class="success-story-image"
      src="./assets/images/{{ homePageInfo.successStory.heroImage }}" alt="" />
    <img *ngIf="toMobileView" class="success-story-image" src="./assets/images/pexels-ikram-nasma-mobile.png" alt="" />
    <div class="success-story-content container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="success-story-heading">
            <h4>{{ homePageInfo.successStory.title }}</h4>
          </div>
          <div class="success-story-para">
            <p>{{ homePageInfo.successStory.para }}</p>
          </div>
          <ng-container *ngIf="!isLoggedIn">
            <div class="success-story-button">
              <app-button [label]="'Join Us Now'" [padding]="'15px 15'" [borderRadius]="'30px !important'"
                [color]="'#fff'" [fontWeight]="'600'" [type]="'login'" [bgColor]="'var(--color-blue)'"
                [fontSize]="'clamp(9px, 4vw, 15px)'" [routerLink]="['/login']">
              </app-button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <!-- review section  -->
  <!-- <section class="our-customers">
    <div class="our-customers-heading">
      <h4>{{ homePageInfo.ourCustomers.title }}</h4>
    </div>
    <div class="our-customers-carousel">
      <app-carousel [type]="'home&AboutusPage'" [items]="homePageInfo.ourCustomers.CaroselItems">
      </app-carousel>
    </div>
  </section> -->
  <section class="card-section" *ngIf="false">
    <div class="card-ab" *ngFor="let data of homePageInfo.homeCardData">
      <app-card [type]="'home-page-card'" [homeData]="data" [style]="'width:57%'">
      </app-card>
    </div>
  </section>
</div>