<div class="card" *ngIf="this.col.length > 0">
  <p-table
    #dt1
    [columns]="cols"
    [value]="col"
    responsiveLayout="scroll"
    [rows]="10"
    [showCurrentPageReport]="true"
    styleClass="p-datatable-gridlines"
  >
    <!-- <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button
          pButton
          label="Clear"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="clear(dt1)"
        ></button>
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search ml-5"></i>
          <input
            class="ml-5"
            pInputText
            type="text"
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template> -->

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          <div class="p-d-flex p-jc-between p-ai-center text-center">
            {{ col.header }}
            <!-- <p-columnFilter
              type="text"
              field="{{ col.field }}"
              display="menu"
            ></p-columnFilter> -->
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-ri="rowIndex"
    >
      <tr>
        <ng-container *ngFor="let box of rowData; let i = index">
          <td *ngIf="this.clonedCol">
            <div class="col-md-12 text-center" *ngIf="this.textArea">
              <app-text-box
                (updateData)="updateData(ri, $event, box.modelName)"
                [inputInfo]="box"
                [changeFlag]="changeFlag"
                *ngIf="box.type == 'text2'"
                (submit)="onSubmit()"
              ></app-text-box>
              <!-- <span class="mt-3">
                <app-text-area
                  [inputInfo]="this.textArea"
                  [changeFlag]="this.changeFlag"
                  (updateData)="updateData($event, this.textArea.modelName)"
                ></app-text-area>
              </span> -->
            </div>
            <div class="col-md-12 text-center">
              <app-check-box
                [inputInfo]="box"
                [labelCheck]="'true'"
                [changeFlag]="changeFlag"
                (updateData)="updateData(ri, $event, box.modelName)"
                *ngIf="box.type == 'checkBox'"
                (submit)="onSubmit()"
              ></app-check-box>
              <p *ngIf="box.type == 'fieldType'" class="mt-4">
                {{ box.contactListType }}
              </p>
            </div>
          </td>
        </ng-container>
        <td style="text-align: center">
          <!-- <button
            pButton
            pRipple
            type="button"
            icon="pi pi-pencil"
            (click)="onRowEditInit(rowData)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-check"
            (click)="onRowEditSave(rowData)"
            class="p-button-rounded p-button-text p-button-success p-mr-2"
          ></button> -->
          <button
            pButton
            pRipple
            type="button"
            *ngIf="rowData; ri"
            icon="pi pi-times"
            label="Delete"
            (click)="onRowEditCancel(rowData, ri)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </td>
      </tr> </ng-template
  ></p-table>
</div>
