import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.css"],
})
export class CarouselComponent implements OnInit{
  @Input() items: any[] = []; // Ensure items are defined as an array
  @Input() type: string;
  @Output() buttonClicked = new EventEmitter();
  @Output() cardClicked = new EventEmitter();
  @Output() onPage = new EventEmitter();
  responsiveOptions: any[] | undefined;

  // Define the responsive options for the carousel
  ngOnInit() {
   

    this.responsiveOptions = [
      {
          breakpoint: '1199px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '991px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
      }
  ];
}

  constructor() {}

  onPageClicked(event) {
    this.onPage.emit(event);
  }

  onCardButtonClick(event) {
    this.buttonClicked.emit(event);
  }

  onCardClicked(event) {
    this.cardClicked.emit(event);
  }
}
