import { MessageServiceService } from "src/app/message.service";
import { BookService } from "./../../../services/book.service";
import { Component, OnInit } from "@angular/core";
import { BooksPageInfo } from "src/app/models/books-page-info";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/auth/token-storage.service";

@Component({
  selector: "app-books",
  templateUrl: "./books.component.html",
  styleUrls: ["./books.component.css"],
})
export class BooksComponent implements OnInit {
  isLoggedIn: boolean;
  booksPageInfo: BooksPageInfo = {
    pageHeroImage: "book-artical-ss.png",
    title: "Books & Articles",
    description:
      "Explore books & articles for practical advice on marriage and relationships.",
    books: {
      title: "Latest Books",
      books: [],
    },
    articles: {
      title: "Latest Articles",
      articles: [],
    },
  };
  constructor(
    private bookService: BookService,
    private messageService: MessageServiceService,
    private router: Router,
    private tokenStorage: TokenStorageService
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.tokenStorage.getToken() ? true : false;

    this.bookService.getAllBooks().subscribe(
      (data) => this.handleBooksData(data),
      (error) => this.handleError(error)
    );

    this.bookService.getAllArticles().subscribe(
      (data) => this.handleArticlesData(data),
      (error) => this.handleError(error)
    );
  }

  handleArticlesData(data) {
    this.booksPageInfo.articles.articles = data;
  }

  handleBooksData(data) {
    this.booksPageInfo.books.books = data;
  }

  onCardClick(data) {
    if (data.cardName == "storyCard") {
      let id = data.id;
      if (this.isLoggedIn) {
        this.router.navigate(["user/article"], {
          queryParams: { id },
        });
      } else {
        this.router.navigate(["article"], {
          queryParams: { id },
        });
      }
      window.scrollTo(0, 0);
    }
  }

  handleError(error) {
    const errorMessage = error.error.message
      ? error.error.message
      : error.error.reason
      ? error.error.reason
      : error.reason;
    this.messageService.addMessages("error", "Error", errorMessage);
  }
}
