<div class="login-modal"></div>
<div class="body">
  <div class="navbar-header">
    <app-navbar
      class="custom-menubar"
      [items]="item"
      [logoPath]="'assets/images/shia_spouse.png'"
      [accessFrom]="'navigations'"
      [logoSrc]="''"
    >
    </app-navbar>
  </div>
</div>
