<div class="stories">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/{{ storyPageInfo.pageHeroImage }}"
      alt=""
    />
    <div class="hero-content">
      <div class="hero-pageTitle">
        <h2>{{ storyPageInfo.title }}</h2>
      </div>
      <div class="hero-pageDescription">
        <p>{{ storyPageInfo.description }}</p>
      </div>
    </div>
  </div>
  <section class="story-section container-fluid">
    <div class="story-section-title">
      <h3 [innerHTML]="storyPageInfo.article.title"></h3>
    </div>
    <div class="row">
      <!-- <div class="story-section-image">
        <img [src]="storyPageInfo.article.image" alt="" />
      </div> -->
      <div class="story-section-para">
        <p [innerHTML]="storyPageInfo.article.description"></p>
      </div>
    </div>
  </section>
  <section class="latest-stories-section container-fluid">
    <div class="latest-stories-section-title">
      <h3>{{ storyPageInfo.latestArticles.title }}</h3>
    </div>
    <div class="latest-stories-section-main">
      <div class="row">
        <ng-container
          *ngIf="
            storyPageInfo &&
            storyPageInfo.latestArticles &&
            storyPageInfo.latestArticles.articles
          "
        >
          <div
            class="col-sm-6 col-md-4 mb-4"
            *ngFor="let data of storyPageInfo.latestArticles.articles"
          >
            <app-card
              [type]="'storyCard'"
              [cardData]="data"
              (cardClicked)="onCardClick($event)"
            ></app-card>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</div>
