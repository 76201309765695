<div class="delete">
  <div class="delete-main">
    <h2 class="delete-title">Delete Account</h2>
    <div class="delete-input" *ngFor="let box of inputInfo">
      <app-radio-button
        [inputInfo]="box"
        (updateData)="updateData($event, box.modelName)"
        [changeFlag]="changeFlag"
        [data]="box.option"
        (preSubmit)="preSubmit()"
        (submit)="onSubmit()"
      ></app-radio-button>
    </div>
    <div class=" delete-text">Deleted Accounts - all information is removed from our platform after 60 days.</div>
    <app-button
      [label]="'Delete Account'"
      [padding]="'5px 0'"
      [color]="'#fff'"
      [type]="'login'"
      (button)="preSubmit()"
      [bgColor]="'var(--color-danger)'"
      [borderRadius]="'35px !important'"
      [fontSize]="'clamp(16px, 4vw, 22px)'"
      [maxWidth]="'400px'"
      [padding]="'1rem 2rem'"
    >
    </app-button>
  </div>
</div>
