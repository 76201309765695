import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { SubscriptionCard } from "src/app/models/subscription-info/subscription-card";
import { InputInfo } from "../../componentModel/input-info";
import { AboutUsCard } from "src/app/models/about-us-info/about-us-card";
import { HomeCard } from "src/app/models/home-card-info/home-card";
import Cropper from "cropperjs";
import { ElementRef, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { isPlatformBrowser } from "@angular/common";
import { MessageServiceService } from "src/app/message.service";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.css"],
})
export class CardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isLoggedIn: boolean;
  @Input() inputInfoData: InputInfo[];
  isHovered = false;
  selectedConvoId: number;
  // profile image uploading variables and flags
  @Input() imageUploadedFlag: boolean;
  imageUploadedFlagLocal: boolean;
  selectedFile: File | null = null;
  imageURL: SafeResourceUrl | null = null;
  uploadedProfileImageName: string;
  //  This is an input from the parent class containig our card's data
  @Input() cardData: any;
  @Input() changeFlag: boolean;

  // This is an input from the parent class containig our card's type
  @Input() type: string;
  // this is an input from parent class of subscription
  @Input() subsData: SubscriptionCard;
  SubsData = new SubscriptionCard();
  @Output() updateDataparent = new EventEmitter<SubscriptionCard>();
  @Output() updateRadioData: EventEmitter<any> = new EventEmitter<any>();
  @Input() aboutData: AboutUsCard;
  @Input() homeData: HomeCard;
  @Input() mobileMsgSize: boolean;
  @Input() conversationSelectedFlag: any;
  // @Output() submitData = new EventEmitter<string>();
  @Output() selectedConversationId = new EventEmitter<number>();
  @Output() cardClicked = new EventEmitter<any>();
  @Output() cardButtonClick = new EventEmitter<any>();
  @Output() cardSubmit = new EventEmitter<any>();
  @Output() cardPreSubmit = new EventEmitter<any>();
  @Output() cardUpdate = new EventEmitter<any>();
  @Output() changeWindow = new EventEmitter<any>();

  inputInfo: InputInfo[] = [];
  radioData: InputInfo = new InputInfo();
  counterInputs: any;
  selectedLi: boolean = false;
  resetValueFlag: boolean = false;
  isBrowser: Boolean = false;

  isMobileView: boolean;
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (this.isBrowser) {
      const width = event.target.innerWidth;
      this.isMobileView = width < 768;
    }
  }

  // cropper elements
  private cropper: Cropper;
  @ViewChild("croppingImage") croppingImageElement: ElementRef;
  public imageDestination: string;

  // message card
  @ViewChild("chatContainer") chatContainer: ElementRef;
  messageCardLoaded: boolean = false;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private tokenStorage: TokenStorageService,
    private messageService: MessageServiceService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      const width = window.innerWidth;
      this.isMobileView = width < 768;
    }

    if(this.tokenStorage.getToken()==null){
      this.isLoggedIn = false;
    }

  }

 

  updateData(event, modelName) {
    // console.log('updated', event, modelName);
    this.subsData.subsPrice = event.value;
    this.subsData.subsradio = event.value;
    this.subsData.id = event.id;

    const objectApi = {
      id: this.subsData.id,
      subsPrice: this.subsData.subsPrice,
    }


    this.updateRadioData.emit(objectApi); // Emit data to parent

  }
  onSubmit() {
    this.subsData.subsradio &&
      this.subsData.subsPrice &&
      this.subsData.subsTitle;
    let radioObj = new SubscriptionCard();
    radioObj.subsradio = this.subsData.subsradio;
    radioObj.subsPrice = this.subsData.subsPrice;
    radioObj.subsTitle = this.subsData.subsTitle;
    radioObj.id = this.subsData.id;
    this.updateDataparent.emit(radioObj);
  }
  // This function is called when the card is clicked.

  onStoryCardClick(id: number) {
    this.router.navigate(["/stories"], {
      queryParams: { id },
    });
  }

  onEventCardClick(slug: string, type) {
    if (type == "eventCard") {
      if (this.tokenStorage.getToken()) {
        this.router.navigate(["user/event"], {
          queryParams: { slug },
        });
      } else {
        this.router.navigate(["/event"], {
          queryParams: { slug },
        });
      }
      if (this.isBrowser) {
        window.scrollTo(0, 0);
      }
    }
  }

  conversationSelected(value) {
    this.selectedConvoId = 0;
    this.selectedConversationId.emit(value);
    this.selectedConvoId = value;
  }
  changeCardWindow(value) {
    this.changeWindow.emit(value);
  }
  onClick(value, cardName, id?) {
    this.cardClicked.emit({ value, cardName, id });
  }

  onCardButtonClick(event: Event, cardButtonName, id) {
    if (cardButtonName == "profileImageUploadButton") {
      const cropedImage = this.imageDestination;
      const cropedImageName = this.uploadedProfileImageName;
      this.cardButtonClick.emit({
        cardButtonName,
        cropedImage,
        cropedImageName,
      });
    }

       if (cardButtonName == "favouriteBtn") {
         event.stopPropagation();
         this.cardButtonClick.emit({ cardButtonName, id });
        }
      

    if (cardButtonName == "messageBtn") {
      event.stopPropagation();
      this.cardButtonClick.emit({ cardButtonName, id });
    }
    if (cardButtonName === "uploadImageForCrop") {
      const inputElement = event.target as HTMLInputElement;
      if (inputElement.files && inputElement.files.length > 0) {
        const selectedFile = inputElement.files[0];
        this.uploadedProfileImageName = inputElement.files[0].name;

        const extension = selectedFile.name.split(".").pop().toLowerCase();

        // List of allowed image file extensions
        const allowedExtensions = ["jpg", "jpeg", "png"];

        // Check if the extension is in the allowed list
        if (allowedExtensions.includes(extension)) {
          // It's an image, you can proceed with cropping using CropperJS

          // Create a URL for the selected File
          const reader = new FileReader();
          reader.onload = (e) => {
            const dataURL = e.target.result as string;
            this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(
              dataURL
            ) as SafeResourceUrl;
            // Notify that the image is uploaded
            this.imageUploadedFlagLocal = true;
            setTimeout(() => {
              this.initCropImage();
            }, 10);
          };
          reader.readAsDataURL(selectedFile);
        } else {
          this.imageUploadedFlagLocal = false;
          this.messageService.addMessages(
            `error`,
            ``,
            "Invalid file type. Please upload an image file."
          );
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.imageUploadedFlagLocal) {
      this.initCropImage();
    }
    if (this.messageCardLoaded) {
      this.scrollChatToBottom();
    }
  }

  initCropImage() {
    const image = this.croppingImageElement.nativeElement;
    this.cropper = new Cropper(image, {
      // aspectRatio: 16 / 9,
      cropBoxResizable: true,
      responsive: true,
      viewMode: 1,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas({
          imageSmoothingQuality: "high",
          fillColor: "#fff",
        });
        this.imageDestination = canvas.toDataURL("image/png");
      },
    });
  }

  ngOnDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.conversationSelectedFlag &&
      !changes.conversationSelectedFlag.firstChange
    ) {
      this.conversationSelected(
        changes.conversationSelectedFlag.currentValue.id
      );
    }
    if (changes.imageUploadedFlag) {
    }
    if (changes.imageUploadedFlag && !changes.imageUploadedFlag.firstChange) {
      this.imageUploadedFlagLocal = false;
    }
    // message card
    if (
      changes.cardData &&
      !changes.cardData.firstChange &&
      changes.cardData.currentValue.conversationId
    ) {
      this.messageCardLoaded = true;
    }
  }

  onCardUpdateData(value, modelName) {
    this.cardUpdate.emit({ value, modelName });
    if (modelName == "block" || modelName == "unBlock") {
      this.cardData.blocked = !this.cardData.blocked;
    }
  }
  onCardPreSubmit() {
    this.cardPreSubmit.emit("messagePreSubmit");
  }
  onCardSubmit() {
    this.cardSubmit.emit("messageSubmit");
    this.resetValueFlag = !this.resetValueFlag;
  }

  loadImage() {
    // Trigger the file input element to open the file selection dialog
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  }

  private scrollChatToBottom() {
    if (this.chatContainer) {
      const containerElement = this.chatContainer.nativeElement;
      containerElement.scrollTop = containerElement.scrollHeight;
    }
  }
}
