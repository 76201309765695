import { EventMembers } from "./member-info";

export class EventRegistrationInfo {
  fullName: string;
  gender: string;
  age: string;
  relationshipStatus: any;
  children: string;
  city: string;
  state: string;
  country: string;
  members: EventMembers[] = [];
  totalFee: string;
  eventId: string;
  createdBy: string;
}
