<div class="events">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/{{ eventsPageInfo.pageHeroImage }}"
      alt=""
    />
    <div class="hero-content">
      <div class="hero-pageTitle">
        <h2>{{ eventsPageInfo.title }}</h2>
      </div>
      <div class="hero-pageDescription">
        <p>{{ eventsPageInfo.description }}</p>
      </div>
    </div>
  </div>

  <div class="events-menu">
    <app-tab-menu
      [type]="'eventMenuTab'"
      [tabItems]="eventsPageInfo.eventTabs"
      [activeTab]="activeTab"
      (changedTab)="tabSelected($event)"
    ></app-tab-menu>
  </div>
  <section class="events-section container-fluid">
    <div class="row gx-0 gx-sm-5">
      <ng-container *ngIf="eventsPageInfo && eventsPageInfo.events">
        <div
          class="col-sm-6 col-md-4 mb-4"
          *ngFor="let data of eventsPageInfo.events"
        >
          <app-card [type]="'eventCard'" [cardData]="data"></app-card>
        </div>
      </ng-container>
      <ng-container *ngIf="eventsPageInfo && eventsPageInfo.events.length == 0">
        <p>No Upcoming Events</p>
      </ng-container>
    </div>
  </section>
</div>
