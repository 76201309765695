<div class="profile">
  <div class="hero">
    <img class="hero-image" [src]="profilePageInfo.profileHeroImage" alt="hero image" />
  </div>
  <div class="profile-section-Image-outer">
    <div class="profile-section-Image">
      <ng-container>
        <ng-container *ngIf="!profilePageInfo.profileImage">
          <p-skeleton shape="circle" size="10rem" styleClass="mr-2"></p-skeleton>
        </ng-container>
        <ng-container *ngIf="profilePageInfo.profileImage">
          <img class="profileImage" [src]="profilePageInfo.profileImage" alt="profile picture" />
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="!myProfileFlag && profileInfoLoadedFlag">
      <div class="profile-section-send-message">
        <app-button [type]="'btnWithIcon'" [color]="'white'" [bgColor]="'var(--color-blue)'"
          [borderRadius]="'50px !important'" [icon]="'fad fa-comments'" (button)="sendMessage(profileId)">
        </app-button>
        <!-- <i class="fad fa-comments" (click)="sendMessage(profileId)"></i> -->
      </div>
    </ng-container>
  </div>
  <div class="container-fluid userInfoDiv">
    <ng-container *ngIf="!profileInfoLoadedFlag">
      <div class="profile-section-info-skeleton">
        <p-skeleton width="100%" height="2rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        <p-skeleton width="70%" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        <p-skeleton width="70%" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        <p-skeleton width="40%" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      </div>
    </ng-container>
    <div class="profile-section-Info" *ngIf="profileInfoLoadedFlag">
      <h4 class="userName">{{ profilePageInfo.nickname }}</h4>
      <ng-container *ngIf="profilePageInfo.profession">
        <p class="userProfession">
          {{ profilePageInfo.profession }}
        </p>
      </ng-container>
      <p class="userAddress">
        <!-- {{ profilePageInfo.address }} -->
          {{ profilePageInfo.city }}, {{ profilePageInfo.country }}
      </p>
      <span class="profile-view" (click)="userProfileView()" *ngIf="myProfileFlag && myProfileFlag == true">How other's
        see my profile?</span>
      <!-- <p class="userStatus">
        <span class="Ss">Status :</span
        > -->
      <!-- <span
          class="Ds"
          [ngClass]="{
            verified: profilePageInfo.status == 'Verified'
          }"
          >{{ profilePageInfo.status }}</span
        > -->
      <!-- ... -->
      <!-- </p> -->
      <!-- add social media icon  -->
      <!-- <div class="social-icons">
        <a [href]="profilePageInfo.instagram_link" target="_blank">
          <i class="pi pi-instagram" style="font-size: 1.7rem"></i>
        </a>
        <a [href]="profilePageInfo.facebook_link" target="_blank">
          <i class="pi pi-facebook" style="font-size: 1.7rem"></i>
        </a>
      </div> -->

      <ng-container *ngIf="this.requestReceiveStatus && !myProfileFlag">
        <div>{{ profilePageInfo.nickname }} requested to view your photo</div>
        <div class="acceptOrReject">
          <app-button [label]="'Accept'" [bgColor]="'#1CA9F4'" [type]="'login'" [fontSize]="'clamp(12px, 3vw, 16px)'"
            [fontWeight]="'500'" [padding]="
              isMobileView ? '10px 10px !important' : '10px 20px !important'
            " [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'" (button)="
              onButtonClick('accept-request', this.profileRequestRecieved.id)
            "></app-button>
          <app-button [label]="'Reject'" [bgColor]="'#ff2222'" [type]="'login'" [fontSize]="'clamp(12px, 3vw, 16px)'"
            [fontWeight]="'500'" [padding]="
              isMobileView ? '10px 10px !important' : '10px 20px !important'
            " [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'" (button)="
              onButtonClick('reject-request', this.profileRequestRecieved.id)
            "></app-button>
        </div>
      </ng-container>
    </div>
    <div class="profile-section-Info" *ngIf="myProfileFlag && myProfileFlag == true">
      <div class="editProfileBtn">
        <app-button [label]="'Edit Profile'" [bgColor]="'#1CA9F4'" [type]="'login'"
          [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="
            isMobileView ? '10px 10px !important' : '10px 20px !important'
          " [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'"
          (button)="onButtonClick('edit-profile')"></app-button>
        <app-button [label]="
            this.isProfileProtected == false
              ? 'Make Photo Private'
              : 'Make Photo Public'
          " [bgColor]="'#c31cf4'" [type]="'login'" [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'"
          [padding]="
            isMobileView ? '10px 10px !important' : '10px 20px !important'
          " [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'"
          (button)="onButtonClick('protected-profile')"></app-button>
      </div>
    </div>
    <div class="profile-section-Info" *ngIf="
        myProfileFlag == false && this.requestSentStatus && this.protectedFlag
      ">
      <div class="connectAndBlockBtn">
        <app-button *ngIf="profileRequestSendStatus !== 'accepted'" [label]="
            profileRequestSendStatus === 'pending'
              ? 'Request has been sent'
              : profileRequestSendStatus === 'rejected'
              ? 'Request to View Profile Image'
              : 'Request to View Profile Image'
          " [bgColor]="'#1CA9F4'" [type]="'login'" [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'"
          [padding]="
            isMobileView ? '10px 10px !important' : '10px 20px !important'
          " [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'" (button)="
            profileRequestSendStatus === 'pending'
              ? onButtonClick('invite-to-connect-pending')
              : onButtonClick('invite-to-connect')
          "></app-button>
        <!-- <app-button
          [label]="'Block'"
          [bgColor]="'#ff3e3e'"
          [type]="'login'"
          [fontSize]="'clamp(12px, 3vw, 16px)'"
          [fontWeight]="'500'"
          [padding]="'10px 20px !important'"
          [borderRadius]="'50px !important'"
          [color]="'#fff'"
          [width]="'100% !important'"
          (button)="onButtonClick('block-profile')"
        ></app-button> -->
      </div>
    </div>
  </div>
  <div class="noteDiv" *ngIf="noteFlag == true && myProfileFlag == true">
    <p>
      Note: {{ note }}
      <span (click)="noteFlag = false"><i class="fal fa-times"></i></span>
    </p>
  </div>
  <!-- event notification section started here  -->
  <section class="event-notification" *ngIf="currentNotificationText.length>0 && myProfileFlag">
    <div class="notification-btn">
      <i class="pi pi-bell" style="font-size: 1.4rem"></i>
      <h2>Events</h2>
    </div>
    <div class="notification-text" [ngClass]="{ 'fade': animate }">
      <ng-container *ngIf="currentNotificationText && currentNotificationText.length; else loading">
        <span [innerHTML]="currentNotificationText"></span>
        <a class="register-event-btn" [routerLink]="['/user/event-registration']"
          [queryParams]="{ slug: notificationSlug }">
          click here to register
        </a>
      </ng-container>
      <ng-template #loading>
        Please wait while we load your Events...
      </ng-template>
    </div>
  </section>

  <!-- event notification section end here  -->

  <!-- search moudule starts from here  -->
  <div class="container">
    <form [formGroup]="searchForm" class="search-form" (ngSubmit)="onSearch()">
      <div class="form-group">
        <select formControlName="country">
          <option value="" disabled>Select Country</option>
          <option *ngFor="let country of countryOptions" [value]="country">{{ country }}</option>
        </select>
      </div>

      <div class="form-group">
        <select formControlName="age_from" (change)="onAgeFromChange($event)">
          <option value="" disabled>Select age from</option>
          <option *ngFor="let age of ageRange" [value]="age">{{ age }}</option>
        </select>
      </div>

      <div class="form-group">
        <select formControlName="age_to">
          <option value="" disabled>Select age to</option>
          <option *ngFor="let age of filteredAgeTo" [value]="age">{{ age }}</option>
        </select>
      </div>

      <div class="form-group">
        <button type="submit" class="search-button">Find</button>
      </div>
    </form>
  </div>

  <!-- search moudule end here  -->

  <div class="description" *ngIf="this.profilePageInfo.description">
    <div class="description-container">
      <h2>About me</h2>
      <q>{{ this.profilePageInfo.description }}</q>
    </div>
  </div>
  <ng-container *ngIf="!isMobileView">
    <div class="wideScreenDiv">
      <div class="aboutUsDiv">
        <div class="row">
          <div class="col-md-3 menuSide">
            <h2>About</h2>
            <div id="menu">
              <app-menu [items]="aboutUsMenuItems" [menuType]="'aboutUs'" [activeTab]="activeTab"></app-menu>
            </div>
          </div>
          <div class="col-md-9">
            <div *ngIf="aboutUsDataSide == 'basicLifeStyle'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <div class="data row justify-content-between">
                <div class="col-6 infoDiv row">
                  <div class="col-6 staticSide">ID</div>
                  <div class="col-6 dynamicSide">
                    {{userId }}
                  </div>
                  <div class="col-6 staticSide">Age</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.age }}
                  </div>
                  <div class="col-6 staticSide">Date of Birth</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.dateOfBirth }}
                  </div>
                  <div class="col-6 staticSide">Marital Status</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.maritalStatus }}
                  </div>
                  <div class="col-6 staticSide">Height</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.height }}
                  </div>
                  <div class="col-6 staticSide">Skin Tone</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.skinTone }}
                  </div>
                  <div class="col-6 staticSide">Do you Pray Regularly</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.prayStatus }}
                  </div>
                  <div class="col-6 staticSide">Do you Have Children</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.childrenStatus }}
                  </div>
                  <ng-container *ngIf="aboutUserInfo.basicAndLifestyle.noOfChildren">
                    <div class="col-6 staticSide">No. of Children</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.noOfChildren }}
                    </div>
                  </ng-container>
                </div>
                <div class="col-6 infoDiv row">
                  <div class="col-6 staticSide">Body Weight</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.bodyWeight }}
                  </div>
                  <div class="col-6 staticSide">Smoke</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.smoke }}
                  </div>
                  <!-- <div class="col-6 staticSide">Contact No</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.contactNo }}
                  </div> -->
                  <div class="col-6 staticSide">Body Type</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.bodyType }}
                  </div>
                  <div class="col-6 staticSide">Working Status</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.workingStatus }}
                  </div>
                  <div class="col-6 staticSide">Are you Shia Ithna Ashari</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.ithnaAshri }}
                  </div>
                  <div class="col-6 staticSide">Language</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.language }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="aboutUsDataSide == 'personalDetails'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <div class="data row justify-content-between">
                <div class="col-6 infoDiv row">
                  <!-- <div class="col-6 staticSide">ID</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.id }}
                  </div> -->
                  <div class="col-6 staticSide">Gender</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.gender }}
                  </div>
                  <div class="col-6 staticSide">Country</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.country }}
                  </div>
                  <div class="col-6 staticSide">Residency</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.residency }}
                  </div>
                </div>
                <div class="col-6 infoDiv row">
                  <div class="col-6 staticSide">Syed?</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.syed }}
                  </div>
                  <div class="col-6 staticSide">City</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.city }}
                  </div>
                  <div class="col-6 staticSide">Ethnicity/ Culture</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.ethnicity }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="aboutUsDataSide == 'ratingVerification'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <div class="data row justify-content-between">
                <div class="col-6 infoDiv row">
                  <div class="col-6 staticSide">Ratings</div>
                  <div class="col-6 dynamicSide">
                    <!-- {{ aboutUserInfo.ratingAndVerification.ratings }} -->
                    <app-rating [type]="'readOnly'" [score]="verificationInfo.rating" [stars]="5"></app-rating>
                  </div>
                  <div class="col-6 staticSide">Email Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.email_verified }}
                  </div>
                </div>
                <div class="col-6 infoDiv row">
                  <div class="col-6 staticSide">Maulana Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.maulana }}
                  </div>
                  <div class="col-6 staticSide">Phone Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.phone }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="aboutUsDataSide == 'educationCarrer'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <div class="data row justify-content-between">
                <div class="col-6 infoDiv row">
                  <div class="col-6 staticSide">Education</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.educationAndCareer.education }}
                  </div>
                  <div class="col-6 staticSide">Profession</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.educationAndCareer.profession }}
                  </div>
                  <div class="col-6 staticSide">Income</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.educationAndCareer.income }}
                  </div>
                </div>
                <!-- <div class="col-6 infoDiv row">
                  <div class="col-6 staticSide">Maulana Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.maulana }}
                  </div>
                  <div class="col-6 staticSide">Phone Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.phone }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="otherVerificationDiv" *ngIf="myProfileFlag && myProfileFlag == true">
        <div class="row removingRowMargins">
          <div class="col-md-6 menuSide">
            <h2>Other Verifications</h2>
          </div>
          <div class="col-md-6 otherVerificationDiv-toggle">
            <div *ngIf="aboutUsDataSide == 'basicLifeStyle'">
              <!-- <div class="toggle">
            <span><i class="fas fa-ellipsis-h"></i></span>
          </div> -->
            </div>
          </div>
        </div>
        <div class="documentUpload container-fluid row">
          <div class="documentUpload-container">
            <p class="other-verification-upload-line">
              Upload documents or videos for verification
            </p>
            <div class="uploadSection row container">
              <div class="col-4 px-2">
                <p>File</p>
                <app-file-upload #fileUpload [mode]="'basic'"
                  [accept]="'.jpg,.jpeg,.png,.gif,.bmp,.svg,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp4,.avi,.mov,.mkv,.wmv,.flv,.ogg,.webm'"
                  [maxFileSize]="52428800" [accessFrom]="'profileVideoVerification'" [customUpload]="false"
                  [acceptedTypes]="['documents', 'images', 'videos']" [label]="'upload file'"
                  (error)="onUploadError($event)" (dataReady)="updateData($event, 'image', 'otherVerification')"
                  [minWidth]="'110px'"></app-file-upload>
              </div>

              <div class="col-sm-8 px-2">
                <p>Document Type</p>
                <ng-container *ngFor="let box of documentTypeInfo">
                  <app-drop-down [inputInfo]="box" [changeFlag]="changeFlag2" [data]="box.data"
                    *ngIf="box.type == 'profileCreateDropdown'" [scrollHeight]="'200px'"
                    [accessFrom]="'profileVideoVerification'" [type]="'profileCreate'" (updateData)="
                  updateData($event, box.modelName, 'otherVerification')
                " (preSubmit)="preSubmit2()" (submit)="onSubmit2()">
                  </app-drop-down>
                </ng-container>
              </div>
            </div>
            <div class="other-verification-note-section">
              <p>Document Type cannot be blank.</p>
              <p>
                Note: ID documents are for admin use and will not be shared with
                other users.
              </p>
            </div>
            <div class="other-verification-section-btn">
              <app-button [label]="'Save'" [bgColor]="'#1CA9F4'" [type]="'login'" [fontSize]="'clamp(12px, 3vw, 16px)'"
                [fontWeight]="'500'" [padding]="'10px 0px !important'" [borderRadius]="'50px !important'"
                [color]="'#fff'" [width]="'100%'" (button)="preSubmit2()"></app-button>
            </div>
          </div>
          <div class="documentUploaded-container" *ngIf="idDocuments.length > 0">
            <h4 class="documentUploaded-title">Uploaded ID Documents</h4>
            <div class="ids-container">
              <div class="id-card" *ngFor="let card of idDocuments">
                <div class="id-card-image">
                  <img [src]="card.image" [alt]="card.title" />
                </div>
                <div class="id-card-info">
                  <div class="id-card-name">
                    <strong>Document Type: </strong>
                    <p class="id-card-type">{{ card.documentType }}</p>
                  </div>
                  <div class="id-card-btn-container">
                    <div class="id-card-btn-chip" [ngClass]="{
                    pending: card.status === 'Not Verified',
                    verified: card.status === 'Verified',
                    rejected: card.status === 'Rejected'
                  }">
                      <p-chip [label]="card.status"></p-chip>
                    </div>
                    <div class="id-card-btn-remove">
                      <app-button [label]="'Delete'" [padding]="'1px'" [color]="'#fff'" [type]="'login'"
                        (button)="deleteDocument(card.id)" [bgColor]="'red'" [borderRadius]="'35px !important'"
                        [fontSize]="'clamp(10px, 4vw, 12px)'" [padding]="'.4rem .1rem '"></app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="verificationDiv" *ngIf="myProfileFlag && myProfileFlag == true">
        <div class="row">
          <div class="col-md-3 menuSide">
            <h2>Verifications</h2>
            <div id="menu">
              <app-menu [items]="verificationsMenuItems" [menuType]="'verifications'"></app-menu>
            </div>
          </div>
          <div class="col-md-9">
            <div *ngIf="verificationDataSide == 'verificationStatus'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <div class="data row justify-content-between">
                <div class="col-6 infoDiv row g-2">
                  <div class="col-12 staticSide">
                    You get {{ verificationInfo.noOfStars }} star<span *ngIf="verificationInfo.rating > 1">s</span>.
                  </div>
                  <div class="col-12 dynamicSide">
                    <app-rating [type]="'readOnly'" [score]="verificationInfo.rating" [stars]="5"></app-rating>
                  </div>
                  <div class="col-12 staticSide" *ngIf="verificationInfo.rating != 5">
                    Complete your profile to get 100% results.
                  </div>
                  <div class="col-12 staticSide verifications" [ngClass]="{
                      verified:
                        verificationInfo.verificationStatus.alimReference ==
                        'Verified'
                    }">
                    Alim(Religious Scholar) Reference ({{
                    verificationInfo.verificationStatus.alimReference
                    | titlecase
                    }})
                  </div>
                  <div class="col-12 staticSide verifications" [ngClass]="{
                      verified:
                        verificationInfo.verificationStatus.email == 'Verified'
                    }">
                    Email Verification ({{
                    verificationInfo.verificationStatus.email | titlecase
                    }})
                  </div>
                  <div class="col-12 staticSide verifications" [ngClass]="{
                      verified:
                        verificationInfo.verificationStatus.location ==
                        'Verified'
                    }">
                    Location Verification ({{
                    verificationInfo.verificationStatus.location | titlecase
                    }})
                  </div>
                  <div class="col-12 staticSide verifications" [ngClass]="{
                      verified:
                        verificationInfo.verificationStatus.phone == 'Verified'
                    }">
                    Phone Verification ({{
                    verificationInfo.verificationStatus.phone | titlecase
                    }})
                  </div>
                  <div class="col-12 staticSide verifications" [ngClass]="{
                      verified:
                        verificationInfo.verificationStatus.photo == 'Verified'
                    }">
                    Photo Verification ({{
                    verificationInfo.verificationStatus.photo | titlecase
                    }})
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="verificationDataSide == 'alimReference'">
              <div class="alimReference-bar toggle">
                <ng-container>
                  <p-tabMenu [model]="alimTabs" [activeItem]="alimActiveTab"
                    (activeItemChange)="alimTabClicked($event)"></p-tabMenu>
                </ng-container>
                <!-- <span><i class="fas fa-ellipsis-h"></i></span> -->
              </div>
              <div class="data row justify-content-start" *ngIf="
                  this.verificationInfo.verificationStatus.alimReference ==
                    'unVerified' && !this.showAppliedAlimTab
                ">
                <div [ngClass]="
                    box.modelName == 'dialCode'
                      ? 'col-5 infoDiv row'
                      : box.modelName == 'contactNo'
                      ? 'col-7 infoDiv row alimContactNo'
                      : 'col-12 infoDiv row'
                  " *ngFor="let box of this.inputInfo">
                  <div [ngClass]="
                      box.modelName == 'name' || box.modelName == 'email'
                        ? 'col-2 staticSide'
                        : box.modelName == 'dialCode'
                        ? 'col-5 staticSide'
                        : 'col-3 staticSide'
                    ">
                    <label class="alimLabels">{{ box.label }}</label>
                  </div>
                  <div [ngClass]="
                      box.modelName == 'name' || box.modelName == 'email'
                        ? 'col-10 dynamicSide'
                        : box.modelName == 'dialCode'
                        ? 'col-7 dynamicSide'
                        : 'col-9 dynamicSide'
                    ">
                    <app-drop-down [inputInfo]="box" [changeFlag]="changeFlag" [data]="box.data"
                      [accessFrom]="'profileVideoVerification'" *ngIf="box.type == 'profileCreateDropdown'"
                      [scrollHeight]="'200px'" [type]="'profileCreate'" (updateData)="
                        updateData($event, box.modelName, 'alimReference')
                      " (preSubmit)="preSubmit()" (submit)="this.saveType = 'alimReference'; onSubmit()">
                    </app-drop-down>
                    <app-text-box [inputInfo]="box" [changeFlag]="this.changeFlag" *ngIf="box.type == 'text2'"
                      (updateData)="
                        updateData($event, box.modelName, 'alimReference')
                      " (preSubmit)="preSubmit()" (submit)="this.saveType = 'alimReference'; onSubmit()"
                      [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'" [borderRadius]="'35px'" [width]="'100%'"
                      [height]="'40px'" [padding]="'0 2rem'"></app-text-box>
                  </div>
                </div>
                <div class="col-10 row alimLastLine">
                  <p>
                    **Note: Please make sure Alim/scholar is aware of the name
                    used for registration on this website.
                  </p>
                </div>
                <div class="col-2 alimLastLine">
                  <app-button [label]="'Save'" [bgColor]="'#1CA9F4'" [type]="'login'"
                    [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="'8px 10px !important'"
                    [borderRadius]="'50px !important'" [width]="'100% !important'" [color]="'#fff'"
                    (button)="preSubmit()"></app-button>
                </div>
              </div>
              <div class="row col-12 justify-content-center" *ngIf="
                  this.verificationInfo.verificationStatus.alimReference ==
                    'Verified' && !this.showAppliedAlimTab
                ">
                <p class="verified text-center">Alim Refrence is verified</p>
              </div>
              <div class="applied-alim-tab" *ngIf="showAppliedAlimTab">
                <div class="applied-alim-container">
                  <div class="applied-alim-card" *ngFor="let data of alimData">
                    <div class="applied-alim-info">
                      <h3>Name: {{ data.name }}</h3>
                      <!-- <p>
                        Contact No: {{ data.dialCode }}-{{ data.contactNo }}
                      </p> -->
                      <p>Address: {{ data.address }}</p>
                    </div>
                    <div class="applied-alim-status">
                      <div class="id-card-btn-chip" [ngClass]="{
                          pending: data.status === 'Not Verified',
                          verified: data.status === 'Verified',
                          rejected: data.status === 'Rejected'
                        }">
                        <p-chip [label]="data.status"></p-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="verificationDataSide == 'emailVerification'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <div class="data row justify-content-between">
                <div class="col-12 infoDiv justify-content-center row">
                  <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="
                      this.verificationInfo.verificationStatus.email ==
                      'unVerified'
                    ">
                    <!-- <div class="col-6" *ngFor="let box of this.inputInfo">
                      <app-text-box
                        [inputInfo]="box"
                        [changeFlag]="this.changeFlag"
                        *ngIf="box.type == 'text2'"
                        (updateData)="
                          updateData($event, box.modelName, 'verifyEmail')
                        "
                        (preSubmit)="preSubmit()"
                        (submit)="this.saveType = 'verifyEmail'; onSubmit()"
                        [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                        [borderRadius]="'35px'"
                        [width]="'100%'"
                        [height]="'40px'"
                        [padding]="'0 2rem'"
                      ></app-text-box>
                    </div> -->
                    <div class="col-4">
                      <app-button [label]="'Verify Email'" [bgColor]="'#1CA9F4'" [type]="'login'"
                        [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'bold'" [padding]="'10px 10px'"
                        [borderRadius]="'50px !important'" [color]="'#fff'" (button)="
                          this.saveType = 'emailVerification'; verifyEmail()
                        "></app-button>
                    </div>
                  </div>
                  <div class="col-4" *ngIf="
                      this.verificationInfo.verificationStatus.email !=
                      'unVerified'
                    ">
                    <p class="verified">Email Address Verified</p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="verificationDataSide == 'locationVerification'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <div class="data row justify-content-center">
                <div class="col-4" *ngIf="
                    this.verificationInfo.verificationStatus.location ==
                    'unVerified'
                  ">
                  <app-button [label]="'Approve Location'" [bgColor]="'#1CA9F4'" [type]="'login'"
                    [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'bold'" [padding]="'10px 10px'"
                    [borderRadius]="'50px !important'" [color]="'#fff'" (button)="verifyLocation()"></app-button>
                </div>
                <div class="col-4" *ngIf="
                    this.verificationInfo.verificationStatus.location !=
                    'unVerified'
                  ">
                  <p class="verified">Location is Verified</p>
                </div>
              </div>
            </div>
            <div *ngIf="verificationDataSide == 'phoneVerification'">
              <!-- <div class="toggle">
                <span><i class="fas fa-ellipsis-h"></i></span>
              </div> -->
              <!-- <div
                class="col-12 infoDiv row"
                *ngFor="let box of this.inputInfo"
              > -->
              <!-- <div class="col-2 staticSide">
                  <label class="alimLabels">{{ box.label }}</label>
                </div> -->
              <!-- <div class="col-10 dynamicSide">
                  <app-text-box
                    [inputInfo]="box"
                    [changeFlag]="this.changeFlag"
                    *ngIf="box.type == 'disabled'"
                    (updateData)="
                      updateData($event, box.modelName, 'phoneVerification')
                    "
                    (preSubmit)="preSubmit()"
                    (submit)="onSubmit()"
                    [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                    [borderRadius]="'35px'"
                    [width]="'100%'"
                    [height]="'40px'"
                    [padding]="'0 2rem'"
                  ></app-text-box>
                </div> -->
              <!-- </div> -->
              <div class="container col-6" *ngIf="
                  this.verificationInfo.verificationStatus.phone == 'unVerified'
                ">
                <app-button [label]="'Send Verification Code'" [bgColor]="'#1CA9F4'" [type]="'login'"
                  [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'bold'" [padding]="'10px 10px'"
                  [borderRadius]="'50px !important'" [color]="'#fff'" (button)="
                    this.saveType = 'phoneVerification'; verifyPhoneNo()
                  "></app-button>
              </div>
              <div class="col-12" *ngIf="
                  this.verificationInfo.verificationStatus.phone != 'unVerified'
                ">
                <p class="verified text-center">Phone No. is Verified</p>
              </div>
            </div>
            <div *ngIf="verificationDataSide == 'photoVerification'" style="width: 100%; height: 100%">
              <ng-container *ngIf="photoVerifiedFlag == true">
                <p style="width: 100%; height: 100%" class="verified flex justify-content-center align-items-center">
                  photo is Verified
                </p>
              </ng-container>
              <ng-container *ngIf="photoVerifiedFlag == false">
                <p style="width: 100%; height: 100%" class="unVerified flex justify-content-center align-items-center">
                  photo is not Verified
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobileView">
    <div class="mobileScreenDiv">
      <div class="profile-accordion">
        <h3 class="aboutUs-mobile-heading">About</h3>
        <p-accordion class="aboutUs-accordion" (onOpen)="onAboutTabOpen($event)">
          <p-accordionTab header="Basic & Lifestyle">
            <div class="">
              <div class="container">
                <div class="col-12 infoDiv row">
                  <div class="col-6 staticSide">ID</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.id }}
                  </div>
                  <div class="col-6 staticSide">Age</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.age }}
                  </div>
                  <div class="col-6 staticSide">Date of Birth</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.dateOfBirth }}
                  </div>
                  <div class="col-6 staticSide">Marital Status</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.maritalStatus }}
                  </div>
                  <div class="col-6 staticSide">Height</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.height }}
                  </div>
                  <div class="col-6 staticSide">Skin Tone</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.skinTone }}
                  </div>
                  <div class="col-6 staticSide">Do you Pray Regularly</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.prayStatus }}
                  </div>
                  <div class="col-6 staticSide">Do you Have Children</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.childrenStatus }}
                  </div>
                  <div class="col-6 staticSide">No. of Children</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.noOfChildren }}
                  </div>

                  <div class="col-6 staticSide">Body Weight</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.bodyWeight }}
                  </div>
                  <div class="col-6 staticSide">Smoke</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.smoke }}
                  </div>
                  <!-- <div class="col-6 staticSide">Contact No</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.contactNo }}
                  </div> -->
                  <div class="col-6 staticSide">Body Type</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.bodyType }}
                  </div>
                  <div class="col-6 staticSide">Working Status</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.workingStatus }}
                  </div>
                  <div class="col-6 staticSide">Are you Shia Ithna Ashari</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.ithnaAshri }}
                  </div>
                  <div class="col-6 staticSide">Language</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.basicAndLifestyle.language }}
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Personal Details">
            <div class="container">
              <div class="col-12 infoDiv row">
                <div class="col-6 staticSide">ID</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.personalDetails.id }}
                </div>
                <div class="col-6 staticSide">Gender</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.personalDetails.gender }}
                </div>
                <div class="col-6 staticSide">Country</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.personalDetails.country }}
                </div>
                <div class="col-6 staticSide">Residency</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.personalDetails.residency }}
                </div>
                <div class="col-6 staticSide">Syed?</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.personalDetails.syed }}
                </div>
                <div class="col-6 staticSide">City</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.personalDetails.city }}
                </div>
                <div class="col-6 staticSide">Ethnicity/ Culture</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.personalDetails.ethnicity }}
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Rating & Verification ">
            <div class="container">
              <div class="col-12 infoDiv row">
                <div class="col-6 staticSide">Ratings</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.ratingAndVerification.ratings }}
                </div>
                <div class="col-6 staticSide">Email Verified</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.ratingAndVerification.email_verified }}
                </div>
                <div class="col-6 staticSide">Maulana Verified</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.ratingAndVerification.maulana }}
                </div>
                <div class="col-6 staticSide">Phone Verified</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.ratingAndVerification.phone }}
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Education & Career">
            <div class="container">
              <div class="col-12 infoDiv row">
                <div class="col-6 staticSide">Education</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.educationAndCareer.education }}
                </div>
                <div class="col-6 staticSide">Profession</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.educationAndCareer.profession }}
                </div>
                <!-- <div class="col-6 staticSide">Maulana Verified</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.ratingAndVerification.maulana }}
                </div>
                <div class="col-6 staticSide">Phone Verified</div>
                <div class="col-6 dynamicSide">
                  {{ aboutUserInfo.ratingAndVerification.phone }}
                </div> -->
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
        <h3 class="verification-mobile-heading" *ngIf="myProfileFlag && myProfileFlag == true">
          Verifications
        </h3>
        <p-accordion class="verification-accordion" (onOpen)="onVerificationsTabOpen($event)"
          *ngIf="myProfileFlag && myProfileFlag == true">
          <p-accordionTab header="Verification Status">
            <div class="container-fluid">
              <div class="col-12 infoDiv row">
                <div class="col-12 staticSide">
                  You get {{ verificationInfo.noOfStars }} star<span *ngIf="verificationInfo.rating > 1">s</span>.
                </div>
                <div class="col-12 dynamicSide">
                  <app-rating [type]="'readOnly'" [score]="verificationInfo.rating" [stars]="5"></app-rating>
                </div>
                <div class="col-12 staticSide" *ngIf="verificationInfo.rating != 5">
                  Complete your profile to get 100% results.
                </div>
                <div class="col-12 staticSide verifications" [ngClass]="{
                    verified:
                      verificationInfo.verificationStatus.alimReference ==
                      'Verified'
                  }">
                  Alim(Religious Scholar) Reference ({{
                  verificationInfo.verificationStatus.alimReference
                  | titlecase
                  }})
                </div>
                <div class="col-12 staticSide verifications" [ngClass]="{
                    verified:
                      verificationInfo.verificationStatus.email == 'Verified'
                  }">
                  Email Verification ({{
                  verificationInfo.verificationStatus.email | titlecase
                  }})
                </div>
                <div class="col-12 staticSide verifications" [ngClass]="{
                    verified:
                      verificationInfo.verificationStatus.location == 'Verified'
                  }">
                  Location Verification ({{
                  verificationInfo.verificationStatus.location | titlecase
                  }})
                </div>
                <div class="col-12 staticSide verifications" [ngClass]="{
                    verified:
                      verificationInfo.verificationStatus.phone == 'Verified'
                  }">
                  Phone Verification ({{
                  verificationInfo.verificationStatus.phone | titlecase
                  }})
                </div>
                <div class="col-12 staticSide verifications" [ngClass]="{
                    verified:
                      verificationInfo.verificationStatus.photo == 'Verified'
                  }">
                  Photo Verification ({{
                  verificationInfo.verificationStatus.photo | titlecase
                  }})
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Alim(Religious Scholar) Reference">
            <div class="alimReference-bar">
              <ng-container>
                <p-tabMenu [model]="alimTabs" [activeItem]="alimActiveTab"
                  (activeItemChange)="alimTabClicked($event)"></p-tabMenu>
              </ng-container>
            </div>
            <div class="container-fluid">
              <div class="infoDiv row row-gap-2" *ngIf="
                  this.verificationInfo.verificationStatus.alimReference ==
                    'unVerified' && !this.showAppliedAlimTab
                ">
                <div [ngClass]="
                    box.modelName == 'dialCode'
                      ? 'col-sm-12 infoDiv row align-items-baseline mb-2'
                      : box.modelName == 'contactNo'
                      ? 'col-sm-12 infoDiv row alimContactNo align-items-baseline mb-2'
                      : 'col-sm-12 infoDiv row align-items-baseline mb-2'
                  " *ngFor="let box of this.inputInfo">
                  <div [ngClass]="
                      box.modelName == 'name' || box.modelName == 'email'
                        ? 'col-sm-3 staticSide'
                        : box.modelName == 'dialCode'
                        ? 'col-sm-3 staticSide'
                        : 'col-sm-3 staticSide'
                    ">
                    <label class="alimLabels">{{ box.label }}</label>
                  </div>
                  <div [ngClass]="
                      box.modelName == 'name' || box.modelName == 'email'
                        ? 'col-sm-9 dynamicSide'
                        : box.modelName == 'dialCode'
                        ? 'col-sm-9 dynamicSide'
                        : 'col-sm-9 dynamicSide'
                    ">
                    <app-drop-down [inputInfo]="box" [changeFlag]="changeFlag" [data]="box.data"
                      [accessFrom]="'profileVideoVerification'" *ngIf="box.type == 'profileCreateDropdown'"
                      [scrollHeight]="'200px'" [type]="'profileCreate'" (updateData)="
                        updateData($event, box.modelName, 'alimReference')
                      " (preSubmit)="preSubmit()" (submit)="this.saveType = 'alimReference'; onSubmit()">
                    </app-drop-down>
                    <app-text-box [inputInfo]="box" [changeFlag]="this.changeFlag" *ngIf="box.type == 'text2'"
                      (updateData)="
                        updateData($event, box.modelName, 'alimReference')
                      " (preSubmit)="preSubmit()" (submit)="this.saveType = 'alimReference'; onSubmit()"
                      [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'" [borderRadius]="'35px'" [width]="'100%'"
                      [height]="'40px'" [padding]="'0 2rem'"></app-text-box>
                  </div>
                </div>
                <div class="col-sm-10 row alimLastLine">
                  <p>
                    **Note: Please make sure Alim/scholar is aware of the name
                    used for registration on this website.
                  </p>
                </div>
                <div class="col-sm-2 alimLastLine">
                  <app-button [label]="'Save'" [bgColor]="'#1CA9F4'" [type]="'login'"
                    [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="'8px 10px'"
                    [width]="'100% !important'" [borderRadius]="'50px !important'" [color]="'#fff'"
                    (button)="preSubmit()"></app-button>
                </div>
              </div>
              <div class="row col-12 justify-content-center" *ngIf="
                  this.verificationInfo.verificationStatus.alimReference ==
                    'Verified' && !this.showAppliedAlimTab
                ">
                <p class="verified text-center">Alim Refrence is verified</p>
              </div>
              <div class="applied-alim-tab" *ngIf="showAppliedAlimTab">
                <div class="applied-alim-container">
                  <div class="applied-alim-card" *ngFor="let data of alimData">
                    <div class="applied-alim-info">
                      <h3>Name: {{ data.name }}</h3>
                      <!-- <p>
                        Contact No: {{ data.dialCode }}-{{ data.contactNo }}
                      </p> -->
                      <p>Address: {{ data.address }}</p>
                    </div>
                    <div class="applied-alim-status">
                      <div class="id-card-btn-chip" [ngClass]="{
                          pending: data.status === 'Not Verified',
                          verified: data.status === 'Verified',
                          rejected: data.status === 'Rejected'
                        }">
                        <p-chip [label]="data.status"></p-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Email Verification">
            <div class="container-fluid">
              <div class="col-12 infoDiv row">
                <div class="col-12 infoDiv justify-content-center row">
                  <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="
                      this.verificationInfo.verificationStatus.email ==
                      'unVerified'
                    ">
                    <!-- <div class="col-sm-10" *ngFor="let box of this.inputInfo">
                      <app-text-box
                        [inputInfo]="box"
                        [changeFlag]="this.changeFlag"
                        *ngIf="box.type == 'text2'"
                        (updateData)="
                          updateData($event, box.modelName, 'verifyEmail')
                        "
                        (preSubmit)="preSubmit()"
                        (submit)="this.saveType = 'verifyEmail'; onSubmit()"
                        [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                        [borderRadius]="'35px'"
                        [width]="'100%'"
                        [height]="'40px'"
                        [padding]="'0 2rem'"
                      ></app-text-box>
                    </div> -->
                    <div class="col-sm-6">
                      <app-button [label]="'Verify Email'" [bgColor]="'#1CA9F4'" [type]="'login'"
                        [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'bold'" [padding]="'10px 10px !important'"
                        [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'" (button)="
                          this.saveType = 'emailVerification'; verifyEmail()
                        "></app-button>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex justify-content-center" *ngIf="
                      this.verificationInfo.verificationStatus.email !=
                      'unVerified'
                    ">
                    <p class="verified">Email Address Verified</p>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Location Verification">
            <div class="container-fluid">
              <div class="col-12 infoDiv row">
                <div class="col-md-6 d-flex justify-content-center" *ngIf="
                    this.verificationInfo.verificationStatus.location ==
                    'unVerified'
                  ">
                  <app-button [label]="'Approve Location'" [bgColor]="'#1CA9F4'" [type]="'login'"
                    [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'bold'" [padding]="'10px 10px'"
                    [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'"
                    (button)="verifyLocation()"></app-button>
                </div>
                <div class="col-md-6 d-flex justify-content-center" *ngIf="
                    this.verificationInfo.verificationStatus.location !=
                    'unVerified'
                  ">
                  <p class="verified">Location is Verified</p>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <p-accordionTab header="Phone Verification">
            <div class="container-fluid">
              <div class="col-12 infoDiv justify-content-center row" *ngFor="let box of this.inputInfo">
                <div class="col-12 staticSide">
                  <label class="alimLabels">{{ box.label }}</label>
                </div>
                <!-- <div class="col-12 dynamicSide">
                  <app-text-box
                    [inputInfo]="box"
                    [changeFlag]="this.changeFlag"
                    *ngIf="box.type == 'disabled'"
                    (updateData)="
                      updateData($event, box.modelName, 'phoneVerification')
                    "
                    (preSubmit)="preSubmit()"
                    (submit)="onSubmit()"
                    [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                    [borderRadius]="'35px'"
                    [width]="'100%'"
                    [height]="'40px'"
                    [padding]="'0 2rem'"
                  ></app-text-box>
                </div> -->
                <div class="col-6" *ngIf="
                    this.verificationInfo.verificationStatus.phone ==
                    'unVerified'
                  ">
                  <app-button [label]="'Send Verification Code'" [bgColor]="'#1CA9F4'" [type]="'login'"
                    [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'bold'" [padding]="'10px 12px !important'"
                    [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100% !important'" (button)="
                      this.saveType = 'phoneVerification'; verifyPhoneNo()
                    "></app-button>
                </div>
                <div class="col-12" *ngIf="
                    this.verificationInfo.verificationStatus.phone !=
                    'unVerified'
                  ">
                  <p class="verified text-center">Phone No. is Verified</p>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <p-accordionTab header="Photo Verification">
            <div class="container-fluid">
              <div class="col-12 infoDiv row">
                <div style="width: 100%; height: 100%">
                  <ng-container *ngIf="photoVerifiedFlag == true">
                    <p style="width: 100%; height: 100%"
                      class="verified flex justify-content-center align-items-center">
                      photo is Verified
                    </p>
                  </ng-container>
                  <ng-container *ngIf="photoVerifiedFlag == false">
                    <p style="width: 100%; height: 100%"
                      class="unVerified flex justify-content-center align-items-center">
                      photo is not Verified
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>

      <!-- <app-accordion
        [myProfileFlag]="this.myProfileFlag"
        [inputInfo]="this.inputInfo"
        [type]="'profile'"
        [accordionData]="this.accordionObj"
        (selectedAccordion)="selectedMenu($event.menuType, $event.menuItem)"
        (accordionUpdateData)="
          updateData($event.value, $event.modelName, $event.sectionName)
        "
        (accordionPreSubmit)="preSubmit()"
        (accordionOnSubmit)="onSubmit()"
      >
      </app-accordion> -->
    </div>
  </ng-container>

  <div class="photosDiv" *ngIf="
      myProfileFlag == true ||
      (protectedFlag == false && isPicPrivate == false) ||
      profileRequestSendStatus == 'accepted'
    ">
    <div class="menuSide">
      <h2>Photos</h2>
      <ng-container *ngIf="myProfileFlag == true">
        <div class="photosDiv-btn">
          <app-button [label]="'+ Add Photos'" [bgColor]="'transparent'" [type]="'login'"
            [fontSize]="'clamp(14px, 3vw, 18px)'" [fontWeight]="'700'" [color]="'val(--color-blue)'"
            [boxShadow]="'none !important'" [width]="'100%'" (button)="showImageUplaoderDialog()"></app-button>
        </div>
      </ng-container>
    </div>
    <div class="px-3 pb-3">
      <ng-container *ngIf="userImageInfo.length > 0">
        <app-galleria [imagesData]="userImageInfo" [isMyProfile]="myProfileFlag" [deleteImageIdFlag]="deleteImageIdFlag"
          [type]="'profileImage'" (confirmAction)="onConfirmation($event)"></app-galleria>
      </ng-container>
      <ng-container *ngIf="userImageInfo.length == 0">
        <div class="text-center pb-3">No Images uploaded</div>
      </ng-container>
    </div>
  </div>
  <div class="viewedDiv" *ngIf="myProfileFlag == true">
    <div class="recently-viewed">
      <h3 class="recently-viewed-title">Recently Viewed</h3>
      <ng-container *ngIf="this.recentlyViewed && this.recentlyViewed.length > 0">
        <div class="recently-viewed-main" *ngFor="let data of this.recentlyViewed">
          <app-card [type]="'viewed-card'" [cardData]="data" (cardClicked)="profileClicked($event)">
          </app-card>
        </div>
      </ng-container>
      <ng-container *ngIf="this.recentlyViewed.length == 0">
        <div class="d-flex justify-content-center align-items-center">
          <p class="text-center py-6 mb-2">No one recently viewed</p>
        </div>
      </ng-container>
    </div>
    <div class="who-viewed-me">
      <h3 class="who-viewed-me-title">Who Viewed me?</h3>
      <ng-container *ngIf="this.whoViewedMe && this.whoViewedMe.length > 0">
        <div class="who-viewed-me-main" *ngFor="let data of this.whoViewedMe">
          <app-card [type]="'viewed-card'" [cardData]="data" (cardClicked)="profileClicked($event)">
          </app-card>
        </div>
      </ng-container>
      <ng-container *ngIf="this.whoViewedMe && this.whoViewedMe.length == 0">
        <div class="d-flex justify-content-center align-items-center">
          <p class="text-center py-6 mb-2">No profile views yet</p>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="subscriptionDiv" *ngIf="myProfileFlag && myProfileFlag == true">
    <div class="row removingRowMargins">
      <div class="col-md-3 menuSide" *ngIf="subscribedFlag == false">
        <h2>Subscription</h2>
      </div>
      <div class="col-12 menuSide">
        <ng-container *ngIf="subscribedFlag == true">
          <div class="not-subscribed">
            <div class="not-subscribed-main">
              <h2>{{ subscribedIData.title }}</h2>
              <p>{{ subscribedIData.description }}</p>
            </div>
          </div>
        </ng-container>
        <p *ngIf="subscribedFlag == false">
          You are not subscribed to any plan. Choose our plan that suits your
          needs to get started.
        </p>
      </div>
      <div class="subscription-section-btn" *ngIf="subscribedFlag == false">
        <app-button [label]="'Subscribe Now'" [bgColor]="'#1CA9F4'" [type]="'login'"
          [fontSize]="'clamp(12px, 3vw, 16px)'" [fontWeight]="'500'" [padding]="'10px 10px !important'"
          [borderRadius]="'50px !important'" [color]="'#fff'" [width]="'100%'" (button)="subscribeNow()"></app-button>
      </div>
    </div>
  </div>
</div>

<app-dialog (close)="ImageUploadDialogClosed()" [openDialog]="displayImageUploadDialog" [type]="'profile-image-upload'"
  [inputInfo]="inputInfo" (updateData)="dialogUpdateData($event)">
</app-dialog>

<app-dialog (close)="phoneVerifyDialogClosed()" [openDialog]="displayPhoneVerifyDialog" [type]="'verify'"
  [inputInfo]="inputInfo" [changeFlag]="changeFlag" (updateData)="updateData($event, inputInfo[0].modelName)"
  (preSubmit)="preSubmit()" (submitData)="onSubmit()">
</app-dialog>