<!-- <div class="row justify-content-center">
  <div class="col-md-12">
    <p-dropdown
      [options]="data"
      [formControl]="formControl"
      optionLabel="name"
      [filter]="inputInfo.search"
      filterBy="name"
      [showClear]="true"
      placeholder="Select a Country"
      class="dropDown"
      (keyup.enter)="onEnter()"
    >
      <ng-template pTemplate="selecteDItem">
        <div class="country-item country-item-value" *ngIf="formControl">
          <div>{{ formControl.value.name }}</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          <div>{{ country.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div> -->
<div class="d-flex justify-content-center homeDropdown" *ngIf="this.inputInfo.type == 'dropdown'">
  <span class="p-input-icon-left p-float-label">
    <p-dropdown [ngClass]="
        inputInfo.modelName == 'lookingFor' ? 'firstChildClass' : 'dropDown'
      " [options]="this.data" [formControl]="formControl" optionLabel="name" [showClear]="true"
      [scrollHeight]="this.scrollHeight" [virtualScroll]="false" [filter]="inputInfo.search" filterBy="name"
      virtualScrollItemSize="30" placeholder="{{ this.inputInfo.placeHolder | capitalization }}" class="dropDown"
      (keyup.enter)="onEnter()" (onClick)="click()">
      <ng-template class="list" pTemplate="selecteDItem">
        <div class="size country-item country-item-value" *ngIf="formControl">
          <div>{{ formControl.value.name }}</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item" class="list">
        <div class="country-item">
          <div>{{ country.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
  </span>
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <!-- <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
  }}</small> -->
</div>

<div class="d-flex gap-2  mt-2 justify-content-center profileCreateDropdown"
  *ngIf="inputInfo.type == 'profileCreateDropdown'" [ngClass]="
    accessFrom == 'profileVideoVerification' ? 'profileVideoVerification' : ''
  ">
  <span class="p-input-icon-left p-float-label">
    <!-- <label for="username">{{this.inputInfo.placeHolder}}</label> -->

    <p-dropdown *ngIf="this.inputInfo.type == 'profileCreateDropdown'" [options]="this.data" [formControl]="formControl"
      optionLabel="name" [showClear]="true" [scrollHeight]="this.scrollHeight" [disabled]="this.inputInfo.disabled"
      [virtualScroll]="true" [filter]="inputInfo.search" filterBy="name" virtualScrollItemSize="50"
      [style.width]="width" placeholder="{{ this.inputInfo.placeHolder | capitalization }}" class="dropDown"
      (keyup.enter)="onEnter()" (onClick)="click()">
      <ng-template class="list" pTemplate="selecteDItem">
        <div class="size country-item country-item-value" *ngIf="formControl">
          <div>{{ formControl.value.name }}</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item" class="list">
        <div class="country-item">
          <div>{{ country.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
  </span>
  <!-- <div class="hidebox">
    <p-checkbox 
    value="hide" 
    inputId="hide" />
    <p>Hide</p>
  </div> -->
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
</div>
<small *ngIf="
    this.inputInfo.type == 'profileCreateDropdown' && this.formControl.dirty
  " id="username2-help" class="p-error">{{ getError() }}</small>
<div class="homeDropdown transBg matchesDropdown " *ngIf="inputInfo.type == 'myMatches'">
  <p-dropdown [options]="this.data" [formControl]="formControl" optionLabel="name" [showClear]="true"
    [scrollHeight]="this.scrollHeight" [virtualScroll]="true" [filter]="inputInfo.search" filterBy="name"
    virtualScrollItemSize="30" placeholder="{{ this.inputInfo.placeHolder | capitalization }}" class="dropDown"
    (keyup.enter)="onEnter()" (onClick)="click()">
    <ng-template class="list" pTemplate="selecteDItem">
      <div class="size country-item country-item-value" *ngIf="formControl">
        <div>{{ formControl.value.name }}</div>
      </div>
    </ng-template>
    <ng-template let-country pTemplate="item" class="list">
      <div class="country-item">
        <div>{{ country.name }}</div>
      </div>
    </ng-template>
  </p-dropdown>
  <!-- <label for="inputField">{{ this.inputInfo.placeHolder }}</label> -->
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <div class="matchesDropdownError">
    <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
      getError()
      }}</small>
  </div>
</div>