<button
  label="{{ label }}"
  [style.background]="bgColor"
  pButton
  pRipple
  (click)="onClick()"
  type="button"
  [icon]="icon"
  class="p-button-rounded p-button-text"
></button>

