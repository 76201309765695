<div class="contact-us">
    <div class="hero">
        <img class="hero-image" src="../assets/images/{{ ContactUsPageInfo.pageHeroImage }}" alt="hero-image">
        <div class="hero-content">
            <div class="hero-pageTitle">
                <h2>{{ ContactUsPageInfo.title }}</h2>
            </div>
            <div class="hero-pageDescription">
                <p>{{ ContactUsPageInfo.description }}</p>
            </div>
        </div>
    </div>

    <div class="contact-us-section container-fluid">
        <!-- Left Side: Contact Form -->
        <div class="form-container">
            <div class="card">
                <div class="card-body">
                    <h3>For any information regarding this site, please use the contact form below:</h3>

                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="form-row">
                            <!-- First Name -->
                            <div class="form-group">
                                <label for="firstName">First Name</label>
                                <input id="firstName" type="text" formControlName="first_name" placeholder="First Name"
                                    required />
                                <div *ngIf="contactForm.controls['first_name'].invalid && contactForm.controls['first_name'].touched"
                                    class="error-message">
                                    First Name is required
                                </div>
                            </div>

                            <!-- Last Name -->
                            <div class="form-group">
                                <label for="lastName">Last Name</label>
                                <input id="lastName" type="text" formControlName="last_name" placeholder="Last Name"
                                    required />
                                <div *ngIf="contactForm.controls['last_name'].invalid && contactForm.controls['last_name'].touched"
                                    class="error-message">
                                    Last Name is required
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <!-- Email -->
                            <div class="form-group">
                                <label for="email">E-mail</label>
                                <input id="email" type="email" formControlName="email" placeholder="Email" required />
                                <div *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched"
                                    class="error-message">
                                    <ng-container *ngIf="contactForm.controls['email'].errors?.['required']">Email is
                                        required</ng-container>
                                    <ng-container *ngIf="contactForm.controls['email'].errors?.['email']">Please enter a
                                        valid email address</ng-container>
                                </div>
                            </div>

                            <!-- Contact No -->
                            <div class="form-group">
                                <label for="contactNo">Contact No</label>
                                <input id="contactNo" type="text" formControlName="mobile_number"
                                    placeholder="Contact No" required pattern="[0-9]*" />
                                <div *ngIf="contactForm.controls['mobile_number'].invalid && contactForm.controls['mobile_number'].touched"
                                    class="error-message">
                                    <ng-container
                                        *ngIf="contactForm.controls['mobile_number'].errors?.['required']">Contact No is
                                        required</ng-container>
                                    <ng-container
                                        *ngIf="contactForm.controls['mobile_number'].errors?.['pattern']">Please enter a
                                        valid phone number (digits only)</ng-container>
                                </div>
                            </div>
                        </div>

                        <!-- Query -->
                        <div class="form-row">
                            <div class="form-group full-width">
                                <label for="query">Query</label>
                                <textarea id="query" rows="5" formControlName="body" placeholder="Write Your Query"
                                    required></textarea>
                                <div *ngIf="contactForm.controls['body'].invalid && contactForm.controls['body'].touched"
                                    class="error-message">
                                    Query is required
                                </div>
                            </div>
                        </div>

                        <!-- Recaptcha and Submit Button in Two Columns -->
                        <div class="form-row">
                            <div class="form-group half-width">
                                <label>Captcha</label>
                                <div>
                                    <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel"
                                        (resolved)="resolved($event)" (errored)="errored($event)"
                                        [(ngModel)]="this.captcha" required
                                        [class.is-invalid]="this.captchaErrorFlag"></re-captcha>
                                    <div class="error-message" *ngIf="this.captchaErrorFlag == false">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group half-width text-right">
                                <button type="submit" [disabled]="!contactForm.valid">Submit</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        <!-- Right Side: E-mail Info -->
        <div class="info-container">
            <div class="card">
                <div class="card-body">
                    <h4><i class="pi pi-envelope"
                            style="margin-right: 10px;font-size: larger; color: #D70381;"></i>E-Mail</h4>
                    <p>info&#64;shiaspouse.org</p>
                </div>
            </div>
        </div>
    </div>