<div class="profile">
    <div class="hero">
      <img
        class="hero-image"
        [src]="profilePageInfo.profileHeroImage"
        alt="hero image"
      />
    </div>
    <div class="profile-section-Image-outer">
      <div class="profile-section-Image">
        <ng-container>
          <ng-container *ngIf="!profilePageInfo.profileImage">
            <p-skeleton
              shape="circle"
              size="10rem"
              styleClass="mr-2"
            ></p-skeleton>
          </ng-container>
          <ng-container *ngIf="profilePageInfo.profileImage">
            <img
              class="profileImage"
              [src]="profilePageInfo.profileImage"
              alt="profile picture"
            />
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="!myProfileFlag && profileInfoLoadedFlag">
        <div class="profile-section-send-message">
          <app-button
            [type]="'btnWithIcon'"
            [color]="'white'"
            [bgColor]="'var(--color-blue)'"
            [borderRadius]="'50px !important'"
            [icon]="'fad fa-comments'"
            (button)="sendMessage(profileId)"
          >
          </app-button>
          <!-- <i class="fad fa-comments" (click)="sendMessage(profileId)"></i> -->
        </div>
      </ng-container>
    </div>
    <div class="container-fluid userInfoDiv">
      <ng-container *ngIf="!profileInfoLoadedFlag">
        <div class="profile-section-info-skeleton">
          <p-skeleton
            width="100%"
            height="2rem"
            styleClass="mb-2"
            borderRadius="16px"
          ></p-skeleton>
          <p-skeleton
            width="70%"
            styleClass="mb-2"
            borderRadius="16px"
          ></p-skeleton>
          <p-skeleton
            width="70%"
            styleClass="mb-2"
            borderRadius="16px"
          ></p-skeleton>
          <p-skeleton
            width="40%"
            styleClass="mb-2"
            borderRadius="16px"
          ></p-skeleton>
        </div>
      </ng-container>
      <div class="profile-section-Info" *ngIf="profileInfoLoadedFlag">
        <h4 class="userName">{{ profilePageInfo.nickname }}</h4>
        <ng-container *ngIf="profilePageInfo.profession">
          <p class="userProfession">
            {{ profilePageInfo.profession }}
          </p>
        </ng-container>
        <p class="userAddress">
          <!-- {{ profilePageInfo.address }} -->
          {{ profilePageInfo.city }}, {{ profilePageInfo.country }}
        </p>
        <span class="profile-view" (click)="goBack()">Go back to your profile</span>
        <!-- <p class="userStatus">
          <span class="Ss">Status :</span
          > -->
          <!-- <span
            class="Ds"
            [ngClass]="{
              verified: profilePageInfo.status == 'Verified'
            }"
            >{{ profilePageInfo.status }}</span
          > -->
          <!-- ... -->
        <!-- </p> -->
        <!-- add social media icon  -->
        <!-- <div class="social-icons">
          <a [href]="profilePageInfo.instagram_link" target="_blank">
            <i class="pi pi-instagram" style="font-size: 1.7rem"></i>
          </a>
          <a [href]="profilePageInfo.facebook_link" target="_blank">
            <i class="pi pi-facebook" style="font-size: 1.7rem"></i>
          </a>
        </div> -->
        
       
      </div>
     
      <div
        class="profile-section-Info"
        *ngIf="
          myProfileFlag == false && this.requestSentStatus && this.protectedFlag
        "
      >
        <div class="connectAndBlockBtn">
          <app-button
            *ngIf="profileRequestSendStatus !== 'accepted'"
            [label]="
              profileRequestSendStatus === 'pending'
                ? 'Request has been sent'
                : profileRequestSendStatus === 'rejected'
                ? 'Request to View Profile Image'
                : 'Request to View Profile Image'
            "
            [bgColor]="'#1CA9F4'"
            [type]="'login'"
            [fontSize]="'clamp(12px, 3vw, 16px)'"
            [fontWeight]="'500'"
            [padding]="
              isMobileView ? '10px 10px !important' : '10px 20px !important'
            "
            [borderRadius]="'50px !important'"
            [color]="'#fff'"
            [width]="'100% !important'"
            (button)="
              profileRequestSendStatus === 'pending'
                ? onButtonClick('invite-to-connect-pending')
                : onButtonClick('invite-to-connect')
            "
          ></app-button>
          <!-- <app-button
            [label]="'Block'"
            [bgColor]="'#ff3e3e'"
            [type]="'login'"
            [fontSize]="'clamp(12px, 3vw, 16px)'"
            [fontWeight]="'500'"
            [padding]="'10px 20px !important'"
            [borderRadius]="'50px !important'"
            [color]="'#fff'"
            [width]="'100% !important'"
            (button)="onButtonClick('block-profile')"
          ></app-button> -->
        </div>
      </div>
    </div>
    <div class="noteDiv" *ngIf="noteFlag == true && myProfileFlag == true">
      <p>
        Note: {{ note }}
        <span (click)="noteFlag = false"><i class="fal fa-times"></i></span>
      </p>
    </div>
   
  
    <div class="description" *ngIf="this.profilePageInfo.description">
      <div class="description-container">
        <h2>About me</h2>
        <q>{{ this.profilePageInfo.description }}</q>
      </div>
    </div>
    <ng-container *ngIf="!isMobileView">
      <div class="wideScreenDiv">
        <div class="aboutUsDiv">
          <div class="row">
            <div class="col-md-3 menuSide">
              <h2>About</h2>
              <div id="menu">
                <app-menu
                  [items]="aboutUsMenuItems"
                  [menuType]="'aboutUs'"
                  [activeTab]="activeTab"
                ></app-menu>
              </div>
            </div>
            <div class="col-md-9">
              <div *ngIf="aboutUsDataSide == 'basicLifeStyle'">
                <!-- <div class="toggle">
                  <span><i class="fas fa-ellipsis-h"></i></span>
                </div> -->
                <div class="data row justify-content-between">
                  <div class="col-6 infoDiv row">
                    <div class="col-6 staticSide">ID</div>
                    <div class="col-6 dynamicSide">
                      {{userId }}
                    </div>
                    <div class="col-6 staticSide">Age</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.age }}
                    </div>
                    <div class="col-6 staticSide">Date of Birth</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.dateOfBirth }}
                    </div>
                    <div class="col-6 staticSide">Marital Status</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.maritalStatus }}
                    </div>
                    <div class="col-6 staticSide">Height</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.height }}
                    </div>
                    <div class="col-6 staticSide">Skin Tone</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.skinTone }}
                    </div>
                    <div class="col-6 staticSide">Do you Pray Regularly</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.prayStatus }}
                    </div>
                    <div class="col-6 staticSide">Do you Have Children</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.childrenStatus }}
                    </div>
                    <ng-container
                      *ngIf="aboutUserInfo.basicAndLifestyle.noOfChildren"
                    >
                      <div class="col-6 staticSide">No. of Children</div>
                      <div class="col-6 dynamicSide">
                        {{ aboutUserInfo.basicAndLifestyle.noOfChildren }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="col-6 infoDiv row">
                    <div class="col-6 staticSide">Body Weight</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.bodyWeight }}
                    </div>
                    <div class="col-6 staticSide">Smoke</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.smoke }}
                    </div>
                    <!-- <div class="col-6 staticSide">Contact No</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.contactNo }}
                    </div> -->
                    <div class="col-6 staticSide">Body Type</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.bodyType }}
                    </div>
                    <div class="col-6 staticSide">Working Status</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.workingStatus }}
                    </div>
                    <div class="col-6 staticSide">Are you Shia Ithna Ashari</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.ithnaAshri }}
                    </div>
                    <div class="col-6 staticSide">Language</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.language }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="aboutUsDataSide == 'personalDetails'">
                <div class="toggle">
                  <span><i class="fas fa-ellipsis-h"></i></span>
                </div>
                <div class="data row justify-content-between">
                  <div class="col-6 infoDiv row">
                    <!-- <div class="col-6 staticSide">ID</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.personalDetails.id }}
                    </div> -->
                    <div class="col-6 staticSide">Gender</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.personalDetails.gender }}
                    </div>
                    <div class="col-6 staticSide">Country</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.personalDetails.country }}
                    </div>
                    <div class="col-6 staticSide">Residency</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.personalDetails.residency }}
                    </div>
                  </div>
                  <div class="col-6 infoDiv row">
                    <div class="col-6 staticSide">Syed?</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.personalDetails.syed }}
                    </div>
                    <div class="col-6 staticSide">City</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.personalDetails.city }}
                    </div>
                    <div class="col-6 staticSide">Ethnicity/ Culture</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.personalDetails.ethnicity }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="aboutUsDataSide == 'ratingVerification'">
                <div class="toggle">
                  <span><i class="fas fa-ellipsis-h"></i></span>
                </div>
                <div class="data row justify-content-between">
                  <div class="col-6 infoDiv row">
                    <div class="col-6 staticSide">Ratings</div>
                    <div class="col-6 dynamicSide">
                      <!-- {{ aboutUserInfo.ratingAndVerification.ratings }} -->
                      <app-rating
                      [type]="'readOnly'"
                      [score]="verificationInfo.rating"
                      [stars]="5"
                    ></app-rating>
                    </div>
                    <div class="col-6 staticSide">Email Verified</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.ratingAndVerification.email_verified }}
                    </div>
                  </div>
                  <div class="col-6 infoDiv row">
                    <div class="col-6 staticSide">Maulana Verified</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.ratingAndVerification.maulana }}
                    </div>
                    <div class="col-6 staticSide">Phone Verified</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.ratingAndVerification.phone }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="aboutUsDataSide == 'educationCarrer'">
                <div class="toggle">
                  <span><i class="fas fa-ellipsis-h"></i></span>
                </div>
                <div class="data row justify-content-between">
                  <div class="col-6 infoDiv row">
                    <div class="col-6 staticSide">Education</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.educationAndCareer.education }}
                    </div>
                    <div class="col-6 staticSide">Profession</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.educationAndCareer.profession }}
                    </div>
                    <div class="col-6 staticSide">Income</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.educationAndCareer.income }}
                    </div>
                  </div>
                  <!-- <div class="col-6 infoDiv row">
                    <div class="col-6 staticSide">Maulana Verified</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.ratingAndVerification.maulana }}
                    </div>
                    <div class="col-6 staticSide">Phone Verified</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.ratingAndVerification.phone }}
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isMobileView">
      <div class="mobileScreenDiv">
        <div class="profile-accordion">
          <h3 class="aboutUs-mobile-heading">About</h3>
          <p-accordion
            class="aboutUs-accordion"
            (onOpen)="onAboutTabOpen($event)"
          >
            <p-accordionTab header="Basic & Lifestyle">
              <div class="">
                <div class="container">
                  <div class="col-12 infoDiv row">
                    <div class="col-6 staticSide">ID</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.id }}
                    </div>
                    <div class="col-6 staticSide">Age</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.age }}
                    </div>
                    <div class="col-6 staticSide">Date of Birth</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.dateOfBirth }}
                    </div>
                    <div class="col-6 staticSide">Marital Status</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.maritalStatus }}
                    </div>
                    <div class="col-6 staticSide">Height</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.height }}
                    </div>
                    <div class="col-6 staticSide">Skin Tone</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.skinTone }}
                    </div>
                    <div class="col-6 staticSide">Do you Pray Regularly</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.prayStatus }}
                    </div>
                    <div class="col-6 staticSide">Do you Have Children</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.childrenStatus }}
                    </div>
                    <div class="col-6 staticSide">No. of Children</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.noOfChildren }}
                    </div>
  
                    <div class="col-6 staticSide">Body Weight</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.bodyWeight }}
                    </div>
                    <div class="col-6 staticSide">Smoke</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.smoke }}
                    </div>
                    <!-- <div class="col-6 staticSide">Contact No</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.contactNo }}
                    </div> -->
                    <div class="col-6 staticSide">Body Type</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.bodyType }}
                    </div>
                    <div class="col-6 staticSide">Working Status</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.workingStatus }}
                    </div>
                    <div class="col-6 staticSide">Are you Shia Ithna Ashari</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.ithnaAshri }}
                    </div>
                    <div class="col-6 staticSide">Language</div>
                    <div class="col-6 dynamicSide">
                      {{ aboutUserInfo.basicAndLifestyle.language }}
                    </div>
                  </div>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab header="Personal Details">
              <div class="container">
                <div class="col-12 infoDiv row">
                  <div class="col-6 staticSide">ID</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.id }}
                  </div>
                  <div class="col-6 staticSide">Gender</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.gender }}
                  </div>
                  <div class="col-6 staticSide">Country</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.country }}
                  </div>
                  <div class="col-6 staticSide">Residency</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.residency }}
                  </div>
                  <div class="col-6 staticSide">Syed?</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.syed }}
                  </div>
                  <div class="col-6 staticSide">City</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.city }}
                  </div>
                  <div class="col-6 staticSide">Ethnicity/ Culture</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.personalDetails.ethnicity }}
                  </div>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab header="Rating & Verification ">
              <div class="container">
                <div class="col-12 infoDiv row">
                  <div class="col-6 staticSide">Ratings</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.ratings }}
                  </div>
                  <div class="col-6 staticSide">Email Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.email_verified }}
                  </div>
                  <div class="col-6 staticSide">Maulana Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.maulana }}
                  </div>
                  <div class="col-6 staticSide">Phone Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.phone }}
                  </div>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab header="Education & Career">
              <div class="container">
                <div class="col-12 infoDiv row">
                  <div class="col-6 staticSide">Education</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.educationAndCareer.education }}
                  </div>
                  <div class="col-6 staticSide">Profession</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.educationAndCareer.profession }}
                  </div>
                  <!-- <div class="col-6 staticSide">Maulana Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.maulana }}
                  </div>
                  <div class="col-6 staticSide">Phone Verified</div>
                  <div class="col-6 dynamicSide">
                    {{ aboutUserInfo.ratingAndVerification.phone }}
                  </div> -->
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
  
        <!-- <app-accordion
          [myProfileFlag]="this.myProfileFlag"
          [inputInfo]="this.inputInfo"
          [type]="'profile'"
          [accordionData]="this.accordionObj"
          (selectedAccordion)="selectedMenu($event.menuType, $event.menuItem)"
          (accordionUpdateData)="
            updateData($event.value, $event.modelName, $event.sectionName)
          "
          (accordionPreSubmit)="preSubmit()"
          (accordionOnSubmit)="onSubmit()"
        >
        </app-accordion> -->
      </div>
    </ng-container>
   
    <div
      class="photosDiv"
      *ngIf="
        myProfileFlag == true ||
        (protectedFlag == false && isPicPrivate == false) ||
        profileRequestSendStatus == 'accepted'
      "
    >
      <div class="menuSide">
        <h2>Photos</h2>
      
      </div>
      <div class="px-3 pb-3">
        <ng-container *ngIf="userImageInfo.length > 0">
          <app-galleria
            [imagesData]="userImageInfo"
            [type]="'profileImage'"
            (confirmAction)="onConfirmation($event)"
          ></app-galleria>
        </ng-container>
        <ng-container *ngIf="userImageInfo.length == 0">
          <div class="text-center pb-3">No Images uploaded</div>
        </ng-container>
      </div>
    </div>
  
  
  </div>
  
 
  
  