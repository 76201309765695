<div class="chips">
  <ng-container *ngFor="let chip of chipsData">
    <p-chip
      *ngIf="!chip.removable"
      [label]="chip.label"
      [image]="chip.image"
      [icon]="chip.icon"
      [removable]="chip.removable"
      [styleClass]="chip.className"
      (click)="onClick($event, chip.modelName, chip.id)"
    ></p-chip>
    <p-chip
      *ngIf="chip.removable"
      [label]="chip.label"
      [image]="chip.image"
      [icon]="chip.icon"
      [removable]="chip.removable"
      [styleClass]="chip.className"
      (onRemove)="onRemoveClicked($event)"
    ></p-chip>
  </ng-container>
</div>
