<div class="container-fluid login-page">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/signup-login-ss.png"
      alt=""
    />
    <!-- <div class="hero-content">
      <div class="hero-pageTitle">
        <h2>{{ videosPageInfo.title }}</h2>
      </div>
      <div class="hero-pageDescription">
        <p>{{ videosPageInfo.description }}</p>
      </div>
    </div> -->
  </div>
  <div class="row justify-content-center login-inner">
    <div class="login-heading text-center m-auto">
      <div class="login-heading-title">
        <h3>Welcome to ShiaSpouse</h3>
      </div>
      <div class="login-heading-para">
        <p>Secure, reliable, confidential and respectful of your needs.</p>
      </div>
    </div>
    <div class="login-main">
      <div class="col-md-12 mt-4" *ngFor="let box of this.inputInfo">
        <!-- <label class="label" *ngIf="box.type == 'text2'">{{
            box.label | capitalization
          }}</label> -->
        <app-text-box
          [inputInfo]="box"
          [changeFlag]="this.changeFlag"
          *ngIf="box.type == 'text2'"
          (updateData)="updateData($event, box.modelName)"
          (preSubmit)="preSubmit()"
          (submit)="onSubmit()"
          [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
          [borderRadius]="'35px'"
          [width]="'100%'"
          [height]="'65px'"
          [padding]="'0 2rem'"
        ></app-text-box>
        <!-- <div class="d-flex">
        <app-check-box
          [inputInfo]="box"
          [changeFlag]="changeFlag"
          (updateData)="updateData($event, box.modelName)"
          *ngIf="box.type == 'checkBox'"
          (preSubmit)="preSubmit()"
          (submit)="onSubmit()"
          [boxShadow]="'rgb(0 0 0 / 13%) 0px 0px 12px;'"
        ></app-check-box>
        <label class="label ml-2 pt-1" *ngIf="box.type == 'checkBox'"
          >Remember Me</label
        >
      </div> -->
      </div>
      <div class="row">
        <div class="forgot-password container-fluid mt-4">
          <a [routerLink]="'/user/recover'">Forgot Password?</a>
        </div>
      </div>
      <div class="col-md-12 mt-4">
        <app-button
          [label]="'Login'"
          [padding]="'5px 0'"
          [color]="'#fff'"
          [type]="'login'"
          (button)="preSubmit()"
          [bgColor]="'var(--color-pink-bg)'"
          [borderRadius]="'35px !important'"
          [fontSize]="'clamp(16px, 4vw, 22px)'"
          [padding]="'1rem 3rem'"
          [width]="'100%'"
        >
        </app-button>
      </div>

      <div class="row">
        <div class="divider container-fluid mt-5 mb-4">
          <span>OR</span>
        </div>
      </div>
      <div class="row flex align-items-center">
      <div class="col-sm-12 signIn-google-btn">
        <!-- <asl-google-signin-button
          type="standard"
          size="large"
          shape="pill"
          width="200"
        ></asl-google-signin-button> -->

        <!-- <app-button
          [label]="'Sign in with google'"
          [padding]="'5px 0'"
          [color]="'#000000'"
          [type]="'btnWithCustomIcon'"
          [bgColor]="'#ffffff'"
          [borderRadius]="'35px !important'"
          [border]="'1px solid #707070'"
          [fontSize]="'clamp(14px, 4vw, 20px)'"
          [padding]="'1rem 3rem'"
          [width]="'100%'"
          [imgSrc]="'./assets/images/google-icon.png'"
          (button)="loginWithGoogle()"
        >
        </app-button> -->

        <app-google-signin
          (loginWithGoogle)="loginWithGoogle($event)"
        ></app-google-signin>
      </div>
      <!-- <div class="col-sm-6  signIn-fb-btn">
        <app-button
          [label]="'Sign in with facebook'"
          [padding]="'5px 0'"
          [color]="'#fff'"
          [type]="'btnWithCustomIcon'"
          [bgColor]="'var(--color-tabMenu-bg)'"
          [borderRadius]="'35px !important'"
          [border]="'1px solid #707070'"
          [fontSize]="'clamp(12px, 4vw, 16px)'"
          [padding]="'1rem 3rem'"
          [width]="'100%'"
          [imgSrc]="'./assets/images/facebook-app-symbol.png'"
          (button)="loginWithFacebook()"
        >
        </app-button>
      </div> -->
    </div>
      <div class="row">
        <div
          class="d-flex justify-content-center align-items-center container-fluid my-4 sign-up-container"
        >
          <p>
            Dont have an account?
            <a href="./user/signup">Sign Up Free</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
