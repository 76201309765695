export class SearchInfo {
  looking_for: any;
  country: any;
  age_from: any;
  age_to: any;
  occupation: any;
  ethnicity: any;
  is_syed: any;
  language: any;
  education: any;
  relationship_status: any;
  working_status: any;
  rating: any;
  body_type: any;
  skin_tone: any;
  smoke: any;
}
