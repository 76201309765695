<div class="terms">
  <div class="hero">
    <div class="hero-content">
      <div class="hero-icon">
        <i class="pi pi-list"></i>
      </div>
      <div class="hero-pageTitle">
        <h2>Terms of Use</h2>
      </div>
    </div>
  </div>

  <section class="terms-section container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="well-box">
            <p><strong>Membership Terms and Conditions</strong></p>
            <p>
              Please read these Terms &amp; Conditions very carefully. By using
              services provided by
              <strong>Majlis e Ulama e Shia (Europe)</strong>, you agree to
              honour these.
            </p>
            <p>
              <br />
              This website <strong>www.shiaspouse.org</strong> is provided by
              Majlis e Ulama e Shia Europe. This is a legal agreement between
              you and Majlis e Ulama e Shia Europe. By&nbsp;using this website,
              you agree to be bound to the following Terms and Conditions. If
              you do not agree with these terms and conditions then you are
              not&nbsp;authorised to use or access this web site. The
              application form is a contractual offer by you to us subject to
              these Terms and Conditions and no others. On&nbsp;acceptance by
              us, there exists a binding contractual relationship between you
              and us. This document ('Membership Terms and Conditions') sets out
              the&nbsp;relationship between you and us based on the following
              definitions;
            </p>
            <p>
              'We', 'Us' &amp; 'Our' are used interchangeably to refer to Majlis
              e Ulama e Shia (Europe)<br />
              'You', 'Your' &amp; 'Member(s)' &amp; 'Advertiser(s)' refers to
              you, the paid &amp; unpaid users of
              www.ShiaSpouse.org.&nbsp;'Content' means the information and other
              material available within this Site.&nbsp;'Site' &amp; 'Portal'
              are used interchangeably to refer to our website located at
              www.ShiaSpouse.org.&nbsp;'Advertisement(s)' &amp; 'Profile(s)'
              mean the information entity you have created for other members'
              viewing.&nbsp;'T&amp;Cs' mean The Membership Terms and Conditions
              set out in this document in entirety.
            </p>
            <p>
              <br />
              <strong>1 General Membership Conditions:</strong><br />
              <strong>1.1</strong> Your membership is for your sole, personal
              use only. You may not authorise others to use your membership, and
              you may not assign or otherwise transfer&nbsp;your account to any
              other person or entity.
            </p>
            <p>
              <br />
              <strong>1.2 </strong>ShiaSpouse is only a means of introducing
              prospective partner-seekers; it is designed to prove
              trustworthiness and profile accuracy by multiple levels
              of&nbsp;verification, and however it cannot confirm 100% member
              trustworthiness, sincerity or character affirmation. We can only
              request that you be honest so you get&nbsp;honesty in return. We
              are not responsible for the accuracy of information provided by
              members.
            </p>
            <p>
              <br />
              <strong>1.3</strong> Subscription fees are charged in advance and
              are non-refundable. However, in exceptional circumstances, we may
              try to make exceptions on case by case&nbsp;basis and at our
              discretion. We reserve the right not to provide reasons or
              explanations for our&nbsp; decisions. You do not have a right of
              appeal.
            </p>
            <p>
              <br />
              <strong>1.4 </strong>Your profile will be vetted by us prior to
              being made available on www.shiaspouse.org to see if it complies
              with our Membership T&amp;Cs. If deemed unsuitable,&nbsp;we
              reserve the right to remove or change the offending
              elements.&nbsp;1.5 The minimum legal age for a British citizens
              getting married is 16 years old. In England and Wales the written
              consent of the parents or Guardians is&nbsp;required for persons
              who have not reached 18 years old and have not been previously
              married. To apply for membership you must be at least 18 years old
              from&nbsp;the date of membership application.
            </p>
            <p>
              <br />
              <strong>1.6 </strong>We cooperate with law enforcement enquiries
              including other parties rights to enforce laws such as
              intellectual property rights fraud and other rights.
              You&nbsp;expressly authorise us to disclose any information about
              you to law enforcement and other Government Agencies including the
              Police which we at our sole&nbsp;discretion believe necessary or
              appropriate in connection with any fraud, intellectual property
              infringements or other activity which is or may be illegal or
              may&nbsp;expose us to legal liability.
            </p>
            <p>
              <br />
              <strong>2 Our Responsibilities:</strong><br />
              <strong>2.1 </strong>No personal details will ever leave the
              portal database or passed onto third parties in any circumstances
              by ShiaSpouse. Members' are responsible for&nbsp;making their own
              profiles public or private.
            </p>
            <p>
              <br />
              <strong>2.2</strong> We will automatically remove you from the
              register after your membership has expired, unless you choose to
              renew your membership and pay the further&nbsp;applicable fees.
            </p>
            <p>
              <br />
              <strong>2.3</strong> Signing and submitting the application form,
              for processing, authorises us to disclose your chosen methods of
              contact to other members and display your&nbsp;photograph as part
              of your member profile.
            </p>
            <p>
              <br />
              <strong>2.4</strong> We reserve the right to modify the services
              offered at www.ShiaSpouse.org without giving any prior notice.
            </p>
            <p>
              <br />
              <strong>3 Your Responsibilities:</strong><br />
              <strong>3.1 </strong>You will not post on our service, or transmit
              to other members or employees, any defamatory, inaccurate,
              abusive, obscene, profane, offensive sexually&nbsp;oriented,
              threatening, harassing, racially offensive, or illegal material,
              or any material which infringes or violates another party's rights
              (including, but not limited&nbsp;to, proprietary rights and rights
              of privacy).
            </p>
            <p>
              <br />
              <strong>3.2</strong> It is your responsibility to ensure the
              accuracy of all details (personal or otherwise) provided by you
              about yourself to us for inclusion in your advertisement.<br />
              It is your responsibility to update your profile for any changes
              to personal details or circumstances at the earliest opportunity.
              You are solely responsible for the<br />
              content or information you transmit to other members.
            </p>
            <p>
              <br />
              <strong>3.3</strong> You will not post on our service, or transmit
              to other members or employees, any defamatory, inaccurate,
              abusive, obscene, profane, offensive sexually&nbsp;oriented,
              threatening, harassing, racially offensive, or illegal material,
              or any material which infringes or violates another party's rights
              (including, but not limited&nbsp;to, proprietary rights, and
              rights of privacy).
            </p>
            <p>
              <br />
              <strong>3.4</strong> You will use our service in a manner
              consistent with any and all applicable laws and regulations. You
              will not include in your profile any telephone
              numbers,&nbsp;street addresses, last names, URL's, E-mail
              addresses, offensive anatomical or sexual references, or offensive
              sexually suggestive or connotative language,&nbsp;and you will not
              post any photos containing nudity or personal information. We
              reserve the right, but have no obligation, to reject any profile
              or photo which does&nbsp;not comply with these prohibitions.
            </p>
            <p>
              <br />
              <strong>3.5</strong> You will not engage in advertising to, or
              solicitation of, other members to buy or sell any products or
              services through the Service. You will not transmit any&nbsp;chain
              letters or junk E-mail to other members.
            </p>
            <p>
              <br />
              <strong>3.6</strong> If you do advertise or promote any service
              without our permission you agree to pay a standard advertising
              rate of £20 per day for everyday your profile has&nbsp;been on
              www.ShiaSpouse.org starting from the day you registered. You will
              be personally liable if we are not able to recover the sum of
              monies from the&nbsp;company advertised and we will take legal
              action to enforce this.
            </p>
            <p>
              <br />
              <strong>3.7</strong> You will not post, distribute or reproduce in
              any way any copyrighted material, trademarks, or other proprietary
              information without obtaining the prior&nbsp;consent of the owner
              of such proprietary rights.
            </p>
            <p>
              <br />
              <strong>3.8 </strong>You will not share, exchange, sell or pass on
              details of members registered on this portal to third parties or
              attempt introductions between members and&nbsp;non-members outside
              this portal. This is a serious breach and will result in
              termination of your membership and possibly sued for damages in a
              court of law.
            </p>
            <p>
              <br />
              <strong>3.9</strong> It is solely your responsibility to cancel
              PayPal subscription to avoid any unwanted recurring charges.
              Members do not usually give their real names and/or&nbsp;email
              addresses when registering on this website and therefore it's very
              time-consuming for us to handle subscription cancellations.
              Personal details supplied to&nbsp;us often don't match the details
              held by PayPal. www.ShiaSpouse.org will NOT entertain any claims
              for refunds for subscription renewals as these are
              doneautomatically by PayPal and not directly by us and therefore
              deemed authorised by the subcribers. Alternatively, we can cancel
              the subscription on behalf of&nbsp;members subject to minimum of 1
              month notice before renewal date, submitted in writing (email) by
              the subscriber giving their profile name, real name and&nbsp;email
              address registered at PayPal.
            </p>
            <p>
              <br />
              <strong>4 Website Availability:</strong><br />
              <strong>4.1</strong> You acknowledge that it is technically
              impossible to provide the site free of faults and that we do not
              undertake to do so; that faults may lead to the&nbsp;temporary
              unavailability of the site; and that the operation of the site may
              be adversely affected by conditions and performances outside our
              control, including,&nbsp;without limitation, transmission and
              telecommunications links between us and you and between us and
              other systems and networks. We and/or our suppliers&nbsp;may make
              improvements and/or changes in or to the site at any time.
            </p>
            <p>
              <br />
              <strong>4.2 </strong>We accept no liability for any temporary or
              even permanent unavailability of the site arising from events of
              any kind or circumstances which are outside our&nbsp;control even
              though we shall use all reasonable endeavours to minimise or
              mitigate the effects of any such force majeure but this will not
              release you by reason&nbsp;of such force majeure from any
              obligation to indemnify or make any payment due under these Terms
              and Conditions.
            </p>
            <p>
              <br />
              <strong>5 Limitations of use:</strong><br />
              <strong>5.1</strong> Members are given 60 days from date of
              registration to subscribe. Failure to do so within this period
              will mark your profile for deletion thereafter. Deactivated
              accounts can be activated within 60 days before they are deleted
              permanently. Deleted accounts can't be recovered.&nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
