<div *ngIf="type && type === 'home&AboutusPage'" class="my-carousel">

  <div class="card">
    <p-carousel 
    [value]="items"
    [numVisible]="3"
    [numScroll]="1"
    [circular]="true" 
    [responsiveOptions]="responsiveOptions"
    autoplayInterval="3000">
        
        <ng-template let-item pTemplate="item" >
          <div class="title">{{ item.title }}</div>
          <div>
            <p class="desc">{{ item.description }}</p>
          </div>
        </ng-template>
    </p-carousel>
</div>

</div>

<div class="myMatches" *ngIf="type && type === 'myMatches'">
  <p-carousel
    [value]="items"
    [numVisible]="1"
    [numScroll]="1"
    [responsiveOptions]="responsiveOptions"
    (onPage)="onPageClicked($event)"
  >
    <ng-template let-item pTemplate="item">
      <div class="carousel-image">
        <img [src]="item.image" alt="{{ item.title }}" class="image-per" />
      </div>
      <div class="carousel-footer"></div>
      <div class="carousel-person-info">
        <app-card
          [type]="'matchCardCarousel'"
          [cardData]="item"
          (cardButtonClick)="onCardButtonClick($event)"
          (cardClicked)="onCardClicked($event)"
        ></app-card>

      </div>
    </ng-template>
  </p-carousel>
</div>
