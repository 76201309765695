/**
 * This is our text box module, to be used through out our program
 */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  FormGroupDirective,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { Output, EventEmitter } from "@angular/core";
import { InputInfo } from "../../componentModel/input-info";
import { CustomValidatorsInfo } from "../../componentModel/customValidator-info";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { Router } from "@angular/router";

/**
 * These are the components of our text box file
 */
@Component({
  selector: "app-text-box",
  templateUrl: "./text-box.component.html",
  styleUrls: ["./text-box.component.css"],
})
export class TextBoxComponent implements OnInit, OnChanges {
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  isEditProfileRoute: boolean = false;
  mapOptions: Options = {
    types: ['(cities)'], // Restrict results to cities
    bounds: null,
    componentRestrictions: null,
    fields: null,
    origin: null,
    strictBounds: null
  };


  @Input() customValidator: CustomValidatorsInfo;
  /**
   *This input function gets data from our parent class of the type input info
   */
  @Input() inputInfo: InputInfo;
  /**
   *This input function gets value change flag from our parent class of the type boolean
   */
  @Input() changeFlag: boolean;
  @Input() resetValueFlag: boolean;
  /**
   * disabled field boolean variable
   */
  disabled: boolean = true;

  @Input() boxShadow: string;
  @Input() color: string;
  @Input() borderRadius: string;
  @Input() padding: string;
  @Input() width: string;
  @Input() height: string;
  @Input() bgColor: string;
  @Input() border: string;
  @Input() fontSize: string;
  @Input() fontFamily: string;

  /**
   * This output function send the updated data back to our parent class
   */
  @Output() updateData = new EventEmitter<string>();
  @Output() hideData = new EventEmitter<string>();
  @Output() handleAddress = new EventEmitter<Address>();
  /**
   * This output function works for the enter button as it call out the submit function of our parent class
   */
  @Output() preSubmit = new EventEmitter();
  /**
   * submit call of the text box
   */
  @Output() submit = new EventEmitter();
  /**
   * click emiiter
   */
  @Output() click = new EventEmitter();

  /**
   * This is an object of Form Control through which we can perform function and variation to the input values taken from the user
   */
  formControl = new UntypedFormControl("", []);
  formControlHide = new UntypedFormControl("", []);
  /**
   * This is the constructor of text box
   */
  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  };
  /**
   * This is the constructor of our component
   */
  constructor(private router: Router) { }
  /**
   *This function checks the input in the text box for any errors and value change.
   */
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    // Do some stuff
    this.handleAddress.emit(address);
  }

  // nonNegativeNumberValidator(): ValidatorFn {
  //     return (control: AbstractControl): { [key: string]: any } | null => {
  //       const isNegative = control.value < 0;
  //       return isNegative ? { 'negativeNumber': { value: control.value } } : null;
  //     };
  //   }
  ngOnInit(): void {
    this.inputInfo.control = this.formControl;
    if (this.inputInfo?.val) {
      this.formControl?.setValue(this.inputInfo?.val);
    }
    if (this.inputInfo.disabled == true) {
      this.formControl?.disable();
    }
    if (this.inputInfo.hideValue && this.inputInfo.hideValue !== "") {
      this.formControlHide.setValue(this.inputInfo.hideValue);
    }
    this.formControl?.setValidators(
      this.inputInfo?.validatorsInfo?.map((item) => item?.type)
    );
    this.formControl?.updateValueAndValidity();
    this.formControl?.valueChanges?.subscribe((value) => {
      if (this.inputInfo.inputType == 'number' && value < 0) {
        this.formControl?.setValue(0);
        return;
      }
      this.updateData.emit(value);
    });
    this.formControlHide.valueChanges.subscribe((value) => {
      this.hideData.emit(value);
    });

    // add input style check on the base of route 
    this.isEditProfileRoute = this.router.url.includes('edit-profile');
  }
  /**
   * This function is used to check for error, if there is any error then it send out the error msg, if not then simply changes eroor flag to false.
   * @returns
   */
  getError() {
    var err = [];
    if (this.formControl.errors) {
      err = Object.keys(this.formControl.errors);
    }
    if (err.length > 0 && this.inputInfo.validatorsInfo.length > 0) {
      this.inputInfo.errorFlag = true;

      let val = this.inputInfo.validatorsInfo.find(
        (item) => item.type.name == err[0]
      );
      if (val) {
        return val.msg;
      } else {
        let val = this.inputInfo.validatorsInfo.find(
          (item) => item.name == err[0]
        );
        if (val) {
          return val.msg;
        }
        if (err[0] == 'validatePhoneNumber') {
          return 'Enter a valid phone number';
        }
      }
    } else {
      this.inputInfo.errorFlag = false;
    }
    if (
      this.customValidator &&
      this.customValidator.activeName != "" &&
      this.customValidator.msg &&
      this.customValidator.msg != ""
    ) {
      return this.customValidator.msg.toString();
    }

    return "";
  }
  /**
   * This function works for when we press enter to log in instead of using the login button and it call the submit function of the parent class.
   */
  onEnter() {
    this.preSubmit.emit();
  }
  /**
   * This function checks whether there is any change in the initial value, saves it and validates it.
   * @param changes This is a boolean type flag to check for changes in value.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeFlag && !changes.changeFlag.firstChange) {
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
      this.submit.emit();
    }
    
    if (
      changes.customValidator &&
      changes.customValidator.currentValue &&
      changes.customValidator.currentValue.activeName != ""
    ) {
      // this.formControl.addValidators([this.checkCustomValidator()]);
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    if (
      changes.customValidator &&
      changes.customValidator.currentValue &&
      changes.customValidator.currentValue.notActiveName != "" &&
      this.formControl.errors
    ) {
      delete this.formControl.errors[
        changes.customValidator.currentValue.notActiveName
      ];
      // this.formControl.clearValidators();
      this.formControl.markAsDirty();

      this.formControl.updateValueAndValidity();
    }
    if (changes.resetValueFlag && !changes.resetValueFlag.firstChange) {
      this.formControl.setValue("");
    }
    if (this.inputInfo.inputType === 'number' && this.formControl.value < 0) {
      this.formControl.setValue(0); // Reset to 0 or any other default value
    }
  }

  checkCustomValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined) {
        // Check if the input type is 'number' and if the value is negative
        if (this.inputInfo.inputType === 'number' && control.value < 0) {
          return { 'negativeNumber': true }; // Validation error for negative number
        }

        // Existing custom validator check
        if (this.customValidator && this.customValidator.activeName != "") {
          return { [this.customValidator.activeName]: true };
        }
      }
      return null;
    };
  }

  /**
   * this functio emits call on field click
   */
  onClick() {
    this.click.emit();
  }
}
