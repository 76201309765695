import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment.prod";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventNotificationService {

  private baseUrl = environment.url;
  httpOptionsSaved = {
    headers: new HttpHeaders({
      "Content-Type": "*/*",
      Accept: "*/*",
      // "Access-Control-Allow-Origin": `${this.baseUrl}`,
    }),
  };

  constructor(private http: HttpClient,private tokenStorage: TokenStorageService) { }

  getAllEvents(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/home-events`,
      httpOptionsSaved
    );
  }

  // get events for profile 
  getProfileEvents(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/profile-events`,
      httpOptionsSaved
    );
  }
}
