import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, PLATFORM_ID } from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import * as e from "express";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";
import { EventRegistrationInfo } from "src/app/models/event-registration/event-registration";
import { EventMembers } from "src/app/models/event-registration/member-info";
import { EventService } from "src/app/services/event.service";
import { ProfileService } from "src/app/services/profile.service";

@Component({
  selector: "app-event-registration",
  templateUrl: "./event-registration.component.html",
  styleUrls: ["./event-registration.component.css"],
})
export class EventRegistrationComponent {
  eventRegistrationInfo: EventRegistrationInfo = new EventRegistrationInfo();
  eventRegistrationData: EventRegistrationInfo = new EventRegistrationInfo();
  changeFlag = false;
  counterInputs = 0;
  addMemberFlag: boolean = false;
  membersArray: any = [];

  inputInfo: InputInfo[] = [];
  memberInputInfo: InputInfo[] = [];
  slug: string;
  extraMembersCounter: number = 0;
  numberOfExtraMembers;
  feeExtraMember = 0;

  genderOption = [];
  maritalOption = [];
  memberRelationship = [];
  isBrowser: Boolean = false;
  isLoading: boolean;
  isUserUk: boolean;
  isEventApplied: boolean = false;
  isEligible: boolean;

  eventInfo: any = {};

  constructor(
    private messageService: MessageServiceService,
    private profileService: ProfileService,
    private Jarvis: AuthService,
    private eventService: EventService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isLoading = true;
    this.route.queryParams.subscribe((params) => {
      this.slug = params["slug"];
      this.eventService.getEvent(this.slug).subscribe(
        (data) => this.handleEventData(data),
        (error) => this.handleError(error)
      );
    });
  }

  handleEventData(data) {
    if (data) {
      this.eventInfo["event"] = data;
      this.eventRegistrationData.eventId = data.id;

      this.eventRegistrationData.totalFee =
        typeof data.fee !== "undefined" ? data.fee : 0;
      this.numberOfExtraMembers =
        typeof data.extra_members !== "undefined" ? data.extra_members : 0;
      this.feeExtraMember =
        typeof data.fee_extra_member !== "undefined"
          ? data.fee_extra_member
          : 0;
      this.getOptions();


    }
  }

  getOptions() {
    this.profileService.getAllOptions().subscribe(
      (data) => this.handleOptions(data),
      (error) => this.handleError(error)
    );
  }
  handleOptions(data) {
    this.genderOption = [];
    this.maritalOption = [];
    this.memberRelationship = [
      { name: "Mother" },
      { name: "Father" },
      { name: "Sister" },
      { name: "Brother" },
      { name: "Aunt" },
      { name: "Uncle" },
      { name: "Friend" },
    ];

    for (let [key, value] of Object.entries(data.data.GenderOptions)) {
      this.genderOption.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.MaritalOptions)) {
      this.maritalOption.push({
        value: key,
        name: value.toString(),
      });
    }

    this.getOwnProfileData();
  }

  getOwnProfileData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    this.initializingData(data);
  }

  initializingData(data) {
    this.eventInfo["userData"] = data.data;
    const userData = data.data.user;
    const profileData = data.data.profile;

    this.eventRegistrationData.fullName = userData.full_name;
    this.eventRegistrationData.city = userData.city;
    this.eventRegistrationData.state = userData.state;
    this.eventRegistrationData.country = userData.country;
    this.eventRegistrationData.gender = this.genderOption.find(
      (el) => el.value == userData.gender
    );

    let timeDiff = Math.abs(
      Date.now() - new Date(userData.date_of_birth).getTime()
    );
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    this.eventRegistrationData.age = age.toString();

    this.eventRegistrationData.relationshipStatus = this.maritalOption.find(
      (el) => el.value == profileData.relationship_status
    );
    if (profileData.children != null || profileData.children == "") {
      this.eventRegistrationData.children = profileData.children;
    } else {
      this.eventRegistrationData.children = null;
    }
    this.generateFormData();
    this.Jarvis.eventApllied(this.eventRegistrationData.eventId).subscribe(
      (data) => this.handleEventApllied(data),
      (error) => this.handleError(error)
    );
  }

  handleEventApllied(data) {
    this.eventInfo["eventApplied"] = data.data;
    this.isUserUk = data.data.isUserUk;
    this.isEventApplied = data.data.eventApplied ? true : false;
    if (data.data.age_group != "") {
      this.isEligible = true;
    } else {
      this.isEligible = false;
    }

    this.isLoading = false;
  }

  generateFormData() {
    this.createInput(
      ``,
      `fullName`,
      `text`,
      `Full Name`,
      `disabled`,
      this.eventRegistrationData.fullName
        ? this.eventRegistrationData.fullName
        : ``,

      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your first name`,
        },
      ],
      true
    );
    this.createInput(
      ``,
      `gender`,
      `text`,
      `Gender`,
      `profileCreateDropdown`,
      this.eventRegistrationData.gender
        ? this.eventRegistrationData.gender
        : ``,
      this.genderOption,

      [
        {
          type: Validators.required,
          msg: `You must enter your gender`,
        },
      ],
      true
    );
    this.createInput(
      ``,
      `age`,
      `text`,
      `Age`,
      `profileCreate`,
      this.eventRegistrationData.age ? this.eventRegistrationData.age : ``,
      this.genderOption,
      [
        {
          type: Validators.required,
          msg: `You must enter your gender`,
        },
      ],
      true
    );
    this.createInput(
      ``,
      `relationshipStatus`,
      `text`,
      `Marital Status`,
      `profileCreateDropdown`,
      this.eventRegistrationData.relationshipStatus
        ? this.eventRegistrationData.relationshipStatus
        : ``,
      this.maritalOption,

      [
        {
          type: Validators.required,
          msg: `You must enter your marital status`,
        },
      ], true

    );

    if (this.eventRegistrationData.relationshipStatus.name != "Single") {


      this.createInput(
        ``,
        `children`,
        `number`,
        `Children`,
        `profileCreate`,
        this.eventRegistrationData.children
          ? this.eventRegistrationData.children
          : ``,
        [],
        [
          // {
          //   type: Validators.required,
          //   msg: `You must enter your marital status`,
          // },
        ], true
      );
    }
    this.createInput(
      ``,
      `city`,
      `text`,
      `City`,
      `profileCreate`,
      this.eventRegistrationData.city ? this.eventRegistrationData.city : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter event city`,
        },
      ],
      true
    );
    this.createInput(
      ``,
      `state`,
      `text`,
      `State`,
      `profileCreate`,
      this.eventRegistrationData.state ? this.eventRegistrationData.state : ``,
      [],
      [
        {
          type: Validators.required,
          msg: `You must enter your marital status`,
        },
      ],
      true
    );
    this.createInput(
      ``,
      `totalFee`,
      `number`,
      `Total Fee`,
      `profileCreate`,
      typeof this.eventRegistrationData.totalFee !== "undefined"
        ? this.eventRegistrationData.totalFee
        : 0,
      [],
      [
        // {
        //   type: Validators.required,
        //   msg: `You must enter your marital status`,
        // },
      ],
      true
    );
  }

  generateMembers() {
    if (this.extraMembersCounter < this.numberOfExtraMembers) {
      let obj1 = this.createMemberInput(
        ``,
        `Name`,
        `text`,
        `Full Name`,
        `profileCreate`,
        ``,
        [],
        [
          {
            type: Validators.required,
            msg: `You must enter name`,
          },
        ]
      );
      let obj2 = this.createMemberInput(
        ``,
        `Relation`,
        `text`,
        `Relationship`,
        `profileCreateDropdown`,
        ``,
        this.memberRelationship,
        [
          {
            type: Validators.required,
            msg: `You must enter your relationship`,
          },
        ]
      );
      this.membersArray.push({ name: obj1, relation: obj2 });
      this.extraMembersCounter++;
    }
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    option,
    validatorsInfo,
    disabled?,
    messageBody?,
    data?
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.option = option;
    inputObj.validatorsInfo = validatorsInfo;
    inputObj.disabled = disabled;
    inputObj.messageBody = messageBody;
    inputObj.data = data;
    this.inputInfo.push(inputObj);
  }

  createMemberInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    option,
    validatorsInfo,
    disabled?,
    messageBody?,
    data?
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.option = option;
    inputObj.validatorsInfo = validatorsInfo;
    inputObj.disabled = disabled;
    inputObj.messageBody = messageBody;
    inputObj.data = data;
    // this.memberInputInfo.push(inputObj);
    return inputObj;
  }

  updateData(value, modelName, dropDown, index) {
    if (index == undefined) {
      this.eventRegistrationInfo[modelName] =
        dropDown && dropDown == true
          ? value?.value
          : value?.value
            ? value.value
            : value;
    } else {
      if (!this.eventRegistrationInfo.members[index]) {
        this.eventRegistrationInfo.members[index] = {
          name: "",
          relationship: "",
        };
      }

      if (modelName == "Relation") {
        this.eventRegistrationInfo.members[index].relationship = value?.name;
      }
      if (modelName == "Name") {
        this.eventRegistrationInfo.members[index].name = value;
      }
    }
  }

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }
  onSubmit() {
    this.counterInputs++;
    if (
      this.counterInputs <
      this.inputInfo.length + this.membersArray.length * 2
    ) {
      return;
    }

    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    let membersErrorFlag = false;
    for (let arr of this.membersArray) {
      for (const key in arr) {
        if (arr.hasOwnProperty(key)) {
          const obj = arr[key];
          membersErrorFlag = obj.errorFlag == true;
          if (membersErrorFlag == true) {
            return;
          }
        }
      }
    }

    if (errorFlag) {
      return;
    }

    let formData = new FormData();
    formData.append("EventForm[name]", this.eventRegistrationInfo.fullName);
    formData.append("EventForm[age]", this.eventRegistrationInfo.age);
    formData.append(
      "EventForm[relationship_status]",
      this.eventRegistrationInfo.relationshipStatus
    );
    formData.append(
      "EventForm[children]",
      this.eventRegistrationInfo.children
        ? this.eventRegistrationInfo.children
        : "0"
    );
    formData.append("EventForm[city]", this.eventRegistrationInfo.city);
    formData.append("EventForm[state]", this.eventRegistrationInfo.state);
    formData.append("EventForm[gender]", this.eventRegistrationInfo.gender);
    for (let i = 0; i < this.eventRegistrationInfo.members.length; i++) {
      const member = this.eventRegistrationInfo.members[i];
      formData.append(`extraName[${i}]`, member.name);
      formData.append(`extraRelation[${i}]`, member.relationship);
    }

    let eventData = {
      formData: formData,
      id: this.eventRegistrationData.eventId,
    };

    this.messageService.addMessages(`info`, `Info`, `Please Wait...`);

    this.Jarvis.eventRegistration(eventData).subscribe(
      (data) => this.handleEventRegistration(data),
      (error) => this.handleError(error)
    );
  }

  handleEventRegistration(data) {
    if (data.status == 200) {
      this.messageService.addMessages(
        `success`,
        `Success`,
        `Make Payment to complete registration`
      );
      if (data.data.url) {
        if (this.isBrowser) {
          window.open(data.data.url, "_self");
        }
      } else {
      }
    }
  }

  membersButtonClicked(index, buttonName) {
    if (buttonName == "add") {
      this.generateMembers();
    }
    if (buttonName == "remove") {
      this.membersArray.splice(index, 1);
      this.extraMembersCounter--;
    }
    this.getPrice();
  }

  addMembers() {
    this.addMemberFlag = !this.addMemberFlag;
    if (this.addMemberFlag == true) {
      this.generateMembers();
    } else {
      this.membersArray = [];
      this.eventRegistrationInfo.members = [];
      this.extraMembersCounter = 0;
    }
    this.getPrice();
  }

  getPrice() {
    let price = this.eventRegistrationData.totalFee ?? "0";
    let field = this.inputInfo.find(a => a.modelName == "totalFee");
    if (field) {
      field.control.setValue(parseFloat(price) + (this.feeExtraMember * this.membersArray.length));
    }

  }

  handleError(error) { }
}

