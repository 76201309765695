import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.css"],
})
export class RatingComponent implements OnInit {
  @Input() score: number;
  @Input() type: string;
  @Input() stars: number;
  constructor() {}

  ngOnInit(): void {}
}
