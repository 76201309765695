<div class="mt-3" *ngIf="this.inputInfo.type == 'text'">
  <textarea [rows]="4" [cols]="50" pInputTextarea class="col-md-12 txt-box" autoResize="true"
    [formControl]="this.formControl" placeholder="{{ this.inputInfo.placeHolder }}"></textarea>
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
    }}</small>
</div>
<div class="" *ngIf="this.inputInfo.type == 'textArea'">
  <textarea [rows]="4" [cols]="50" [style.width]="width" [style.border-radius]="borderRadius"
    [style.font-size]="fontSize" [style.font-family]="fontFamily" [style.color]="color" pInputTextarea
    class="col-md-12 txt-box" autoResize="true" [formControl]="this.formControl"
    placeholder="{{ this.inputInfo.placeHolder }}"></textarea>
  <p-progressBar class="barLoc" *ngIf="this.value" [value]="this.value" [showValue]="false"></p-progressBar>
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
    }}</small>
</div>