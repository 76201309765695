import { TokenStorageService } from "./token-storage.service";
import { LoginInfo } from "./../models/login-info";
// import { User } from './../models/user';
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { JwtResponse } from "./jwt-response";
import { environment } from "src/environments/environment.prod";
/**
 * it is a variable of constant type that defines the api of request header and also get the meta data
 */
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "https://sms.innovacontents.com/api/",
    "Access-Control-Allow-Origin": environment.url,
  }),
};
/**
 * It is a Decorator that marks a class as available to be provided and injected as a dependency.
 */
@Injectable({
  providedIn: "root",
})
export class AuthService {
  /**
   * It is a url of an api
   */
  private baseUrl = environment.url;

  /**
   * This is our constructor
   * @param http object of http client
   * @param tokenStorage parameter of token storage service
   */
  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) { }

  /**
   * This function is used to login, it stores user login credentials
   * @param credentials this parameter stores and passes user credentials
   * @returns
   */
  public authenticate(credentials: any): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        // _csrf:
        //   "mS7b93o3rlo4zwlEJ3ze-BehmLoOe_Vc25gXbl-2Vr7BcbiBHGPBImqkZBVTDOi1XNap7XsDkTDtr14Lcuc40g==",
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/authorize`,
      credentials,
      httpOptionsSaved
    );
  }

  // social login
  public socialLogin(data: any): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        // "access-token": this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/social-login`,
      data,
      httpOptionsSaved
    );
  }

  public signUp(credentials: LoginInfo): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        // "access-token": this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/register`,
      credentials,
      httpOptionsSaved
    );
  }
  public logout(): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/logout`,
      httpOptionsSaved
    );
  }
  public eventApllied(id: string): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/event-applied?id=${id}`,
      httpOptionsSaved
    );
  }
  public submit(data): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/`,
      data,
      httpOptionsSaved
    );
  }

  public verifyLocation(): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/approve_location`,
      httpOptionsSaved
    );
  }

  public getPhonetoken(): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/send-phone-token`,
      httpOptionsSaved
    );
  }
  public verifyPhoneNo(data: FormData): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/verify-phone-otp`,
      data,
      httpOptionsSaved
    );
  }

  public sendEmailToken(): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/send-email-token`,
      httpOptionsSaved
    );
  }
  public verifyEmail(data: FormData): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/verify-email-otp`,
      data,
      httpOptionsSaved
    );
  }

  public verifyDocuments(data: FormData): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        // Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/manual-upload`,
      data,
      httpOptionsSaved
    );
  }

  public searchData(data): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/`,
      data,
      httpOptionsSaved
    );
  }
  // /**
  //  * This function is used to sign up user, it stores user sign up credentials
  //  * @param credentials this parameter stores and passes user credentials
  //  * @returns
  //  */
  // public signUp(credentials: LoginInfo): Observable<JwtResponse> {
  //   const httpOptionsSaved = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  // "Access-Control-Allow-Origin": `${this.baseUrl}`,
  //       "x-access-token": this.tokenStorage.getToken(),
  //     }),
  //   };
  //   return this.http.post<JwtResponse>(
  //     `${this.baseUrl}/users/register`,
  //     credentials,
  //     httpOptionsSaved
  //   );
  // }

  // get Own subscription plans
  mySubscriptionPlan(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/plans`,
      httpOptionsSaved
    );
  }

  // For Subscription Page
  public subscription(data): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/upgrade?radio_3=${data.radio_3}&PlanInvoice[membership_id]=${data.plan}`,
      data,
      httpOptionsSaved
    );
  }


  // apply promo code functionality  
  public applyPromoCode(data): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/promotion-apply`,
      data,
      httpOptionsSaved
    );
  }

  // get subscription plans
  subscriptionPlans(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/plan`,
      httpOptionsSaved
    );
  }

  // get location details 
  private apiUrl = 'https://services.leadconnectorhq.com/funnels/funnel/geo-location/';

  getLocationDetails(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.apiUrl, { headers });
  }

  // otp
  public verifyOtp(data): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/otp`,
      data,
      httpOptionsSaved
    );
  }

  // resend otp
  public resendOtpCode(data): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/resendcode`,
      data,
      httpOptionsSaved
    );
  }

  // deactivate / delete account
  public disableAccount(data: FormData): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/user-deactivate`,
      data,
      httpOptionsSaved
    );
  }
  // Reactivate account
  public reactivateAccount(data: FormData): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<JwtResponse>(
      `${this.baseUrl}/site-api/user-reactivate`,
      data,
      httpOptionsSaved
    );
  }

  //  =========================== profile APIs start ===========================

  // get own profile data
  public getOwnProfileData(): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/me`,
      httpOptionsSaved
    );
  }

  // get profile data
  public getProfileData(): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/profile`,
      httpOptionsSaved
    );
  }

  // get others profile data
  public getOtherProfileData(id): Observable<JwtResponse> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<JwtResponse>(
      `${this.baseUrl}/site-api/other-profile?id=${id}`,
      httpOptionsSaved
    );
  }

  // edit profile
  editProfile(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/edit-profile`,
      data,
      httpOptionsSaved
    );
  }

  // contact form
  contactForm(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/contact`,
      data,
      httpOptionsSaved
    );
  }

  // request profile
  requestProfile(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/request-profile?id=${data.id}`,
      httpOptionsSaved
    );
  }

  // block profile
  blockProfile(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/block`,
      data,
      httpOptionsSaved
    );
  }
  unBlockProfile(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/unblock`,
      data,
      httpOptionsSaved
    );
  }
  // accept/reject request
  acceptOrReject(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/request?id=${data.id}&state=${data.state}`,
      httpOptionsSaved
    );
  }
  // change Image
  changeImage(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/change-image`,
      data,
      httpOptionsSaved
    );
  }
  // upload Image
  uploadImage(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/file-upload?modelId=${data.id}&modelType=app%5Cmodels%5CUser`,
      data.formData,
      httpOptionsSaved
    );
  }

  // set Image as profile picture
  setProfileImage(id: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/select-profile/${id}`,
      httpOptionsSaved
    );
  }

  // delete image
  deleteImage(id: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/file-delete?id=${id}`,
      httpOptionsSaved
    );
  }

  // add alim refrence
  addAlimReference(data: FormData): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/alim-reference-add`,
      data,
      httpOptionsSaved
    );
  }

  // add or remove person from shortlist
  shortlistToggle(formData: FormData): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/add-to-wishlist`,
      formData,
      httpOptionsSaved
    );
  }

  // show shortlist
  myShortlist(page: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/wishlist?page=${page}`,
      httpOptionsSaved
    );
  }
  // delete the identification document
  documentDelete(id): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/file-delete?id=${id}`,
      httpOptionsSaved
    );
  }

  //  =========================== profile APIs end ===========================

  //  =========================== recover/ reset APIs start ===========================

  // recover with token sending to mail
  recover(formData: FormData): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/recover`,
      formData,
      httpOptionsSaved
    );
  }

  // recover without token sending to mail
  recoverWithoutEmail(formData: FormData): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/recover-token`,
      formData,
      httpOptionsSaved
    );
  }

  // recover Password
  recoverPassword(resetData: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/recover-password?token=${resetData.activation_key}`,
      resetData.formData,
      httpOptionsSaved
    );
  }

  // reset Password
  resetPassword(resetData: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/reset-password?token=${resetData.activation_key}`,
      resetData.formData,
      httpOptionsSaved
    );
  }

  // protect/unprotect profile
  protectProfile(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/protect-profile`,
      data,
      httpOptionsSaved
    );
  }

  //  =========================== recover/ reset APIs end ===========================

  //  =========================== Notification APIs start ===========================

  // getting all notifications count
  notificationsCount(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/notify`,
      httpOptionsSaved
    );
  }

  // getting all notifications
  notificationsList(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/list-notifications`,
      httpOptionsSaved
    );
  }

  // reading all notifications
  readAllNotifications(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/read-notifications`,
      httpOptionsSaved
    );
  }

  //  =========================== Notification APIs end ===========================

  //  =========================== Chat APIs start ===========================

  // getting unread messages count
  messagesCount(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/notify-msg`,
      httpOptionsSaved
    );
  }

  // getting chat by Id
  chatById(id: number): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/chat-message?id=${id}`,
      httpOptionsSaved
    );
  }
  chatsList(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/chats`,
      httpOptionsSaved
    );
  }

  chatSearch(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl}/site-api/chat-search`,
      httpOptionsSaved
    );
  }

  sendMessage(data: FormData): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        // "Content-Type":
        //   "multipart/form-data",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/send-message`,
      data,
      httpOptionsSaved
    );
  }

  //  =========================== Chat apis end ===========================

  //  =========================== Event apis start ===========================

  // event registration api
  eventRegistration(data): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.post<any>(
      `${this.baseUrl}/site-api/event-apply/${data.id}`,
      data.formData,
      httpOptionsSaved
    );
  }

  //  =========================== Event apis end ===========================
}
