import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { InputInfo } from "../../componentModel/input-info";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent implements OnInit {
  darkModeFlag = false;
  constructor() {}
  darkMode() {
    if (this.darkModeFlag == true) {
    } else {
    }
  }
  @Input() rowsPerPage: string;
  @Input() pageNumber: string;
  @Input() totalRecord: string;
  @Input() type: string;
  @Output() updateData = new EventEmitter<string>();
  @Input() inputInfo: InputInfo;
  @Output() nextPage = new EventEmitter();
  formControl = new FormControl("", []);
  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.updateData.emit(value);
    });
  }
  getError() {
    var err = [];
    if (this.formControl.errors) {
      err = Object.keys(this.formControl.errors);
    }
    if (err.length > 0 && this.inputInfo.validatorsInfo.length > 0) {
      this.inputInfo.errorFlag = true;
      let val = this.inputInfo.validatorsInfo.find(
        (item) => item.type.name == err[0]
      );
      if (val) {
        return val.msg;
      } else {
        let val = this.inputInfo.validatorsInfo.find(
          (item) => item.name == err[0]
        );
        if (val) {
          return val.msg;
        }
      }
    } else {
      this.inputInfo.errorFlag = false;
    }
    return "";
  }
  paginate(event) {
    this.nextPage.emit(event);
  }
}
