import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";
import { ChatMessage } from "src/app/models/chat-message";
import { interval } from "rxjs";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.css"],
})
export class MessagesComponent implements OnInit {
  selectedConversation;

  currentUser = {
    id: 1,
    name: "faizan",
    avatarUrl: "../assets/images/hannah-nicollet-JQ2D4I-2eyw-unsplash.jpg",
    isOnline: true,
  };
  messageDialog: boolean = false;
  messageDialogData: any = {
    message: "",
    header: "",
  };
  messages = {
    conversations: [
      // {
      //   conversationId: 1,
      //   friendId: 2,
      //   friendName: "Muhammad Umar",
      //   residency: "London",
      //   avatarUrl: "./assets/images/Umar.jpg",
      //   isOnline: true,
      //   lastMessage: "",
      //   lastMessageTime: "",
      //   messages: [
      //     {
      //       messageId: 1,
      //       senderId: 1,
      //       messageText: "Hey, how are you doing?",
      //       timestamp: "2023-05-04T10:30:00Z",
      //     },
      //     {
      //       messageId: 2,
      //       senderId: 2,
      //       messageText: "I'm good, thanks for asking. How about you?",
      //       timestamp: "2023-05-04T10:31:00Z",
      //     },
      //     {
      //       messageId: 3,
      //       senderId: 1,
      //       messageText: "I'm doing well too. What have you been up to?",
      //       timestamp: "2023-05-04T10:32:00Z",
      //     },
      //     {
      //       messageId: 4,
      //       senderId: 2,
      //       messageText:
      //         "Not much, just working on a project for work. How about you?",
      //       timestamp: "2023-05-04T10:33:00Z",
      //     },
      //     {
      //       messageId: 5,
      //       senderId: 1,
      //       messageText: "Same here, just trying to stay productive.",
      //       timestamp: "2023-05-04T10:34:00Z",
      //     },
      //     {
      //       messageId: 6,
      //       senderId: 2,
      //       messageText:
      //         "Yeah, it's important to stay focused. Have you tried any new hobbies lately?",
      //       timestamp: "2023-05-04T10:35:00Z",
      //     },
      //     {
      //       messageId: 7,
      //       senderId: 1,
      //       messageText:
      //         "Actually, I've been taking some cooking classes. It's been really fun!",
      //       timestamp: "2023-05-04T10:36:00Z",
      //     },
      //     {
      //       messageId: 8,
      //       senderId: 2,
      //       messageText:
      //         "That's awesome! What kind of dishes have you learned to cook?",
      //       timestamp: "2023-05-04T10:37:00Z",
      //     },
      //     {
      //       messageId: 8,
      //       senderId: 1,
      //       messageText: "I have learned to make pie.",
      //       timestamp: "2023-05-04T10:36:00Z",
      //     },
      //     {
      //       messageId: 9,
      //       senderId: 2,
      //       messageText:
      //         "Thats sounds great. Now if you excuse me i have dishes to wash",
      //       timestamp: "2023-05-04T10:37:00Z",
      //     },
      //   ],
      // },
      // {
      //   conversationId: 2,
      //   friendId: 3,
      //   friendName: "Zara",
      //   residency: "Karachi",
      //   avatarUrl:
      //     "./assets/images/muhammad-ruqi-yaddin-L9ZVO0K0sco-unsplash.jpg",
      //   isOnline: false,
      //   messages: [
      //     {
      //       messageId: 1,
      //       senderId: 1,
      //       messageText: "Hey, how's it going?",
      //       timestamp: "2023-05-04T12:00:00Z",
      //     },
      //     {
      //       messageId: 2,
      //       senderId: 3,
      //       messageText:
      //         "It's going pretty well, thanks for asking. How about you?",
      //       timestamp: "2023-05-04T12:01:00Z",
      //     },
      //     {
      //       messageId: 3,
      //       senderId: 1,
      //       messageText:
      //         "I'm doing alright, just trying to finish up this report. How's the weather where you are?",
      //       timestamp: "2023-05-04T12:02:00Z",
      //     },
      //   ],
      // },
    ],
  };
  messagesCopy = {
    conversations: [],
  };
  inputInfo: InputInfo[] = [];
  messageInfo: ChatMessage = new ChatMessage();
  changeFlag: boolean = false;
  counterInputs: number = 0;

  optionsMenu: MenuItem[];
  editMenu: MenuItem[];
  linkMenu: MenuItem[];
  picsMenu: MenuItem[];
  emojiMenu: MenuItem[];

  myId: string;
  myFullName: string;
  isMobileView: boolean;
  showMessage: boolean = false;
  isUserSubscribed: boolean = false;
  isBrowser: Boolean = false;
  initialLoad = true;
  alreadySelected;
  alreadySelectedFlagAndId: any = {};
  messageFromParam = false;
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (this.isBrowser) {
      const width = event.target.innerWidth;
      this.isMobileView = width < 769;
    }
    this.mobileMessageView();
  }
  messageFlag = false;
  globalSearchMessages: string = "";

  constructor(
    private messageService: MessageService,
    private messageService2: MessageServiceService,
    private Jarvis: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.initialLoad = true;
    this.isBrowser = isPlatformBrowser(platformId);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && Object.keys(params).length > 0) {
        const id = params["id"];
        this.messageFromParam = true;
        this.messages.conversations = [];
        // interval(5000).subscribe((x) => {
        if (this.initialLoad == false) {
          this.chatsInit(id);
        }
        // });
        if (this.initialLoad == true) {
          this.chatsInit(id);
          this.initialLoad = false;
        }
      } else {
        interval(5000).subscribe((x) => {
          if (this.initialLoad == false) {
            this.chatsInit();
          }
        });
        if (this.initialLoad == true) {
          this.chatsInit();
          this.initialLoad = false;
        }
      }
    });
  }

  ngOnInit(): void {
    // Set initial value of isMobileView based on window width
    this.getOwnProfileData();
    if (this.isBrowser) {
      const width = window.innerWidth;
      this.isMobileView = width < 769;
    }

    this.optionsMenu = [
      {
        label: "Options",
        items: [
          {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {
              this.update();
            },
          },
          {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
              this.delete();
            },
          },
        ],
      },
    ];
    this.editMenu = [
      {
        label: "Options",
        items: [
          {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {
              this.update();
            },
          },
          {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
              this.delete();
            },
          },
        ],
      },
    ];
    this.linkMenu = [
      {
        label: "Options",
        items: [
          {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {
              this.update();
            },
          },
          {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
              this.delete();
            },
          },
        ],
      },
    ];
    this.picsMenu = [
      {
        label: "Options",
        items: [
          {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {
              this.update();
            },
          },
          {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
              this.delete();
            },
          },
        ],
      },
    ];
    this.emojiMenu = [
      {
        label: "Options",
        items: [
          {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {
              this.update();
            },
          },
          {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
              this.delete();
            },
          },
        ],
      },
    ];

    this.generateFormData();
    this.getLastMessageInfo();
    this.mobileMessageView();
    this.selectedConversation;
  }

  getOwnProfileData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    this.isUserSubscribed =
      data.data.user.membership_type != null ? true : false;
    this.myId = data.data.user.id;
    this.myFullName = data.data.user.full_name;
  }

  generateFormData() {
    this.inputInfo = [];
    this.createInput(
      "",
      "searchMessages",
      "text",
      "Search Conversation",
      "searchMessage",
      "",
      "",
      []
    );
    this.createInput(
      "",
      "messageInput",
      "text",
      "Type a message here",
      "messageInput",
      "",
      "",
      []
    );
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    data,
    validatorsInfo
  ) {
    let inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.val = value;
    inputObj.data = data;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  chatsInit(id?) {
    this.Jarvis.chatSearch().subscribe(
      (data) => this.handleChatsList(data, id),
      (error) => this.handleError(error)
    );
    // this.Jarvis.chatsList().subscribe(
    //   (data) => this.handleChatsList(data, id),
    //   (error) => this.handleError(error)
    // );
  }

  handleChatsList(data, id) {
    // const dataArray = Object.values(data);
    if (data.list) {
      const dataArray = data.list;
      this.messages.conversations = [];
      for (let info of dataArray) {
        this.messages.conversations.push({
          conversationId: info["id"],
          friendId: info["id"],
          friendName: info["full_name"],
          residency: info["country"],
          avatarUrl: info["profile_file"],
          isOnline: info["online"],
          blocked: info["blocked"],
          lastActivity: info["last_activity"],
          lastMessage:
            info["lastMessage"] && this.isUserSubscribed
              ? info["lastMessage"].message
              : "",
          lastMessageTime: info["lastMessage"]
            ? info["lastMessage"].created_on
            : "",
          messages: [],
          unread: info["unread"] == "" || info["unread"] == null ? false : true,
        });
      }

      this.sortChatList();

      this.messagesCopy = JSON.parse(JSON.stringify(this.messages));
    }

    if (id != undefined) {
      this.getChatMessages(id, "byId");
    }

    if (this.globalSearchMessages != "") {
      this.messages.conversations = [];
      for (let convo of this.messagesCopy.conversations) {
        let name = convo.friendName.toLowerCase();
        let search = this.globalSearchMessages.toLowerCase();
        if (name.includes(search)) {
          this.messages.conversations.push(convo);
        }
      }
    } else {
      this.messages = JSON.parse(JSON.stringify(this.messagesCopy));
    }
  }

  sortChatList() {
    this.messages.conversations.sort((a, b) => {
      return (
        <any>new Date(b.lastMessageTime) - <any>new Date(a.lastMessageTime)
      );
    });
  }

  handleChatById(data) {
    if (
      this.messages.conversations.find(
        (convo) => convo.friendId == data.data.toUser.id
      ) == undefined
    ) {
      this.messages.conversations.unshift({
        conversationId: data.data.toUser.id,
        friendId: data.data.toUser.id,
        friendName: data.data.toUser.full_name,
        residency: data.data.toUser.country,
        avatarUrl: data.data.toUser.profile_file,
        blocked: data.data.toUser["blocked"],
        isOnline: data.data.toUser.online,
        lastActivity: data.data.toUser.last_activity,
        lastMessage: "",
        lastMessageTime: "",
        messages: [],
      });
    }
    this.messagesCopy = JSON.parse(JSON.stringify(this.messages));
    // this.chatsInit();
    this.alreadySelectedFlagAndId = {
      alreadySelectedFlag: true,
      id: data.data.toUser.id,
    };
  }

  updateData(value, modelName) {
    if (modelName == "messageInput") {
      this.messageInfo.message = value;
    }
    if (modelName == "searchMessages") {
      if (value.length > 0) {
        this.globalSearchMessages = value;
        this.messages.conversations = [];
        for (let convo of this.messagesCopy.conversations) {
          let name = convo.friendName.toLowerCase();
          let search = value.toLowerCase();
          if (name.includes(search)) {
            this.messages.conversations.push(convo);
          }
        }
      } else if (value.length == 0) {
        this.globalSearchMessages = value;
        this.messages = JSON.parse(JSON.stringify(this.messagesCopy));
      }
    }
    if (modelName == "unBlock") {
      this.unblockUser(value);
    }
    if (modelName == "block") {
      this.blockUser(value);
    }
    if (modelName == "back") {
      if (this.isBrowser) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      this.showMessage = false;
    }
  }
  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }
  checkBlockStatus() {
    if (this.selectedConversation.blocked == true) {
      // this.messageDialogData["header"] = "User Blocked";
      // this.messageDialogData["message"] = "User is blocked";
      // this.messageDialog = true;
      // return;
      this.messageService2.addMessages("error", "Error", " User is blocked");
    }
  }
  onSubmit() {
    this.counterInputs++;
    let length =
      this.isMobileView == false
        ? this.inputInfo.length
        : this.inputInfo.length - 1;
    if (this.counterInputs < length) {
      return;
    }
    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }

    if (
      this.messageInfo.message != "" &&
      this.selectedConversation.blocked != true
    ) {
      this.initSendingMessage();
    }
  }

  initSendingMessage() {
    const formData = new FormData();
    formData.append(
      "Chatmessage[to_user_id]",
      this.selectedConversation.friendId
    );
    formData.append(
      "Chatmessage[to_user_name]",
      this.selectedConversation.friendName
    );
    formData.append("Chatmessage[from_user_id]", this.myId);
    formData.append("Chatmessage[from_user_name]", this.myFullName);
    formData.append("Chatmessage[message]", this.messageInfo.message);

    this.Jarvis.sendMessage(formData).subscribe(
      (data) => this.handleSendMessage(data),
      (error) => this.handleError(error)
    );
  }

  handleSendMessage(data) {
    if (data.status == 200 && !data.hasOwnProperty("error")) {
      this.getChatMessages(this.selectedConversation.friendId);
      this.chatsInit();
    }

    if (data.status == 1000 && data.hasOwnProperty("error")) {
      let err = "";
      if (data.error.includes("blocked")) {
        err = data.error.match(/[^.!?]*(?:[.!?]|$)/)[0];
        this.messageDialogData["header"] = "User Blocked";
        this.messageDialogData["message"] = err;
      } else {
        err = data["error"].replace("/site/plan", "/user/plans");
        err = data["error"].replace("/api/site/plan", "/user/plans");
        this.messageDialogData["header"] = "Subscription Required";
        this.messageDialogData["message"] = err;
      }

      this.messageDialog = true;
    }
  }

  update() {
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Data Updated",
    });
  }

  delete() {
    this.messageService.add({
      severity: "warn",
      summary: "Delete",
      detail: "Data Deleted",
    });
  }
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    if (this.showMessage == true && this.isMobileView == true) {
      this.showMessage = false;
      event.preventDefault();
      if (this.isBrowser) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      history.go(1);
    }
  }
  selectedConvo(value, fromParam) {
    if (fromParam == false) {
      this.router.navigateByUrl("/user/chat/panel");
    } else {
      this.messageFromParam = false;
    }
    this.getChatMessages(value);
    this.selectedConversation = this.messages.conversations.find(
      (obj) => obj.conversationId == value
    );
    if (this.isMobileView) {
      this.showMessage = true;
      // this.getChatMessages(id, "byId");
    }
    // this.messageInfo = new ChatMessage();
    // this.messageInfo.recieverId = this.selectedConversation.friendId;
    // this.messageInfo.recieverName = this.selectedConversation.friendName;
    // this.messageInfo.senderId = this.myId;
    // this.messageInfo.senderName = this.myFullName;
    // this.showMessage = true;
    // this.mobileMessageView();
  }
  getChatMessages(data, from?) {
    this.Jarvis.chatById(data).subscribe(
      (data) => {
        if (from != undefined) {
          this.handleChatById(data);
        } else if (from == undefined) {
          this.handleChatMessages(data, from);
        }
      },

      (error) => this.handleError(error)
    );
  }
  handleChatMessages(data, from?) {
    if (!data.hasOwnProperty("error")) {
      if (data.data.list) {
        let lists = Object.keys(data.data.list);
        let messages = [];
        for (let value of lists) {
          data.data.list[value]["messageId"] = +value;
          data.data.list[value]["senderId"] =
            data.data.list[value].from_user_id;
          data.data.list[value]["messageText"] = data.data.list[value].message;
          data.data.list[value]["timestamp"] = data.data.list[value].time;
          messages.push(data.data.list[value]);
        }
        if (
          this.messages.conversations.find(
            (convo) => convo.friendId == data.data.toUser.id
          )
        ) {
          this.messages.conversations.find(
            (convo) => convo.friendId == data.data.toUser.id
          ).messages = [];
        }

        for (let data2 of messages) {
          this.messages.conversations
            .find((convo) => convo.friendId == data.data.toUser.id)
            .messages.push(data2);
        }
        this.selectedConversation.messages = messages;
      }
    } else {
    }
  }

  mobileMessageView() {
    if (this.showMessage && this.isMobileView) {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "hidden";
    } else {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "auto";
    }
  }

  getLastMessageInfo() {
    for (const conversation of this.messages.conversations) {
      let messages = conversation.messages;
      let lastMsg = messages[messages.length - 1];
      conversation.lastMessage = lastMsg.messageText;
      conversation.lastMessageTime = lastMsg.timestamp;
    }
  }

  onCardClicked(data) {
    if (data.value && data.cardName == "messageProfile") {
      this.router.navigateByUrl(`user/${data.id}`);
    }
  }

  handleError(error) { }
  messageDialogClosed() {
    this.messageDialog = false;
  }
  unblockUser(data) {
    let formData = new FormData();
    formData.append("id", data);
    this.Jarvis.unBlockProfile(formData).subscribe(
      (data) => this.handleUnblockData(data),
      (error) => this.handleError(error)
    );
  }
  blockUser(data) {
    let formData = new FormData();
    formData.append("id", data);
    this.Jarvis.blockProfile(formData).subscribe(
      (data) => this.handleBlock(data),
      (error) => this.handleError(error)
    );
  }
  handleUnblockData(data) {
    this.messageService2.addMessages("success", "Success", data.data.msg);
  }
  handleBlock(data) {
    this.messageService2.addMessages("success", "Success", data.data.msg);
  }
}
