<div class="user-navigation">
  <div #navbar class="navBar">
    <app-navbar
      class="custom-menubar"
      [items]="item"
      [logoPath]="this.logoUrl"
      [accessFrom]="'userNavigation'"
      [ngClass]=""
      [logoSrc]="'user'"
    >
    </app-navbar>
  </div>
  <div class="router-body">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
