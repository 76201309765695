import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { SearchInfo } from "src/app/models/search-info";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.css"],
})
export class FilterComponent implements OnInit {
  @Input() inputInfo: InputInfo[] = [];
  @Input() data: any;
  @Input() type: string;

  @Output() updateData = new EventEmitter<any>();
  /**
   * This in an output from child class to parent class after the button is clicked
   */
  @Output() action = new EventEmitter();

  /**
   * submit call of the text box
   */
  @Output() submit = new EventEmitter();

  counterInputs: number = 0;
  changeFlag: boolean = false;
  filterModel: SearchInfo = new SearchInfo();
  constructor() {}

  ngOnInit(): void {}

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }
  onSubmit() {
    this.counterInputs++;
    if (this.counterInputs < this.inputInfo.length) {
      return;
    }
    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }
    let cleanData = Object.entries(this.filterModel)
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    this.submit.emit(cleanData);
  }

  updateFilterData(value, modelName) {
    if (modelName == "age_from") {
      if (value != null) {
        let tempAgeToArr = JSON.parse(JSON.stringify(this.age_to));
        tempAgeToArr = this.age_to.filter((age) => age.value >= value.value);
        this.inputInfo.find((input) => input.modelName == "age_to").option =
          tempAgeToArr;
        this.inputInfo.find((input) => input.modelName == "age_to").data =
          tempAgeToArr;
      } else {
        let tempAgeToArr = JSON.parse(JSON.stringify(this.age_to));
        this.inputInfo.find((input) => input.modelName == "age_to").option =
          tempAgeToArr;
        this.inputInfo.find((input) => input.modelName == "age_to").data =
          tempAgeToArr;
      }
    }
    let tempArr = [];
    if (
      modelName == "looking_for" ||
      modelName == "is_syed" ||
      modelName == "occupation" ||
      modelName == "ethnicity" ||
      modelName == "country"
    ) {
      if (value != null) {
        this.filterModel[modelName] =
          modelName == "country" ? value.name : value.value;
      } else {
        delete this.filterModel[modelName];
      }
    } else if (value != null && Array.isArray(value)) {
      if (value.length > 0) {
        for (let data of value) {
          // need to change later
          tempArr.push(data.value);
        }
        this.filterModel[modelName] = tempArr;
      } else {
        delete this.filterModel[modelName];
      }
    } else {
      if (value != null) {
        this.filterModel[modelName] = value.name;
      } else {
        delete this.filterModel[modelName];
      }
    }
    // this.filterModel[modelName] = value;
  }
  age_to = [
    // { name: 16, value: 16 },
    // { name: 17, value: 17 },
    { name: 18, value: 18 },
    { name: 19, value: 19 },
    { name: 20, value: 20 },
    { name: 21, value: 21 },
    { name: 22, value: 22 },
    { name: 23, value: 23 },
    { name: 24, value: 24 },
    { name: 25, value: 25 },
    { name: 26, value: 26 },
    { name: 27, value: 27 },
    { name: 28, value: 28 },
    { name: 29, value: 29 },
    { name: 30, value: 30 },
    { name: 31, value: 31 },
    { name: 32, value: 32 },
    { name: 33, value: 33 },
    { name: 34, value: 34 },
    { name: 35, value: 35 },
    { name: 36, value: 36 },
    { name: 37, value: 37 },
    { name: 38, value: 38 },
    { name: 39, value: 39 },
    { name: 40, value: 40 },
    { name: 41, value: 41 },
    { name: 42, value: 42 },
    { name: 43, value: 43 },
    { name: 44, value: 44 },
    { name: 45, value: 45 },
    { name: 46, value: 46 },
    { name: 47, value: 47 },
    { name: 48, value: 48 },
    { name: 49, value: 49 },
    { name: 50, value: 50 },
    { name: 51, value: 51 },
    { name: 52, value: 52 },
    { name: 53, value: 53 },
    { name: 54, value: 54 },
    { name: 55, value: 55 },
    { name: 56, value: 56 },
    { name: 57, value: 57 },
    { name: 58, value: 58 },
    { name: 59, value: 59 },
    { name: 60, value: 60 },
    { name: 61, value: 61 },
    { name: 62, value: 62 },
    { name: 63, value: 63 },
    { name: 64, value: 64 },
    { name: 65, value: 65 },
    { name: 66, value: 66 },
    { name: 67, value: 67 },
    { name: 68, value: 68 },
    { name: 69, value: 69 },
    { name: 70, value: 70 },
    { name: 71, value: 71 },
    { name: 72, value: 72 },
    { name: 73, value: 73 },
    { name: 74, value: 74 },
    { name: 75, value: 75 },
    { name: 76, value: 76 },
    { name: 77, value: 77 },
    { name: 78, value: 78 },
    { name: 79, value: 79 },
    { name: 80, value: 80 },
  ];
}
