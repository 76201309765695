import { VideoService } from "./../../../services/video.service";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MessageServiceService } from "src/app/message.service";
import { VideosPageInfo } from "src/app/models/videos-page-info";

@Component({
  selector: "app-vidoes",
  templateUrl: "./vidoes.component.html",
  styleUrls: ["./vidoes.component.css"],
})
export class VidoesComponent implements OnInit {
  videosPageInfo: VideosPageInfo = {
    pageHeroImage: "video-ss.png",
    title: "Video Lectures",
    description:
      "Watch videos from renowned videos about the importance of marriage.",
    videos: {
      title: "Our Videos",
      videoItem: [],
    },
  };
  constructor(
    private videoService: VideoService,
    private messageService: MessageServiceService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.videoService.getAllVideos().subscribe(
      (data) => this.handleVideos(data),
      (error) => this.handleError(error)
    );
  }

  handleVideos(data) {
    for (let value of data) {
      value.url = this.sanitizer.bypassSecurityTrustHtml(value.url);
    }
    this.videosPageInfo.videos.videoItem = data;
  }
  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error && error.error.reason
        ? error.error.reason
        : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }
}
