<div class="search-result">
  <div class="hero">
    <img class="hero-image" [src]="searchResultInfo.pageHeroImage" alt="" />
    <div class="hero-content">
      <div class="hero-pageTitle">
        <h2>Search Result</h2>
      </div>
    </div>
  </div>

  <div class="sr-main">
    <div class="container-fluid pb-3">
      <div class="row">
        <div class="sr-filters col-md-3">
          <div class="sr-filters-inner p-3">
            <div class="small-img">
              <img [src]="this.userInfo.image" alt="user profile image" />
            </div>
            <div class="user-name">
              <p>{{ this.userInfo.name }}</p>
            </div>
          </div>
          <div class="searchResult-search-box">
            <div
              class="search-box-items"
              *ngFor="let box of this.inputInfo; let i = index"
            >
              <div class="dropDownWidth">
                <app-drop-down
                  [inputInfo]="box"
                  [changeFlag]="changeFlag"
                  [data]="box.data"
                  [scrollHeight]="
                    box.data?.length >= 1 && box.data?.length <= 3
                      ? box.data?.length * 50 + 'px'
                      : '200px'
                  "
                  (updateData)="updateData($event, box.modelName)"
                  (preSubmit)="preSubmit()"
                  (submit)="onSubmit()"
                >
                </app-drop-down>
              </div>
            </div>
            <div class="search-box-btn">
              <app-button
                [label]="'Search'"
                [borderRadius]="'35px'"
                [color]="'#ffffff'"
                [type]="'homeSearch'"
                [bgColor]="'#DD1485'"
                [fontWeight]="'600'"
                [width]="'85%'"
                [height]="'100%'"
                (button)="preSubmit()"
                [fontSize]="'clamp(12px, 4vw, 16px)'"
                iconPos="right"
              >
              </app-button>
            </div>
          </div>
        </div>
        <div class="sr-data col-md-9">
          <ng-container
            *ngFor="let data of searchResultInfo.searchResultCardData"
          >
            <app-card
              [isLoggedIn]="isLogin"
              [type]="'searchResultCard'"
              [cardData]="data"
            ></app-card>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
