<ng-container *ngIf="inputInfo">
  <ng-container *ngFor="let value of inputInfo.value">
    <div class="p-field-checkbox">
      <div class="checkbox-wrapper">
        <p-checkbox
          name="inputInfo.name"
          [value]="value"
          [formControl]="formControl"
          [inputId]="value"
        >
        </p-checkbox>
        <label for="{{ value }}" class="checkbox-label">{{ inputInfo.details }}</label>
        <a href="#">{{ inputInfo.link }}</a>
      </div>
      <small class="p-error" *ngIf="formControl.dirty">{{ getError() }}</small>
    </div>
  </ng-container>
</ng-container>
