import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/auth/auth.service";
import { MessageServiceService } from "src/app/message.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
  userState: number;
  userEmail: string = "";
  isLoading: boolean = true;
  constructor(
    private Jarvis: AuthService,
    private messageService: MessageServiceService
  ) {}
  ngOnInit(): void {
    this.getOwnProfileData();
  }

  getOwnProfileData() {
    this.isLoading = true;
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    this.userState = data.data.user.state_id;
    this.isLoading = false;
  }

  accountDeactivated() {
    this.getOwnProfileData();
  }

  reactiveAccount() {
    this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
    let formData = new FormData();
    formData.append("email", this.userEmail);
    this.Jarvis.reactivateAccount(formData).subscribe(
      (data) => this.handleReactivateAccount(data),
      (error) => this.handleError(error)
    );
  }

  handleReactivateAccount(data) {
    this.messageService.addMessages(`success`, `Success`, data.data.message);
    this.getOwnProfileData();
  }

  handleError(error) {}
}
