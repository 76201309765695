import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Carousel } from "primeng/carousel";
import { Observable } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";
import { MatchCardInfo } from "src/app/models/matchCard-info";
import { SearchInfo } from "src/app/models/search-info";
import { UserInfo } from "src/app/models/user-info";
import { ProfileService } from "src/app/services/profile.service";
import { UserProfileConverterService } from "src/app/services/user-profile-converter.service";

@Component({
  selector: "app-my-shortlist",
  templateUrl: "./my-shortlist.component.html",
  styleUrls: ["./my-shortlist.component.css"],
})
export class MyShortlistComponent implements OnInit {
  inputInfo: InputInfo[] = [];
  searchInfo: SearchInfo = new SearchInfo();
  searchedData: SearchInfo;
  userInfo: UserInfo = new UserInfo();

  page = 1;
  totalCount;
  rowsCount;
  paginatorChangeFlag = false;

  isBrowser: Boolean = false;

  changeFlag = false;
  counterInputs = 0;
  displayFilter: boolean = false;

  dropdownOptions: SearchInfo = new SearchInfo();

  shortlistInfo: MatchCardInfo[];
  shortlistDisplay: MatchCardInfo[];

  isMobileView: boolean;
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (this.isBrowser) {
      const width = event.target.innerWidth;
      this.isMobileView = width < 769;
    }
  }

  constructor(
    private messageService: MessageServiceService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private profileService: ProfileService,
    private Jarvis: AuthService,
    private userProfileConverter: UserProfileConverterService,
    private tokenStorage: TokenStorageService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    Carousel.prototype.onTouchMove = () => { };
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      // Set initial value of isMobileView based on window width
      const width = window.innerWidth;
      this.isMobileView = width < 769;
    }
    this.paginatorChangeFlag = false;
    this.getMyShortlist(this.page);
  }

  getMyShortlist(page) {
    this.Jarvis.myShortlist(page).subscribe(
      (data) => this.handleMyShortlist(data),
      (error) => this.handleError(error)
    );
  }


  getOwnProfileData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    let protectedProfileStatus;
    if (data.data.sentProfileRequest) {
      if (data.data.sentProfileRequest.state_id == 1) {
        protectedProfileStatus = false;
      } else {
        protectedProfileStatus = true;
      }
    } else {
      protectedProfileStatus = true;
    }
    const userData = data.data.user;
    this.userInfo.name = data.data.user.full_name;
    this.userInfo.image = this.userProfileConverter.getProfileImage(
      true,
      userData,
      protectedProfileStatus
    );
  }

  handleMyShortlist(data) {
    this.shortlistInfo = [];
    if (data.pagination && this.paginatorChangeFlag == false) {
      this.totalCount = data.pagination.totalCount;
      this.rowsCount = data.pagination.pageSize;
    }
    if (data.data.length > 0) {
      for (let p of data.data) {
        let protectedProfileStatus;
        if (p.sentProfileRequest) {
          if (p.sentProfileRequest.state_id == 1) {
            protectedProfileStatus = false;
          } else {
            protectedProfileStatus = true;
          }
        } else {
          protectedProfileStatus = true;
        }
        let timeDiff = Math.abs(
          Date.now() - new Date(p.user.date_of_birth).getTime()
        );
        const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        let obj = {
          id: p.user.id,
          image: this.userProfileConverter.getProfileImage(
            false,
            p.user,
            protectedProfileStatus
          ),
          name: p.profile.nickname,
          description: p.user.about_me ? p.user.about_me : "",
          age: age,
          height: p.profile.height ? p.profile.height : "",
          profession: "",
          country: p.user.country,
          address: p.user.address
            ? p.user.address
            : p.user.country + " , " + p.user.city,
          status: p.user.status ? p.user.status : "Unverified",
          gender: p.user.gender == 1 ? "Male" : "Female",
          shortlist: true,
        };

        this.userValueToString("profession", p.profile.profession).subscribe(
          (profession) => {
            obj.profession = profession; // Update the profession property
            this.shortlistInfo.push(obj);
          }
        );
      }
    }
    this.shortlistDisplay = this.shortlistInfo;
  }


  onCardClicked(data) {
    if (data.value && data.cardName == "match&shortListCard") {
      this.router.navigateByUrl(`user/${data.id}`);
    }
  }

  onCardButtonClick(event) {
    if (event.cardButtonName === "messageBtn") {
      const id = event.id;
      this.router.navigate(["user/chat/panel"], {
        queryParams: { id },
      });
    }
    if (event.cardButtonName === "favouriteBtn") {
      let formData = new FormData();
      formData.append("id", event.id);
      this.Jarvis.shortlistToggle(formData).subscribe(
        (data) => this.handleShortlistToggle(data, event.id),
        (error) => this.handleError(error)
      );
    }
  }

  handleShortlistToggle(data, id) {
    if (data.status == 200) {
      const index = this.shortlistInfo.findIndex((el) => el.id == id);
      this.shortlistInfo.splice(index, 1);
      this.messageService.addMessages(
        "success",
        "Success",
        `Person has been ${data.data.message === "added" ? "added to" : "removed from"
        } your wishlist.`
      );
    }
  }

  filterDialogClosed() {
    this.displayFilter = false;
  }
  showFilter() {
    this.displayFilter = true;
  }

  userValueToString(label: string, value: string): Observable<string> {
    return this.userProfileConverter.convertToText(label, value);
  }

  nextRecords(event) {
    this.page = event.page + 1;
    this.paginatorChangeFlag = true;
    this.getMyShortlist(this.page);
    this.shortlistDisplay = [];
  }

  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error && error.error.reason
          ? error.error.reason
          : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }
}
