import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Validators } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { AuthService } from "src/app/auth/auth.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";

@Component({
  selector: "app-deactivate-account",
  templateUrl: "./deactivate-account.component.html",
  styleUrls: ["./deactivate-account.component.css"],
})
export class DeactivateAccountComponent implements OnInit {
  deactivateInputInfo: any = [];
  changeFlag = false;
  otherFlag = false;
  counterInputs = 0;
  deactivateOptions = [
    { key: "No longer looking for marriage partner.", value: "1", id: 1 },
    { key: "Didn‘t like the website", value: "12", id: 2 },
    { key: "Couldn‘t find a good match", value: "13", id: 3 },
    { key: "Want to hide profile for some time period", value: "14", id: 4 },
    { key: "No longer want any notifications", value: "15", id: 5 },
    { key: "Can‘t pay or don‘t want to pay.", value: "16", id: 6 },
    { key: "In talk with the potential match.", value: "24", id: 7 },
    { key: "Other", value: "2", id: 8 },
  ];

  inputInfo: InputInfo[] = [];
  @Output() submited = new EventEmitter();
  constructor(
    private messageService: MessageServiceService,
    private confirmationService: ConfirmationService,
    private Jarvis: AuthService
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  generateForm() {
    this.inputInfo = [];
    this.createInput(
      ``,
      `deactivate`,
      `radioButton`,
      ``,
      `text`,
      "",
      this.deactivateOptions,
      "",
      "",
      [
        {
          type: Validators.required,
          msg: `You must select one option`,
        },
      ]
    );
    this.createInput(
      ``,
      `otherTextArea`,
      `textArea`,
      ``,
      `textArea`,
      "",
      "",
      "",
      "",
      [
        {
          type: Validators.required,
          msg: `You must write the reason`,
        },
      ]
    );
  }

  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    option,
    data,
    val,
    validatorsInfo
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.option = option;
    inputObj.data = data;
    inputObj.value = value;
    inputObj.val = val;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  updateData(event, modelName) {
    if (event) {
      this.deactivateInputInfo[modelName] = event.value;

      if (event.key == "Other") {
        this.otherFlag = true;
      } else {
        this.otherFlag = false;
        const otherTextArea = this.inputInfo.find(
          (el) => el.modelName === "otherTextArea"
        );
        if (otherTextArea) {
          otherTextArea.errorFlag = false;
        }
      }
    } else {
      const foundElement = this.inputInfo.find(
        (el) => el.modelName === "deactivate"
      );
      if (modelName == "deactivate") {
        if (foundElement) {
          foundElement.errorFlag = true;
        }

        this.messageService.addMessages(
          `error`,
          `Error`,
          "You must select one option "
        );
      } else {
        foundElement.errorFlag = false;
      }
    }
  }

  preSubmit() {
    this.counterInputs = 0;
    this.changeFlag = !this.changeFlag;
  }

  onSubmit() {
    this.counterInputs++;
    if (this.otherFlag) {
      if (this.counterInputs < this.inputInfo.length) {
        return;
      }
      const otherTextArea = this.inputInfo.find(
        (el) => el.modelName === "otherTextArea"
      );
      if (otherTextArea.val === "") {
        return;
      }
    } else {
      if (this.counterInputs < this.inputInfo.length - 1) {
        return;
      }
    }

    const errorFlag = this.inputInfo.find((item) => item.errorFlag == true);
    if (errorFlag) {
      return;
    }

    let formData = new FormData();
    formData.append("state_id", "deactivate");
    formData.append("reason_id", this.deactivateInputInfo.deactivate);
    formData.append(
      "other_message",
      this.deactivateInputInfo.deactivate
        ? this.deactivateInputInfo.deactivate
        : ""
    );

    this.confirmationService.confirm({
      message: "Are you sure that you want to deactivate account?",
      accept: () => {
        this.messageService.addMessages(`info`, `Info`, `Please Wait...`);
        this.Jarvis.disableAccount(formData).subscribe(
          (data) => this.handleDisableAccount(data),
          (error) => this.handleError(error)
        );
      },
      reject: () => {
        this.otherFlag = false;
        // this.generateForm();
      },
    });
  }

  handleDisableAccount(data) {
    this.messageService.addMessages(
      `success`,
      `Success`,
      `Profile Deactivated Successfully`
    );
    this.submited.emit();
  }

  handleError(error) {}
}
