import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MenuItem } from "primeng/api";
import { first } from "rxjs/operators";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit, AfterViewInit {
  @Input() items: MenuItem[];
  @Input() menuType: string;

  @ViewChild("menu") menu: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.activeTabInit();
  }

  activeTabInit() {
    if (this.menu) {
      const ul = this.menu.nativeElement.querySelector(".p-menu-list");
      if (ul) {
        const firstLi = ul.querySelector("li:first-child");
        firstLi.classList.add("active-tab");
      }
    }
  }

  tabSelected(event) {
    // console.log("tabSelected", event);
    // Remove the "active-tab" class from all <li> elements
    const allLiElements =
      this.menu.nativeElement.querySelectorAll(".p-menu-list li");
    allLiElements.forEach((li) => {
      li.classList.remove("active-tab");
    });

    // Add the "active-tab" class to the clicked tab's <li> element
    const closestLi = event.srcElement.closest("li");
    closestLi.classList.add("active-tab");
  }
}
