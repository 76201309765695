<div class="readOnly" *ngIf="type == 'readOnly'">
  <p-rating
    [(ngModel)]="score"
    [stars]="stars"
    readonly="true"
    [cancel]="false"
  ></p-rating>
</div>

<div class="readOnlySearchCard" *ngIf="type == 'readOnlySearchCard'">
  <p-rating
    [(ngModel)]="score"
    [stars]="stars"
    readonly="true"
    [cancel]="false"
  ></p-rating>
</div>
