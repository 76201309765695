<div class="profile-images mb-2" *ngIf="type == 'profileImage'">
  <p-galleria
    [(value)]="images"
    [(visible)]="displayCustom"
    [(activeIndex)]="activeIndex"
    [responsiveOptions]="responsiveOptions"
    [numVisible]="7"
    [circular]="true"
    [fullScreen]="true"
    [showItemNavigators]="true"
    [showThumbnails]="false"
    [baseZIndex]="100000"
  >
    <ng-template pTemplate="item" let-item>
      <img
        [src]="item.previewImageSrc"
        style="width: 100%; height: 100vh; display: block; object-fit: contain"
      />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div class="grid grid-nogutter justify-content-center">
        <img [src]="image.thumbnailImageSrc" style="display: block" />
      </div>
    </ng-template>
  </p-galleria>
  <div *ngIf="images" class="grid galleria-images">
    <div
      *ngFor="let image of images; let index = index"
      class="galleria-image"
      key="index"
    >
      <div class="galleria-thumbnails-overlay">
        <ng-container *ngIf="isMyProfile">
          <app-chips
            [chipsData]="filterChips(image.chipData)"
            (chipClicked)="onChipClicked($event)"
          ></app-chips>
        </ng-container>
      </div>

      <img
        [src]="image.thumbnailImageSrc"
        [alt]="image.alt"
        style="cursor: pointer"
        (click)="imageClick(index)"
      />
    </div>
  </div>
</div>
<p-confirmPopup></p-confirmPopup>
