<div class="mt-2" *ngIf="!type">
  <button label="{{ label }}" [style.background]="bgColor" [disabled]="disabled" [style.color]="color"
    [style.width]="width" pButton pRipple (click)="onClick()" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn buttonHover"></button>
</div>
<div class="mt-2 hov login" *ngIf="type == 'login'">
  <button label="{{ label }}" [style.background]="bgColor" [disabled]="disabled" [style.color]="color"
    [style.font-size]="fontSize" [style.font-weight]="fontWeight" [style.background-image]="backgroundImage"
    [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius" [style.padding]="padding"
    [style.margin]="margin" [style.width]="width" [style.max-width]="maxWidth" [style.border]="border" pButton pRipple
    icon="{{ icon }}" iconPos="iconPos" (click)="onClick()" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn buttonHover"></button>
</div>
<div class="mt-2 hov" *ngIf="type == 'btnWithCustomIcon'">
  <button label="{{ label }}" [src]="imgSrc" [style.background]="bgColor" [disabled]="disabled" [style.color]="color"
    [style.font-size]="fontSize" [style.font-weight]="fontWeight" [style.background-image]="backgroundImage"
    [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius" [style.padding]="padding"
    [style.margin]="margin" [style.width]="width" [style.border]="border" pButton pRipple icon="{{ icon }}"
    iconPos="iconPos" (click)="onClick()" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn buttonHover">
    <img alt="logo" src="{{ imgSrc }}" style="width: 1.5rem" />
  </button>
</div>

<div class="btnWithIcon hov" *ngIf="type == 'btnWithIcon'">
  <button label="{{ label }}" [style.background]="bgColor" [disabled]="disabled" [style.color]="color"
    [style.font-size]="fontSize" [style.font-weight]="fontWeight" [style.background-image]="backgroundImage"
    [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius" [style.padding]="padding"
    [style.margin]="margin" [style.width]="width" [style.border]="border" [style.column-gap]="columnGap" pButton pRipple
    icon="{{ icon }}" iconPos="iconPos" (click)="onClick()" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn buttonHover"></button>
</div>
<div class="btnWithIcon hov" *ngIf="type == 'roundedBtnWithIcon'">
  <button label="{{ label }}" [style.background]="bgColor" [disabled]="disabled" [style.color]="color"
    [style.font-size]="fontSize" [style.font-weight]="fontWeight" [style.background-image]="backgroundImage"
    [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius" [style.padding]="padding"
    [style.margin]="margin" [style.width]="width" [style.border]="border" [style.column-gap]="columnGap" pButton pRipple
    icon="{{ icon }}" iconPos="iconPos" (click)="onClick()" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn buttonHover p-button-rounded"></button>
</div>

<div class="homeSearch" *ngIf="type == 'homeSearch'">
  <button label="{{ label }}" [style.background]="bgColor" [disabled]="disabled" [style.color]="color"
    [style.font-size]="fontSize" [style.height]="height" [style.width]="width" [style.font-weight]="fontWeight"
    [style.background-image]="backgroundImage" [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius"
    [style.padding]="padding" [style.margin]="margin" [style.width]="width" [style.min-width]="minWidth" pButton pRipple
    icon="{{ icon }}" iconPos="{{ iconPos }}" (click)="onClick()" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn"></button>
</div>

<div class="messages" *ngIf="type == 'messages'">
  <button label="{{ label }}" [style.background]="bgColor" [disabled]="disabled" [style.color]="color"
    [style.font-size]="fontSize" [style.height]="height" [style.width]="width" [style.font-weight]="fontWeight"
    [style.background-image]="backgroundImage" [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius"
    [style.padding]="padding" [style.margin]="margin" [style.position]="position" [style.top]="top"
    [style.right]="right" pButton pRipple icon="{{ icon }}" iconPos="{{ iconPos }}" (click)="onClick()"
    (click)="menu.toggle($event)" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn"></button>
  <p-menu #menu [popup]="true" [model]="ButtonModelData"></p-menu>
</div>
<div class="messages" *ngIf="type == 'sendMessages'">
  <button label="{{ label }}" [disabled]="disabled" [style.background]="bgColor" [style.color]="color"
    [style.font-size]="fontSize" [style.height]="height" [style.width]="width" [style.font-weight]="fontWeight"
    [style.background-image]="backgroundImage" [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius"
    [style.padding]="padding" [style.margin]="margin" [style.position]="position" [style.top]="top"
    [style.right]="right" pButton pRipple icon="{{ icon }}" iconPos="{{ iconPos }}" (click)="onClick()" type="button"
    class="p-button-raised p-button-info p-button-text col-md-12 text-center btn"></button>
</div>