<ng-container *ngIf="type == 'myMatchesFilters'">
  <div class="search-box">
    <div
      class="search-box-items"
      *ngFor="let box of this.inputInfo; let i = index"
    >
      <div class="dropDownWidth">
        <app-drop-down
          *ngIf="box.inputType == 'text'"
          [inputInfo]="box"
          [changeFlag]="changeFlag"
          [data]="box.data"
          [scrollHeight]="
            box.data?.length >= 1 && box.data?.length <= 3
              ? box.data?.length * 50 + 'px'
              : '200px'
          "
          (updateData)="updateFilterData($event, box.modelName)"
          (preSubmit)="preSubmit()"
          (submit)="onSubmit()"
        >
        </app-drop-down>
        <app-multi-select
          *ngIf="box.inputType == 'multi'"
          [inputInfo]="box"
          [changeFlag]="changeFlag"
          [data]="box.data"
          (updateData)="updateFilterData($event, box.modelName)"
          (preSubmit)="preSubmit()"
          (submit)="onSubmit()"
        >
        </app-multi-select>
      </div>
    </div>
    <div class="search-box-btn">
      <app-button
        [label]="'Search'"
        [borderRadius]="'35px'"
        [color]="'#ffffff'"
        [type]="'homeSearch'"
        [bgColor]="'#DD1485'"
        [fontWeight]="'600'"
        [width]="'75% !important'"
        [height]="'100%'"
        (button)="preSubmit()"
        [fontSize]="'clamp(12px, 4vw, 16px)'"
        iconPos="right"
      >
      </app-button>
    </div>
  </div>
</ng-container>
