import { BasicAndLifeStyleInfo } from "./basicAndLifestyle-info";
import { EducationAndCareerInfo } from "./educationAndCareer-info";
import { PersonalDetailsInfo } from "./personalDetails-info";
import { RatingAndVerificationInfo } from "./ratingAdnVerification-info";

export class AboutUserInfo {
  basicAndLifestyle: BasicAndLifeStyleInfo = new BasicAndLifeStyleInfo();
  personalDetails: PersonalDetailsInfo = new PersonalDetailsInfo();
  ratingAndVerification: RatingAndVerificationInfo =
    new RatingAndVerificationInfo();
  educationAndCareer: EducationAndCareerInfo = new EducationAndCareerInfo();
}
