<div class="container-fluid recover-page">
  <div class="hero">
    <img
      class="hero-image"
      src="./assets/images/signup-login-ss.png"
      alt=""
    />
  </div>
  <div class="row justify-content-center recover-inner">
    <div class="recover-heading text-center m-auto">
      <div class="recover-heading-title">
        <h3>Reset password</h3>
      </div>
      <div class="recover-heading-para">
        <p>
          Please enter your email address. A link to reset password will be sent
          there.
        </p>
      </div>
    </div>
    <div class="recover-main">
      <div class="col-md-12 mt-4" *ngFor="let box of this.inputInfo">
        <app-text-box
          [inputInfo]="box"
          [changeFlag]="this.changeFlag"
          [customValidator]="box.customValidator"
          *ngIf="box.type == 'text2'"
          (updateData)="updateData($event, box.modelName)"
          (preSubmit)="preSubmit()"
          (submit)="onSubmit()"
          [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
          [borderRadius]="'35px'"
          [width]="'100%'"
          [height]="'65px'"
          [padding]="'0 2rem'"
        ></app-text-box>
      </div>
      <div class="col-md-12 mt-5">
        <app-button
          [label]="'Send'"
          [padding]="'5px 0'"
          [color]="'#fff'"
          [type]="'login'"
          (button)="preSubmit()"
          [bgColor]="'var(--color-blue)'"
          [borderRadius]="'35px !important'"
          [fontSize]="'clamp(16px, 4vw, 22px)'"
          [padding]="'1rem 3rem'"
          [width]="'100%'"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
