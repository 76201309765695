<div class="about-us">
    <div class="hero">
        <img 
        class="hero-image"
            src="../assets/images/{{ aboutUsPageInfo.pageHeroImage }}" 
            alt="hero-image">
      <div class="hero-content">
        <div class="hero-pageTitle">
          <h2>{{ aboutUsPageInfo.title }}</h2>
        </div>
        <div class="hero-pageDescription">
          <p>{{ aboutUsPageInfo.description }}</p>
        </div>
      </div>
    </div>

    <section class="about-us-section container-fluid">
        <div class="about-us-section-title">
            <h3>About Us </h3>
          </div>
          <p class="first-para">
            It is a project of Majlis-e-Ulama-e-Shia (Europe), a registered charity based in the United Kingdom to promote marriages across the globe. <br><br>
            Our vision is to provide a comprehensive platform to all those who are willing to get married. We ensure that this platform, where marriage candidates will look for their potential spouses, is secure, reliable, confidential and respectful of your needs. <br><br>
            We have been offering match-making services for many years and this website has been designed considering all aspects of confidentiality, ease of use and to expedite the process where individuals and families can contact each other directly. We realise that there are several services online and various community groups trying to create lists of people wanting to get married. However, most of them are unstructured efforts, without a clear system in place, causing frustration for the candidate and in some cases their parents too. Therefore, we gathered all those who are experienced in this field to obtain their feedback, collected the views of our scholars and took advice from IT professionals to create the ShiaSpouse website.<br><br>
            Join with peace of mind!<br><br>
        </p>
        <div>
            <h3 class="about-us-section-feat">Features:</h3>
        </div>
        <p class="second-para">
          At ShiaSpouse, we prioritize your privacy, security, and peace of mind. Our platform is designed to ensure a safe, secure, and completely anonymous experience, allowing you to explore and connect with potential matches confidently. <br>
          We offer an exceptional matchmaking experience, tailored to meet your unique needs. With a variety of options at your disposal, finding the most suitable match has never been easier.<br>
          Our services are affordable, with low subscription fees that are essential to maintain and continually enhance our offerings, ensuring you receive the best experience possible.<br>
          We’ve introduced a Profile Star Rating system, where the more you complete your profile, especially by featuring an Alim reference, the higher your rating will be. This not only increases your chances of finding the perfect match but also builds trust within our community. <br>
          Enjoy complete control with our user-friendly interface and features, designed to make your journey as smooth and intuitive as possible. <br>
          Join ShiaSpouse today, and take the next step toward finding your ideal partner in a safe, supportive, and faith-aligned environment.
        </p>
        
    </section>
    <div>
         <source srcset="image-large.jpg" media="(min-width: 1024px)">
         <source srcset="image-medium.jpg" media="(min-width: 768px)">
        <img class="marriage-couple" src="../assets/images/{{ aboutUsPageInfo.pagecenterimage }}" alt="Marriage Couple">
    </div>
    <section>
        <div class="about-us-section container-fluid">
            <p class="third-para">
                All of us at ShiaSpouse understand the importance of Data Protection and Online Security. 
                All suitable measures are in place to keep your data as secure and compliant as possible. 
                Further details are available in our Privacy Policy. <br><br>
                Majlis-e-Ulama-e-Shia (MUS) Europe, a registered 
                UK Charity, is an organisation of Shia Scholars and Communities running since 1992. MUS is a well-respected 
                organisation in the UK, Europe and worldwide. Its purpose is to understand the needs of the Shia communities 
                and provide services accordingly with the participation of experienced individuals. For details, 
                <span
                ><a class="link-on" href="https://www.majlis.org.uk" target="_blank">
                     www.majlis.org.uk</a
                   ></span> <br><br>
               
                We do not want this website to be free or run on donations in order to provide 
                a continuous service maintaining a quality system by professional efforts which need to be paid for 
                appropriately. Also, when projects run on donations then most of the time is spent on fundraising than
                maintaining the project itself. We hope that you appreciate this initiative and pay a small amount to 
                use this website. If anyone genuinely, for any legitimate reason, is unable to afford the cost then please 
                contact us directly and we will make our best efforts to help.<br><br> 
                We at ShiaSpouse work hard to provide this 
                service and will continue to do so to improve it for the benefit of the community. Therefore, your feedback 
                and recommendations are vital to our success and pray that this project helps you find your soulmate soon. 
                <br><br> Contact us at info@shiaspouse.org for any feedback and suggestions.<br><br>
                May Allah swt keep us all on the right path
            </p>
        </div>
    </section>
</div>