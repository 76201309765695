export class ProfileCreateInfo {
  profile_for: string;
  is_shia: string;
  first_name: string;
  securityCode: string;
  is_syed: string;
  middle_name: string;
  education: string;
  last_name: string;
  profession: string;
  nickname: string;
  city: string;
  date_of_birth: Date;
  state: string;
  email: string;
  country: string;
  password: string;
  nationality: string;
  confirm_password: string;
  residency: string;
  gender: string;
  contact_no: string;
  dial_code: string;
  relationship_status: string;
  captcha: any;
  agreePolicy: string;
  afirmInformation: string;
  images = [];
}
