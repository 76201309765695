import { AlimREferenceInfo } from "./alimReference-info";
import { OtherVerificationInfo } from "./otherVerification-info";
import { VerificationStatusInfo } from "./verificationStatus-info";

export class VerificationInfo {
  verificationStatus: VerificationStatusInfo = new VerificationStatusInfo();
  otherVerification: OtherVerificationInfo = new OtherVerificationInfo();
  alimReferenceInfo: AlimREferenceInfo = new AlimREferenceInfo();
  rating: number;
  noOfStars: string;
}
