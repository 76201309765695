<div class="navigation">
  <!-- <ng-container *ngIf="displayLogin">
    <app-dialog
      (close)="loginDialogClosed()"
      (showRegisterDialog)="registerDialogShow()"
      [openDialog]="displayLogin"
      [type]="'login'"
    >
    </app-dialog>
  </ng-container>
  <ng-container *ngIf="displayRegister">
    <app-dialog
      (close)="registerDialogClosed()"
      [openDialog]="displayRegister"
      [type]="'register'"
    >
    </app-dialog>
  </ng-container> -->
  <div class="header">
    <app-header class="header-navbar" [item]="item"></app-header>
  </div>
  <div class="router-body">
    <router-outlet></router-outlet>
  </div>
  <ng-container *ngIf="footerFlag">
    <app-footer></app-footer>
  </ng-container>
</div>
