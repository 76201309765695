/**
 * This is our dialog component, to be used through out our program
 */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { InputInfo } from "../../componentModel/input-info";
import { MatchCardInfo } from "src/app/models/matchCard-info";
import { Validators } from "@angular/forms";
import { SearchInfo } from "src/app/models/search-info";
import { ActivatedRoute } from "@angular/router";

/**
 * This is our component file of link-button component
 */
@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class DialogComponent implements OnInit {
  imageUploadedFlag: boolean = false;

  @Output() showRegisterDialog = new EventEmitter();
  @Output() searchedData = new EventEmitter();

  /**
   *This input function gets data from our parent class of the type input info
   */
  @Input() inputInfo = new InputInfo();
  @Input() dialogData: any;
  /**
   *type of the dialog
   */
  @Input() type: string;
  @Input() changeFlag: boolean;
  /**
   *call to open dialog
   */
  @Input() openDialog: boolean;
  /**
   *call to close
   */
  @Output() close = new EventEmitter();
  /**
   * emiiter when data is updated
   */
  @Output() updateData = new EventEmitter<string>();
  @Output() preSubmit = new EventEmitter<any>();
  @Output() submitData = new EventEmitter<any>();

  /**
   * name of the template
   */
  templateName: string;

  inputInfoDialog: InputInfo[] = [];

  /**
   * @param messageService display messages throught our application
   */
  constructor(
    private messageService: MessageService,
    private activatedRouter: ActivatedRoute
  ) {}
  /**
   * ngOnInIt is an angular lifecycle function that run when we load our component or application
   */
  ngOnInit(): void {}

  /**
   * closes modal and emits close
   */
  closeModal() {
    this.imageUploadedFlag = !this.imageUploadedFlag;
    this.openDialog = false;
    this.close.emit();
  }
  /**
   * saves template name
   * @param data template name
   */
  saveData(data, label) {
    this.templateName = data;
  }
  /**
   * when submit is called update data emits
   * @returns
   */
  onSubmit() {
    this.updateData.emit(this.templateName);
  }
  onSubmitVerification() {
    this.submitData.emit(this.templateName);
  }
  onUpdateData(event) {
    this.updateData.emit(event);
  }

  showRegisterModal() {
    this.showRegisterDialog.emit();
  }

  onDialogSubmit(event) {
    this.preSubmit.emit(event);
  }

  onDailogFilterSubmit(event) {
    this.closeModal();
    this.submitData.emit(event);
  }

  onCardButtonClicked(value) {
    this.closeModal();
    this.updateData.emit(value);
  }
}
