<div class="row justify-content-between matchesBody">
  <!-- save search modal  -->
  <p-dialog 
header="Are you sure you want to save this search?" 
[(visible)]="displayDialog" 
modal="modal" 
[closable]="true" 
[style]="{width: '30vw'}"
[contentStyle]="{padding: '20px'}"
[baseZIndex]="10000"
>
<div>
<p>Enter Search name</p>
<input type="text" pInputText [(ngModel)]="searchTitle" placeholder="Enter title here" style="width: 100%;margin-bottom: 25px;" required
#searchTitleInput="ngModel"/>
<div *ngIf="searchTitleInput.invalid && searchTitleInput.touched" style="color: red; margin-bottom: 15px;">
  Please enter the search name.
</div>
</div>
<div class="p-d-flex p-jc-end">
<button 
  pButton 
  type="button" 
  label="Cancel" 
  (click)="onCancel()" 
  class="custom-button">
</button>
<button 
  pButton 
  type="button" 
  label="Save" 
  (click)="onSaveSearch()" 
  class="custom-button p-ml-2"
  [disabled]="searchTitleInput.invalid"
  >
</button>
</div>
</p-dialog>


   <!-- save search model end  -->
<ng-container *ngIf="!isMobileView">
      

<div class="col-3 userSide">
       <!-- saved search card  -->
 
       <div class="scrollable-container">
        <div class="saved-search-header">Saved Searchs</div>
        <div *ngFor="let item of savedSearch" class="item">
          <a (click)="getSavedSearch(item.json_obj)">
            <span>{{ item.name }}</span>
          </a>
          <i class="pi pi-trash" (click)="deleteSavedSearch(item.id)"></i>
        </div>
      </div>
      
      
    

<!-- saved search card end  -->
  <div class="userSide-inner p-3">
    <!-- <div class="small-img"> -->
      <!-- <ng-container *ngIf="this.userInfo.image">
        <img [src]="this.userInfo.image" alt="user profile image" />
      </ng-container>
    </div>
    <div class="user-name">
      <p>{{ this.userInfo.name }}</p>
    </div> -->
    <div>
      Advanced Search
    </div>
  </div>
  <app-filter
    [type]="'myMatchesFilters'"
    [inputInfo]="this.inputInfo"
    (submit)="onFilterSubmit($event)"
    (updateData)="onUpdateData($event)"
  >
  </app-filter>
</div>


<div class="col-9 cardSide">
  
  <div class="action-buttons">
    <button pButton type="button" class="p-button-text" label="Save Search" (click)="showDialog()">
      <i class="pi pi-save" style="margin-left: 8px;"></i>
    </button>
    <button pButton type="button" class="p-button-text" label="Reset Filters" (click)="resetFilters()">
      <i class="pi pi-filter-slash" style="margin-left: 8px;"></i>
    </button>
  </div>
  
  
  <div
    class="my-matches-card cardSide"
    *ngIf="matchDisplay && matchDisplay.length > 0"
  >
    <div class="card" *ngFor="let data of matchDisplay">
      <app-card
        (cardClicked)="onCardClicked($event)"
        (cardButtonClick)="onCardButtonClick($event)"
        [type]="'matchCard'"
        [cardData]="data"
      ></app-card>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12">
    <app-pagination
      [rowsPerPage]="rowsCount"
      [totalRecord]="totalCount"
      (nextPage)="nextRecords($event)"
    ></app-pagination>
  </div>
</div>
</ng-container>
</div>

<ng-container *ngIf="isMobileView">
<app-dialog
(close)="filterDialogClosed()"
[openDialog]="displayFilter"
[type]="'mobileFilters'"
[inputInfo]="inputInfo"
(searchedData)="onSearchedData($event)"
(submitData)="onFilterSubmit($event)"
>
</app-dialog>
<div class="mobile-filter">
<app-button
[type]="'btnWithIcon'"
[label]="'Filters'"
[padding]="'5px 0'"
[color]="'#000000'"
[bgColor]="'#ffffff'"
[borderRadius]="'5px !important'"
[border]="'1px solid #707070'"
[fontSize]="'clamp(10px, 3vw, 14px)'"
[padding]="'.3rem .5rem'"
[columnGap]="'.2rem'"
icon="pi pi-sliders-h"
(click)="showFilter()"
></app-button>
<div class="mobile-filter-label">
<p>{{ searchResultCount }} Results</p>
</div>
<!-- save search button for mobile  -->
<p-button (onClick)="menu.toggle($event)" label="Saved Searches" [outlined]="true" severity="secondary"></p-button>
<p-menu #menu [model]="savedSearchMobile" [popup]="true"></p-menu>
</div>
<div class="carousel-match">
<app-carousel
  [type]="'myMatches'"
  [items]="matchDisplay"
  (buttonClicked)="onCardButtonClick($event.event)"
  (onPage)="onPageClicked($event)"
  (cardClicked)="onCardClicked($event)"
>
</app-carousel>
</div>
</ng-container>
