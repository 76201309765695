import { Component, OnInit } from "@angular/core";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { FooterPageInfo } from "src/app/models/footer-page-info";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  footerPageInfo: FooterPageInfo = {
    logoPath: "shia_spouse.png",
    para: "A project of MUS (Europe) to help Shia people across the globe find spouses and conduct marriages.",
    mediaItems: [
      {
        label: "facebook icon",
        link: "https://www.facebook.com/shiaspouse.org",
        imgPath: "facebook-app-symbol.png",
        styleClass: "icon-facebook",
      },
      {
        label: "instagram icon",
        link: "https://www.instagram.com/shiaspouse/",
        imgPath: "instagram-2.png",
        styleClass: "icon-instagram",
      },
      {
        label: "TikTok  icon",
        link: "https://www.tiktok.com/@shiaspouse",
        imgPath: "tiktok.png",
        styleClass: "icon-tiktok",
      },
      {
        label: "youtube icon",
        link: "https://www.youtube.com/@shiaspouse5713",
        imgPath: "youtube-logo-hd-8.png",
        styleClass: "icon-youtube",
        
      },
    ],
    company: {
      title: "Orgnization",
      navigationItems: [
        {
          label: "Contact Us",
          link: "contactus",
        },
        {
          label: "About Us",
          link: "site/about",
        },
        // {
        //   label: "Events",
        //   link: "events",
        // },
        {
          label: "Articles",
          link: "site/books",
        },


        // {
        //   label: "Books",
        //   link: "site/books",
        // },
        // {
        //   label: "Videos",
        //   link: "site/video",
        // },
      ],
    },
    needHelp: {
      title: "Need Help?",
      navigationItems: [

        {
          label: "Member Login",
          disable: false,
          link: "login",
        },
        {
          label: "Sign Up",
          disable: false,
          link: "user/signup",
        },
        // {
        //   label: "Partner Search",
        //   link: "/user/my-matches",
        // },
        {
          label: "Subscription Plans",
          disable: false,
          link: "/user/plans",
        },
      ],
    },
    privacy: {
      title: "Privacy & You",
      navigationItems: [
        {
          label: "Terms of use",
          link: "terms",
        },
        {
          label: "Privacy Policy",
          link: "policy",
        },
      ],
    },
    more: {
      title: "More",
      navigationItems: [
        {
          label: "FAQS",
          link: "site/faq",
        },
        {
          label: "Events",
          link: "events",
        },
        {
          label: "Advanced Search",
          link: "/site/search",
        },
        {
          label: "Shariah Board",
          link: "https://majlis.org.uk/sharia-board/",
        },
      ],
    },
    copyright: "Copyrights 2024. All rights reserved",
  };
  loggedInFlag = false;
  constructor(private tokenStorage: TokenStorageService) {
    if (this.tokenStorage.getToken() != null) {
      this.loggedInFlag = true;
    }
    this.footerPageInfo.needHelp.navigationItems[0].link =
      this.loggedInFlag == true ? "" : "login";
    this.footerPageInfo.needHelp.navigationItems[0].disable =
      this.loggedInFlag == true ? true : false;
    this.footerPageInfo.needHelp.navigationItems[1].link =
      this.loggedInFlag == true ? "" : "user/signup";
    this.footerPageInfo.needHelp.navigationItems[1].disable =
      this.loggedInFlag == true ? true : false;
      
      this.footerPageInfo.more.navigationItems[2].link =
      this.loggedInFlag == true ? "site/search" : "site/search";

    // this.footerPageInfo.company.navigationItems[0].link=this.loggedInFlag == true ? "" : "contactus";

  }


  ngOnInit(): void { }
}
