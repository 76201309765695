<div class="mt-2" *ngIf="this.inputInfo.type == 'text'">
  <h5>{{ this.inputInfo.label | capitalization }} :</h5>
  <div>
    <input autocomplete="nope" class="p-inputtext-md col-md-12" type="{{ this.inputInfo.inputType }}" pInputText
      [formControl]="this.formControl" placeholder="Please Enter {{ this.inputInfo.label | capitalization }}"
      (keyup.enter)="onEnter()" />
  </div>
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
    }}</small>
</div>
<!-- <div class="mt-2" *ngIf="this.inputInfo.modelName == 'city'">
  <h5>{{ this.inputInfo.label | capitalization }} :</h5>
  <div class="p-float-label">
    
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
  </div>

  <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
    }}</small>
</div> -->
<div class="mt-2" *ngIf="this.inputInfo.type == 'text2'">
  <div class="p-float-label">
    <input autocomplete="nope" class="p-inputtext-md col-md-12" type="{{ this.inputInfo.inputType }}" pInputText
      [formControl]="this.formControl" [style.box-shadow]="boxShadow" [style.border-radius]="borderRadius"
      [style.width]="width" [style.height]="height" [style.padding]="padding"
      placeholder=" {{ this.inputInfo.placeHolder }}" (keyup.enter)="onEnter()" (onClick)="onClick()" />
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
    <!-- [disabled]="this.inputInfo.disabled" -->
  </div>
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
    }}</small>
</div>

<div class="mt-2" *ngIf="this.inputInfo.type == 'captcha'">
  <div class="p-float-label flex gap-2 align-items-center">
    <input *ngIf="this.inputInfo.modelName != 'city' && this.inputInfo.inputType != 'phone'" autocomplete="nope"
      class="p-inputtext-md col-md-12" type="{{ this.inputInfo.inputType }}" pInputText [formControl]="this.formControl"
      [style.box-shadow]="boxShadow" [style.color]="color" [style.border-radius]="borderRadius" [style.width]="width"
      [style.height]="height" [style.padding]="padding" [style.font-size]="fontSize" [style.font-family]="fontFamily"
      [disabled]="this.inputInfo.disabled" placeholder=" {{ this.inputInfo.placeHolder }}" (keyup.enter)="onEnter()"
      (onClick)="onClick()" />
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
  </div>
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
    }}</small>
</div>
<div class="mt-2 d-flex flex-column" *ngIf="this.inputInfo.type == 'profileCreate'">
  <div class=" d-flex align-items-center gap-2">
    <div class="p-float-label d-flex flex-grow-1 gap-2 align-items-center">
      <!-- <label for="username">{{this.inputInfo.placeHolder}}</label> -->

      <input *ngIf="this.inputInfo.modelName != 'city' && this.inputInfo.inputType != 'phone'" autocomplete="nope"
        class="p-inputtext-md col-md-12 d-flex flex-grow-1" type="{{ this.inputInfo.inputType }}" pInputText
        [formControl]="this.formControl" [style.box-shadow]="boxShadow" [style.color]="color"
        [style.border-radius]="borderRadius" [style.width]="width" [style.height]="height" [style.padding]="padding"
        [style.font-size]="fontSize" [style.font-family]="fontFamily" [disabled]="this.inputInfo.disabled"
        placeholder=" {{ this.inputInfo.placeHolder }}" (keyup.enter)="onEnter()" (onClick)="onClick()" />

      <input *ngIf="this.inputInfo.modelName == 'city' && this.inputInfo.inputType != 'phone'" autocomplete="nope"
        ngx-google-places-autocomplete [options]='mapOptions' #placesRef="ngx-places"
        (onAddressChange)="handleAddressChange($event)" class="p-inputtext-md col-md-12" type="text" pInputText
        [formControl]="this.formControl" [style.box-shadow]="boxShadow" [style.color]="color"
        [style.border-radius]="borderRadius" [style.width]="width" [style.height]="height" [style.padding]="padding"
        [style.font-size]="fontSize" [style.font-family]="fontFamily" [disabled]="this.inputInfo.disabled"
        placeholder="Please Enter {{ this.inputInfo.label | capitalization }}" (keyup.enter)="onEnter()" />



      <ngx-intl-tel-input *ngIf="this.inputInfo.inputType == 'phone' && this.inputInfo.modelName != 'city'"
        [cssClass]="'p-inputtext-md custom-phone-input col-md-12'" [preferredCountries]="[CountryISO.UnitedKingdom]"
        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
        [separateDialCode]="true" [selectedCountryISO]="CountryISO.UnitedKingdom" [maxLength]="15"
        [phoneValidation]="true" [inputId]="my-input-id" (keyup.enter)="onEnter()" name="phone"
        [formControl]="this.formControl"></ngx-intl-tel-input>


      <!-- <div class="hidebox">
        <p-checkbox 
        value="hide" 
        inputId="hide" />
        <p>Hide</p>
      </div> -->


      <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
      <!-- [disabled]="this.inputInfo.disabled" -->
    </div>
    <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
      <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
      <p>Hide</p>
    </div>
  </div>
  <small id="username2-help" class="p-error" *ngIf="formControl.touched && formControl.dirty">{{
    getError()
    }}</small>
</div>
<div class="mt-2" *ngIf="this.inputInfo.type == 'disabled'">
  <div class="p-float-label">
    <input autocomplete="nope" id="disabled-input" class="p-inputtext-md col-md-12"
      type="{{ this.inputInfo.inputType }}" pInputText [style.box-shadow]="boxShadow"
      [style.border-radius]="borderRadius" [style.padding]="padding" [style.width]="width" [style.height]="height"
      [formControl]="this.formControl" disabled placeholder=" {{ this.inputInfo.placeHolder }}"
      (keyup.enter)="onEnter()" (onClick)="onClick()" />
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
    <!-- [disabled]="this.inputInfo.disabled" -->
  </div>
  <div *ngIf="inputInfo.hiddenCheckBox" class="hidebox">
    <p-checkbox value="on" [formControl]="formControlHide" inputId="hide" />
    <p>Hide</p>
  </div>
  <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
    }}</small>
</div>

<div *ngIf="this.inputInfo.type == 'searchMessage'">
  <span class="p-input-icon-left d-flex justify-center p-float-label">
    <i class="pi pi-search"></i>
    <input autocomplete="nope" class="p-inputtext-md col-12 message-custom-input" type="{{ this.inputInfo.inputType }}"
      pInputText [formControl]="this.formControl" [style.box-shadow]="boxShadow" [style.font-size]="fontSize"
      [style.border-radius]="borderRadius" [style.width]="width" [style.height]="height" [style.padding]="padding"
      placeholder=" {{ this.inputInfo.placeHolder }}" (keyup.enter)="onEnter()" (onClick)="onClick()" />
    <label for="inputField">{{ this.inputInfo.placeHolder }}</label>
  </span>
</div>
<div *ngIf="this.inputInfo.type == 'messageInput'">
  <input autocomplete="nope" class="p-inputtext-md col-12" type="{{ this.inputInfo.inputType }}" pInputText
    [formControl]="this.formControl" [style.box-shadow]="boxShadow" [style.background-color]="bgColor"
    [style.border-radius]="borderRadius" [style.font-size]="fontSize" [style.border]="border" [style.width]="width"
    [style.height]="height" [style.padding]="padding" placeholder=" {{ this.inputInfo.placeHolder }}"
    (keyup.enter)="onEnter()" (onClick)="onClick()" />

  <!-- [disabled]="this.inputInfo.disabled" -->

  <!-- <small id="username2-help" class="p-error" *ngIf="this.formControl.dirty">{{
    getError()
  }}</small> -->
</div>