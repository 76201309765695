import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  // items: MenuItem[];
  showNavbar = true;

  @Input() accessFrom: string;
  @Input() logoSrc: string;
  @Input() logoPath: string;
  @Input() name: string;
  @Input() items: MenuItem[];

  navbarItemsWhite: boolean = false;
  currentUrl: string;
  constructor(private router: Router) {
    router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe(() => {
        this.currentUrl = this.router.url;
        
        if (
          this.currentUrl.includes("/site/search") ||
          this.currentUrl.includes("/user/my-matches") ||
          this.currentUrl.includes("/user/wishlist") ||
          this.currentUrl.includes("/user/chat/panel") ||
          this.currentUrl == "/user/site/user-view" ||
          this.currentUrl == "/user/plans"
        ) {
          this.navbarItemsWhite = false;
        } else if(this.currentUrl.includes("/site/search")) {
          this.navbarItemsWhite = false;
        }
        else {
          this.navbarItemsWhite = true;
        }
        

        if (this.currentUrl.includes("/user/otp")) {
          this.showNavbar = false;
        } else {
          this.showNavbar = true;
        }
      });
  }

  ngOnInit() {}
}
