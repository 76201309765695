export class EditProfileInfo {
  profile_for: any;
  first_name: string;
  middle_name: string;
  last_name: string;
  nickname: string;
  date_of_birth: Date;
  contact_no: string;
  dial_code: string;
  dialCode: string;
  gender: any;
  hijab: any;
  relationship_status: any;
  is_shia: any;
  is_syed: any;
  children: any;
  visible: any;
  language: any;
  education: any;
  profession: any;
  working_status: any;
  income: string;
  currency: any;
  facebook: string;
  instagram: string;
  city: string;
  state: string;
  country: string;
  nationality: string;
  residency: any;
  ethnicity: any;
  height: string;
  weight: string;
  body_type: any;
  skin_tone: any;
  smoke: any;
  pray_regularly: any;
  aboutMe: string;
  disability: any;
  disabilityDetails: any;
}
