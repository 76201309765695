import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { JwtResponse } from "../auth/jwt-response";
import { TokenStorageService } from "../auth/token-storage.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventService {
  private baseUrl = environment.url;
  httpOptionsSaved = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "*/*",
      // "Access-Control-Allow-Origin": `${this.baseUrl}`,
    }),
  };

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) {}

  getAllEvents(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/site-api/events`,
      this.httpOptionsSaved
    );
  }
  getEvent(slug: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/site-api/event-view?slug=${slug}`,
      this.httpOptionsSaved
    );
  }
}
