<div class="privacy">
  <div class="hero">
    <div class="hero-content">
      <div class="hero-icon">
        <i class="pi pi-list"></i>
      </div>
      <div class="hero-pageTitle">
        <h2>Privacy Policy</h2>
      </div>
    </div>
  </div>

  <section class="privacy-section container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="well-box">
            <p><strong>ShiaSpouse - Privacy Policy</strong></p>
            <p><strong>General Information</strong></p>
            <p>
              Welcome to ShiaSpouse! At <strong>ShiaSpouse</strong> ("we", "us",
              "our") we value your privacy and trust, so we’re providing you
              with all the information in this Privacy Policy about how we
              handle your data, who we share it with, your rights around the
              data, and our organisation pages operated by
              <strong>ShiaSpous</strong>e on social networking sites and other
              platforms) (collectively, the "Services").
            </p>
            <p>&nbsp;</p>
            <p>
              For the purposes of EU data protection laws ("<strong
                >Data Protection Legislation</strong
              >"), ShiaSpouse is the data controller (i.e. the company which is
              responsible for, and controls the processing of, your personal
              data)
            </p>
            <p>&nbsp;</p>
            <p><strong>1. Where this Privacy Policy applies</strong></p>
            <p>
              This Privacy Policy applies to websites, apps, events and other
              services operated by ShiaSpouse. For simplicity, we refer to all
              of these as our "<strong>Service</strong>" in this Privacy Policy.
              Some services may require their own unique privacy policy. If a
              particular service has its own privacy policy, then that policy -
              not this Privacy Policy - applies.
            </p>
            <p>&nbsp;</p>
            <p><strong>2. What Information We Collect</strong></p>
            <p>
              We may collect, use, store and transfer different kinds of
              personal data about you which we have grouped together follows:
            </p>
            <ol>
              <li>
                <p>
                  <strong>Information You Provide</strong>. When you register,
                  use or subscribe to any of our Services or take part in any
                  interactive features of the Services (such as any contests,
                  games, promotions, quizzes, surveys, research or other
                  services or features), we may collect a variety of
                  information, including:&nbsp;
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>"Contact Data"</strong>&nbsp;such as your name,
                      email address, and phone number&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>"Profile Data"</strong>. " when you complete your
                      profile, you can share with us additional information,
                      such as details on your personality, lifestyle, interests,
                      demographic information, place of work or education,
                      gender, age, dating age range preference, physical
                      characteristics, personal description, life experiences,
                      geographic location and other details about you, as well
                      as content such as photos and videos. To add certain
                      content, like pictures or videos
                      ("<strong>Photos</strong>"), you may allow us to access
                      your photo album. Some of the information you choose to
                      provide us may be considered "special" or "sensitive" in
                      certain jurisdictions, for example your racial or ethnic
                      origins, sexual orientation and religious
                      beliefs.&nbsp;<strong
                        >By choosing to provide this information, you consent to
                        our processing of that information.</strong
                      >&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      “<strong>Validation Data”. </strong>When you go through
                      the multiple levels of validation requirements, you may
                      decide to provide us with images of your Photo IDs,
                      Passport &amp; Driving license. This data will be used to
                      validate the profile data you have submitted, after
                      validation, all the personal ID’s listed above that you
                      have provided as images, will be deleted from all servers
                      upon request. When you delete you profile, data will be
                      removed during our regular data updates. Temporary
                      de-activation of your profile will not automatically
                      delete your profile and it may be stored for certain
                      period to allow you to activate your profile. Long
                      de-activated profiles and data may be removed.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>"Transaction Data"</strong>. such as when you
                      subscribe to a paid service or make a purchase directly
                      from us, you provide us or our payment service provider
                      with information, such as your debit or credit card number
                      or other financial information including, for example,
                      expiration date, name, address, transaction ID,
                      timestamps, and email address. Credit card transactions
                      are processed through industry standard Secure Socket
                      Layer (SSL) and / or strong encryption by a third party,
                      which only uses your information for that purpose.&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Information from Content You Upload</strong>. When
                      you upload photos, videos or other content to the
                      Services, we may collect information about the content,
                      such as the time, date and place the photo or content was
                      taken or uploaded (also known as metadata), and how you
                      use them, who views them or with whom you share..&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>"Communications Data"</strong>. if you contact our
                      customer care team, we will collect the information you
                      give us during the interaction. Sometimes, we monitor or
                      record these interactions for training purposes and to
                      ensure a high quality of service. Of course, we also
                      process your chats with other users as well as the content
                      you publish, as part of the operation of the
                      Services.&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      When you participate in surveys or focus groups, you give
                      us your insights into our products and services, responses
                      to our questions and testimonials.&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      When you choose to participate in our promotions, events
                      or contests, we collect the information that you use to
                      register or enter.&nbsp;
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <strong>Information Automatically Collected</strong>. When you
                  access our Services, we and our third party partners
                  automatically record information from your device and its
                  software, such as your IP address, browser type, Internet
                  service provider, platform type, the site from which you came
                  and the site to which you are going when you leave our
                  website, date and time stamp and one or more cookies that may
                  uniquely identify your browser or your account. When you
                  access our Services using a mobile device, we may also receive
                  or collect identification numbers associated with your device
                  (such as a unique device ID, IDFA, Google AdID), device type,
                  model and manufacturer, mobile device operating system brand
                  and model, phone number, email address, and other apps that
                  you have downloaded ("<strong>Technical Data</strong>"). We
                  also collect information about your usage of, and activity on,
                  our Services ("<strong>Usage Data</strong>").&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Information About How You Use the Services</strong>.
                  We may collect information about your participation and
                  actions on the Services. This may include information such as
                  the pages and profiles you view, how you browse the Services
                  and dating insights. It can also include the various functions
                  and features that you use, the connections you make, other
                  friends or contacts you invite to the Services and profile
                  searches you perform, or your use of our applications.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Location Data</strong>. We do not currently collect
                  your precise geo location or any latitude or longitude
                  coordinates for you. Some of the information we collect, for
                  example an IP address, can sometimes be used to approximate a
                  device’s location. In some cases, we assign the latitude and
                  longitude of the centre of a city or state on record for you
                  to your profile as a way to improve our analytics and matches
                  for you. This is a general latitude and longitude and does not
                  correspond to your real time or historical location
                  specifically.&nbsp;
                </p>
              </li>
            </ol>
            <p>
              We may link or combine the personal data we collect about you and
              the information we collect automatically. This allows us to
              provide you with a personalised experience regardless of how you
              interact with us.
            </p>
            <p>
              We may anonymise and aggregate any of the personal data we collect
              (so that it does not directly identify you). We may use anonymised
              information for our business purposes including testing our IT
              systems, research, data analysis, improving our Services and
              developing new products and features. We may also share such
              anonymised information with others.
            </p>
            <p>
              Where you do not provide the personal data detailed in this
              Privacy Policy, we may not be able to provide you with the
              Services.
            </p>
            <p>&nbsp;</p>
            <p><strong>3. How We Collect and Use Information</strong></p>
            <p>
              The main reason we use your information is to deliver and improve
              our Services. Additionally, we use your information to help keep
              you safe and to provide you with advertising that may be of
              interest to you.
            </p>
            <p>
              Generally, we will use your personal data in the following
              circumstances:
            </p>
            <ul>
              <li>
                <p>
                  Where we need to perform the contract we are about to enter
                  into or have entered into with you for the Services.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Where you have given consent for us to use your personal data
                  and your sensitive personal data to provide certain Services.
                  (You may withdraw your consent for some uses of the data at
                  any time by contacting us on details at end of this
                  policy.)&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Where it is necessary for our legitimate interests (or those
                  of a third party) and your interests and fundamental rights do
                  not override those interests.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Where we need to comply with a legal or regulatory
                  obligation.&nbsp;
                </p>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong
                >4. Purposes For Which We Will Use Your Personal Data</strong
              >
            </p>
            <p>
              We have set out in the table below a description of all the ways
              we plan to use your personal data, and which of the legal bases we
              rely on to do so. Please&nbsp;contact us&nbsp;if you need details
              about the specific legal basis we are relying on to process your
              personal data where more than one legal basis has been set out.
            </p>
            <table border="1">
              <thead>
                <tr>
                  <td>
                    <p><strong>Purpose/Activity</strong></p>
                  </td>
                  <td>
                    <p>
                      <strong
                        >Type of data&nbsp;<br />
                        (defined in Section 2 above)</strong
                      >
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong
                        >Lawful basis for processing including basis of
                        legitimate interest</strong
                      >
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>To register you as a new member</p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Contact Data</p>
                      </li>
                      <li>
                        <p>Technical Data</p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      bases:
                    </p>
                    <ol>
                      <li>
                        <p>To provide our Service to you</p>
                      </li>
                      <li>
                        <p>
                          With your consent in the case of sensitive personal
                          data
                        </p>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>To Validate your ‘Maulana reference’</p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Name</p>
                      </li>
                      <li>
                        <p>Town</p>
                      </li>
                      <li>
                        <p>Syed/Non Syed</p>
                      </li>
                      <li>
                        <p>Profile description</p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      Our unique validation method which is optional, those who
                      provide a Maulana reference, we have legitimate basis to
                      provide primary reference data as listed on the left to
                      confirm that the Maulana can verify these details.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>To Validate your ID and accuracy of your profile</p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Validation Data</p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      Based on legitimate interest, with our consent this data
                      will be used once for verifying your profile then deleted
                      immediately.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>To provide the Services to you, including:</p>
                    <ol>
                      <li>
                        <p>
                          Maintaining a profile, answering questions, and
                          providing information about yourself.
                        </p>
                      </li>
                      <li>
                        <p>
                          Analysing your personal data to match you with other
                          users.
                        </p>
                      </li>
                      <li>
                        <p>
                          Allowing you to communicate with other users of
                          ShiaSpouse to make a match.
                        </p>
                      </li>
                      <li>
                        <p>
                          Enabling you to purchase subscriptions and premium
                          add-ons to enhance your opportunities to make a match
                          with other ShiaSpouse users.
                        </p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Contact Data</p>
                      </li>
                      <li>
                        <p>Photos</p>
                      </li>
                      <li>
                        <p>Communications Data</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                      <li>
                        <p>Technical Data</p>
                      </li>
                      <li>
                        <p>Transaction Data</p>
                      </li>
                      <li>
                        <p>
                          Analytics and other scores used to make matches with
                          other ShiaSpouse users
                        </p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      bases:
                    </p>
                    <ol>
                      <li>
                        <p>To provide our Service to you</p>
                      </li>
                      <li>
                        <p>
                          With your consent in the case of sensitive personal
                          data
                        </p>
                      </li>
                      <li>
                        <p>
                          Necessary for our legitimate interests (in order to
                          improve our Service)
                        </p>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To manage our relationship with you which will include:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Notifying you about changes to our terms or privacy
                          policy
                        </p>
                      </li>
                      <li>
                        <p>Communicate with you.</p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Contact Data</p>
                      </li>
                      <li>
                        <p>Transaction Data</p>
                      </li>
                      <li>
                        <p>Communications Data</p>
                      </li>
                      <li>
                        <p>Photos</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      bases:
                    </p>
                    <p>&nbsp;</p>
                    <ol>
                      <li>
                        <p>To provide our Service to you</p>
                      </li>
                      <li>
                        <p>Necessary to comply with a legal obligation</p>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To enable you to partake in a prize draw, competition or
                      complete a survey
                    </p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Contact Data</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                      <li>
                        <p>
                          Any information, content, or submissions required as
                          set force in the contest rules when published
                        </p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      bases:
                    </p>
                    <ol>
                      <li>
                        <p>To provide our Service to you</p>
                      </li>
                      <li>
                        <p>
                          Necessary for our legitimate interests (to study how
                          customers use our Services, to develop them and grow
                          our business)
                        </p>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To administer and protect our business, the Services, our
                      website and your personal data (including security, fraud,
                      troubleshooting, data analysis, testing, system
                      maintenance, support, reporting and hosting of data)
                    </p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Contact Data</p>
                      </li>
                      <li>
                        <p>Technical Data</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                      <li>
                        <p>Communications Data</p>
                      </li>
                      <li>
                        <p>
                          Third Party Data provided to us by trusted partners,
                          including proprietary scores, red flags, demographic
                          information, usage data, and other information used to
                          determine whether a ShiaSpouse account is being used
                          improperly, accessed by an unauthorized third party,
                          or represents a security or fraud risk to the
                          ShiaSpouse community
                        </p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      bases:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Necessary for our legitimate interests (for running
                          our business, provision of administration and IT
                          services, cybersecurity, to prevent fraud and in the
                          context of a business reorganisation or group
                          restructuring exercise)
                        </p>
                      </li>
                      <li>
                        <p>Necessary to comply with a legal obligation</p>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To deliver relevant website content and advertisements to
                      you and measure or understand the effectiveness of the
                      advertising we serve to you
                    </p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Device ID</p>
                      </li>
                      <li>
                        <p>IP Address</p>
                      </li>
                      <li>
                        <p>
                          Location (at country, state, city or postal code
                          level)
                        </p>
                      </li>
                      <li>
                        <p>Gender</p>
                      </li>
                      <li>
                        <p>Age</p>
                      </li>
                      <li>
                        <p>Technical Data</p>
                      </li>
                      <li>
                        <p>Cookies, pixels or advertising tags</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      basis:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Necessary for our legitimate interests (to study how
                          customers use our products/services, to develop them,
                          to grow our business and to inform our marketing
                          strategy)
                        </p>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To use data analytics to improve our website,
                      products/services, marketing, customer relationships and
                      experiences
                    </p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                      <li>
                        <p>Technical Data</p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      basis:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Necessary for our legitimate interests (to define
                          types of customers for our products and services, to
                          keep our website updated and relevant, to develop our
                          business and to inform our marketing strategy)
                        </p>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>To respond to requests from law enforcement</p>
                  </td>
                  <td>
                    <ol>
                      <li>
                        <p>Profile Data</p>
                      </li>
                      <li>
                        <p>Transaction Data</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                      <li>
                        <p>Communications Data</p>
                      </li>
                      <li>
                        <p>
                          Location Data (at country, state, city, or postal code
                          level)
                        </p>
                      </li>
                      <li>
                        <p>Technical Data, such as IP Address or Device ID</p>
                      </li>
                      <li>
                        <p>
                          Any other information we are compelled to provide by
                          law
                        </p>
                      </li>
                    </ol>
                  </td>
                  <td>
                    <p>
                      To process your personal, we rely on the following legal
                      basis:
                    </p>
                    <ol>
                      <li>
                        <p>Necessary to comply with our legal obligations</p>
                      </li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>&nbsp;</p>
            <p><strong>5. Marketing</strong></p>
            <p>
              From time to time we may contact you with relevant information
              about our Services. Most messages we send will be by email. For
              some messages, we may use personal data we collect about you to
              help us determine the most relevant information to share with you.
            </p>
            <p>
              If you do not want to receive such messages from us, you will be
              able to tell us by selecting certain boxes on forms we use when we
              first collect your contact details. You can change your marketing
              preferences by clicking on the unsubscribe link at the bottom of
              our emails. You can also opt-out of receiving promotional emails
              and newsletters by clicking on "<strong>un-subscribe</strong>"
              option in the email link and un-checking the undesired promotions.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong
                >6. Disclosure of Your Information to Third Parties</strong
              >
            </p>
            <p>
              We may have to share your personal data with the parties set out
              below for the purposes in the table above.
            </p>
            <ul>
              <li>
                <p>
                  With other entities within the ShiaSpouse Administration
                  (including qualified Scholars in Majlis e Ulama e Shia) in
                  order to provide you with the Services.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Disclosure to Protect Abuse Victims. We reserve the right, but
                  have no obligation, to disclose any information that you
                  submit to the Services, if in our sole opinion, we suspect or
                  have reason to suspect, that the information involves a party
                  who may be the victim of abuse in any form. Abuse may include,
                  without limitation, elder abuse, child abuse, spousal abuse,
                  neglect, or domestic violence. Information may be disclosed to
                  authorities that we, in our sole discretion, deem appropriate
                  to handle such disclosure. Appropriate authorities may
                  include, without limitation, law enforcement agencies, child
                  protection agencies, or court officials. You hereby
                  acknowledge and agree that we are permitted to make such
                  disclosure.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Technology Services Providers, including infrastructure
                  partners (such as AWS), customer support service providers,
                  analytics service providers, cybersecurity partners, fraud
                  analytics companies, payment providers, chargeback
                  representation services and similar partners.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Advertising networks and technology companies that measure
                  advertising performance and attribution.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Third parties, including consultants, who we engage to provide
                  services on our behalf or to jointly provide services to you,
                  including but not limited to contests and sweepstakes,
                  marketing outreach and analytics, customer support, safety
                  checks, and payment services (such as Cyber Source).&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Social Networks, such as Facebook, where you have given
                  permission to do so or where you have used your social network
                  account to log-in to ShiaSpouse. If you have chosen to do
                  this, your data will be governed by the privacy policy of that
                  social network.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  To, in our discretion, (i) satisfy any applicable law,
                  regulation, subpoena/court order, legal process or other
                  government request, (ii) enforce our Terms of Service,
                  including the investigation of potential violations thereof,
                  (iii) investigate and defend ourselves against any third party
                  claims or allegations, (iv) protect against harm to the
                  rights, property or safety of ShiaSpouse, its users or the
                  public as required or permitted by law and (v) detect, prevent
                  or otherwise address criminal (including fraud or stalking),
                  security or technical issues.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  In connection with any company transaction, such as a merger,
                  sale of assets or shares, reorganization, financing, change of
                  control or acquisition of all or a portion of our business by
                  another company or third party or in the event of bankruptcy,
                  dissolution, divestiture or any related or similar
                  proceedings; and&nbsp;
                </p>
              </li>
              <li>
                <p>
                  We require all third parties to respect the security and
                  privacy of your personal data and to treat it in accordance
                  with the law. The majority of the third parties we share your
                  personal data with are service providers that are permitted to
                  process your personal data for specified purposes and in
                  accordance with our instructions only. Some third parties will
                  process your personal data for their own purposes as data
                  controllers; please refer to their privacy policy which
                  applies to their processing of your personal data. We are not
                  responsible for those third parties.&nbsp;
                </p>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>7. International Transfers</strong></p>
            <p>
              The personal data we collect will not be transferred to, and
              processed or stored outside of the EU (Including United Kingdom,
              regardless of Brexit).
            </p>
            <p>
              If you wish to enquire further about these safeguards used, please
              contact us using the details set out at the end of this Privacy
              Policy.
            </p>
            <p>&nbsp;</p>
            <p><strong>8. Data Security</strong></p>
            <p>
              We have extensive security measures in place to protect the loss,
              misuse and alteration of the information stored in our database.
              These measures include the use of Secure Socket Layer (SSL) and/or
              strong encryption technology during credit card transactions and
              administrative access to site data as well as other proprietary
              security measures which are applied to all repositories and
              transfers of user information. We will exercise reasonable care in
              providing secure transmission of information between your computer
              and our servers, but given that no information transmitted over
              the Internet can be guaranteed 100% secure, we cannot ensure or
              warrant the security of any information transmitted to us over the
              Internet and hence accept no liability for any unintentional
              disclosure.
            </p>
            <p>&nbsp;</p>
            <p><strong>9. Data Retention</strong></p>
            <p>
              We will only retain your personal data for as long as necessary to
              fulfil the purposes we collected it for (as set out above),
              including for the purpose of satisfying and legal, accounting, or
              reporting requirements.
            </p>
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature, and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data, and whether we can achieve those
              purposes through other means, and the applicable legal
              requirements.
            </p>
            <p>&nbsp;</p>
            <p><strong>10. Your Legal Rights</strong></p>
            <p>
              You may have certain rights under applicable data protection laws
              in relation to your personal data. You have the right to:
            </p>
            <ul>
              <li>
                <p>
                  Request access to your personal data (commonly known as a
                  “data subject access request”). This enables you to receive a
                  copy of the personal data we hold about you and to check that
                  we are lawfully processing it.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Request correction of the personal data that we hold about
                  you. This enables you to have any incomplete or inaccurate
                  data we hold about you corrected, though we may need to verify
                  the accuracy of the new data you provide to us.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Request erasure of your personal data. This enables you to ask
                  us to delete or remove personal data where there is no good
                  reason for us continuing to process it. You also have the
                  right to ask us to delete or remove your personal data where
                  you have successfully exercised your right to object to
                  processing (see below), where we may have processed your
                  information unlawfully or where we are required to erase your
                  personal data to comply with local law. Note, however, that we
                  may not always be able to comply with your request of erasure
                  for specific legal reasons which will be notified to you, if
                  applicable, at the time of your request.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Object to processing of your personal data where we are
                  relying on a legitimate interest (or those of a third party)
                  and there is something about your particular situation which
                  makes you want to object to processing on this ground as you
                  feel it impacts on your fundamental rights and freedoms. You
                  also have the right to object where we are processing your
                  personal data for direct marketing purposes. In some cases, we
                  may demonstrate that we have compelling legitimate grounds to
                  process your information which override your rights and
                  freedoms.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Request restriction of processing of your personal data. This
                  enables you to ask us to suspend the processing of your
                  personal data in the following scenarios: (a) if you want us
                  to establish the accuracy of the personal data; (b) where our
                  use of the data is unlawful but you do not want us to erase
                  it; (c) where we no longer need the personal data for the
                  purposes set out above, but we are required by you for the
                  establishment, exercise or defence of legal claims; or (d) you
                  have objected to our use of your data but we need to verify
                  whether we have overriding legitimate grounds to use it.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Request the transfer of your personal data to you or to a
                  third party. We will provide to you, or a third party you have
                  chosen, your personal data in a structured, commonly used,
                  machine-readable format. Note that this right only applies to
                  automated information which you initially provided consent for
                  us to use or where we used the information to perform a
                  contract with you.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Withdraw consent at any time where we are relying on consent
                  to process your personal data. However, this will not affect
                  the lawfulness of any processing carried out before you
                  withdraw your consent. If you withdraw your consent, we may
                  not be able to provide certain features of the Services to
                  you. We will advise you if this is the case at the time you
                  withdraw your consent.&nbsp;
                </p>
              </li>
            </ul>
            <p>
              If you wish to exercise any of these rights, you may contact us on
              details provided at the end of this policy.
            </p>
            <p>&nbsp;</p>
            <p><strong>11. Cookies and Similar Technologies</strong></p>
            <p>
              You may have certain rights under applicable data protection laws
              in relation to your personal data. You have the right to:
            </p>
            <ol>
              <li>
                <p>
                  The Service uses cookies and similar technologies to
                  distinguish you from other users of the Service. This helps us
                  to provide you with a good experience when you browse the
                  Service and also allows us to improve the Service.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Cookies are pieces of code that allow for personalisation of
                  the Service experience by saving your information such as user
                  ID and other preferences. A cookie is a small data file that
                  we transfer to your device's hard disk (such as your computer
                  or smartphone) for record-keeping purposes.&nbsp;
                </p>
              </li>
              <li>
                <p>We use the following types of cookies:</p>
                <ol>
                  <li>
                    <p>
                      <strong>Strictly necessary cookies</strong>. These are
                      cookies that are required for the essential operation of
                      the Service such as to authenticate users and prevent
                      fraudulent use.&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Analytical/performance cookies</strong>. These
                      allow us to recognise and count the number of visitors and
                      to see how visitors move around the Service when they are
                      using it. This helps us to improve the way the Service
                      works, for example, by ensuring that users are finding
                      what they are looking for easily.&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Functionality cookies</strong>. These are used to
                      recognise you when you return to the Service. This enables
                      us to personalise our content for you, greet you by name
                      and remember your preferences (for example, your choice of
                      language or region).&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Targeting cookies</strong>. These cookies record
                      your visit to our website, the pages you have visited and
                      the links you have followed. We will use this information
                      to make our website and the advertising displayed on it,
                      and the marketing messages we send to you more relevant to
                      your interests. We may also share this information with
                      third parties who provide a service to us for this
                      purpose.&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Third party cookies</strong>. Please be aware that
                      advertisers and other third parties may use their own
                      cookies tags when you click on an advertisement or link on
                      our website. These third parties are responsible for
                      setting out their own cookie and privacy policies.&nbsp;
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol>
              <li>
                <p>
                  The cookies we use are designed to help you get the most from
                  the Service but if you do not wish to receive cookies, most
                  browsers allow you to change your cookie settings. Please note
                  that if you choose to refuse cookies you may not be able to
                  use the full functionality of the Service. These settings will
                  typically be found in the “<strong>options</strong>” or
                  “<strong>preferences</strong>” menu of your browser. In order
                  to understand these settings, the following links may be
                  helpful, otherwise you should use the “<strong>Help</strong>”
                  option in your browser for more details.
                </p>
              </li>
            </ol>
            <ol>
              <li>
                <p>
                  <a href="https://support.apple.com/kb/PH5042?locale=en_GB"
                    >Cookie settings in Safari web</a
                  >&nbsp;and&nbsp;<a
                    href="https://support.apple.com/en-gb/HT201265"
                    >iOS.</a
                  >&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a href="https://support.google.com/chrome/answer/95647?hl=en"
                    >Cookie settings in Chrome</a
                  >&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a
                    href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                    >Cookie settings in Firefox</a
                  >&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a
                    href="https://support.microsoft.com/en-us/products/windows?os=windows-10"
                    >Cookie settings in Internet Explorer</a
                  >&nbsp;
                </p>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol>
              <li>
                <p>
                  We participate in interest-based advertising and use third
                  party advertising companies to serve you targeted
                  advertisements based on your online browsing history and your
                  interests. To do this, we or our advertising partners may
                  collect information about how you use or connect to our
                  Service, or the types of other websites, social media
                  services, content and ads that you (or others using your
                  device) visit or view or connect to our Service. Typically,
                  this information is collected through cookies and similar
                  tracking technologies.&nbsp;
                </p>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ul>
              <li>
                <p>
                  We also use clear gifs in HTML-based emails sent to our
                  Customers to track which emails are opened and which links are
                  clicked by recipients. The information allows for more
                  accurate reporting and improvement of our Service. You can set
                  your e-mail options to prevent the automatic downloading of
                  images that may contain these technologies that would allow us
                  to know whether you have accessed our e-mail and performed
                  certain functions with it.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  If you would like to find out more about cookies and other
                  similar technologies, please visit&nbsp;<a
                    href="http://www.allaboutcookies.org/"
                    >www.allaboutcookies.org</a
                  >&nbsp;or the Network Advertising Initiative's online sources
                  at&nbsp;<a href="https://www.networkadvertising.org/"
                    >www.networkadvertising.org</a
                  >.&nbsp;
                </p>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>12. Links To Third Party Sites</strong></p>
            <p>
              The Service may, from time to time, contain links to and from
              third party services. If you follow a link to any of these
              services, please note that these services have their own privacy
              policies and that we do not accept any responsibility or liability
              for their policies. Please check the individual policies before
              you submit any information to those services.
            </p>
            <p>&nbsp;</p>
            <p><strong>13. Our Policy Towards Children</strong></p>
            <p>
              The Service is not directed at persons under 16 and we do not
              knowingly collect personal data from persons under 16.
            </p>
            <p>&nbsp;</p>
            <p><strong>14. Changes To This Policy</strong></p>
            <p>
              We evaluate our privacy policies and procedures to implement
              improvements and refinements from time to time. Accordingly, we
              may update this Privacy Policy from time to time, and so you
              should review this page periodically. If we make material changes
              to this Privacy Policy, we will update the "last updated" data at
              the start of this Privacy Policy. Changes to this Privacy Policy
              are effective when they are posted on this page.
            </p>
            <p>&nbsp;</p>
            <p><strong>15. Notice To You</strong></p>
            <p>
              If we need to provide you with information about something,
              whether for legal, marketing or other business related purposes,
              we will select what we believe is the best way to get in contact
              with you. We will usually do this through email or by placing a
              notice on the Service. The fact that we may send notices to you
              will not stop you from being able to opt out of certain types of
              contact as described in this Privacy Policy.
            </p>
            <p>&nbsp;</p>
            <p><strong>16. Contacting Us</strong></p>
            <p>
              Questions, comments and requests regarding this Policy are welcome
              and should be sent to:
            </p>
            <ul>
              <li>
                <p>
                  Privacy Officer&nbsp;<br />
                  ShiaSpouse, 19 Ash Road, Woking, GU22 0BJ, UK<br />
                  admin&#64;ShiaSpouse.org
                </p>
              </li>
            </ul>
            <p>
              Please also visit our website&nbsp;<a
                href="https://www.ShiaSpouse.org/"
                >https://www.ShiaSpouse.org</a
              >&nbsp;and select our help link at the bottom right portion of the
              page. You will then be able to search our FAQ's to find the answer
              you are looking for, or send us an e-mail and our Customer Care
              agents will be happy to assist you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
