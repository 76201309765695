<div class="editProfile">
  <div class="hero">
    <img class="hero-image" [src]="editProfilePageInfo.profileHeroImage" alt="hero image" />
  </div>
  <div class="editProfile-section-Image-outer">
    <div class="editProfile-section-Image">
      <ng-container *ngIf="editProfilePageInfo.profileImage">
        <img class="editProfile-image" [src]="editProfilePageInfo.profileImage" alt="editProfile picture" />
      </ng-container>
    </div>
  </div>

  <div class="editProfile-main">
    <div class="editProfile-main-inner container" *ngIf="this.editProfileInputInfo.length > 0">
      <div class="editProfile-form" *ngFor="let input of this.editProfileInputInfo">
        <h2 class="editProfile-title">{{ input.label }}</h2>
        <div class="editProfile-input row">
          <div [ngClass]=" box.modelName === 'aboutMe'
                ? 'col-sm-12 mt-4'
                : 'col-md-6 mt-4'
            " *ngFor="let box of input.inputInfo">
            <app-text-box [inputInfo]="box" [changeFlag]="this.changeFlag" *ngIf="box.type == 'profileCreate'"
              (handleAddress)="handleAddress($event)" (updateData)="updateData($event, box.modelName)"
              [customValidator]="box.customValidator" (preSubmit)="preSubmit()" (submit)="onSubmit()"
              [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'" (hideData)="hideData($event, box.modelName)"
              [borderRadius]="'35px'" [width]="'100%'" [height]="'65px'" [padding]="'0 2rem'"
              [fontSize]="'clamp(14px, 3vw, 18px)'" [color]="'rgba(0, 0, 0, 0.87)'"
              [fontFamily]="'Montserrat'"></app-text-box>
            <app-drop-down *ngIf="box.type == 'profileCreateDropdown'" [inputInfo]="box" [width]="'100%'"
              (updateData)="updateData($event, box.modelName, true)" [customValidator]="box.customValidator"
              (hideData)="hideData($event, box.modelName)" [changeFlag]="changeFlag" [data]="box.option" [scrollHeight]="
                box.data?.length >= 1 && box.data?.length <= 3
                  ? box.data?.length * 50 + 'px'
                  : '200px'
              " (preSubmit)="preSubmit()" (submit)="onSubmit()">
            </app-drop-down>

            <app-multi-select *ngIf="box.type == 'profileCreateMulti'" [inputInfo]="box" [changeFlag]="changeFlag"
              (hideData)="hideData($event, box.modelName)" [data]="box.data"
              (updateData)="updateData($event, box.modelName)" (preSubmit)="preSubmit()"
              (submit)="onSubmit()"></app-multi-select>

            <app-calender [accessFrom]="'createProfile'" [inputInfo]="box" *ngIf="box.type == 'calender'"
              [changeFlag]="this.changeFlag" [selectionMode]="'basic'" [inLine]="false" [maxDate]="maxDate"
              (updateData)="updateData($event, box.modelName)" (hideData)="hideData($event, box.modelName)"
              (preSubmit)="preSubmit()" (submit)="onSubmit()"></app-calender>

            <app-text-area [inputInfo]="box" [changeFlag]="this.changeFlag" *ngIf="box.type == 'textArea'"
              (updateData)="updateData($event, box.modelName)" [customValidator]="box.customValidator"
              (preSubmit)="preSubmit()" (submit)="onSubmit()" [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
              (hideData)="hideData($event, box.modelName)" [borderRadius]="'35px'" [width]="'100%'"
              [borderRadius]="'20px'"></app-text-area>
            <app-radio-button *ngIf="box.type == 'radio'" [inputInfo]="box"
              (updateData)="updateData($event, box.modelName)" [changeFlag]="changeFlag" [data]="box.option"
              (preSubmit)="preSubmit()" (submit)="onSubmit()">
            </app-radio-button>
            <ng-container *ngIf="this.editProfileInfo['disability'] == 1">
              <app-text-area [inputInfo]="box" [changeFlag]="this.changeFlag"
                *ngIf="box.modelName === 'disabilityDetails'" (updateData)="updateData($event, box.modelName)"
                [customValidator]="box.customValidator" (preSubmit)="preSubmit()" (submit)="onSubmit()"
                (hideData)="hideData($event, box.modelName)" [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
                [borderRadius]="'35px'" [width]="'80%'" [borderRadius]="'20px'" [fontSize]="'clamp(14px, 3vw, 18px)'"
                [color]="'rgba(0, 0, 0, 0.87)'" [fontFamily]="'Montserrat'"></app-text-area>
            </ng-container>
          </div>
        </div>
      </div>
      <app-button [label]="'Submit'" [padding]="'5px 0'" [color]="'#fff'" [type]="'login'" (button)="preSubmit()"
        [bgColor]="'var(--color-pink-bg)'" [borderRadius]="'35px !important'" [fontSize]="'clamp(16px, 4vw, 22px)'"
        [maxWidth]="'400px'" [padding]="'1rem 2rem'">
      </app-button>
    </div>
  </div>
</div>