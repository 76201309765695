import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { JwtResponse } from "../auth/jwt-response";
import { TokenStorageService } from "../auth/token-storage.service";
import { Observable, Subject } from "rxjs";
import { ProfileCreateInfo } from "../models/profileCreate-info";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private otpComponentDestroyed = new Subject<void>();
  private profileImageChanged = new Subject<void>();

  otpComponentDestroyed$ = this.otpComponentDestroyed.asObservable();
  profileImageChanged$ = this.profileImageChanged.asObservable();

  triggerOtpComponentDestroyed() {
    this.otpComponentDestroyed.next();
  }

  triggerProfileImageChange() {
    this.profileImageChanged.next();
  }

  private baseUrl = environment.url;
  httpOptionsSaved = {
    // headers: new HttpHeaders({
    //   "Content-Type": "*/*",
    //   Accept: "*/*",
    //   "Access-Control-Allow-Origin": `${this.baseUrl}`,
    // }),
  };

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) { }


  getCaptcha(): Observable<any> {
    return this.http.get<any>(
      `${environment.url}/site-api/get-captcha?rand=${Math.random()}`,
      this.httpOptionsSaved
    );
  }

  getAllOptions(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/site-api/signupdropdown`,
      this.httpOptionsSaved
    );
  }

  createProfile(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/site-api/register`,
      data,
      this.httpOptionsSaved
    );
  }

  mymatches(data: any, page?: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        // "Access-Control-Allow-Origin": `${this.baseUrl}`,
        Authorization: this.tokenStorage.getToken(),
      }),
    };
    return this.http.get<any>(
      `${this.baseUrl
      }/site-api/mymatches?${data}&access-token=${this.tokenStorage.getToken()}`,
      httpOptionsSaved
    );
  }



  searchResult(data: any, page?: any): Observable<any> {
    const authToken = localStorage.getItem('AuthToken');

    if (authToken) {
      const httpOptionsSaved = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "*/*",
          // "Access-Control-Allow-Origin": `${this.baseUrl}`,
          Authorization: this.tokenStorage.getToken(),
        }),
      };
      return this.http.get<any>(
        `${this.baseUrl
        }/site-api/search-auth?page=${page}&${data}`,
        httpOptionsSaved
      );
    } else {
      const httpOptionsSaved = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "*/*",
          // "Access-Control-Allow-Origin": `${this.baseUrl}`,
          // Authorization: this.tokenStorage.getToken(),
        }),
      };
      return this.http.get<any>(
        `${this.baseUrl
        }/site-api/search?page=${page}&${data}`,
        httpOptionsSaved
      );
    }

  }




  // savesearch api integration 
  saveSearch(data: any): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: this.tokenStorage.getToken(),
      }),
    };

    // Combine search_name and data into a single object


    // Send the POST request with the combined payload
    return this.http.post<any>(
      `${this.baseUrl}/site-api/save-search`,
      data,
      httpOptionsSaved
    );
  }

  // get all searches 
  getAllSearches(): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: this.tokenStorage.getToken(),
      }),
    };

    // Combine search_name and data into a single object


    // Send the POST request with the combined payload
    return this.http.get<any>(
      `${this.baseUrl}/site-api/get-search`,
      httpOptionsSaved
    );
  }

  deleteSearch(id): Observable<any> {
    const httpOptionsSaved = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: this.tokenStorage.getToken(),
      }),
    };

    // Combine search_name and data into a single object


    // Send the POST request with the combined payload
    return this.http.post<any>(
      `${this.baseUrl}/site-api/delete-search`,
      { id: id },
      httpOptionsSaved
    );


  }

}
