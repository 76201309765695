import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SidePanelComponent } from "./component/htmlComponents/side-panel/side-panel.component";
import { AboutusComponent } from "./modules/pages/aboutus/aboutus.component";
import { BooksComponent } from "./modules/pages/books/books.component";
import { EventComponent } from "./modules/pages/event/event.component";
import { EventsComponent } from "./modules/pages/events/events.component";
import { FooterComponent } from "./modules/pages/footer/footer.component";
import { HeaderComponent } from "./modules/pages/header/header.component";
import { HomeComponent } from "./modules/pages/home/home.component";
import { MessagesComponent } from "./modules/pages/messages/messages.component";
import { MyMatchesComponent } from "./modules/pages/my-matches/my-matches.component";
import { MyShortlistComponent } from "./modules/pages/my-shortlist/my-shortlist.component";
import { NavigationsComponent } from "./modules/pages/navigations/navigations.component";
import { ProfileCreateComponent } from "./modules/pages/profile-create/profile-create.component";
import { NotificationComponent } from "./modules/pages/notification/notification.component";
import { StoriesComponent } from "./modules/pages/stories/stories.component";
import { SubscriptionComponent } from "./modules/pages/subscription/subscription.component";
import { UserNavigationComponent } from "./modules/pages/user-navigation/user-navigation.component";
import { VidoesComponent } from "./modules/pages/vidoes/vidoes.component";
import { LoginComponent } from "./modules/shared/sharedPages/login/login.component";
import { SignUpComponent } from "./modules/shared/sharedPages/sign-up/sign-up.component";
import { ProfileComponent } from "./modules/pages/profile/profile.component";
import { SearchResultComponent } from "./modules/pages/search-result/search-result.component";
import { LoggedInGuard } from "./guards/logged-in.guard";
import { OtpComponent } from "./modules/pages/otp/otp.component";
import { VerifiedGuard } from "./guards/verified.guard";
import { EditProfileComponent } from "./modules/pages/edit-profile/edit-profile.component";
import { RecoverComponent } from "./modules/pages/recover/recover.component";
import { ResetPasswordComponent } from "./modules/shared/sharedPages/reset-password/reset-password.component";
import { RedirectGuard } from "./guards/redirect.guard";
import { EventRegistrationComponent } from "./modules/pages/event-registration/event-registration.component";
import { SettingsComponent } from "./modules/pages/settings/settings.component";
import { FaqComponent } from "./modules/pages/faq/faq.component";
import { TermsOfUseComponent } from "./modules/pages/terms-of-use/terms-of-use.component";
import { PrivacyPolicyComponent } from "./modules/pages/privacy-policy/privacy-policy.component";
import { AdvancedSearchComponent } from "./advanced-search/advanced-search.component";
import { UserProfileViewComponent } from "./user-profile-view/user-profile-view.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";

const routes: Routes = [
  // { path: "sign-up", component: SignUpComponent },
  // { path: "side-panel", component: SidePanelComponent },

  {
    path: "",
    canActivate: [RedirectGuard],
    component: NavigationsComponent,
    children: [
      // { path: "", pathMatch: "full", redirectTo: "home" },
      { path: "login", component: LoginComponent },
      { path: "user/signup", component: ProfileCreateComponent },
      { path: "user/recover", component: RecoverComponent },
      // { path: "header", component: HeaderComponent },
      { path: "site/books", component: BooksComponent },
      { path: "article", component: StoriesComponent },
      { path: "site/video", component: VidoesComponent },
      { path: "site/about", component: AboutusComponent },
      { path: "", component: HomeComponent },
      { path: "events", component: EventsComponent },
      { path: "event", component: EventComponent },
      // { path: "footer", component: FooterComponent },
      { path: "search", component: SearchResultComponent },
      { path: "reset-password", component: ResetPasswordComponent },
      { path: "site/faq", component: FaqComponent },
      { path: "terms", component: TermsOfUseComponent },
      { path: "policy", component: PrivacyPolicyComponent },
      { path: "site/search", component: AdvancedSearchComponent },
      { path: "contactus", component: ContactUsComponent },
    ],
  },
  {
    path: "user",
    component: UserNavigationComponent,
    canActivate: [LoggedInGuard],
    children: [
      // { path: "", pathMatch: "full", redirectTo: "home" },
      {
        path: "otp",
        canActivate: [LoggedInGuard],
        component: OtpComponent,
      },
      {
        path: "",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: HomeComponent,
      },

      {
        path: "site/books",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: BooksComponent,
      },
      {
        path: "article",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: StoriesComponent,
      },
      {
        path: "site/video",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: VidoesComponent,
      },
      {
        path: "site/about",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: AboutusComponent,
      },
      {
        path: "events",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: EventsComponent,
      },
      {
        path: "contactus",
        // canActivate: [LoggedInGuard, VerifiedGuard],
        component: ContactUsComponent,
      },
      {
        path: "event",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: EventComponent,
      },
      {
        path: "my-matches",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: MyMatchesComponent,
      },
      {
        path: "wishlist",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: MyShortlistComponent,
      },
      {
        path: "chat/panel",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: MessagesComponent,
      },
      {
        path: "site/user-view",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: NotificationComponent,
      },
      {
        path: "plans",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: SubscriptionComponent,
      },
      {
        path: "site/search",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: AdvancedSearchComponent,
      },
      {
        path: "search",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: SearchResultComponent,
      },
      {
        path: "edit-profile",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: EditProfileComponent,
      },
      {
        path: "settings",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: SettingsComponent,
      },
      {
        path: "user-changepassword",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: ResetPasswordComponent,
      },
      {
        path: "event-registration",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: EventRegistrationComponent,
      },
      {
        path: "site/faq",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: FaqComponent,
      },
      {
        path: "terms",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: TermsOfUseComponent,
      },
      {
        path: "policy",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: PrivacyPolicyComponent,
      },
      {
        path: ":name",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: ProfileComponent,
      },
      {
        path: "profile-view/:name",
        canActivate: [LoggedInGuard, VerifiedGuard],
        component: UserProfileViewComponent,
      },

    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
