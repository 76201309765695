import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { Carousel } from "primeng/carousel";
import { Observable } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { InputInfo } from "src/app/component/componentModel/input-info";
import { MessageServiceService } from "src/app/message.service";
import { MatchCardInfo } from "src/app/models/matchCard-info";
import { SearchInfo } from "src/app/models/search-info";
import { UserInfo } from "src/app/models/user-info";
import { ProfileService } from "src/app/services/profile.service";

@Component({
  selector: "app-my-matches",
  templateUrl: "./my-matches.component.html",
  styleUrls: ["./my-matches.component.css"],
})
export class MyMatchesComponent implements OnInit {
  inputInfo: InputInfo[] = [];
  searchInfo: SearchInfo = new SearchInfo();
  searchedData: SearchInfo;
  userInfo: UserInfo = new UserInfo();

  changeFlag = false;
  counterInputs = 0;
  displayFilter: boolean = false;
  paginatorChangeFlag = false;
  previousPage = 0;

  dropdownOptions: SearchInfo = new SearchInfo();

  matchInfo: MatchCardInfo[];
  matchDisplay: MatchCardInfo[];
  isBrowser: Boolean = false;
  isLoggedIn:Boolean = true;
  page = 1;
  totalCount;
  rowsCount;
  isMobileView: boolean;
  searchResultCount: number = 0;
  queryString: string;
  savedSearch: MenuItem[] | undefined;
  savedSearchMobile: MenuItem[] | undefined;
  displayDialog: boolean = false;
  searchTitle: string;
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (this.isBrowser) {
      const width = event.target.innerWidth;
      this.isMobileView = width < 769;
    }
  }

  constructor(
    private messageService: MessageServiceService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private profileService: ProfileService,
    private Jarvis: AuthService,
    private tokenStorage: TokenStorageService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    Carousel.prototype.onTouchMove = () => {};
  }


  // save search model 
  showDialog() {
    this.displayDialog = true;
  }

  onCancel() {
    this.searchTitle = '';
    this.displayDialog = false;
  }

  handleSaveSearch(data){
    this.searchTitle = '';
    if (data.status == 200) {
      this.messageService.addMessages(
        "success",
        data.data.msg,
        ""
      );
      this.getAllSearches()
    }

  }

  getSavedSearch(obj: string): void {
    let decodedStr = JSON.parse(obj);
    this.router.navigate(['/user/site/search/'], { queryParams:decodedStr });
  }
  
  

  onSaveSearch() {
    const searchParams = this.router.routerState.snapshot.root.queryParams;
    const searchJson = JSON.stringify(searchParams);
    const data = {
    search_name: this.searchTitle,
      data:searchParams
    }
    this.profileService.saveSearch(data).subscribe(
      (data) => this.handleSaveSearch(data),
      (error) => this.handleError(error)
    )

    // Call your API with the searchJson here
    // Example: this.httpClient.post('your-api-endpoint', searchJson).subscribe();

    this.displayDialog = false;
  }

  resetFilters() {
    this.router.navigate(['/user/site/search/'], { queryParams: {} });
  }


  // getAll searchs 
  getAllSearches() {
    this.profileService.getAllSearches().subscribe(
      (data) => this.handleGetAllSearches(data),
      (error) => this.handleError(error)
    );
  }

  handleGetAllSearches(data){
    this.savedSearch = data.data;

    this.handleAllSearchesforMObile(data)
  }


  handleAllSearchesforMObile(data){

    this.savedSearchMobile = data.data.map(item => ({
      label: item.name,
      command: () => this.getSavedSearch(item.json_obj) // Handle click event
    }));
  }


  deleteSavedSearch(id:any){
    this.profileService.deleteSearch(id).subscribe(
      (data) => this.handleDeleteSavedSearch(data),
      (error) => this.handleError(error)
    );
  }

  handleDeleteSavedSearch(data){
    if (data.status == 200) {
      this.messageService.addMessages(
        "success",
        data.data.msg,
        ""
      );
      this.getAllSearches();
    }
  }
  ngOnInit(): void {
    // saved search items render from api 
    

  this.getAllSearches();



    if (this.isBrowser) {
      // Set initial value of isMobileView based on window width
      const width = window.innerWidth;
      this.isMobileView = width < 769;
    }
    this.getOwnProfileData();
    this.getOptions();
  
  }
  getOwnProfileData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {

    let protectedProfileStatus;
    if (data.data.sentProfileRequest) {
      if (data.data.sentProfileRequest.state_id == 1) {
        protectedProfileStatus = false;
      } else {
        protectedProfileStatus = true;
      }
    } else {
      protectedProfileStatus = true;
    }
    const userData = data.data.user;
    this.userInfo.name = data.data.profile.nickname;
    this.userInfo.image = userData.profile_file;
  }

  getOptions() {
    this.profileService.getAllOptions().subscribe(
      (data) => this.handleOptions(data),
      (error) => this.handleError(error)
    );
  }

  handleOptions(data) {
    this.dropdownOptions.country = [];
    this.dropdownOptions.occupation = [];
    this.dropdownOptions.ethnicity = [];
    this.dropdownOptions.is_syed = [];
    this.dropdownOptions.education = [];
    this.dropdownOptions.education = [];
    this.dropdownOptions.relationship_status = [];
    this.dropdownOptions.working_status = [];
    this.dropdownOptions.language = [];

    this.dropdownOptions.looking_for = [
      { name: `Male`, value: `1` },
      { name: `Female`, value: `2` },
    ];

    for (let [key, value] of Object.entries(data.data.CountryOptions)) {
      this.dropdownOptions.country.push({
        value: key,
        name: value.toString(),
      });
    }

    this.dropdownOptions.age_from = [
      // { name: 16, value: 16 },
      // { name: 17, value: 17 },
      { name: 18, value: 18 },
      { name: 19, value: 19 },
      { name: 20, value: 20 },
      { name: 21, value: 21 },
      { name: 22, value: 22 },
      { name: 23, value: 23 },
      { name: 24, value: 24 },
      { name: 25, value: 25 },
      { name: 26, value: 26 },
      { name: 27, value: 27 },
      { name: 28, value: 28 },
      { name: 29, value: 29 },
      { name: 30, value: 30 },
      { name: 31, value: 31 },
      { name: 32, value: 32 },
      { name: 33, value: 33 },
      { name: 34, value: 34 },
      { name: 35, value: 35 },
      { name: 36, value: 36 },
      { name: 37, value: 37 },
      { name: 38, value: 38 },
      { name: 39, value: 39 },
      { name: 40, value: 40 },
      { name: 41, value: 41 },
      { name: 42, value: 42 },
      { name: 43, value: 43 },
      { name: 44, value: 44 },
      { name: 45, value: 45 },
      { name: 46, value: 46 },
      { name: 47, value: 47 },
      { name: 48, value: 48 },
      { name: 49, value: 49 },
      { name: 50, value: 50 },
      { name: 51, value: 51 },
      { name: 52, value: 52 },
      { name: 53, value: 53 },
      { name: 54, value: 54 },
      { name: 55, value: 55 },
      { name: 56, value: 56 },
      { name: 57, value: 57 },
      { name: 58, value: 58 },
      { name: 59, value: 59 },
      { name: 60, value: 60 },
      { name: 61, value: 61 },
      { name: 62, value: 62 },
      { name: 63, value: 63 },
      { name: 64, value: 64 },
      { name: 65, value: 65 },
      { name: 66, value: 66 },
      { name: 67, value: 67 },
      { name: 68, value: 68 },
      { name: 69, value: 69 },
      { name: 70, value: 70 },
      { name: 71, value: 71 },
      { name: 72, value: 72 },
      { name: 73, value: 73 },
      { name: 74, value: 74 },
      { name: 75, value: 75 },
      { name: 76, value: 76 },
      { name: 77, value: 77 },
      { name: 78, value: 78 },
      { name: 79, value: 79 },
      { name: 80, value: 80 },
    ];

    this.dropdownOptions.age_to = [
      // { name: 16, value: 16 },
      // { name: 17, value: 17 },
      { name: 18, value: 18 },
      { name: 19, value: 19 },
      { name: 20, value: 20 },
      { name: 21, value: 21 },
      { name: 22, value: 22 },
      { name: 23, value: 23 },
      { name: 24, value: 24 },
      { name: 25, value: 25 },
      { name: 26, value: 26 },
      { name: 27, value: 27 },
      { name: 28, value: 28 },
      { name: 29, value: 29 },
      { name: 30, value: 30 },
      { name: 31, value: 31 },
      { name: 32, value: 32 },
      { name: 33, value: 33 },
      { name: 34, value: 34 },
      { name: 35, value: 35 },
      { name: 36, value: 36 },
      { name: 37, value: 37 },
      { name: 38, value: 38 },
      { name: 39, value: 39 },
      { name: 40, value: 40 },
      { name: 41, value: 41 },
      { name: 42, value: 42 },
      { name: 43, value: 43 },
      { name: 44, value: 44 },
      { name: 45, value: 45 },
      { name: 46, value: 46 },
      { name: 47, value: 47 },
      { name: 48, value: 48 },
      { name: 49, value: 49 },
      { name: 50, value: 50 },
      { name: 51, value: 51 },
      { name: 52, value: 52 },
      { name: 53, value: 53 },
      { name: 54, value: 54 },
      { name: 55, value: 55 },
      { name: 56, value: 56 },
      { name: 57, value: 57 },
      { name: 58, value: 58 },
      { name: 59, value: 59 },
      { name: 60, value: 60 },
      { name: 61, value: 61 },
      { name: 62, value: 62 },
      { name: 63, value: 63 },
      { name: 64, value: 64 },
      { name: 65, value: 65 },
      { name: 66, value: 66 },
      { name: 67, value: 67 },
      { name: 68, value: 68 },
      { name: 69, value: 69 },
      { name: 70, value: 70 },
      { name: 71, value: 71 },
      { name: 72, value: 72 },
      { name: 73, value: 73 },
      { name: 74, value: 74 },
      { name: 75, value: 75 },
      { name: 76, value: 76 },
      { name: 77, value: 77 },
      { name: 78, value: 78 },
      { name: 79, value: 79 },
      { name: 80, value: 80 },
    ];
    for (let [key, value] of Object.entries(data.data.ProfessionOptions)) {
      this.dropdownOptions.occupation.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.EthnicityOptions)) {
      this.dropdownOptions.ethnicity.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.Syed)) {
      this.dropdownOptions.is_syed.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.LanguageOptions)) {
      this.dropdownOptions.language.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.EducationOptions)) {
      this.dropdownOptions.education.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.MaritalOptions)) {
      this.dropdownOptions.relationship_status.push({
        value: key,
        name: value.toString(),
      });
    }

    for (let [key, value] of Object.entries(data.data.WorkingStatusOptions)) {
      this.dropdownOptions.working_status.push({
        value: key,
        name: value.toString(),
      });
    }

    this.dropdownOptions.rating = [
      { name: 1, value: 1 },
      { name: 2, value: 2 },
      { name: 3, value: 3 },
      { name: 4, value: 4 },
      { name: 5, value: 5 },
    ];
    this.dropdownOptions.body_type = [
      { name: "Slim", value: 1 },
      { name: "Athletic", value: 2 },
      { name: "Average", value: 3 },
      { name: "Heavy", value: 4 },
    ];
    this.dropdownOptions.skin_tone = [
      { name: "Fair", value: 1 },
      { name: "Medium", value: 2 },
      { name: "Brown", value: 3 },
      { name: "White", value: 4 },
      { name: "Black", value: 5 },
      { name: "Other", value: 6 },
    ];
    this.dropdownOptions.smoke = [
      { name: "Socially", value: 1 },
      { name: "Occasionally", value: 2 },
      { name: "Regularly", value: 3 },
      { name: "Do not smoke", value: 4 },
    ];
    this.processQueryParams();
  }

  processQueryParams() {
    // let filterDataCalled = false;
    this.searchedData = new SearchInfo();
    this.activatedRouter.queryParams.subscribe((params) => {
      if (typeof params === "object" && Object.keys(params).length !== 0) {
        this.searchedData = new SearchInfo();

        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const paramValue = params[key];
            const dropdownEnteries = Object.entries(this.dropdownOptions);
            if (Array.isArray(paramValue)) {
              let tempArr = [];
              for (let value of paramValue) {
                const foundEl = dropdownEnteries
                  .find((element) => key == element[0])[1]
                  .find((el) => el.value == value);

                if (foundEl) {
                  tempArr.push(foundEl);
                }
              }
              this.searchedData[key] = tempArr;
            } else {
              const foundEl = dropdownEnteries
                .find((element) => key == element[0])[1]
                .find((el) => el.value == paramValue || el.name == paramValue);

              if (foundEl) {
                this.searchedData[key] = foundEl;
              }
            }
          }
        }
      } else {
        this.searchedData = new SearchInfo();
      }
      this.filterData(this.searchedData);
      this.generateFormData();
    });
  }
  generateFormData() {
    this.inputInfo = [];

    this.createInput(
      "Looking For",
      "looking_for",
      "text",
      "Looking For",
      "myMatches",
      this.searchedData.looking_for ? this.searchedData.looking_for : "",
      this.dropdownOptions.looking_for,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Type",
        // },
      ]
    );
    this.createInput(
      "Country",
      "country",
      "text",
      "Country",
      "myMatches",
      this.searchedData.country ? this.searchedData.country : "",
      this.dropdownOptions.country,
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Country",
        // },
      ]
    );
    this.createInput(
      "Age From",
      "age_from",
      "text",
      "Age From",
      "myMatches",
      this.searchedData.age_from ? this.searchedData.age_from : "",
      this.dropdownOptions.age_from,
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Age To",
      "age_to",
      "text",
      "Age To",
      "myMatches",
      this.searchedData.age_to ? this.searchedData.age_to : "",
      this.dropdownOptions.age_to,
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );

    this.createInput(
      "Occupation",
      "occupation",
      "text",
      "Occupation",
      "myMatches",
      this.searchedData.occupation ? this.searchedData.occupation : "",
      this.dropdownOptions.occupation,
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Ethnicity/Culture",
      "ethnicity",
      "text",
      "Ethnicity/Culture",
      "myMatches",
      this.searchedData.ethnicity ? this.searchedData.ethnicity : "",
      this.dropdownOptions.ethnicity,
      true,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Syed/Non-Syed",
      "is_syed",
      "text",
      "Syed/Non-Syed",
      "myMatches",
      this.searchedData.is_syed ? this.searchedData.is_syed : "",
      this.dropdownOptions.is_syed,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Language",
      "language",
      "multi",
      "Language",
      "myMatches",
      this.searchedData.language
        ? Array.isArray(this.searchedData.language) == false
          ? [this.searchedData.language]
          : this.searchedData.language
        : "",
      this.dropdownOptions.language,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Education",
      "education",
      "multi",
      "Education",
      "myMatches",
      this.searchedData.education
        ? Array.isArray(this.searchedData.education) == false
          ? [this.searchedData.education]
          : this.searchedData.education
        : "",
      this.dropdownOptions.education,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );

    this.createInput(
      "Relationship",
      "relationship_status",
      "multi",
      "Relationship",
      "myMatches",
      this.searchedData.relationship_status
        ? Array.isArray(this.searchedData.relationship_status) == false
          ? [this.searchedData.relationship_status]
          : this.searchedData.relationship_status
        : "",
      this.dropdownOptions.relationship_status,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Working Status",
      "working_status",
      "multi",
      "Working Status",
      "myMatches",
      this.searchedData.working_status
        ? Array.isArray(this.searchedData.working_status) == false
          ? [this.searchedData.working_status]
          : this.searchedData.working_status
        : "",
      this.dropdownOptions.working_status,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Profile Rating",
      "rating",
      "multi",
      "Profile Rating",
      "myMatches",
      this.searchedData.rating
        ? Array.isArray(this.searchedData.rating) == false
          ? [this.searchedData.rating]
          : this.searchedData.rating
        : "",
      this.dropdownOptions.rating,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Body Type",
      "body_type",
      "multi",
      "Body Type",
      "myMatches",
      this.searchedData.body_type
        ? Array.isArray(this.searchedData.body_type) == false
          ? [this.searchedData.body_type]
          : this.searchedData.body_type
        : "",
      this.dropdownOptions.body_type,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
    this.createInput(
      "Skin Tone",
      "skin_tone",
      "multi",
      "Skin Tone",
      "myMatches",
      this.searchedData.skin_tone
        ? Array.isArray(this.searchedData.skin_tone) == false
          ? [this.searchedData.skin_tone]
          : this.searchedData.skin_tone
        : "",
      this.dropdownOptions.skin_tone,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );

    this.createInput(
      "Smoking",
      "smoke",
      "multi",
      "Smoking",
      "myMatches",
      this.searchedData.smoke
        ? Array.isArray(this.searchedData.smoke) == false
          ? [this.searchedData.smoke]
          : this.searchedData.smoke
        : "",
      this.dropdownOptions.smoke,
      false,
      [
        // {
        //   type: Validators.required,
        //   msg: "You must select Age",
        // },
      ]
    );
  }
  createInput(
    label,
    modelName,
    inputType,
    placeHolder,
    type,
    value,
    data,
    search,
    validatorsInfo
  ) {
    const inputObj = new InputInfo();
    inputObj.label = label;
    inputObj.modelName = modelName;
    inputObj.inputType = inputType;
    inputObj.placeHolder = placeHolder;
    inputObj.type = type;
    inputObj.value = value;
    inputObj.val = value;
    inputObj.data = data;
    inputObj.search = search;
    inputObj.validatorsInfo = validatorsInfo;
    this.inputInfo.push(inputObj);
  }

  onFilterSubmit(event) {
    this.onSearchedData(event);
  }

  onUpdateData(event) {}

  onSearchedData(value) {
    const isEmpty = Object.keys(value).length === 0;
    if (isEmpty) {
      this.searchInfo = new SearchInfo();
      this.searchInfo.looking_for = "";
      this.searchInfo.country = "";
      this.searchInfo.age_from = "";
      this.searchInfo.age_to = "";
    }
    this.matchDisplay = [];
    this.router.navigate([`/user/site/search/`], {
      queryParams: isEmpty == true ? this.searchInfo : value,
    });
    // this.processQueryParams();
  }

  async filterData(data) {
    const queryString = await this.constructQueryString(data);
    this.queryString = queryString;
    this.paginatorChangeFlag = false;
    this.getSearchResult(queryString);
  }

  constructQueryString(data: any): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      const queryStringParts = [];
      // Define a list of keys you want to include
      const keysToInclude = [
        "looking_for",
        "country",
        "age_from",
        "age_to",
        "occupation",
        "ethnicity",
        "is_syed",
        "language",
        "education",
        "relationship_status",
        "working_status",
        "rating",
        "body_type",
        "skin_tone",
        "smoke",
      ];
      for (const key of keysToInclude) {
        const value = data[key] !== undefined ? data[key] : "";
        if (value !== undefined && value !== null && value !== "") {
          if (Array.isArray(value) && value.length > 0) {
            let index = 0;
            for (let info of value) {
              if (
                info.value &&
                (key == "education" ||
                  key == "relationship_status" ||
                  key == "working_status" ||
                  key == "smoke" ||
                  key == "body_type" ||
                  key == "skin_tone")
              ) {
                queryStringParts.push(
                  `UserProfile[${key}][${index}]=${encodeURIComponent(
                    info.value.toString()
                  )}`
                );
                index++;
              } else if (info.value && key == "rating") {
                queryStringParts.push(
                  `UserProfile[star_rating][${index}]=${encodeURIComponent(
                    info.value.toString()
                  )}`
                );
                index++;
              } else if (info.value) {
                queryStringParts.push(
                  `User[${key}][${index}]=${encodeURIComponent(
                    info.value.toString()
                  )}`
                );
                index++;
              }
            }
          } else {
            if (key == "occupation") {
              queryStringParts.push(
                `User[profession]=${encodeURIComponent(value.value.toString())}`
              );
            } else if (key == "country") {
              queryStringParts.push(
                `User[${key}]=${encodeURIComponent(value.name)}`
              );
            } else if (
              key == "education" ||
              key == "relationship_status" ||
              key == "working_status" ||
              key == "rating" ||
              key == "smoke" ||
              key == "body_type" ||
              key == "skin_tone"
            ) {
              queryStringParts.push(
                `UserProfile[${key}][0]=${encodeURIComponent(
                  value.value.toString()
                )}`
              );
            } else if (key == "rating") {
              queryStringParts.push(
                `UserProfile[star_rating][0]=${encodeURIComponent(
                  value.value.toString()
                )}`
              );
            } else {
              queryStringParts.push(
                `User[${key}]=${encodeURIComponent(value.value.toString())}`
              );
            }
          }
        }
        // else if (
        //   (Object.keys(data)[0] == "looking_for" ||
        //     Object.keys(data)[0] == "country" ||
        //     Object.keys(data)[0] == "age_from" ||
        //     Object.keys(data)[0] == "age_to") &&
        //   value === ""
        // ) {
        //   queryStringParts.push(
        //     `User[${key}]=${encodeURIComponent(value.toString())}`
        //   );
        // }
      }
      const queryString = queryStringParts.join("&");
      resolve(queryString);
    });
  }

  getSearchResult(data: any) {
    if (data == "") {
      data = `User[country]=&User[age_from]=&User[age_to]=`;
    }
    this.profileService.mymatches(data, this.page).subscribe(
      (data) => this.handleSearchResult(data),
      (error) => this.handleError(error)
    );
  }

  handleSearchResult(data) {

    this.matchInfo = [];
    this.searchResultCount = data.pagination.totalCount;
    if (data.pagination && this.paginatorChangeFlag == false) {
      this.totalCount = data.pagination.totalCount;
      this.rowsCount = data.pagination.pageSize;
    }
    if (data.data.length > 0) {
      for (let p of data.data) {
        let protectedProfileStatus;
        if (p.sentProfileRequest) {
          if (p.sentProfileRequest.state_id == 1) {
            protectedProfileStatus = false;
          } else {
            protectedProfileStatus = true;
          }
        } else {
          protectedProfileStatus = true;
        }
        let timeDiff = Math.abs(
          Date.now() - new Date(p.date_of_birth).getTime()
        );
        const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        let obj = {
          id: p.id,
          image: p.profile_file,
          name: p.full_name,
          description: p.about_me ? p.about_me : "",
          age: age,
          height: p.height ? p.height : "",
          profession: p.profession
            ? this.dropdownOptions.occupation.find(
                (el) => el.value == p.profession
              ).name
            : "",
          country: p.country,
          address: p.country + " , " + p.city,
          status: p.status ? p.status : "Unverified",
          gender: p.gender == 1 ? "Male" : "Female",
          shortlist: p.in_wishlist,
        };

        this.matchInfo.push(obj);
      }
    }

    if (this.isMobileView) {
      if (!this.matchDisplay) {
        this.matchDisplay = [];
      }
      this.matchDisplay = this.matchDisplay.concat(this.matchInfo);
    } else {
      this.matchDisplay = this.matchInfo;
    }

  }

  onCardClicked(data) {
    if (data.value && data.cardName == "match&shortListCard") {
      this.router.navigateByUrl(`user/${data.id}`);
    }
  }

  onCardButtonClick(event) {
    if (event.cardButtonName === "messageBtn") {
      const id = event.id;
      this.router.navigate(["user/chat/panel"], {
        queryParams: { id },
      });
    }
    if (event.cardButtonName === "favouriteBtn") {
      let formData = new FormData();
      formData.append("id", event.id);
      this.Jarvis.shortlistToggle(formData).subscribe(
        (data) => this.handleShortlistToggle(data),
        (error) => this.handleError(error)
      );
    }
  }

  onPageClicked(event) {
    if (
      event.page > this.previousPage &&
      event.page < this.totalCount &&
      event.page % 12 == 11
    ) {
      this.page = this.page + 1;
      this.getSearchResult(this.queryString);
      this.previousPage = event.page;
    }
  }

  handleShortlistToggle(data) {
    if (data.status == 200) {
      this.messageService.addMessages(
        "success",
        "Success",
        `Person has been ${
          data.data.message === "added" ? "added to" : "removed from"
        } your wishlist.`
      );
    }
  }

  filterDialogClosed() {
    this.displayFilter = false;
  }
  showFilter() {
    this.displayFilter = true;
  }

  handleError(error) {
    let errorMessage =
      error.error && error.error.message
        ? error.error.message
        : error.error && error.error.reason
        ? error.error.reason
        : error.message;
    this.messageService.addMessages(`error`, `Error`, errorMessage);
  }
  nextRecords(event) {
    this.page = event.page + 1;
    this.paginatorChangeFlag = true;
    this.getSearchResult(this.queryString);
    if (!this.isMobileView) {
      this.matchDisplay = [];
    }
  }
}
