import { SocialAuthService } from "@abacritt/angularx-social-login";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { TokenStorageService } from "src/app/auth/token-storage.service";
import { MessageServiceService } from "src/app/message.service";
import { NotificationsService } from "src/app/services/notifications.service";
import { ProfileService } from "src/app/services/profile.service";

@Component({
  selector: "app-user-navigation",
  templateUrl: "./user-navigation.component.html",
  styleUrls: ["./user-navigation.component.css"],
})
export class UserNavigationComponent implements OnInit, OnDestroy {
  item: any[];
  userName: string;
  userImage: string;
  userCity: string;
  logoUrl: string;
  logoutFlag = false;
  currentUrl: string;
  notificationsCount: number;
  messagesCount: number;
  private notificationSubscription: Subscription;
  private messageSubscription: Subscription;
  private otpComponentDestroyedSubscription: Subscription;
  private profileImageChangedSubscription: Subscription;

  @ViewChild("navbar") navbar: ElementRef;

  constructor(
    private router: Router,
    private Jarvis: AuthService,
    private messageService: MessageServiceService,
    private tokenStorage: TokenStorageService,
    private notificationService: NotificationsService,
    private renderer: Renderer2,
    private profileService: ProfileService,
    private socialAuthService: SocialAuthService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentUrl = this.router.url;

        if (
          this.currentUrl.includes("/user/my-matches") ||
          this.currentUrl.includes("/user/wishlist") ||
          this.currentUrl.includes("/user/chat/panel") ||
          this.currentUrl == "/user/site/user-view" ||
          this.currentUrl == "/user/plans"
        ) {
          this.logoUrl = "assets/images/shia_spouse_black.png";
        } else {
          this.logoUrl = "assets/images/shia_spouse.png";
        }
      });
  }

  ngOnInit(): void {
    this.otpComponentDestroyedSubscription =
      this.profileService.otpComponentDestroyed$.subscribe(() => {
        this.getOwnProfileData();
      });

    this.profileImageChangedSubscription =
      this.profileService.profileImageChanged$.subscribe(() => {
        this.getOwnProfileData();
      });
    this.getOwnProfileData();
  }

  ngAfterViewInit(): void {
    this.notificationInit();
  }

  private updateSpans() {
    if (this.navbar) {
      const unorderedList = this.navbar.nativeElement.querySelector(
        ".p-menubar-root-list"
      );
      const messageLi = unorderedList.querySelector(
        ".messages-link .p-menuitem-link .p-menuitem-text"
      );
      const notificationLi = unorderedList.querySelector(
        ".notifications-link .p-menuitem-link .p-menuitem-text"
      );

      if (messageLi && notificationLi) {
        // Check if spans are already created
        const messageSpan = messageLi.querySelector("span");
        const notificationSpan = notificationLi.querySelector("span");
        if (!messageSpan && this.messagesCount != 0) {
          // If the span doesn't exist, create and append it
          const newMessageSpan = this.renderer.createElement("span");
          const newMessageSpanText = this.renderer.createText(
            this.messagesCount > 9 ? "9+" : `${this.messagesCount}`
          );
          this.renderer.appendChild(newMessageSpan, newMessageSpanText);
          this.renderer.appendChild(messageLi, newMessageSpan);
        } else {
          if (this.messagesCount != 0) {
            messageSpan.textContent =
              this.messagesCount > 9 ? "9+" : `${this.messagesCount}`;
          } else {
            if (messageLi.contains(messageSpan)) {
              messageLi.removeChild(messageSpan);
            }
          }
        }

        if (!notificationSpan && this.notificationsCount != 0) {
          // If the span doesn't exist, create and append it
          const newNotificationSpan = this.renderer.createElement("span");
          const newNotificationSpanText = this.renderer.createText(
            this.notificationsCount > 9 ? "9+" : `${this.notificationsCount}`
          );
          this.renderer.appendChild(
            newNotificationSpan,
            newNotificationSpanText
          );
          this.renderer.appendChild(notificationLi, newNotificationSpan);
        } else {
          if (this.notificationsCount != 0) {
            // Update existing span text if count has changed

            notificationSpan.textContent =
              this.notificationsCount > 9 ? "9+" : `${this.notificationsCount}`;
          } else {
            if (notificationLi.contains(notificationSpan)) {
              notificationLi.removeChild(notificationSpan);
            }
          }
        }
      }
    }
  }

  notificationInit() {
    // subscribe for notification count
    this.notificationSubscription =
      this.notificationService.notificationsCount$.subscribe((count) => {
        this.notificationsCount = count;
        this.updateSpans();
      });
    this.messageSubscription =
      this.notificationService.messagesCount$.subscribe((count) => {
        this.messagesCount = count;
        this.updateSpans();
      });
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.notificationSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.otpComponentDestroyedSubscription.unsubscribe();
    this.profileImageChangedSubscription.unsubscribe();
  }

  routeTo(path) {
    this.router.navigateByUrl(path);
  }

  logout() {
    this.Jarvis.logout().subscribe(
      (data) => this.handleLogoutData(data),
      (error) => this.handleError(error)
    );
  }
  handleLogoutData(data) {
    this.tokenStorage.signOut();
    this.router.navigateByUrl("/");
    this.messageService.addMessages("success", "Success", data.data[0]);
    if (this.socialAuthService) {
      if (this.socialAuthService.authState) {
        this.socialAuthService
          .signOut()
          .then(() => { })
          .catch((error) => { });
      } else {
      }
    }
  }

  getOwnProfileData() {
    this.Jarvis.getProfileData().subscribe(
      (data) => this.handleGetOwnProfileData(data),
      (error) => this.handleError(error)
    );
  }

  handleGetOwnProfileData(data) {
    this.userName = "";
    this.userImage = "";
    this.userCity = "";
    let userData = data.data.user;
    let profileData = data.data?.profile;

    this.userName = profileData?.nickname;
    this.userImage = userData.profile_file;
    this.userCity = userData.city ? userData.city : "Not Defined";
    let userHandle = data.data.user.id;
    this.item = [
      {
        label: `
          <div class="navbar_menu-info">
            <div class="menu-info_image">
              <img src="${this.userImage}" alt="" />
            </div>
            <div class="menu-info_description">
              <h4>${this.userName}</h4>
              <p>lives in ${this.userCity}</p>
            </div>
          </div>`,
        escape: false,
        styleClass: "img-p-burger",
      },
      {
        label: "My Profile",
        routerLink: [`/user/${userHandle}`],
        styleClass: "sub-profile-burger",
      },
      {
        label: "Home",
        // icon: 'pi pi-plus',
        routerLink: ["./"],
      },
      {
        label: "About Us",
        // icon: 'pi pi-plus',
        routerLink: ["./site/about"],
        styleClass: "about-us",
      },
      {
        label: "Events",
        // icon: 'pi pi-plus',
        // routerLink: ["./events"],
        styleClass: "events",

        items: [
          // {
          //   label: "New Events",
          //   routerLink: ["./events"],
          //   queryParams: { tab: "new" },
          //   styleClass: "new-event",
          // },
          {
            label: "Upcoming Events",
            routerLink: ["./events"],
            queryParams: { tab: "upcoming" },
            styleClass: "upcoming-event",
          },
          {
            label: "Past Events",
            routerLink: ["./events"],
            queryParams: { tab: "past" },
            styleClass: "past-event",
          },
        ],
      },
      {
        label: "Books",
        // icon: 'pi pi-plus',
        routerLink: ["./site/books"],
        styleClass: "books-btn",
      },
      {
        label: "Videos",
        // icon: 'pi pi-plus',
        routerLink: ["./site/video"],
        styleClass: "videos-btn",
      },
      {
        label: "My Matches",
        // icon: 'pi pi-plus',
        routerLink: ["./my-matches"],
        styleClass: "my-matches",
      },
      {
        label: "My Shortlist",
        // icon: 'pi pi-plus',
        routerLink: ["./wishlist"],
      },
      {
        label: "Messages",
        // icon: "pi pi-circle-fill",
        routerLink: ["./chat/panel"],
        styleClass: "messages-link",
      },
      {
        label: "Notifications",
        // icon: "pi pi-circle-fill",
        routerLink: ["./site/user-view"],
        styleClass: "notifications-link",
      },
      {
        label: "Subscription",
        // icon: 'pi pi-plus',
        routerLink: ["./plans"],
        styleClass: "subs-btn",
      },
      {
        label: `<img style.border-radius="50px;" src="${this.userImage}" alt="no image"/>`,
        escape: false,
        // command: () => {
        //   this.routeTo(`./u/${this.userName}`);
        // },
        styleClass: "main-img",
        items: [
          {
            label: `
            <div class="navbar_menu-info">
            <div class="menu-info_image">
              <img src="${this.userImage}" alt="" />
            </div>
            <div class="menu-info_description">
              <h4>${this.userName}</h4>
              <p>lives in ${this.userCity}</p>
            </div>
          </div>`,
            escape: false,
            styleClass: "img-p-p",
          },
          {
            label: "My Profile",
            routerLink: [`/user/${userHandle}`],
            styleClass: "sub-profile",
          },
          {
            label: "Settings",
            styleClass: "settings",
            command: () => {
              this.routeTo(`user/settings`);
            },
          },
          {
            label: "Change Password",
            styleClass: "change-pas",
            command: () => {
              this.routeTo(`user/user-changepassword`);
            },
          },
          {
            label: "Logout",
            styleClass: "log-out",
            command: () => {
              this.logout();
            },
          },
        ],
      },
      {
        label: "Settings",
        styleClass: "burger-settings",
        command: () => {
          this.routeTo(`user/settings`);
        },
      },
      {
        label: "Change Password",
        styleClass: "burger-change-pas",
        command: () => {
          this.routeTo(`user/user-changepassword`);
        },
      },
      {
        label: "Logout",
        styleClass: "burger-log-out",
        command: () => {
          this.logout();
        },
      },
    ];
  }

  handleError(error) { }
}
