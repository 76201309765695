<!-- <div class="container login-page">
  <div class="row justify-content-center h-100">
    <div class="col-md-6 align-self-center">
      <div class="card login-card">
        <div class="card-body">
          <h3 class="card-title text-center">Forgot Password</h3>

          <div class="card-text">
            <div class="col-md-12" *ngFor="let box of this.inputInfo">
              <app-text-box
                [inputInfo]="box"
                [changeFlag]="this.changeFlag"
                *ngIf="box.type == 'text'"
                (updateData)="updateData($event, box.label)"
                (submit)="onSubmit()"
              ></app-text-box>
            </div>

            <div class="col-md-6 align-self-center">
              <app-button
                class="text-center"
                [label]="'Submit'"
                (button)="onSubmit()"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container-fluid login-page">
  <div class="row justify-content-center h-100">
    <div class="col-md-6 col-lg-6 align-self-center">
      <h3>Forgot Password</h3>
      <div class="col-md-6 col-lg-6" *ngFor="let box of this.inputInfo">
        <app-text-box
          [inputInfo]="box"
          [changeFlag]="this.changeFlag"
          *ngIf="box.type == 'text'"
          (updateData)="updateData($event, box.label)"
          (preSubmit)="preSubmit()"
          (submit)="onSubmit()"
        ></app-text-box>
      </div>
      <div class="col-md-6 text-center">
        <!-- <app-link-button
          (button)="forgotPassword()"
          [label]="'Forgot Password'"
        ></app-link-button> -->
      </div>
      <div class="align-self-center">
        <div class="col-md-6 col-lg-6 center align-self-center">
          <app-button
            [label]="'Submit'"
            (button)="preSubmit()"
            [type]="'a'"
            [color]="'#ffffff'"
            [bgColor]="'#000000'"
          ></app-button>
        </div>

        <div class="col-md-6 col-lg-6 text-center">
          <!-- <app-link-button
            (button)="signUp()"
            [label]="'Don’t have an account yet?'"
          ></app-link-button> -->
        </div>
      </div>
    </div>
  </div>
</div>
