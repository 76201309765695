import { RouterModule } from "@angular/router";
import { CustomMaterialModule } from "./core/material.module";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule } from "@auth0/angular-jwt";
import { IconsProviderModule } from "./icons-provider.module";
import { CommonModule, registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { LoginComponent } from "./modules/shared/sharedPages/login/login.component";
import { ButtonComponent } from "./component/htmlComponents/button/button.component";
import { TextBoxComponent } from "./component/htmlComponents/text-box/text-box.component";
import { CapitalizationPipe } from "src/Pipes/camelCaseToCapitalization.pipe";
import { SignUpComponent } from "./modules/shared/sharedPages/sign-up/sign-up.component";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LinkButtonComponent } from "./component/htmlComponents/link-button/link-button.component";
import { ResetPasswordComponent } from "./modules/shared/sharedPages/reset-password/reset-password.component";
import { SidePanelComponent } from "./component/htmlComponents/side-panel/side-panel.component";
import { ForgotPasswordComponent } from "./modules/shared/sharedPages/forgot-password/forgot-password.component";
import { MessageService } from "primeng/api";
import { ConfirmationService } from "primeng/api";
import { SidenavModule } from "angular-ng-sidenav";
import { DropDownComponent } from "./component/htmlComponents/drop-down/drop-down.component";
import { TextAreaComponent } from "./component/htmlComponents/text-area/text-area.component";
import { MultiSelectComponent } from "./component/htmlComponents/multi-select/multi-select.component";
import { SplitButtonComponent } from "./component/htmlComponents/split-button/split-button.component";
import { SelectButtonComponent } from "./component/htmlComponents/select-button/select-button.component";
import { CheckBoxComponent } from "./component/htmlComponents/check-box/check-box.component";
import { TextIconButtonComponent } from "./component/htmlComponents/text-icon-button/text-icon-button.component";
import { InputTableComponent } from "./component/htmlComponents/input-table/input-table.component";
import { InputChipsSpecialComponent } from "./component/htmlComponents/input-chips-special/input-chips-special.component";
import { TableComponent } from "./component/htmlComponents/table/table.component";
import { RadioButtonComponent } from "./component/htmlComponents/radio-button/radio-button.component";
import { LineChartComponent } from "./component/htmlComponents/line-chart/line-chart.component";
import { DatePipe } from "@angular/common";
import { CalenderComponent } from "./component/htmlComponents/calender/calender.component";
import { DialogComponent } from "./component/htmlComponents/dialog/dialog.component";
import { BooksComponent } from "./modules/pages/books/books.component";
import { NavbarComponent } from "./component/htmlComponents/navbar/navbar.component";
import { AboutusComponent } from "./modules/pages/aboutus/aboutus.component";
import { CarouselComponent } from "./component/htmlComponents/carousel/carousel.component";
import { HeaderComponent } from "./modules/pages/header/header.component";
import { NavigationsComponent } from "./modules/pages/navigations/navigations.component";
import { StoriesComponent } from "./modules/pages/stories/stories.component";
import { CardComponent } from "./component/htmlComponents/card/card.component";
import { VidoesComponent } from "./modules/pages/vidoes/vidoes.component";
import { EventsComponent } from "./modules/pages/events/events.component";
import { TabMenuModule } from "primeng/tabmenu";
import { SafePipe } from "../Pipes/safe.pipe";
import { TabMenuComponent } from "./component/htmlComponents/tab-menu/tab-menu.component";
import { EventComponent } from "./modules/pages/event/event.component";
import { CarouselModule } from "primeng/carousel";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { MenuModule } from "primeng/menu";
import { GalleriaModule } from "primeng/galleria";

import { FooterComponent } from "./modules/pages/footer/footer.component";
import { SubscriptionComponent } from "./modules/pages/subscription/subscription.component";
import { HomeComponent } from "./modules/pages/home/home.component";
import { PanelMenuComponent } from "./component/htmlComponents/panel-menu/panel-menu.component";
import { UserNavigationComponent } from "./modules/pages/user-navigation/user-navigation.component";
import { MyMatchesComponent } from "./modules/pages/my-matches/my-matches.component";
import { MyShortlistComponent } from "./modules/pages/my-shortlist/my-shortlist.component";
import { MessagesComponent } from "./modules/pages/messages/messages.component";
import { NotificationComponent } from "./modules/pages/notification/notification.component";
import { NotificationCardComponent } from "./component/htmlComponents/notification-card/notification-card.component";
import { FileUploadComponent } from "./component/htmlComponents/file-upload/file-upload.component";
import { ProfileCreateComponent } from "./modules/pages/profile-create/profile-create.component";
import { MenuComponent } from "./component/htmlComponents/menu/menu.component";
import { RatingComponent } from "./component/htmlComponents/rating/rating.component";
import { ProfileComponent } from "./modules/pages/profile/profile.component";
import { SearchResultComponent } from "./modules/pages/search-result/search-result.component";
import { GalleriaComponent } from "./component/htmlComponents/galleria/galleria.component";
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from "ng-recaptcha";
import { environment } from "../environments/environment";
import { OtpComponent } from "./modules/pages/otp/otp.component";
import { EditProfileComponent } from "./modules/pages/edit-profile/edit-profile.component";
import { RecoverComponent } from "./modules/pages/recover/recover.component";
import { FilterComponent } from "./modules/shared/filter/filter.component";
import { ChipsComponent } from "./component/htmlComponents/chips/chips.component";
import { PaginationComponent } from "./component/htmlComponents/pagination/pagination.component";
import { EventRegistrationComponent } from "./modules/pages/event-registration/event-registration.component";
// for social logins
import { TabViewModule } from 'primeng/tabview';

// for stepper

import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "@abacritt/angularx-social-login";
import { GoogleSigninComponent } from "./component/htmlComponents/google-signin/google-signin.component";
import { SettingsComponent } from "./modules/pages/settings/settings.component";
import { DeleteAccountComponent } from "./modules/pages/settings/delete-account/delete-account.component";
import { DeactivateAccountComponent } from "./modules/pages/settings/deactivate-account/deactivate-account.component";
import { RegistrationCardComponent } from './modules/pages/event-registration/registration-card/registration-card.component';
import { TermsOfUseComponent } from './modules/pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './modules/pages/privacy-policy/privacy-policy.component';
import { FaqComponent } from './modules/pages/faq/faq.component';
import { CookieConsentComponent } from "./cookie-consent/cookie-consent.component";
import { DummyCookieConsentService } from "./dummy-cookie-consent.service";
import { AccordionModule } from "primeng/accordion";
import { AdvancedSearchComponent } from "./advanced-search/advanced-search.component";
import { UserProfileViewComponent } from "./user-profile-view/user-profile-view.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { httpInterceptorProviders } from "./auth/auth-interceptor";

registerLocaleData(en);
/**
 * This function sends the request to get token.
 * @returns
 */
export function tokenGetter() {
  return localStorage.getItem("access_token");
}
/**
 *
 */
// export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ButtonComponent,
    TextBoxComponent,
    CapitalizationPipe,
    SignUpComponent,
    LinkButtonComponent,
    ResetPasswordComponent,
    SidePanelComponent,
    ForgotPasswordComponent,
    DropDownComponent,
    TextAreaComponent,
    MultiSelectComponent,
    SplitButtonComponent,
    SelectButtonComponent,
    CheckBoxComponent,
    TextIconButtonComponent,
    InputTableComponent,
    InputChipsSpecialComponent,
    TableComponent,
    RadioButtonComponent,
    LineChartComponent,
    CalenderComponent,
    DialogComponent,
    BooksComponent,
    HeaderComponent,
    NavigationsComponent,
    NavbarComponent,
    AboutusComponent,
    CarouselComponent,
    StoriesComponent,
    CardComponent,
    VidoesComponent,
    EventsComponent,
    SafePipe,
    TabMenuComponent,
    EventComponent,
    FooterComponent,
    SubscriptionComponent,
    HomeComponent,
    ProfileCreateComponent,
    FileUploadComponent,
    PanelMenuComponent,
    UserNavigationComponent,
    MyMatchesComponent,
    MyShortlistComponent,
    MessagesComponent,
    NotificationComponent,
    ProfileComponent,
    MenuComponent,
    RatingComponent,
    NotificationCardComponent,
    SearchResultComponent,
    GalleriaComponent,
    OtpComponent,
    EditProfileComponent,
    RecoverComponent,
    FilterComponent,
    ChipsComponent,
    PaginationComponent,
    EventRegistrationComponent,
    GoogleSigninComponent,
    SettingsComponent,
    DeleteAccountComponent,
    DeactivateAccountComponent,
    RegistrationCardComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    CookieConsentComponent,
    AdvancedSearchComponent,
    UserProfileViewComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    FlexLayoutModule,
    BrowserAnimationsModule,
    GooglePlaceModule,
    LayoutModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    SidenavModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TabViewModule,
    NgxIntlTelInputModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: ["localhost:8080"],
        blacklistedRoutes: ["localhost:8080/api/auth/signin"],
      },
    }),
    HttpClientModule,
    AppRoutingModule,
    IconsProviderModule,

    // NgbModule,
    NgxMaskModule.forRoot(),
    NgbModule,
    TabMenuModule,
    CarouselModule,
    ScrollPanelModule,
    MenuModule,
    GalleriaModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    AccordionModule,
  ],
  exports: [CommonModule],
  providers: [
    MessageService,
    ConfirmationService,
    DatePipe,
    DummyCookieConsentService,
    httpInterceptorProviders,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.captchaSiteKey,
      } as RecaptchaSettings,
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientId),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
