export class ProfilePageInfo {
  profileHeroImage: string;
  profileImage: string;
  name: string;
  profession: string;
  address: string;
  status: string;
  phone: string;
  description: string;
  facebook_link: string;
  instagram_link: string;
  nickname:string;
  city: string;
  country: string;
}
