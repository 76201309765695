import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StoryPageInfo } from "src/app/models/story-page-info";
import { BookService } from "src/app/services/book.service";

@Component({
  selector: "app-stories",
  templateUrl: "./stories.component.html",
  styleUrls: ["./stories.component.css"],
})
export class StoriesComponent implements OnInit {
  storyPageInfo: StoryPageInfo = {
    pageHeroImage: "books-hero-image.jpg",
    title: "Books & Articles",
    description:
      "Explore books & articles for practical advice on marriage and relationships.",
    article: {
      title: "",
      description: "",
    },
    latestArticles: {
      title: "Latest Articles",
      articles: [],
    },
  };
  id: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private bookService: BookService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params["id"];
      this.bookService.getArticle(this.id).subscribe(
        (data) => this.handleArticleData(data),
        (error) => this.handleError(error)
      );
    });
  }

  handleArticleData(data) {
    this.storyPageInfo.article.title = data.title;
    this.storyPageInfo.article.description = data.description;

    this.bookService.getAllArticles().subscribe(
      (data) => this.handleArticlesData(data),
      (error) => this.handleError(error)
    );
  }

  handleArticlesData(data) {
    this.storyPageInfo.latestArticles.articles = data;
  }

  onCardClick(data) {
    if (data.cardName == "storyCard") {
      let id = data.id;
      this.router.navigate(["/article"], {
        queryParams: { id },
      });
      window.scrollTo(0, 0);
    }
  }

  handleError(error) {}
}
