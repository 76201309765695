<div class="footer">
  <div class="container-fluid footer-inner">
    <div class="row footer-wrapper">
      <div class="col-md-5 footer-wrapper-first">
        <div class="footer-logo">
          <img src="./assets/images/{{ footerPageInfo.logoPath }}" alt="" />
        </div>
        <div class="footer-para">
          <p>{{ footerPageInfo.para }}</p>
        </div>
        <div class="footer-media">
          <div class="footer-media-item" *ngFor="let item of footerPageInfo.mediaItems"
            [class.disableLink]="item.disable">
            <a [href]="item.link" [disable]="item.disable" target="_blank">
              <img class="{{item.styleClass}}" src="./assets/images/{{ item.imgPath }}" alt="{{ item.label }}" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="row mt-6 mb-4 footer-links-main">

          <div class="footer-company col-md-3">
            <div class="company-title">
              <h4>{{ footerPageInfo.company.title }}</h4>
            </div>
            <div class="company-item-main">
              <div class="company-items" *ngFor="let item of footerPageInfo.company.navigationItems"
                [class.disableLink]="item.disable">
                <a [disable]="item.disable" routerLink="{{ item.link }}">{{ item.label }}</a>
              </div>
            </div>
          </div>
          <div class="footer-help col-md-3">
            <div class="help-title">
              <h4>{{ footerPageInfo.needHelp.title }}</h4>
            </div>
            <div class="help-item-main">
              <div class="help-items" *ngFor="let item of footerPageInfo.needHelp.navigationItems"
                [class.disableLink]="item.disable">
                <a [disable]="item.disable" [routerLink]="item.link">{{ item.label }}</a>
              </div>
            </div>
          </div>
          <div class="footer-privacy col-md-3">
            <div class="privacy-title">
              <h4>{{ footerPageInfo.privacy.title }}</h4>
            </div>
            <div class="privacy-item-main">
              <div class="privacy-items" *ngFor="let item of footerPageInfo.privacy.navigationItems">
                <a [routerLink]="item.link">{{ item.label }}</a>
              </div>
            </div>
          </div>
          <div class="footer-more col-md-3">
            <div class="more-title">
              <h4>{{ footerPageInfo.more.title }}</h4>
            </div>
            <div class="more-item-main">
              <div class="more-items" *ngFor="let item of footerPageInfo.more.navigationItems">
                <a *ngIf="item.label !== 'Shariah Board'" [routerLink]="item.link">{{ item.label }}</a>
                <a *ngIf="item.label == 'Shariah Board'" href="{{ item.link }}" target="_blank">{{ item.label }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <h4>{{ footerPageInfo.copyright }}</h4>
  </div>
</div>