<div class="faq">
  <div class="hero">
    <div class="hero-content">
      <div class="hero-icon">
        <i class="pi pi-list"></i>
      </div>
      <div class="hero-pageTitle">
        <h2>FAQ</h2>
      </div>
    </div>
  </div>

 <!-- make faq section into accordion  -->
 <div class="faq-container">
<p-accordion>
  <p-accordionTab header="Q1. How Do I Sign Up?">
    <p>
      A. It is simple. Click on 'Sign Up' icon at the top of our
      website, answer a few questions and verify your email or phone
      number and that's it. As a registered user, you will have access
      to all the features of our website except chat function.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q2. Are ShiaSpouse Members real and genuine people?">
    <p>
      A. While anyone can sign up to ShiaSpouse, our profile rating
      system, featuring Alim Reference and ID verification ensure that
      our members are credible. Our team works hard to ensure that
      only genuine profiles remain on the site. As a tip, look for star
      rating of a profile. The more number of stars, the higher the
      trust level of a user would be. If you suspect that a user is not
      genuine you can report it to our admin team at
      <a href="mailto:info@shiaspouse.org">info&#64;shiaspouse.org</a>
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q3. Is Shia Spouse website safe and secure to use?">
    <p>
      A. ShiaSpouse website has been designed considering GDPR
      requirements and customer ease at the heart, we have ensured that
      the whole end to end user journey takes into account the required
      security measures, from the selection of our web development firm
      to our data storage. Please read our Privacy Policy and T&amp;C’s
      for further details.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q4. How old do I have to be to Sign Up?">
    <p>
      A. All users must be above the age of 16 to register. Users under
      the age of 16 won’t be able to register.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q5. Can I change my Password?">
    <p>
      A. We understand that passwords get forgotten, so you have the
      option for forgotten password at the time of login.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q6. I’ve found a Partner, how Do I Delete My Account Now?">
    <p>
      A. Congratulations! You can login, go to My profile &gt; Settings
      and delete your account. Please note that any existing
      subscriptions will be cancelled once the account has been deleted.
      You can also temporarily suspend your account by 'de-activating'
      your account instead of deleting it. De-activated account can be
      activated in 60 days without loss of your profile data.
      De-activated accounts do not appear in search results.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q7. I’ve uploaded my Photo but it is not appearing on my profile.">
    <p>
      A. You can view your photo under
      <a href="https://jupiter.toxsl.in/matrimonial/user/photo">My Profile &gt; My Photo</a>.
      If you recently uploaded your photo, it needs to be approved by
      our moderator. You will get a notification when your photo is
      approved. Your photo will be public when it is approved. Only
      registered members will see your photo. If you don’t want all
      members to see your photo, you can hide your photo and make it
      visible only to those who request and you approve their request.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q8. How do I protect my photo so that it is not publicly visible.">
    <p>
      A. Once your photo is verified, it will appear as your profile
      photo. You can hide your photo through My Profile menu. Private
      images will be visible to other users if you accept their request.
      We allow only registered users to send request to view your photo.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q9. What is Verification Status or Star Rating?">
    <p>
      A. Every registered member of our website gets star rating based
      on completion and verification of his/her profile. The higher the
      number of stars, the more trust and verification level it will
      have. Members with more than 3 stars are likely to get more
      responses.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q10. How do I increase my Verification Status or Star Rating?">
    <p>
      A. There are a number of factors which contribute towards star
      rating or verification status. You can increase number of stars
      by:
    </p>
    <ul>
      <li>Completing your profile (more than 90% fields completed = 1 star)</li>
      <li>Profile photo is selected after verification (1 star)</li>
      <li>Verify your mobile number (successful verification = 1 star)</li>
      <li>Alim Reference (successful verification = 1 star)</li>
      <li>ID verification (successful verification = 1 star)</li>
      <li>Purchase subscription (1 star)</li>
    </ul>
    <p>
      A user can get maximum of five stars which show that his/her
      profile is fully trustworthy. We recommend you to have more than 3
      stars to get more responses from potential Shia Spouse matches.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q11. Why is my mobile or contact number required?">
    <p>
      A. We do not share your contact or email with other users. Your
      contact number is required for phone verification which is an
      additional way of verifying our identity. You get a star
      (verification level) when you verify your phone.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q12. How do I verify my mobile number?">
    <p>
      A. You can verify your phone number by receiving a verification
      code from our website (under My Profile) and entering the code.
      Phone verification will add additional trust to your profile by
      increasing star rating. You can also complete this verification if
      mobile OTP is entered at the time of registration.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q13. What is religious scholar (Alim) reference for?">
    <p>
      A. This is a unique feature of our website. You may provide name
      and contact details of a religious scholar (Alim) or Moulana to
      provide your reference. Our administrator will contact him/her to
      verify your identity and details. Upon successful verification,
      you get a Star on your profile which will increase trust level of
      your profile. It is an optional feature.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q14. Why do I need to provide ID documents?">
    <p>
      A. It is an optional feature to verify your identity and increase
      trust level of your profile. Upon successful verification of ID
      document, you get a Star on your profile. At ShiaSpouse, we take
      care of authenticity of our members to provide a quality service.
      This is one of several features to minimize registration of fake
      profiles.
    </p>
  </p-accordionTab>

  <p-accordionTab header="Q15. I don't have an ID document, how do I verify my identity?">
    <p>
      A. You can upload a short video of your face stating that this
      video is for ShiaSpouse. It will be reviewed by the admin and
      if it matches with your profile picture, you get an extra star.
      This is another way of verifying your identity.
    </p>
  </p-accordionTab>

  <p-accordion>
    <p-accordionTab header="Q16. What is video option for?">
      <ng-container>
        A. This is an optional feature. You can upload a short video of your face to introduce yourself. It will be reviewed by our admin team to match with your profile photo. A successful video upload with matching profile photo increases trust level of your profile.
      </ng-container>
    </p-accordionTab>
  
    <p-accordionTab header="Q17. What is criteria for approval of my Profile Photo?">
      <ng-container>
        A. At ShiaSpouse, we implement the following criteria to approve profile photos of our users. Photos which are deemed not following these criteria may be rejected by our administrators.
        <ol>
          <li>A genuine profile photo is uploaded.</li>
          <li>A person can only upload his/her own photo or has consent from the person whom profile is being created.</li>
          <li>Photos of children are not allowed.</li>
          <li>Photos showing nudity or vulgarity are not allowed.</li>
          <li>Photos showing face and body (appropriately dressed) will be approved.</li>
          <li>Photos of celebrities, animals, scenery, wallpapers, posters, movies, etc. are not allowed.</li>
          <li>Shia Spouse Administrator may reject a photo which is deemed against Islamic dress code or showing private body parts off. It is advised to upload a moderate photo.</li>
          <li>You may crop your profile image to show part of your face, etc. once a photo has been approved.</li>
          <li>Profile photo is visible to others. You can, at any time, set your photo to private to make it visible only to the persons you allow.</li>
          <li>You can upload multiple photos to your profile.</li>
        </ol>
      </ng-container>
    </p-accordionTab>
  
    <p-accordionTab header="Q18. How do I use Promo Code for subscription?">
      <ng-container>
        A. You may get a Promo Code as we offer such discounts from time to time. You can use this promo code to reduce the basic subscription fee. Go to My Subscription and at the bottom, there is an option to apply your Promo Code.
      </ng-container>
    </p-accordionTab>
  
    <p-accordionTab header="Q19. I have signed up but my account is not active?">
      <ng-container>
        A. Email verification or phone verification is required to complete the sign-up process. After you have submitted your details, enter the OTP code sent to you by email and text message. This verification is required to minimize fake accounts on our website. If you don't receive the email, check your junk mails or spam folder. Otherwise, you can contact us for further assistance.
      </ng-container>
    </p-accordionTab>
  
    <p-accordionTab header="Q20. How can users in Pakistan subscribe as PayPal is currently not available?">
      <ng-container>
        A. Please use the JAZZ Cash transfer facility as detailed below:
        <br><strong>Details of our account:</strong><br>
        Name: Ali Abbas Shah<br>
        Contact Number: 03002614455
        <br><br>
        You can pick one of the following subscription plans:
        <ul>
          <li>Premium subscription: PKR 3000 for 1 month</li>
          <li>PKR 6000 for 3 months</li>
          <li>PKR 9000 for 6 months</li>
        </ul>
        <strong>Important:</strong> When you have made a Jazz cash deposit, please email the following details to admin&#64;shiaspouse.org:
        <ol>
          <li>The sender mobile number</li>
          <li>The amount transferred</li>
          <li>The subscription plan (select from above)</li>
          <li>The email address registered on our website</li>
        </ol>
      </ng-container>
    </p-accordionTab>
  
    <p-accordionTab header="Q21. What is Premium Subscription?">
      <ng-container>
        A.Premium Subscription allows unlimited messages to any profile. You can connect up to 500 profile each month.
      </ng-container>
    </p-accordionTab>
  </p-accordion>
  
  

</p-accordion>
</div>
</div>
