<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
<div class="deactivate">
  <div class="deactivate-main">
    <h2 class="deactivate-title">Deactivate Account</h2>
    <div class="deactivate-input" *ngFor="let box of inputInfo">
      <app-radio-button
        [inputInfo]="box"
        (updateData)="updateData($event, box.modelName)"
        *ngIf="box.modelName === 'deactivate'"
        [changeFlag]="changeFlag"
        [data]="box.option"
        (preSubmit)="preSubmit()"
        (submit)="onSubmit()"
      ></app-radio-button>

      <div class="other-textArea" *ngIf="box.modelName == 'otherTextArea'">
        <ng-container *ngIf="this.deactivateInputInfo['deactivate'] == '2'">
          <app-text-area
            [inputInfo]="box"
            [changeFlag]="this.changeFlag"
            (updateData)="updateData($event)"
            *ngIf="box.modelName === 'otherTextArea'"
            [customValidator]="box.customValidator"
            (preSubmit)="preSubmit()"
            (submit)="onSubmit()"
            [boxShadow]="'rgb(0 0 0 / 12%) 0px 0px 12px'"
            [borderRadius]="'35px'"
            [width]="'100%'"
            [borderRadius]="'20px'"
          ></app-text-area>
        </ng-container>
      </div>
    </div>
    <div class=" deactivate-text">Deactivate accounts are deleted from our platform after 60 days. You may not receive any notification. You can activate your account before permanent deletion.</div>
    <app-button
      [label]="'Deactivate Account'"
      [padding]="'5px 0'"
      [color]="'#fff'"
      [type]="'login'"
      (button)="preSubmit()"
      [bgColor]="'var(--color-warning)'"
      [borderRadius]="'35px !important'"
      [fontSize]="'clamp(16px, 4vw, 22px)'"
      [maxWidth]="'400px'"
      [padding]="'1rem 2rem'"
    >
    </app-button>
  </div>
</div>
