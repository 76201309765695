<div class="event">
  <div class="hero">
    <img
      class="hero-image"
      [src]="eventPageInfo?.event?.cover ? eventPageInfo?.event?.cover : './assets/images/' + eventPageInfo.pageHeroImage"
      alt=""
    />
    <div class="hero-content">
      <div class="hero-pageTitle">
        <h2>{{ eventPageInfo.title }}</h2>
      </div>
      <div class="hero-pageDescription">
        <p>{{ eventPageInfo.description }}</p>
      </div>
    </div>
  </div>
  <section class="event-section container-fluid" >
    <div class=" left">

      <div class="event-section-title">
        <h3>{{ eventPageInfo.event.title }}</h3>
      </div>
      <div class="event-section-para" [innerHTML]="eventPageInfo.event.description">
      </div>
    </div>

      <div class=" right">
        
        <div class="p-card p-component" >
          <div class="p-card-body">
            
            <ng-container *ngIf="!isLoggedIn; else loggedInTemplate">
              <h2 class="p-card-title">Please login or sign up to register</h2>
              <p class="p-card-subtitle"></p>
              <a pButton type="button" label="Login" [routerLink]="['/login']" class="p-button-outlined" ></a>
              <a pButton type="button" label="Sign Up" [routerLink]="['/sign-up']" class="sign-up-btn"></a>
            </ng-container>
            <ng-template #loggedInTemplate>
              <h2 class="p-card-title">Click here to register the event</h2>
              <p class="p-card-subtitle"></p>
              <a pButton type="button" [label]="(isUserUk && isEventApplied?'View Event':'Register Event')" [routerLink]="['/user/event-registration']" [queryParams]="{ slug: eventPageInfo.event.slug }" class="p-button-outlined"></a>
            </ng-template>
            <p class="event-note"><Span>Note!</Span>If you are a relative, please enter the details of candidate in registration form.</p>
          </div>
        </div>
        
        
        
      </div>
    

  </section>
</div>
